// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import { HashLoader } from "react-spinners";
// import { SITEURL, BASEURL } from "../BASEURL";

// const GetUsers = () => {
// 	const [users, setUsers] = useState([]);
// 	const [loading, setLoading] = useState(true);

// 	// Function to fetch users from the API
// 	const fetchUsers = async () => {
// 		try {
// 			const response = await fetch(`${BASEURL}getUsersStore`);
// 			const result = await response.json();

// 			if (response.status === 200) {
// 				setUsers(result.data); // Store the fetched user data
// 				// Swal.fire("Success", result.message, "success");
// 			} else {
// 				Swal.fire(
// 					"Error",
// 					result.message || "Failed to fetch users",
// 					"error"
// 				);
// 			}
// 		} catch (error) {
// 			Swal.fire(
// 				"Error",
// 				"An error occurred while fetching users",
// 				"error"
// 			);
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	useEffect(() => {
// 		fetchUsers();
// 	}, []);

// 	return (
// 		<div className="intro-y box lg:mt-1">
// 			{/* <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
// 				<h2 className="font-medium text-base mr-auto">
// 					Users Who Opted Out from the Exercise Session
// 				</h2>
// 			</div> */}

// 			{loading ? (
// 				<HashLoader size={30} />
// 			) : (
// 				<div
// 					style={{
// 						border: "1px solid #FC9A30",
// 					}}
// 				>
// 					<div
// 						style={{
// 							backgroundColor: "#FC9A30",
// 							color: "#fff",
// 							padding: "5px 10px",
// 							textAlign: "start",
// 						}}
// 					>
// 						<h3>Users Who Opted Out from the Exercise Session
// 						</h3>
// 					</div>
// 					<div
// 						className="table-outlineBorder"
// 						style={{
// 							border: "1px solid #FC9A30",
// 						}}
// 					>
// 						<div className="employee-table-container">
// 							<table
// 								className="employee-table"
// 								style={{ width: "100%" }}
// 							>
// 								<thead>
// 									<tr>
// 										<th className="border px-4 py-2">Name</th>
// 										<th className="border px-4 py-2">Username</th>
// 										<th className="border px-4 py-2">Department</th>
// 										<th className="border px-4 py-2">Reason</th>
// 										<th className="border px-4 py-2">Time</th>
// 									</tr>
// 								</thead>
// 								<tbody>
// 									{users.length > 0 ? (
// 										users.map((user) => (
// 											<tr key={user.id}>
// 												<td className="border px-4 py-2">
// 													{user.name}
// 												</td>
// 												<td className="border px-4 py-2">
// 													{user.username}
// 												</td>
// 												<td className="border px-4 py-2">
// 													{user.dept}
// 												</td>
// 												<td
// 													className="border px-4 py-2"
// 													style={{
// 														whiteSpace: "pre-wrap",
// 														wordBreak: "break-word",
// 													}}
// 												>
// 													{user.reason}
// 												</td>
// 												<td className="border px-4 py-2">
// 													{new Date(user.created_at).toLocaleString("en-US", {
// 														year: "numeric",
// 														month: "2-digit",
// 														day: "2-digit",
// 														hour: "2-digit",
// 														minute: "2-digit",
// 														hour12: true,
// 													})}
// 												</td>
// 											</tr>
// 										))
// 									) : (
// 										<tr>
// 											<td
// 												colSpan="5"
// 												className="border px-4 py-2 text-center"
// 											>
// 												No users found
// 											</td>
// 										</tr>
// 									)}
// 								</tbody>
// 							</table>
// 						</div>
// 					</div>
// 				</div>
// 			)}
// 		</div>
// 	);
// };

// export default GetUsers;

// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import { HashLoader } from "react-spinners";
// import { SITEURL, BASEURL } from "../BASEURL";

// const GetUsers = () => {
// 	const [users, setUsers] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const [searchQuery, setSearchQuery] = useState("");

// 	// Function to fetch users from the API
// 	const fetchUsers = async () => {
// 		try {
// 			const response = await fetch(`${BASEURL}getUsersStore`);
// 			const result = await response.json();

// 			if (response.status === 200) {
// 				setUsers(result.data); // Store the fetched user data
// 				// Swal.fire("Success", result.message, "success");
// 			} else {
// 				Swal.fire(
// 					"Error",
// 					result.message || "Failed to fetch users",
// 					"error"
// 				);
// 			}
// 		} catch (error) {
// 			Swal.fire(
// 				"Error",
// 				"An error occurred while fetching users",
// 				"error"
// 			);
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	// Handle Search Query
// 	const handleSearch = (e) => {
// 		setSearchQuery(e.target.value);
// 	};

// 	// Filter users based on the search query
// 	const filteredUsers = users.filter((user) => {
// 		return (
// 			user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
// 			user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
// 			user.dept.toLowerCase().includes(searchQuery.toLowerCase()) ||
// 			user.reason.toLowerCase().includes(searchQuery.toLowerCase())
// 		);
// 	});

// 	useEffect(() => {
// 		fetchUsers();
// 	}, []);

// 	return (
// 		<div className="intro-y box lg:mt-1">
// 			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
// 				<div className="p-4">
// 					<input
// 						id="tabulator-html-filter-value"
// 						type="text"
// 						// onChange={filterdata}
// 						className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
// 						placeholder="Search..."
// 					/>
// 				</div>
// 				{/* Add Employee Button */}
// 				<div className="flex mt-5 sm:mt-0 sm:ml-auto">
// 					<button
// 						type="button"
// 						// onClick={() => handleOpen(null)}
// 						className="btn w-50 add-emp-btn"
// 						style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
// 					>
// 						Add Employee
// 					</button>

// 				</div>
// 			</div>
// 			{loading ? (
// 				<HashLoader
// 					color="#5755d9"
// 					size={30}
// 					style={{ position: "absolute", right: "50%" }}
// 				/>
// 			) : (
// 				<div
// 					style={{
// 						padding: "10px",
// 						borderTopLeftRadius: "10px",
// 						borderTopRightRadius: "10px",
// 					}}
// 				>
// 					<div
// 						style={{
// 							backgroundColor: "#FC9A30",
// 							color: "#fff",
// 							padding: "5px 10px",
// 							textAlign: "start",
// 							borderTopLeftRadius: "10px",
// 							borderTopRightRadius: "10px",
// 						}}
// 					>
// 						<h3 style={{ padding: "8px" }}>Users Who Opted Out from the Exercise Session</h3>
// 					</div>
// 					<div
// 						className="table-outlineBorder"
// 						style={{
// 							border: "1px solid #FC9A30",
// 						}}
// 					>
// 						<div className="employee-table-container">
// 							<table className="employee-table" style={{ width: "100%" }}>
// 								<thead>
// 									<tr>
// 										<th className="border px-4 py-2" style={{ width: "25%" }}>
// 											Name
// 										</th>
// 										<th className="border px-4 py-2" style={{ width: "25%" }}>
// 											Department
// 										</th>
// 										<th
// 											className="border px-4 py-2"
// 											style={{
// 												width: "20%", // Reduced width for Reason column
// 												whiteSpace: "pre-wrap",
// 												wordBreak: "break-word",
// 											}}
// 										>
// 											Reason
// 										</th>
// 										<th className="border px-4 py-2" style={{ width: "30%" }}>
// 											Info Date & Time
// 										</th>
// 									</tr>
// 								</thead>
// 								<tbody>
// 									{filteredUsers.length > 0 ? (
// 										filteredUsers.map((user) => (
// 											<tr key={user.id}>
// 												<td className="border px-4 py-2">{user.name}</td>
// 												<td className="border px-4 py-2">{user.dept}</td>
// 												<td
// 													className="border px-4 py-2"
// 													style={{
// 														whiteSpace: "pre-wrap",
// 														wordBreak: "break-word",
// 													}}
// 												>
// 													{user.reason}
// 												</td>
// 												<td className="border px-4 py-2">
// 													{new Date(user.created_at).toLocaleString("en-US", {
// 														year: "numeric",
// 														month: "2-digit",
// 														day: "2-digit",
// 														hour: "2-digit",
// 														minute: "2-digit",
// 														hour12: true,
// 													})}
// 												</td>
// 											</tr>
// 										))
// 									) : (
// 										<tr>
// 											<td
// 												colSpan="4"
// 												className="border px-4 py-2 text-center"
// 											>
// 												No users found
// 											</td>
// 										</tr>
// 									)}
// 								</tbody>
// 							</table>
// 						</div>
// 					</div>
// 				</div>

// 			)}
// 		</div>
// 	);
// };

// export default GetUsers;


import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { HashLoader } from "react-spinners";
import { BASEURL } from "../BASEURL";

const GetUsers = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState("");

	// Function to fetch users from the API
	const fetchUsers = async () => {
		try {
			const response = await fetch(`${BASEURL}getUsersStore`);
			const result = await response.json();

			if (response.status === 200 && Array.isArray(result)) {
				setUsers(result); // Store the fetched user data directly
			} else if (response.status === 200 && Array.isArray(result.data)) {
				setUsers(result.data); // Use `result.data` if the array is nested
			} else {
				Swal.fire("Error", "Unexpected data format", "error");
			}
		} catch (error) {
			Swal.fire("Error", "An error occurred while fetching users", "error");
		} finally {
			setLoading(false);
		}
	};

	// Handle Search Query
	const handleSearch = (e) => {
		setSearchQuery(e.target.value);
	};

	// Filter users based on the search query
	const filteredUsers = Array.isArray(users)
		? users.filter((user) => {
			return (
				user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
				user.dept.toLowerCase().includes(searchQuery.toLowerCase()) ||
				user.reason.toLowerCase().includes(searchQuery.toLowerCase())
			);
		})
		: []; // Default to empty array if `users` is not valid

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<div className="intro-y  lg:mt-1">
			<div className="flex items-center dark:border-darkmode-400">
				<div className="p-4">
					<input
						id="tabulator-html-filter-value"
						type="text"
						onChange={handleSearch}
						className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
						placeholder="Search..."
					/>
				</div>
				{/* Add Employee Button */}
				<div className="flex mt-5 sm:mt-0 sm:ml-auto">
					<button
						type="button"
						className="btn w-50 add-emp-btn"
						style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
					>
						Add Employee
					</button>
				</div>
			</div>
			{loading ? (
				<HashLoader
					color="#5755d9"
					size={30}
					style={{ position: "absolute", right: "50%" }}
				/>
			) : (
				<div className="mt-3"
					style={{
						// padding: "10px",
						borderTopLeftRadius: "10px",
						borderTopRightRadius: "10px",
					}}
				>
					<div
						style={{
							backgroundColor: "#FC9A30",
							color: "#fff",
							padding: "5px 10px",
							textAlign: "start",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
						}}
					>
						<h3 style={{ padding: "8px" }}>Users Who Opted Out from the Exercise Session</h3>
					</div>
					<div
						className="table-outlineBorder"
						style={{
							border: "1px solid #FC9A30",
						}}
					>
						<div className="employee-table-container">
							<table className="employee-table" style={{ width: "100%" }}>
								<thead>
									<tr>
										<th className="border px-4 py-2" style={{ width: "25%" }}>
											Name
										</th>
										<th className="border px-4 py-2" style={{ width: "25%" }}>
											Department
										</th>
										<th
											className="border px-4 py-2"
											style={{
												width: "20%",
												whiteSpace: "pre-wrap",
												wordBreak: "break-word",
											}}
										>
											Reason
										</th>
										<th className="border px-4 py-2" style={{ width: "30%" }}>
											Info Date & Time
										</th>
									</tr>
								</thead>
								<tbody>
									{filteredUsers.length > 0 ? (
										filteredUsers.map((user) => (
											<tr key={user.id}>
												<td className="border px-4 py-2">{user.name}</td>
												<td className="border px-4 py-2">{user.dept}</td>
												<td
													className="border px-4 py-2"
													style={{
														whiteSpace: "pre-wrap",
														wordBreak: "break-word",
													}}
												>
													{user.reason}
												</td>
												<td className="border px-4 py-2">
													{new Date(user.created_at).toLocaleString("en-US", {
														year: "numeric",
														month: "2-digit",
														day: "2-digit",
														hour: "2-digit",
														minute: "2-digit",
														hour12: true,
													})}
												</td>
											</tr>
										))
									) : (
										<tr>
											<td
												colSpan="4"
												className="border px-4 py-2 text-center"
											>
												No users found
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default GetUsers;
