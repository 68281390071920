import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function CommonModal({ isOpen, onClose, content, width = '400px' }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      className="modal-content"
      style={{ content: { maxWidth: width } }}
    >
      <div>

        {content}
      </div>
    </Modal>
  );
}

export default CommonModal;