import React, { useEffect, useRef, useState } from 'react';
import { BASEURL } from './BASEURL';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Timesheet.css';
import axios from 'axios';
// import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { MenuItem, Rating, Select, Stack } from '@mui/material';
import ReactSelect from "react-select";
import Swal from 'sweetalert2';


function Timesheet() {
	let user = JSON.parse(localStorage.getItem("isAuth"))?.uuid;
	let role = JSON.parse(localStorage.getItem("isAuth"))?.role;
	const calendarRef = useRef(null);
	const [selectedDate, setSelectedDate] = useState("");
	const [posdata, setposdata] = useState([]);
	const [pos, setpos] = useState(user);
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
	const today = new Date();
	const [projectsdata, setProjects] = useState([]);
	const [selectedUser, setSelectedUser] = useState()
	const [activities, setActivities] = useState([]);
	const [datewiseData, setDatewiseData] = useState([]);
	const [newlyTimesheets, setNewlyTimesheets] = useState([]);
	const [loading, setLoading] = useState(false); // Changed from single timesheet to an array
	const [submittedTimesheets, setSubmittedTimesheets] = useState([]); // Changed from single timesheet to an array
	const [timesheetData, setTimesheetData] = useState({
		project: '',
		activity: '',
		fromTime: '',
		toTime: '',
		description: '',
	});

	const [timesheetStatus, setTimesheetStatus] = useState()
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setTimesheetData((prev) => ({
			...prev,
			[name]: value,
		}));
		if (name === "project") {
			getActivities(value);
		}
	};

	const handleTimesheetDelete = async (id) => {
		try {
			const result = await Swal.fire({
				title: 'Delete',
				text: 'Are you sure you want to delete this task?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, cancel!',
				reverseButtons: true
			});

			if (result.isConfirmed) {
				const response = await fetch(BASEURL + 'api/delete_timesheet', {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({ id })
				});

				const res = await response.json();
				console.log(res);

				Swal.fire('Deleted!', 'The task has been deleted.', 'success');

				// Call functions to refresh data after deletion
				// this.getTimesheetData(this.state.selecteddate);
				// this.getData();
			}

		} catch (err) {
			console.error(err);
			Swal.fire('Error!', 'Something went wrong.', 'error');
		}
		finally {
			await getDatewiseTimeSheetData(selectedDate)
			await getTimeSheetData(selectedMonth, selectedYear, selectedUser)
		}
	};

	const handleDateClick = (info) => {
		if (info.date > today) return;

		// Convert to YYYY-MM-DD using local time
		const localDate = info.date.toLocaleDateString("en-CA"); // en-CA gives YYYY-MM-DD format

		setSelectedDate(localDate);
		getDatewiseTimeSheetData(localDate)
	};

	const renderDateCell = (arg) => {
		const date = new Date(arg.date);
		const dateStr = date.toLocaleDateString("en-CA"); // YYYY-MM-DD format in local time
		const matchingEntry = timesheetStatus?.find(item => item.date === dateStr);

		return (
			<div className="calendar-cell">
				<div>{arg.dayNumberText}</div>
				<div>
					{matchingEntry?.status == 1 ? (

						<button className="status-btn">Submitted</button>
					)
						: matchingEntry?.status == 2 &&
						(
							<button className="status-btn-saved">Saved</button>
						)
					}
				</div>
			</div>
		);
	};

	const getPosData = async (org = "") => {
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}

		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-getAllUsers`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				uuid: userdata?.companydata[0]?.uuid, // Use `org` or default to `selectedorg`
				usertype: userdata.type,
				// department: userdata?.department || "",
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setposdata(res.data);
					console.log(res.data, 'res.data');

					// let data4 = res.alluser ? res.alluser : [];
					// setalluser(data4);
				}
			})
			.catch((err) => console.log(err));
	};
	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < posdata.length; i++) {
		// console.log(posdata[i].uuid);
		data.push({
			value: posdata[i].uuid,
			label: posdata[i].name,
			username: posdata[i].username,
		});
	}
	const customizeDateCell = (info) => {

		const date = new Date(info.date);
		const dateStr = date.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD

		// Find matching timesheet entry for the date
		const matchingEntry = timesheetStatus?.find(item => item.date === dateStr);
		console.log(matchingEntry, 'matchingEntry');

		// Style based on conditions
		if (matchingEntry) {
			info.el.style.backgroundColor = "#e0f7fa"; // Light cyan for submitted timesheets
			info.el.style.border = "2px solid #00796b"; // Dark teal border
		} else if (date.getDay() === 0 || date.getDay() === 6) {
			// Highlight weekends (Sunday = 0, Saturday = 6)
		} else if (dateStr === selectedDate) {
			// Highlight selected date
			info.el.style.backgroundColor = "#ffc107"; // Yellow for selection
			info.el.style.border = "2px solid #ff9800";
		}

		// Add custom class (optional)
		info.el.classList.add("custom-calendar-cell");
	};

	const handleSave = () => {
		const { fromTime, toTime, description, project, activity } = timesheetData;
		if (!fromTime || !toTime || !description || !project || !activity) {
			alert("Please fill all the fields");
			return;
		}
		const totaltime = calculateTimeDifference(fromTime, toTime);
		const totalworking = new Date(totaltime * 60000).toISOString().substr(11, 5);

		const timesheet = {
			type: 1,
			id: "",
			date: selectedDate,
			project: project,
			activity_id: activity,
			starttime: fromTime,
			endtime: toTime,
			totaltime: `${Math.floor(totaltime / 60)}:${totaltime % 60}`,
			description: description,
			totalworking: totalworking,
		};
		setNewlyTimesheets(timesheet)
		// Ensure we use the latest state for saving
		setSubmittedTimesheets((prev) => {
			const updatedTimesheets = [...prev, timesheet];
			saveTimesheet(timesheet);
			return updatedTimesheets;
		});

		setTimesheetData({
			...timesheetData,
			fromTime: '',
			toTime: '',
			description: ''
		});
	};

	const saveTimesheet = async (timesheet) => {
		const userData = JSON.parse(localStorage.getItem("isAuth"));

		try {
			const payload = {
				userdata: [
					{
						user_id: userData?.id,
						company_uuid: userData?.companydata[0]?.uuid,
						uuid: userData?.uuid,
						name: userData?.name,
						username: userData?.username,
						department: userData?.department,
						type: userData?.type,
						reg_date: new Date()
					}
				],
				data: timesheet
			};

			setLoading(true);

			await fetch(BASEURL + 'api/add-timesheet', {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
					"Authorization": userData?.usertoken,
				},
				body: JSON.stringify(payload),
			})
				.then(response => response.json())
				.then(res => {
					console.log("Save Response:", res);
					if (res.success) {
						Swal.fire("Success", "Timesheet saved successfully!", "success");
					} else {
						Swal.fire("Error", "Failed to save timesheet.", "error");
					}
				})
				.catch(err => {
					console.error("Error saving timesheet:", err);
					Swal.fire("Error", "Something went wrong!", "error");
				});
		} finally {
			setLoading(false);
			await getDatewiseTimeSheetData(selectedDate)
			await getTimeSheetData(selectedMonth, selectedYear, selectedUser)
		}
	};

	const handleSubmit = async () => {
		const userData = JSON.parse(localStorage.getItem("isAuth"));

		try {
			const payload = {
				date: selectedDate,
				username: userData?.username
			}
			await fetch(BASEURL + 'api/submit-timesheet', {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
				body: JSON.stringify(payload),
			})
				.then(response => response.json())
				.then(res => {
					console.log("Save Response:", res);
					if (res.success) {
						Swal.fire("Success", "Timesheet saved successfully!", "success");
					} else {
						Swal.fire("Error", "Failed to save timesheet.", "error");
					}
				})
				.catch(err => {
					console.error("Error saving timesheet:", err);
					Swal.fire("Error", "Something went wrong!", "error");
				});
		} catch (err) {
			console.error(err);
		} finally {

		}
	}

	const calculateTimeDifference = (start, end) => {
		if (!start || !end) return NaN; // Prevent invalid calculations
		const [startH, startM] = start.split(":").map(Number);
		const [endH, endM] = end.split(":").map(Number);
		return (endH * 60 + endM) - (startH * 60 + startM);
	};

	// const changeMonth = (direction) => {
	// 	const calendarApi = calendarRef.current?.getApi();
	// 	if (calendarApi) {
	// 		if (direction === "next") {
	// 			calendarApi.next();
	// 		} else if (direction === "prev") {
	// 			calendarApi.prev();
	// 		} else {
	// 			calendarApi.today();
	// 		}

	// 		// Get new date after changing the month
	// 		const newDate = calendarApi.getDate();
	// 		setSelectedMonth(newDate.getMonth() + 1); // Month is 0-based
	// 		setSelectedYear(newDate.getFullYear());
	// 		const userTofetch = selectedUser
	// 		// Call API with updated month and year
	// 		getTimeSheetData(newDate.getMonth() + 1, newDate.getFullYear(), userTofetch);
	// 	}
	// };

	const changeMonth = (direction) => {
		const calendarApi = calendarRef.current?.getApi();
		if (calendarApi) {
			if (direction === "next") {
				calendarApi.next();
			} else if (direction === "prev") {
				calendarApi.prev();
			} else {
				calendarApi.today();
			}
			const newDate = calendarApi.getDate();
			setSelectedMonth(newDate.getMonth() + 1);
			setSelectedYear(newDate.getFullYear());
			const userToFetch = selectedUser
			getTimeSheetData(newDate.getMonth() + 1, newDate.getFullYear(), userToFetch);
		}
	};

	const getMonthName = (monthNumber) => {
		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return monthNames[monthNumber - 1];
	};

	const getTimeSheetData = async (month, year, data = []) => {

		try {
			setLoading(true);
			const userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (!userdata || !userdata.usertoken || !userdata.companydata) {
				throw new Error("Invalid user data");
			}

			const token = userdata.usertoken;
			const myHeaders = new Headers();
			myHeaders.append("Authorization", token);
			myHeaders.append("Content-Type", "application/json");

			await fetch(BASEURL + 'api/get-monthwise-timesheet', {
				method: "POST",
				body: JSON.stringify({
					user_id: userdata?.role !== 0 ? userdata.uuid : data?.value,
					username: userdata?.role !== 0 ? userdata.username : data?.username,
					month,
					year
				})
			}).then(response => response.json()).then(res => {
				setTimesheetStatus(res?.data);
			}).catch(err => console.log(err));

		} finally {
			setLoading(false);
		}
	};

	const getDatewiseTimeSheetData = async (date) => {
		try {
			setLoading(true);
			const userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (!userdata || !userdata.usertoken || !userdata.companydata) {
				throw new Error("Invalid user data");
			}

			const token = userdata.usertoken;
			const myHeaders = new Headers();
			myHeaders.append("Authorization", token);
			myHeaders.append("Content-Type", "application/json");

			// var month = format(new Date(), 'MM')
			// var year = format(new Date(), 'yyyy')

			await fetch(BASEURL + 'api/get-datewise-timesheet', {
				method: "POST",
				body: JSON.stringify({
					user_id: userdata?.role !== 0 ? userdata.uuid : selectedUser?.value,
					username: userdata?.role !== 0 ? userdata.username : selectedUser?.username,
					date,
					department: userdata.dept
				})
			}).then(response => response.json()).then(res => {
				setDatewiseData(res?.data)
				console.log(res?.start_time)
				setTimesheetData({
					project: '',
					activity: '',
					fromTime: res?.start_time,
					toTime: '',
					description: ''
				})
				// this.setState({ alldata: res.data, projects: res.projects, loading: false });
			}).catch(err => console.log(err));
			// const data = res.json();

		} finally {
			setLoading(false);
		}
	};

	const getProjects = async () => {
		try {
			setLoading(true);
			const userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (!userdata || !userdata.usertoken || !userdata.companydata) {
				throw new Error("Invalid user data");
			}
			const department = userdata.department;
			const token = userdata.usertoken;
			const myHeaders = new Headers();
			myHeaders.append("Authorization", token);
			myHeaders.append("Content-Type", "application/json");

			const response = await fetch(BASEURL + `get-projects`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					department,
					listtype: 1,
					role: 1,
					uuid: userdata.companydata[0].uuid,
				}),
			});

			const res = await response.json();

			if (res.status === 201) {
				setProjects(res.projects);
			} else {
				console.error("Error fetching projects:", res.message || res);
			}
		} catch (error) {
			console.error("Error in getProjects:", error.message);
		} finally {
			setLoading(false);
		}
	};

	const getActivities = async (data) => {
		const id = String(data); // Ensure ID is sent as a string if required
		try {
			const userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (!userdata || !userdata.usertoken || !userdata.companydata) {
				throw new Error("Invalid user data");
			}
			const token = userdata.usertoken;
			const myHeaders = new Headers();
			myHeaders.append("Authorization", token);
			myHeaders.append("Content-Type", "application/plain");
			let response = await fetch(BASEURL + 'api/get-project-activity', {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					id
				})
			})
			let data = await response.json();
			setActivities(data?.data)
		} catch (err) {
			console.error(err);
		}
	};


	useEffect(() => {
		const currentMonth = new Date().getMonth() + 1;
		const currentYear = new Date().getFullYear();
		getProjects();
		getTimeSheetData(currentMonth, currentYear);
		getPosData()
	}, []);

	const addStars = async (payload) => {
		// Ensure ID is sent as a string if required
		try {
			console.log(payload)
			const myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			let response = await fetch(BASEURL + 'api/add-productivity-rating', {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(payload)
			})
			let data = await response.json();
		} catch (err) {
			console.error(err);
		}
	};
	console.log(selectedDate, 'sleeced date');
	const currentDate = new Date().toISOString().split('T')[0];
	console.log(currentDate, 'sleeced date');

	const TimesheetDisplay = ({ timesheetData }) => {
		if (!timesheetData) return null;

		return (

			<div className="timesheet-display">
				{
					(selectedDate === currentDate) &&
					<div className='flex justify-end cursor-pointer' onClick={() => handleTimesheetDelete(timesheetData?.timesheet_id)}>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash-fill" viewBox="0 0 16 16">
							<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
						</svg>
					</div>
				}
				<div className="timesheet-entry">
					<span className="timesheet-label">Project Name</span>
					<span className="timesheet-value">{timesheetData.projname}</span>
				</div>

				<div className="timesheet-entry">
					<span className="timesheet-label">Activity</span>
					<span className="timesheet-value">{timesheetData.activityname}</span>
				</div>

				<div className="timesheet-entry">
					<span className="timesheet-label">Time</span>
					<span className="timesheet-value">
						{timesheetData.fromhrs} to {timesheetData.tohrs}
					</span>
				</div>

				<div className="timesheet-entry">
					<span className="timesheet-label">Description</span>
					<span className="timesheet-value">
						{timesheetData.work}
					</span>
				</div>
			</div>
		);
	};

	const handleRatingChange = async (ratingValue, timesheet_id) => {
		if (ratingValue !== null) {
			const payload = {
				timesheet_id,
				rating: ratingValue,
				date: selectedDate,
				user_id: selectedUser?.username
			};
			try {
				if (role !== 2) {

					await addStars(payload);
				}
			} catch (error) {
				console.error("Error submitting rating:", error);
			}
			finally {
				getDatewiseTimeSheetData(selectedDate)
			}
		}
	};

	const FilledTimesheetDisplay = () => {

		return (

			datewiseData?.map((item) => (
				<>
					<div className="timesheet-display">
						<div className="timesheet-entry">
							<span className="timesheet-label">Project Name</span>
							<span className="timesheet-value">{item?.projname}</span>
						</div>

						<div className="timesheet-entry">
							<span className="timesheet-label">Activity</span>
							<span className="timesheet-value">{item?.activityname}</span>
						</div>


						<div className="timesheet-entry">
							<span className="timesheet-label">Time</span>
							<span className="timesheet-value">
								{item?.fromhrs} to {item?.tohrs}
							</span>
						</div>

						<div className="timesheet-entry">
							<span className="timesheet-label">Description</span>
							<span className="timesheet-value">
								{item?.work}
							</span>
						</div>
					</div>
					<div style={{
						margin: "5px 0px"
					}} >
						<div className='my-2 ' style={{
							display: 'flex', alignItems: "center",
							justifyContent: "space-between"
						}}>
							<div>
								<Stack spacing={1}>
									<Rating
										name="half-rating"
										precision={0.5}
										value={item?.rating}
										onChange={(event, newValue) => handleRatingChange(newValue, item?.timesheet_id)}
									/>
								</Stack>
							</div>
							<div>
								{item?.rating ? Number(item?.rating) * 20 + " %" : "-"}
							</div>
						</div>
					</div>
				</>
			))
		);
	};

	return (
		<>
			<div className="timesheet-wrapper">
				<div className="timesheet-header">
					<div className="view-buttons">
						{/* <button className="view-btn">Month</button>
						<button className="view-btn">Week</button>
						<button className="view-btn">Day</button>
						<button className="view-btn">List</button> */}
						<div className="month-navigation">
							<button onClick={() => changeMonth("prev")}>◀ Prev</button>
							<button onClick={() => changeMonth("next")}>Next ▶</button>
						</div>
						{(role === 0) && <div style={{ width: "200px", margin: "0px 5px" }}>
							<ReactSelect
								defaultValue={posdata[0]}
								value={pos}
								className="w-full text-center"
								onChange={(selectedUser) => {
									setpos(selectedUser); // Update selected user state
									if (selectedUser?.value) {
										setSelectedUser(selectedUser)
										getTimeSheetData(selectedMonth, selectedYear, selectedUser); // Pass UUID
									}
								}}
								options={data}
								getOptionLabel={(option) => option.label}
								styles={{
									container: (provided) => ({ ...provided, width: "100%" }),
									control: (provided) => ({ ...provided, width: "100%" }),
									option: (base) => ({
										...base,
										height: "auto",
										display: "flex",
									}),
								}}
							/>
						</div>}
						{/* <input type="date" className="date-picker" defaultValue="2025-11-01" /> */}
					</div>
					<div className="timesheet-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span>Timesheet</span>
						<span style={{ fontSize: '18px' }}>{`${getMonthName(selectedMonth)}/${selectedYear}`}</span>
					</div>
				</div>

				<div className="timesheet-container grid grid-cols-12 ">
					<div className="calendar-section">
						<FullCalendar
							ref={calendarRef} // Attach ref
							plugins={[dayGridPlugin, interactionPlugin]}
							initialView="dayGridMonth"
							weekends={true}
							dateClick={handleDateClick}
							headerToolbar={false}
							dayCellContent={renderDateCell}
							dayCellDidMount={customizeDateCell}
						/>
					</div>

					{selectedDate && (
						<div className="timesheet-form">
							<h3 className='flex' style={{ fontSize: '17px', fontWeight: 'bold' }}>{role !== 0 ? "Time Sheet" : "Add Time Sheet"}</h3>
							{
								role !== 0 && (selectedDate === currentDate) ?
									<>

										<p><strong>Selected Date:</strong> {selectedDate}</p>
										<select
											className="modal-input"
											name="project"
											value={timesheetData.project || ""}
											onChange={(e) => {
												const project_id = e.target.value;
												setTimesheetData({ ...timesheetData, project: project_id });
												getActivities(project_id);
											}}
										>
											<option value="" disabled>Select Project</option>
											<option value="0">All Projects</option>
											{projectsdata && projectsdata.length > 0 &&
												projectsdata.map((item, i) => (
													<option key={i} value={item.project_id}>
														{item.project_name}
													</option>
												))
											}
										</select>
										<select
											className="modal-input"
											name="activity"
											value={timesheetData.activity || ""}
											onChange={handleInputChange}
										>
											<option value="" disabled>Select Activity</option>
											{activities && activities.length > 0 &&
												activities.map((item, i) => (
													<option key={i} value={item.value}>
														{item.label}
													</option>
												))
											}
										</select>
										<div className="time-inputs">
											<input type="time" name="fromTime" value={timesheetData.fromTime} onChange={handleInputChange} />
											<input type="time" name="toTime" value={timesheetData.toTime} onChange={handleInputChange} />
										</div>
										<textarea
											name="description"
											value={timesheetData.description}
											onChange={handleInputChange}
											placeholder="Description"
										></textarea>

										<button className="save-btn" onClick={handleSave}>Save</button>

										<div className='flex justify-end'>
											<button onClick={handleSubmit} className='submit-btn mt-1 '>
												Submit
											</button>
										</div>


										{/* Added scrollable container */}
										<div style={(selectedDate === currentDate) ? { maxHeight: '200px', overflowY: 'auto' } : { maxHeight: '100vh', overflowY: 'auto' }}>
											{datewiseData.length > 0 &&
												datewiseData.map((timesheet, index) => (
													<TimesheetDisplay key={index} timesheetData={timesheet} />
												))
											}
										</div>
									</>
									:
									<>
										<div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
											<FilledTimesheetDisplay />
										</div>
									</>
							}
						</div>
					)}

				</div>
			</div >
		</>
	);
}

export default Timesheet;
