import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import HashLoader from "react-spinners/HashLoader";

const SummaryApplications = (props) => {
  const [loading, setLoading] = useState(false);
  const [appdata, setAppData] = useState([]);

  useEffect(() => {
    if (props.ldate !== undefined) {
      getAppData(
        format(new Date(props.date), "yyyy-MM-dd"),
        props.ldate,
        props.username
      );
    }
  }, [props.username, props.date, props.ldate]);

  const getAppData = async (date, ldate, username) => {
    setLoading(true);
    const myHeaders = new Headers();
    const userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata) {
      const token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(BASEURL + `get-AppData/`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          date,
          ldate,
          username,
          company_uuid: userdata.companydata[0].uuid,
          master: userdata.master,
        }),
      });
      const res = await response.json();
      if (res.status === 201) {
        setAppData(res.data);
        props.getappdata(res.data);
      } else {
        setAppData([]);
        props.getappdata([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getColor = (index) => {
    const colors = ["#19C2E3", "#24A184", "#FC9A30", "#F36D6D"];
    return colors[index % colors.length];
  };

  return (
    <>
      {loading ? (
        <div className="mt-5" style={{ display: "inline-flex" }}>
          <HashLoader color="#5755d9" size={30} />
        </div>
      ) : (
        <div className="mt-2">
          {appdata && appdata.length > 0 ? (
            <>
              {appdata.slice(0, 3).map((app, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: getColor(index),
                    padding: "16px 10px",
                    textAlign: "center",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    margin: "0 0 10px 0",
                    fontSize: "16px"
                  }}
                >
                  <div className="m-0">{app.appname || "N/A"}</div>
                  <div className="m-0">{app.usage ? `${app.usage}%` : "N/A"}</div>
                </div>
              ))}
              {appdata.length > 3 && (
                <div
                  style={{
                    backgroundColor: "#F36D6D",
                    padding: "15px 10px",
                    textAlign: "center",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    margin: "0 0 10px 0",
                    fontSize: "16px"
                  }}
                >
                  <div className="m-0">Other</div>
                  <div className="m-0">{`${appdata
                    .slice(3)
                    .reduce((sum, app) => sum + (Number(app.usage) || 0), 0)
                    .toFixed(2)}%`}</div>
                </div>
              )}
            </>
          ) : (
            <div>No Data</div>
          )}
        </div>
      )}
    </>
  );
};

export default SummaryApplications;
