import React, { useState, useEffect } from "react";
import { BASEURL } from "./BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";

const WorkingShifts = () => {
  const [timezone, settimezone] =   useState(null);
  const [data, setdata] = useState({
    id: "",
    timezone: "",
    daily_act_report: true,
    clear_att: true,
  });

  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState(false);

  useEffect(() => {
    gettimezone();
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAccountSetting/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        type: 1,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setdata(res.data);
          let daily_act_report;
          if (res.data[0].daily_act_report === 1) {
            daily_act_report = true;
          } else {
            daily_act_report = false;
          }
          let clear_att;
          if (res.data[0].clear_att === 1) {
            clear_att = true;
          } else {
            clear_att = false;
          }
          setdata({
            timezone: res.data[0].timezone,
            daily_act_report: daily_act_report,
            clear_att: clear_att,
          });
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const gettimezone = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== undefined) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllTimezones/`, {
      method: "POST",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          settimezone(res.data);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handlechange = (e) => {
    let value = e.target.value;
    if (e.target.name === "daily_act_report") {
      value = e.target.checked;
    }
    if (e.target.name === "clear_att") {
      value = e.target.checked;
    }

    setdata({ ...data, [e.target.name]: value });
  };

  const savedata = async () => {
    if (data.timezone === "") {
      swal({
        title: "Error",
        text: "Please Enter value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `add-accsettings/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setmsg(res.message);
          setTimeout(() => {
            setmsg("");
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Account Settings</h2>
      </div>
      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          <div className="flex-1 mt-6 xl:mt-0">
            <div className="grid grid-cols-12 gap-x-5">
              <div className="col-span-12 2xl:col-span-6 mt-3">
                <label htmlFor="update-profile-form-2" className="form-label">
                  Timezone*
                </label>
                <select
                  className="form-select"
                  name="timezone"
                  onChange={handlechange}
                  value={data.timezone}
                >
                  <option value="">Select Timezone</option>
                  {loading ? (
                    <HashLoader
                      color="#5755d9"
                      size={30}
                      style={{ position: "absolute", right: "50%" }}
                    />
                  ) : (
                    timezone &&
                    timezone.map((item, i) => {
                      return (
                        <option key={i} value={item.gmtoffset}>
                          {item.gmtzone}
                        </option>
                      );
                    })
                  )}
                </select>
              </div>

              <div className="col-span-12 2xl:col-span-7 mt-3"></div>

              <div className="col-span-12 2xl:col-span-2 mt-3">
                <div className="mt-3">
                  <label>Daily Activity Report</label>
                  <div className="form-switch mt-2">
                    <input
                      type="checkbox"
                      name="daily_act_report"
                      onChange={handlechange}
                      checked={data.daily_act_report === true}
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 2xl:col-span-2 mt-3"></div>

              <div className="col-span-12 2xl:col-span-4 mt-3">
                <div className="mt-3">
                  <label>Clear attendance data on approved Leaves</label>
                  <div className="form-switch mt-2">
                    <input
                      type="checkbox"
                      name="clear_att"
                      onChange={handlechange}
                      checked={data.clear_att === true}
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <button
              type="button"
              onClick={() => savedata()}
              className="btn btn-primary w-20 mt-3"
            >
              Save
            </button>
            <br />
            <br />
            <span className="text-success">{msg ? msg : null}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingShifts;
