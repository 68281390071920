import React, { useState, useEffect } from "react";
import { BASEURL } from "./BASEURL";
import "react-toastify/dist/ReactToastify.css";

import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";

import {
	FaCalendar,
	FaPlus
} from "react-icons/fa";
import CommonModal from "./admin/CommonModal";
import DatePicker from "react-date-picker";
import { FaEdit, FaTrash } from "react-icons/fa";


const Departments = () => {
	const [bkpdata, setbkpdata] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState(null);
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [actionsDropdownIndex, setActionsDropdownIndex] = useState(null);
	const [updateShow, setUpdateShow] = useState(false);
	const [items, setitems] = useState([]);
	const [attdata, setattdata] = useState(null);
	const [isLoad, setIsLoad] = useState(false);
	const [punchtype, setpunchtype] = useState(0);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [data, setdata] = useState({
		id: "",
		start_time: "00:00:00",
		end_time: "00:00:00",
		description: "",
		date: "",
	});

	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});

	let [master,] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	const [selectedDate, setSelectedDate] = useState(null);


	const handleDateChange = (date) => {
		setSelectedDate(date);
		setdata({ ...data, date: format(date, "yyyy-MM-dd") });
		getData()
	};

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getData();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
	}, [itemOffset]);


	const getData = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllTimesheetData/` + JSON.parse(localStorage.getItem("isAuth")).uuid, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 0,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let attarr = [];
					if (res.attdata.length > 0) {
						res.attdata.forEach((element) => {
							// console.log(element);
							let date1 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
								" " +
								element.start_time
							);
							let date2 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
								" " +
								element.end_time
							);
							let diff = date2.getTime() - date1.getTime();
							let msec = diff;
							let hh = Math.floor(msec / 1000 / 60 / 60);
							msec -= hh * 1000 * 60 * 60;

							let mm = Math.floor(msec / 1000 / 60);
							msec -= mm * 1000 * 60;
							let ss = Math.floor(msec / 1000);
							msec -= ss * 1000;

							let mmm = mm >= 10 ? mm : "0" + mm;
							let hhh = hh >= 10 ? hh : "0" + hh;
							let sss = ss >= 10 ? ss : "0" + ss;
							let data = {
								id: element.id,
								uuid: element.uuid,
								name: element.name,
								user_id: element.username,
								date: element.date && format(new Date(element.date), "yyyy-MM-dd"),
								attendance_id: element.attendance_id,
								ticketstatus: element.ticketstatus,
								ticketStarttime: element.ticketStarttime ? element.ticketStarttime : "00:00:00",
								ticketEndtime: element.ticketEndtime ? element.ticketEndtime : "00:00:00",
								in: element.start_time !== "00:00:00" ? element.start_time : "00:00:00",
								out: element.end_time !== "00:00:00" ? element.end_time : "00:00:00",
								duration:
									element.end_time !== "00:00:00"
										? hhh + ":" + mmm + ":" + sss
										: "00:00:00",
								details: "More Details",
							};
							// console.log(data);
							attarr.push(data);
						});
					}

					let arr = [];
					let arr1 = [];
					for (const key in attarr) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}

					setitems(arr);
					setCurrentItems(arr1);
					setPageCount(Math.ceil(arr.length / 10));


					setattdata(attarr);
					setbkpdata(attarr);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};
	const handleOpenFilter = (selectedTicket) => {
		console.log("selectedTicket from handleOpenFilter", selectedTicket);
		setIsModalOpen(true);
		setIsOpenFilter(true);
		setUpdateShow(!!selectedTicket);
		setdata({
			id: selectedTicket?.id || "",
			lunch_start_time: selectedTicket?.in || "00:00:00",
			lunch_end_time: selectedTicket?.out || "00:00:00",
			reason: selectedTicket?.reason || "",
			date: selectedTicket?.date || "",
		});

		setSelectedDate(selectedTicket?.date || new Date());
	};


	const handleCloseFilter = () => {
		setIsOpenFilter(false)
	};

	const handlechange = (e) => {
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value,
		});
	};
	const handleChange = (e) => {
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value,
		});
	};
	const savedata = async (e) => {
		e.preventDefault();
		if (!data.lunch_start_time || !data.lunch_end_time || !data.reason || !selectedDate) {
			swal({
				title: "Error",
				text: "All fields are required!",
				icon: "error",
				timer: 2000,
			});
			return;
		}
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		const payload = {
			company_uuid: userdata.companydata[0].uuid,
			user_uuid: userdata.uuid,
			tickettype: 1,
			punchtype,
			data: {
				id: updateShow ? data.id : "",
				start_time: data.lunch_start_time,
				end_time: data.lunch_end_time,
				message: data.reason,
				date: selectedDate,
			},
		};
		await fetch(BASEURL + `create-ticket/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(payload),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					swal({
						title: "Success",
						text: res.message,
						icon: "success",
						timer: 2000,
					});
					getData();
					setIsModalOpen(false);
					setIsOpenFilter(false);
					setdata({
						id: "",
						lunch_start_time: "00:00:00",
						lunch_end_time: "00:00:00",
						reason: "",
						date: "",
					});
				} else {
					swal({
						title: "Error",
						text: res.message,
						icon: "error",
						timer: 2000,
					});
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const editdata = (selectedTicket) => {
		setIsModalOpen(true);
		setUpdateShow(true);

		setdata({
			id: selectedTicket.id,
			lunch_start_time: selectedTicket.lunch_start_time || "",
			lunch_end_time: selectedTicket.lunch_end_time || "",
			reason: selectedTicket.reason || "",
			date: selectedTicket.date || "",
		});
	};



	const adddata = (data) => {
		setisadd(!isadd);
		setdata({
			id: "",
			start_time: "00:00:00",
			end_time: "00:00:00",
			description: "",
			date: "",
		});
	};

	const removeTicket = (id) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Ticket?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-ticket/${id}`, {
					method: "DELETE",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						getData();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	const filterdata = (event) => {
		if (event.target.value === "") {
			loading ? <HashLoader /> : setattdata(bkpdata);

			let arr = [];
			for (const key in bkpdata) {
				arr.push(key);
			}
			setitems(arr);

			setCurrentItems(arr);
			setPageCount(Math.ceil(arr.length / 10));
		} else {
			var matches = bkpdata.filter(function (s) {
				return s.date.includes(`${event.target.value}`);
			});

			let arr = [];
			for (const key in matches) {
				arr.push(key);
			}
			setitems(arr);

			const newOffset = (matches.length * 10) % matches.length;
			setItemOffset(newOffset);
			setattdata(matches);
			const endOffset = itemOffset + 10;
			setCurrentItems(arr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(matches.length / 10));
		}
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 10) % items.length;

		setItemOffset(newOffset);
	};
	// const handleCloseFilter = () => {
	// 	setIsOpenFilter(false)
	// };
	const handleEdit = (ticket) => {
		setdata({
			// punch_start_time: ticket.punch_start_time,
			// punch_end_time: ticket.punch_end_time,
			lunch_start_time: ticket.lunch_start_time,
			lunch_end_time: ticket.lunch_end_time,
			reason: ticket.reason,
		});

		setUpdateShow(true);
		setIsOpenFilter(true);
	};

	const modalContent = (
		<div>
			<div
				className="flex"
				style={{
					justifyContent: "space-between",
					backgroundColor: "#FC9A30",
					color: "#fff",
					padding: "5px",
					borderTopLeftRadius: "8px",
					borderTopRightRadius: "8px",
				}}
			>
				<div style={{ fontSize: "15px" }} className="ml-2 p-2 bold">
					{updateShow ? "Other Break Ticket" : "Othe Break Ticket"}
				</div>
				<div className="cursor-pointer mr-2" onClick={handleCloseFilter}>
					x
				</div>
			</div>
			<div className="flex flex-col items-center p-6 bg-white shadow-xl rounded-lg w-[360px] relative mx-auto px-4 py-4">
				{/* <div className="col-span-12 2xl:col-span-2 mt-3 mb-4 w-full font-semibold">
					<label htmlFor="update-profile-form-2" className="form-label">
						Select Punch Type <span className="text-danger">(*)</span>
					</label>
					<select
						className="form-select px-3 py-2 border rounded-md text-sm"
						name="department"
						onChange={(e) => setpunchtype(e.target.value)}
						value={punchtype}
					>
						<option key={0} value="0">Break Start</option>
						<option key={1} value="1">Break End</option>
					</select>
				</div> */}

				{/* DatePicker */}
				{/* <div className="w-full mb-4">
					<div className="flex flex-col">
						<label className="form-label text-sm font-semibold">
							Get Attendance <span className="text-red-500">*</span>
						</label>
						<DatePicker
							value={selectedDate}
							onChange={handleDateChange}
							calendarIcon={<FaCalendar className="text-blue-700" />}
							clearIcon=""
							format="y-MM-dd"
							className="form-control w-full"
						/>
					</div>
				</div> */}

				<div className="w-full">
					<form onSubmit={savedata}>

						<div className="grid grid-cols-2 gap-4 mb-4">
							{[
								{ id: "lunch_start_time", label: "Break Start" },
								{ id: "lunch_end_time", label: "Break End" },
							].map(({ id, label }) => (
								<div className="flex flex-col" key={id}>
									<label htmlFor={id} className="form-label text-sm font-semibold">
										{label} <span className="text-red-500">*</span>
									</label>
									<input
										id={id}
										type="text"
										required
										name={id}
										className="form-control px-3 py-2 border rounded-md text-sm"
										onChange={handleChange}
										value={data[id]}
									/>
								</div>
							))}
						</div>
						<div className="mb-4">
							<label htmlFor="reason" className="form-label text-sm font-semibold">
								Reason <span className="text-red-500">*</span>
							</label>
							<textarea
								name="reason"
								className="form-control w-full px-3 py-2 border rounded-md text-sm"
								required
								rows="3"
								onChange={handleChange}
								value={data.reason}
							></textarea>
						</div>
						<div className="flex justify-center gap-4">
							{updateShow ? (
								<button
									type="submit"
									className="modal-button-new"
								>
									Update
								</button>
							) : (
								<button
									type="submit"
									className="modal-button-new"
									style={{ backgroundColor: "#24a184", borderColor: "#24a184", color: "white" }}
								>
									Create Ticket
								</button>
							)}
						</div>

					</form>
				</div>
			</div>
		</div>
	);


	return (
		<div className="intro-y box lg:mt-5">
			{/* <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">All Break Data</h2>
			</div> */}
			<div className="">
				<div className="flex justify-end p-1 m-1">
					<button
						type="button"
						// onClick={() => handleOpen(null)}
						onClick={() => handleOpenFilter()}
						className="btn w-50 add-emp-btn  "
						style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
					>
						Add Break Ticket
					</button>
				</div>
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{isadd === true ? (
						<div className="flex-1 mt-6 xl:mt-0 p-3 mb-2" style={{ border: "1px solid lightgray", borderRadius: "5px" }}>
							<form>
								<div className="grid grid-cols-12 gap-x-5">
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Select Punch Type <span className="text-danger">(*)</span>
										</label>
										<select
											className="form-select"
											name="department"
											onChange={(e) => setpunchtype(e.target.value)}
											value={punchtype}
										>
											<option key={0} value="0">Break Start</option>
											<option key={1} value="1">Break End</option>
										</select>
									</div>
									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Enter Start Time <span className="text-danger">(*)</span>
											</label>
											<input
												id="update-profile-form-1"
												value={data.start_time}
												type="text"
												required
												name="start_time"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>

									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Enter End Time <span className="text-danger">(*)</span>
											</label>
											<input
												id="update-profile-form-1"
												value={data.end_time !== '-' ? data.end_time : '00:00:00'}
												type="text"
												required
												name="end_time"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>

									<div className="col-span-12 2xl:col-span-3">
										<label htmlFor="update-profile-form-2" className="form-label">
											Reason <span className="text-danger">(*)</span>
										</label>
										<br />
										<textarea
											name="description"
											className="form-control"
											cols="80"
											rows="2"
											onChange={handlechange}
											value={data && data.description}
										></textarea>
									</div>

									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Date
										</label>
										<input
											id="update-profile-form-1"
											value={data.date}
											readOnly
											required
											minLength={10}
											type="text"
											name="contactno"
											className="form-control"
											onChange={handlechange}
										/>
									</div>
								</div>

								<button
									type="button"
									onClick={() => savedata()}
									className="btn btn-primary w-20 mt-3"
								>
									Save
								</button>
								<button
									type="button"
									onClick={() => adddata(1)}
									className="btn btn-danger w-20 mt-3 ml-2"
								>
									Cancel
								</button>
								<br />
								<br />
								<span
									className={

										"text-success"
									}
								>
									{msg.msg ? msg.msg : null}
								</span>
							</form>
						</div>
					) : null}
				</div>
				{/* <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex mt-5 sm:mt-0">
						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
					</div>
				</div> */}
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (

						<div
							style={{
								// padding: "10px",
								borderTopLeftRadius: "10px",
								borderTopRightRadius: "10px",
							}}
						>
							<div
								style={{
									backgroundColor: "#FC9A30",
									color: "#fff",
									padding: "10px",
									textAlign: "start",
									borderTopLeftRadius: "10px",
									borderTopRightRadius: "10px",
								}}
							>
								<h3>
									TimeSheet Ticket
								</h3>
							</div>
							<div className="table-outlineBorder" style={{
								border: "1px sold #FC9A30 !important",
							}}>
								<div className="employee-table-container">

									<table className="employee-table" style={{ width: "100%" }}>
										<thead>
											<tr>
												<th className="whitespace-nowrap">Date</th>
												<th className="whitespace-nowrap">Break In</th>
												<th className="whitespace-nowrap">Break Out</th>
												<th className="whitespace-nowrap">Break Duration</th>
												<th className="whitespace-nowrap">Status</th>
												<th className="whitespace-nowrap">Actions</th>
											</tr>
										</thead>
										<tbody>
											{currentItems &&
												currentItems.length === 0 &&
												<tr><td colSpan="8">No data available</td></tr>
											}
											{currentItems &&
												currentItems.length > 0 &&
												currentItems.map((item, i) => {
													return (
														<tr key={i}>
															<td>{attdata[item].date}</td>
															<td>{attdata[item].in}</td>
															<td>{attdata[item].out}</td>
															<td>{attdata[item].duration}</td>
															<td>
																<div className="flex lg:justify-center items-center cursor-pointer">
																	{attdata[item].id !== attdata[item].attendance_id || attdata[item].ticketstatus === 3 ? <>

																	</> : attdata[item].ticketstatus === 0 ?
																		<button className="btn btn-warning btn-sm"
																			title={'Start Time: ' + attdata[item].ticketStarttime + ' || End Time: ' + attdata[item].ticketEndtime}>
																			Pending
																		</button>
																		: attdata[item].ticketstatus === 1 ?
																			<button className="btn btn-success text-white btn-sm">
																				Approved
																			</button> :
																			<button className="btn btn-danger text-white btn-sm">
																				Rejected
																			</button>
																	}

																</div>
															</td>
															<td>
																<div className="relative">
																	<button
																		onClick={() => setActionsDropdownIndex(i === actionsDropdownIndex ? null : i)}
																		className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200"
																	>
																		{actionsDropdownIndex === i ? "▲" : "▼"}
																	</button>

																	{actionsDropdownIndex === i && (
																		<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
																			<ul className="py-1">
																				<li
																					onClick={() => {
																						handleOpenFilter(attdata[item]); // Open modal for editing
																						setActionsDropdownIndex(null);
																					}}
																					className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																				>
																					<FaEdit className="text-gray-600 mr-2" />
																					Edit
																				</li>
																				<li
																					onClick={() => {
																						removeTicket(attdata[item].attendance_id); // Delete ticket
																						setActionsDropdownIndex(null);
																					}}
																					className="flex items-center px-4 py-2 text-sm text-red-700 cursor-pointer hover:bg-red-100"
																				>
																					<FaTrash className="mr-2 text-red-600" />
																					Delete
																				</li>
																			</ul>
																		</div>
																	)}
																</div>
															</td>

														</tr>
													);
												})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}

					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={10}
											marginPagesDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : null}
						</nav>
					</div>
				</div>
			</div>
			<CommonModal isOpen={isOpenFilter} content={modalContent} />
		</div>
	);
};

export default Departments;