import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { FaCalendar, FaFileCsv } from "react-icons/fa";
import { ReactTabulator } from "react-tabulator";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";
import Select from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import ReactPaginate from "react-paginate";
import { Tooltip } from "react-tooltip";

const Report = () => {
  const [loading, setLoading] = useState(false);
  const [attdata, setAttData] = useState([]);
  // const [csvData, setCsvData] = useState([]);
  const today = new Date();
  const curryear = today.getFullYear();
  const currmonth = today.getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState({ year: curryear, month: currmonth });
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0]?.uuid);
  const [monthDays, setMonthDays] = useState(0); 
  const [monthYear, setMonthYear] = useState({});
 
  const [isVisible, setIsVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [dataPerPage] = useState(10);
  const [department, setdepartment] = useState([]);

  const indexOfLastData = (currentPage + 1) * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = attdata.slice(indexOfFirstData, indexOfLastData);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {

    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");
    setSelectedMonth({year: year, month: month})
    getAttendanceData({ year, month: Number(month) });
    
    // getData(format(new Date(value[0]), "yyyy-MM-dd"), format(new Date(value[1]), "yyyy-MM-dd"));
  }, []);
  useEffect(() => {
    calculateMonthDays();
    GetDepartments();
  }, [selectedMonth]);

  const calculateMonthDays = () => {
    const { year, month } = selectedMonth;
    const date = new Date(year, month - 1, 1); 
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const daysInMonth = lastDayOfMonth.getDate();
    setMonthDays(daysInMonth);
  };

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

    

  
  const getorg = async (org) => {
    // setorgval(org);
    org = org.value
    setselectedorg(org);
    let value = JSON.parse(localStorage.getItem("monthyear"));
    if(Object.keys(monthYear).length !== 0){
       value = monthYear;
    }   
    
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");

    
    getAttendanceData({ year, month: Number(month) },org);

  }

  const getAttendanceData = async (monthyear="",org="",departmentId ) => {

    console.log(departmentId);
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
  
    try {
      const response = await fetch(BASEURL + `get-report-data/`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          selectedMonth:monthyear
        }),
      });
  
      const res = await response.json();

      if (res.status === 201) {
        // Ensure res.arr[0] is an array
        setAttData(res.arr)
        // setCsvData([...res.arr])
      
      } else {
        setAttData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  let csv = []

  for (let index = 0; index < attdata.length; index++) {
    const element = attdata[index];
   const obj = {
      Name : element?.name,
      UserName : element?.username,
      Department: element?.department,
      MonthDays : element?.monthday,
      WeekOff: element?.weekoff,
      WorkingDays: element?.workingdays,
      Present: element?.present,
      Absent: element?.absent,
      MonthLeaves: element?.monthleaves,
      LWP: element?.leavetypes[0]?.LWP,
      PL: element?.leavetypes[0]?.PL,
      SL: element?.leavetypes[0]?.SL,
      CL: element?.leavetypes[0]?.CL,
      PTL: element?.leavetypes[0]?.PTL,
    }
    csv.push(obj);
  }

  const handleOnChange = (year, month) => {
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
    const monthname = monthNames[month - 1];
    localStorage.setItem(
      "monthyear",
      JSON.stringify({ year, monthname, month })
    );
    setMonthYear({ year, month: monthname });
    setSelectedMonth({ year, month: Number(month) });
    getAttendanceData({ year, month: Number(month) });
  };

 
  const showMonthPicker = (e) => {
    setIsVisible(!isVisible);
    e.preventDefault();
  };

  const hideMonthPicker = () => {
    setIsVisible(false);
  };

  const getMonthValue = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value
      ? value.monthname
      : monthYear && monthYear.month
      ? monthYear.month
      : 0;
    const year = value
      ? value.year
      : monthYear && monthYear.year
      ? monthYear.year
      : 0;

    let date = new Date();
    let curryear = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return month && year
      ? `${month}-${year}`
      : monthNames[currmonth - 1] + "-" + curryear;
  };
  const handleOnDismiss = () => {
    setIsVisible(false);
  };

  const GetDepartments = async () => {
    const myHeaders = new Headers();
  
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `departments`, {
      method: "GET",
      headers: myHeaders,
    });
    let data2 = await data.json();
    // console.log(data2);
    
    setdepartment(data2);
    // setLoading(false);
  }

  // const [setdpt, dpt] = useState(null)




  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 2xl:col-span-12">
        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 className="text-lg font-medium mr-auto">Monthly Attendance Report</h2>
          <Select
  className="basic-single text-center mr-auto"
 onChange={(e)=>{
  console.log(e);
  getAttendanceData(e.department_id);
  // setdpt(e.department_id);

 }}
  options={department}
  getOptionLabel={(Department) => Department.name} 
  styles={{
    option: (base) => ({
      ...base,
      height: "100%",
      display: "flex",
    }),
  }}
/>


          <div className="ml-auto flex items-center text-primary">
              <div className="flex mt-2 sm:mt-0">
              <div className="input-group mr-2 calenderdate">
                <input
                  type="text"
                  style={{ fontSize: "12px" }}
                  onFocus={(e) => showMonthPicker(e)}
                  value={getMonthValue()}
                  onDismiss={() => hideMonthPicker(false)}    
                  className="form-control calenderdate1 "
                  id="basic-url"
                  aria-describedby="basic-addon3"
                  
                />
                <div className="input-group-append calendaricon">
                  <FaCalendar
                    style={{ fontSize: "12px" }}
                    onClick={(e) => showMonthPicker(e)}
                  />
                </div>
              </div>
              <ReactMonthPicker
                show={isVisible}
                lang={[ 
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                value={selectedMonth}
                onChange={handleOnChange}       
                onDismiss={handleOnDismiss}               
              />
            </div>
          
            <CSVLink
              filename={"All-Employees-Monthly-Attendance.csv"}
              className="ml-3 mr-3"
              data={csv}
            >
              <FaFileCsv style={{ color: "rgb(30 64 175)", fontSize: "25px" }} />
            </CSVLink>
          </div>
        </div>
        {loading ? (
          <div className="mt-5" style={{ display: "-webkit-inline-box" }}>
            <HashLoader color="#5755d9" size={30} />
          </div>
        ) : (
          <div className="intro-y">
            <div className="overflow-x-auto scrollbar-hidden">
              {attdata ? (
                 <table className="table mt-2 table-bordered" style={{ width: "160%" }}>
                 <thead>
                   <tr>
                   {/* <th className="whitespace-nowrap">user_id</th> */}
                     <th className="whitespace-nowrap">username</th>
                     <th className="whitespace-nowrap">Name</th>
                     <th className="whitespace-nowrap">Month Days</th>
                    
                     <th className="whitespace-nowrap">Week Off</th>
                     <th className="whitespace-nowrap">Public Holiday</th>
                     <th className="whitespace-nowrap">Total Work Days</th>
                     <th className="whitespace-nowrap">Present Days</th>
                     <th className="whitespace-nowrap">Absent</th>
                     <th className="whitespace-nowrap">Sandwich Days</th>
                     <th className="whitespace-nowrap">Month Leaves</th>
                    
                   </tr>
                 </thead>
                 <tbody>
                   {currentData.length === 0 && (
                     <tr>
                       <td colSpan="12">No data available</td>
                     </tr>
                   )}
                     {currentData.map((data, index) => (
                       <tr key={index}>
                         {/* <td>{data.user_id}</td> */}
                         <td>{data.username}</td>
                         <td>{data.name}</td>  
                         <td>{data.monthday}</td> 
                         <td>{data.weekoff}</td>
                         <td>{data.holidays}</td>
                         <td>{data.workingdays}</td>
                         <td>{data.present}</td>
                         <td>{data.absent}</td>
                         <td>{data.New_Learnings}</td>
                         <td><a className="btn btn-primary btn-sm" id="not-clickable">{data.monthleaves}</a>
                        <Tooltip anchorSelect="#not-clickable"  place="bottom">
                        <button>LWP : {data.leavetypes[0].LWP} PL : {data.leavetypes[0].PL} SL : {data.leavetypes[0].SL} CL : {data.leavetypes[0].CL} PTL : {data.leavetypes[0].PTL}</button>
                        </Tooltip></td>
                       </tr>
                     ))}
                 </tbody>
               </table>
              ) : (
                <div className="text-danger text-left mt-5 mb-5">No Data Available</div>
              )}
            </div>
            <ReactPaginate
        breakLabel={"..."}
        pageCount={Math.ceil(attdata.length / dataPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageClassName={"page-item"} // Style for pagination numbers
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        previousLabel={<>&laquo;</>}
        nextLabel={<>&raquo;</>}
      />
          </div>
        )}
      </div>
    </div>
  );
};

export default Report;
