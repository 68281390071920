// // import React, { useState, useEffect } from "react";
// // import { SITEURL, BASEURL } from "../BASEURL";
// // import "react-toastify/dist/ReactToastify.css";
// // import swal from "sweetalert";
// // import HashLoader from "react-spinners/HashLoader";
// // import ReactPaginate from "react-paginate";
// // import { Link } from "react-router-dom";
// // import Masteradmin from "../Masteradmin";
// // import CommonModal from "./CommonModal";
// // import {
// // 	FaSignInAlt,
// // 	FaTrashAlt,
// // 	FaEdit,
// // 	FaToggleOff,
// // 	FaToggleOn,
// // } from "react-icons/fa";

// // const Departments = () => {
// // 	const [userdata, setuserdata] = useState([]);
// // 	const [dept, setdept] = useState(null);
// // 	const [bkpdata, setbkpdata] = useState([]);
// // 	const [errors, setErrors] = useState({});
// // 	const [pageCount, setPageCount] = useState(0);
// // 	const [clients, setClients] = useState(0);
// // 	const [itemOffset, setItemOffset] = useState(0);
// // 	const [currentItems, setCurrentItems] = useState(null);
// // 	const [isOpen, setIsOpen] = useState(false); // Modal state
// // 	const [isEdit, setIsEdit] = useState(false);
// // 	const [items, setitems] = useState([]);
// // 	const [add, setadd] = useState(2);
// // 	const [dept_selected, setdept_selected] = useState();
// // 	let [tabtype, settabtype] = useState(false);
// // 	let [seledept, setseledept] = useState("");

// // 	const [getdata, setgetdata] = useState([]);
// // 	// const [data, setdata] = useState({
// // 	// 	id: "",
// // 	// 	name: "",
// // 	// 	department: "0",
// // 	// 	status: true,
// // 	// 	uuid: ``,
// // 	// });
// // 	const [openDropdown, setOpenDropdown] = useState(null);
// // 	const [data, setdata] = useState({
// // 		id: "",
// // 		name: "",
// // 		department: "0",
// // 		client: "0",
// // 		status: true,
// // 		permanent_address: "",
// // 		category: "", // New field
// // 	});
// // 	const handleEdit = (uuid) => {
// // 		if (uuid) {
// // 			setIsEdit(true)
// // 			// setUid(uuid)
// // 		}
// // 		setIsOpen(true);
// // 		// getEmployeeData(uuid)

// // 	}
// // 	const toggleDropdown = (id) => {
// // 		setOpenDropdown(openDropdown === id ? null : id);
// // 	};

// // 	const closeDropdown = () => {
// // 		setOpenDropdown(null);
// // 	};


// // 	const [isadd, setisadd] = useState(false);
// // 	let [loading, setLoading] = useState(false);
// // 	let [msg, setmsg] = useState({
// // 		type: "",
// // 		msg: "",
// // 	});

// // 	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

// // 	useEffect(() => {
// // 		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
// // 		const endOffset = itemOffset + 10;
// // 		setCurrentItems(items.slice(itemOffset, endOffset));
// // 		setPageCount(Math.ceil(items.length / 10));
// // 		getdepartments();
// // 		getData();
// // 	}, []);

// // 	useEffect(() => {
// // 		const endOffset = itemOffset + 10;
// // 		setCurrentItems(items.slice(itemOffset, endOffset));
// // 		setPageCount(Math.ceil(items.length / 10));
// // 	}, [itemOffset]);

// // 	const getdepartments = async () => {
// // 		setLoading(true);
// // 		const myHeaders = new Headers();
// // 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 		if (userdata !== "00:00:00") {
// // 			let token = userdata.usertoken;
// // 			myHeaders.append("Authorization", token);
// // 		}
// // 		myHeaders.append("Content-Type", "application/json");
// // 		await fetch(BASEURL + `getAllDepartments/`, {
// // 			method: "POST",
// // 			headers: myHeaders,
// // 			body: JSON.stringify({
// // 				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
// // 					.uuid,
// // 			}),
// // 		})
// // 			.then((response) => response.json())
// // 			.then((res) => {
// // 				if (res.status === 201) {
// // 					// console.log(res.data);
// // 					if (userdata.role == 2) {
// // 						let dept = res.data.filter(
// // 							(item) => item.department_id === userdata.department
// // 						);
// // 						setdept(dept);
// // 						getUserData(dept && dept[0].department_id);
// // 					} else {
// // 						setdept(res.data);
// // 						getUserData(res.data && res.data[0].department_id);
// // 					}

// // 					//   setdept_selected(res.data[0].department_id)
// // 					// console.log(res.data);
// // 				}
// // 				setLoading(false);
// // 			})
// // 			.catch((err) => console.log(err));
// // 	};

// // 	const getData = async () => {
// // 		settabtype(false);
// // 		setLoading(true);
// // 		const myHeaders = new Headers();
// // 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 		if (userdata != null) {
// // 			let token = userdata.usertoken;
// // 			myHeaders.append("Authorization", token);
// // 		}
// // 		myHeaders.append("Content-Type", "application/json");
// // 		let data = await fetch(BASEURL + `addorganization`, {
// // 			method: "GET",
// // 			headers: myHeaders,
// // 		});
// // 		let data2 = await data.json();
// // 		setgetdata(data2.data);
// // 		setLoading(false);
// // 	};

// // 	const handlePageClick = (event) => {
// // 		const newOffset = (event.selected * 10) % items.length;

// // 		setItemOffset(newOffset);
// // 	};
// // 	useEffect(() => {
// // 		getClients()
// // 	}, [])
// // 	const getClients = async () => {
// // 		setLoading(true);
// // 		const myHeaders = new Headers();
// // 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 		if (userdata !== "00:00:00") {
// // 			let token = userdata.usertoken;
// // 			myHeaders.append("Authorization", token);
// // 		}
// // 		myHeaders.append("Content-Type", "application/json");
// // 		await fetch(BASEURL + `clients`, {
// // 			method: "POST",
// // 			headers: myHeaders,
// // 			body: JSON.stringify({
// // 				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
// // 				listtype: 1, // Example payload, adjust as needed
// // 				role: 1,
// // 				master: 1,
// // 				isactive: 1
// // 			}),
// // 		})
// // 			.then((response) => response.json())
// // 			.then((res) => {
// // 				if (res.status === 201) {
// // 					setClients(res.data); // Store clients in state
// // 				}
// // 				setLoading(false);
// // 			})
// // 			.catch((err) => {
// // 				console.log(err);
// // 				setLoading(false);
// // 			});
// // 	};



// // 	const getUserData = async (e = "") => {
// // 		setLoading(true);
// // 		const myHeaders = new Headers();
// // 		let userdata = JSON.parse(localStorage.getItem("isAuth"));

// // 		if (userdata !== "00:00:00") {
// // 			let token = userdata.usertoken;
// // 			myHeaders.append("Authorization", token);
// // 		}
// // 		myHeaders.append("Content-Type", "application/json");

// // 		await fetch(BASEURL + `get-projects/`, {
// // 			method: "POST",
// // 			headers: myHeaders,
// // 			body: JSON.stringify({
// // 				department: e,
// // 				listtype: 1,
// // 				role: 1,
// // 				uuid: userdata.companydata[0].uuid,
// // 			}),
// // 		})
// // 			.then((response) => response.json())
// // 			.then((res) => {
// // 				if (res.status === 201) {
// // 					setuserdata(res.projects);
// // 					setCurrentItems(Object.keys(res.projects).slice(0, 10)); // Show initial 10 items
// // 				}
// // 			})
// // 			.catch((err) => console.error(err));

// // 		setLoading(false);
// // 	};

// // 	const handleStatusChange = (projectId, newStatus) => {
// // 		setuserdata((prev) =>
// // 			prev.map((project) =>
// // 				project.project_id === projectId ? { ...project, status: newStatus } : project
// // 			)
// // 		);
// // 		// Call an API to update status (not implemented)
// // 	};

// // 	const handlechange = (e) => {
// // 		let value = e.target.value;
// // 		if (e.target.name === "status") {
// // 			value = e.target.checked;
// // 		}
// // 		setdata({
// // 			...data,
// // 			[e.target.name]: value,
// // 		});
// // 		// console.log(data);
// // 	};


// // 	const savedata = async () => {
// // 		if (
// // 			data.name === "" ||
// // 			data.department === "" ||
// // 			data.client === "" ||
// // 			data.category === ""
// // 		) {
// // 			swal({
// // 				title: "Error",
// // 				text: "Please fill in all required fields.",
// // 				icon: "error",
// // 				timer: 2000,
// // 			});
// // 			return;
// // 		}

// // 		setLoading(true);
// // 		const myHeaders = new Headers();
// // 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 		if (userdata != null) {
// // 			let token = userdata.usertoken;
// // 			myHeaders.append("Authorization", token);
// // 		}
// // 		myHeaders.append("Content-Type", "application/json");

// // 		await fetch(BASEURL + `add-project/`, {
// // 			method: "POST",
// // 			headers: myHeaders,
// // 			body: JSON.stringify({
// // 				company_uuid: userdata.companydata[0].uuid,
// // 				company: userdata,
// // 				data, // Includes `data.category`
// // 			}),
// // 		})
// // 			.then((response) => response.json())
// // 			.then((res) => {
// // 				if (res.status === 201) {
// // 					getUserData(data.department);
// // 					setmsg({ type: res.type, msg: res.message });
// // 					setdata({
// // 						id: "",
// // 						name: "",
// // 						department: "0",
// // 						client: "0",
// // 						status: true,
// // 						permanent_address: "",
// // 						category: "", // Reset category
// // 					});

// // 					swal({
// // 						title: "Success",
// // 						text: res.message,
// // 						icon: "success",
// // 						timer: 2000,
// // 					});
// // 				} else {
// // 					swal({
// // 						title: "Error",
// // 						text: res.message,
// // 						icon: "error",
// // 						timer: 2000,
// // 					});
// // 				}
// // 			})
// // 			.catch((err) => console.log(err))
// // 			.finally(() => setLoading(false));
// // 	};


// // 	// const savedata = async (elm) => {
// // 	// 	if (data.name === "" || data.department === "" || data.client === "") {
// // 	// 		swal({
// // 	// 			title: "Error",
// // 	// 			text: "Please fill in all required fields.",
// // 	// 			icon: "error",
// // 	// 			timer: 2000,
// // 	// 		});
// // 	// 		return;
// // 	// 	}
// // 	// 	// console.log(data); return
// // 	// 	setLoading(true);
// // 	// 	const myHeaders = new Headers();
// // 	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 	// 	if (userdata != null) {
// // 	// 		let token = userdata.usertoken;
// // 	// 		myHeaders.append("Authorization", token);
// // 	// 	}
// // 	// 	myHeaders.append("Content-Type", "application/json");
// // 	// 	await fetch(BASEURL + `add-project/`, {
// // 	// 		method: "POST",
// // 	// 		headers: myHeaders,
// // 	// 		body: JSON.stringify({
// // 	// 			company_uuid: userdata.companydata[0].uuid,
// // 	// 			company: userdata,
// // 	// 			data,
// // 	// 		}),
// // 	// 	})
// // 	// 		.then((response) => response.json())
// // 	// 		.then((res) => {
// // 	// 			if (res.status === 201) {
// // 	// 				getUserData(data.department);
// // 	// 				userdata.companydata[0].name = data.name;
// // 	// 				localStorage.setItem("isAuth", JSON.stringify(userdata));
// // 	// 				setmsg({
// // 	// 					type: res.type,
// // 	// 					msg: res.message,
// // 	// 				});
// // 	// 				setdata({
// // 	// 					id: "",
// // 	// 					name: "",
// // 	// 					department: 1,
// // 	// 					status: true,
// // 	// 				});

// // 	// 				swal({
// // 	// 					title: "Success",
// // 	// 					text: res.message,
// // 	// 					icon: "success",
// // 	// 					timer: 2000,
// // 	// 				});
// // 	// 				setTimeout(() => {
// // 	// 					setmsg({
// // 	// 						type: "",
// // 	// 						msg: "",
// // 	// 					});
// // 	// 					setisadd(!isadd);
// // 	// 				}, 1000);
// // 	// 			} else {
// // 	// 				swal({
// // 	// 					title: "Error",
// // 	// 					text: res.message,
// // 	// 					icon: "error",
// // 	// 					timer: 2000,
// // 	// 				});
// // 	// 			}
// // 	// 		})
// // 	// 		.catch((err) => console.log(err));
// // 	// 	setLoading(false);
// // 	// };

// // 	const editdata = (data) => {
// // 		setisadd(true);
// // 		let status;
// // 		if (data.status === 1) {
// // 			status = true;
// // 		} else {
// // 			status = false;
// // 		}
// // 		// console.log(data.companyuuid);
// // 		setdata({
// // 			id: data.project_id,
// // 			name: data.name,
// // 			department: data.department,
// // 			status: status,
// // 		});
// // 	};

// // 	const adddata = (data) => {
// // 		// console.log(11);
// // 		settabtype(false);
// // 		setisadd(!isadd);
// // 		setdata({
// // 			id: "",
// // 			name: "",
// // 			department: 0,
// // 			status: true,
// // 		});
// // 	};

// // 	const filterdata = (event) => {
// // 		if (event.target.value === "") {
// // 			loading ? <HashLoader /> : setuserdata(bkpdata);

// // 			let arr = [];
// // 			for (const key in bkpdata) {
// // 				arr.push(key);
// // 			}
// // 			setitems(arr);

// // 			setCurrentItems(arr);
// // 			setPageCount(Math.ceil(arr.length / 10));
// // 		} else {
// // 			var matches = bkpdata.filter(function (s) {
// // 				return s.name.toLowerCase().includes(`${event.target.value}`);
// // 			});

// // 			let arr = [];
// // 			for (const key in matches) {
// // 				arr.push(key);
// // 			}
// // 			setitems(arr);

// // 			const newOffset = (matches.length * 10) % matches.length;
// // 			setItemOffset(newOffset);
// // 			setuserdata(matches);
// // 			const endOffset = itemOffset + 10;
// // 			setCurrentItems(arr.slice(itemOffset, endOffset));
// // 			setPageCount(Math.ceil(matches.length / 10));
// // 		}
// // 	};

// // 	const deletedata = (id) => {
// // 		swal({
// // 			title: "Delete",
// // 			text: `Please confirm if you want to Delete Project?`,
// // 			buttons: ["No", "Yes"],
// // 			showCloseButton: true,
// // 		}).then((willDelete) => {
// // 			if (willDelete) {
// // 				setLoading(true);
// // 				const myHeaders = new Headers();
// // 				let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 				if (userdata != null) {
// // 					let token = userdata.usertoken;
// // 					myHeaders.append("Authorization", token);
// // 				}
// // 				myHeaders.append("Content-Type", "application/json");
// // 				fetch(BASEURL + `delete-project/${id}`, {
// // 					method: "DELETE",
// // 					headers: myHeaders,
// // 				})
// // 					.then((response) => response.json())
// // 					.then((res) => {
// // 						getUserData(seledept);
// // 						swal({
// // 							title: "Success",
// // 							text: res.msg,
// // 							icon: "success",
// // 							timer: 2000,
// // 						});
// // 					})
// // 					.catch((err) => console.log(err));
// // 				setLoading(false);
// // 			}
// // 		});
// // 	};
// // 	const updateEmployee = async (uuid) => {
// // 		// e.preventDefault();
// // 		const userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 		const myHeaders = new Headers();
// // 		if (userdata) {
// // 			myHeaders.append("Authorization", userdata.usertoken);
// // 		}
// // 		myHeaders.append("Content-Type", "application/json");

// // 		const payload = { uuid };
// // 		// if (data.name !== employeeData.name) payload.name = data.name;
// // 		// if (data.department !== employeeData.department)
// // 		// 	payload.department = data.department;
// // 		// if (data.hod !== employeeData.type) payload.hod = data.hod;
// // 		// if (data.contactno !== employeeData.contact_no)
// // 		// 	payload.contactno = data.contactno;
// // 		// if (data.email !== employeeData.email) payload.email = data.email;
// // 		// if (data.dob !== employeeData.dob) payload.dob = data.dob;
// // 		// if (data.date_of_joining !== employeeData.date_of_joining)
// // 		// 	payload.date_of_joining = data.date_of_joining;
// // 		// if (data.status !== employeeData.status) payload.status = data.status;

// // 		try {
// // 			const response = await fetch(BASEURL + `update-employee`, {
// // 				method: "POST",
// // 				headers: myHeaders,
// // 				body: JSON.stringify(payload),
// // 			});
// // 			const res = await response.json();

// // 			if (res.status === 201) {
// // 				swal({
// // 					title: "Success",
// // 					text: res.message,
// // 					icon: "success",
// // 					timer: 2000,
// // 				}).then(() => {
// // 					// navigate("/admin/3");
// // 				});
// // 			} else {
// // 				swal({
// // 					title: "Error",
// // 					text: "Please Enter Required Values",
// // 					icon: "error",
// // 					timer: 2000,
// // 				});
// // 			}
// // 		} catch (error) {
// // 			console.error("Error updating employee:", error);
// // 		} finally {
// // 			setIsOpen(false)
// // 		}
// // 	};
// // 	const handlechangeinput = (e) => {
// // 		let value = e.target.value;
// // 		if (e.target.name === "status") {
// // 			value = e.target.checked;
// // 		}
// // 		setdata({
// // 			...data,
// // 			[e.target.name]: value,
// // 		});
// // 	};
// // 	// const savedata = async () => {
// // 	// 	if (data.name === "" || data.department === "" || data.client === "") {
// // 	// 		swal({
// // 	// 			title: "Error",
// // 	// 			text: "Please fill in all required fields.",
// // 	// 			icon: "error",
// // 	// 			timer: 2000,
// // 	// 		});
// // 	// 		return;
// // 	// 	}

// // 	// 	setLoading(true);
// // 	// 	const myHeaders = new Headers();
// // 	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
// // 	// 	if (userdata != null) {
// // 	// 		let token = userdata.usertoken;
// // 	// 		myHeaders.append("Authorization", token);
// // 	// 	}
// // 	// 	myHeaders.append("Content-Type", "application/json");

// // 	// 	await fetch(BASEURL + `add-project/`, {
// // 	// 		method: "POST",
// // 	// 		headers: myHeaders,
// // 	// 		body: JSON.stringify({
// // 	// 			company_uuid: userdata.companydata[0].uuid,
// // 	// 			company: userdata,
// // 	// 			data, // Ensure `data.client` and `data.permanent_address` are set
// // 	// 		}),
// // 	// 	})
// // 	// 		.then((response) => response.json())
// // 	// 		.then((res) => {
// // 	// 			if (res.status === 201) {
// // 	// 				getUserData(data.department);
// // 	// 				setmsg({ type: res.type, msg: res.message });
// // 	// 				setdata({
// // 	// 					id: "",
// // 	// 					name: "",
// // 	// 					department: "0",
// // 	// 					client: "0",
// // 	// 					status: true,
// // 	// 					permanent_address: "",
// // 	// 				});

// // 	// 				swal({
// // 	// 					title: "Success",
// // 	// 					text: res.message,
// // 	// 					icon: "success",
// // 	// 					timer: 2000,
// // 	// 				});
// // 	// 			} else {
// // 	// 				swal({
// // 	// 					title: "Error",
// // 	// 					text: res.message,
// // 	// 					icon: "error",
// // 	// 					timer: 2000,
// // 	// 				});
// // 	// 			}
// // 	// 		})
// // 	// 		.catch((err) => console.log(err))
// // 	// 		.finally(() => setLoading(false));
// // 	// };


// // 	const handleOpen = (project) => {
// // 		if (project) {
// // 			setdata({
// // 				id: project.project_id,
// // 				name: project.name,
// // 				department: project.department,
// // 				status: project.status === 1,
// // 			});
// // 			setIsEdit(true);
// // 		} else {
// // 			resetForm();
// // 		}
// // 		setIsOpen(true);
// // 	};

// // 	const handleClose = () => {
// // 		setIsOpen(false);
// // 		resetForm();
// // 	};

// // 	const resetForm = () => {
// // 		setdata({
// // 			id: "",
// // 			name: "",
// // 			department: "0",
// // 			status: true,
// // 		});
// // 		setIsEdit(false);
// // 	};

// // 	const modalContent = (
// // 		<>
// // 			<div>
// // 				<div style={{
// // 					textAlign: 'center',
// // 					fontSize: "20px",
// // 					fontWeight: "600",
// // 					display: 'flex',
// // 					justifyContent: 'space-between',
// // 				}}>

// // 					<div></div>
// // 					<h2>
// // 						Add Project
// // 					</h2>

// // 					<div className="cursor-pointer" onClick={handleClose}>
// // 						x
// // 					</div>
// // 				</div>
// // 				<div className="my-2 mt-5 ">

// // 					<div className="mb-3">
// // 						<input
// // 							type="text"
// // 							name="name"
// // 							placeholder="Project Name"
// // 							value={data.name}
// // 							onChange={(e) => setdata({ ...data, name: e.target.value })}
// // 							className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
// // 						/>

// // 					</div>
// // 					<div className="mb-3">
// // 						<div className="col-span-12 md:col-span-6 mb-4">
// // 							<select
// // 								className="modal-input"
// // 								name="department"
// // 								value={data.department || ""}
// // 								onChange={(e) => setdata({ ...data, department: e.target.value })}
// // 							>
// // 								<option value="" disabled>
// // 									Select Department
// // 								</option>
// // 								<option key={-1} value="0">
// // 									All Departments
// // 								</option>
// // 								{dept && dept.length > 0 &&
// // 									dept.map((item, i) => (
// // 										<option key={i} value={item.department_id}>
// // 											{item.deptname}
// // 										</option>
// // 									))
// // 								}
// // 							</select>
// // 							{errors.department && (
// // 								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// // 									{errors.department}
// // 								</span>
// // 							)}
// // 						</div>
// // 					</div>
// // 					<div className="mb-3">
// // 						<div className="col-span-12 md:col-span-6 mb-4">
// // 							<select
// // 								className="modal-input"
// // 								name="client"
// // 								onChange={handlechangeinput}
// // 								value={data.client || ""}
// // 							>
// // 								<option value="" disabled>
// // 									Select a client
// // 								</option>
// // 								<option key={-1} value="0">
// // 									All clients
// // 								</option>
// // 								{clients &&
// // 									clients.length > 0 &&
// // 									clients.map((client, i) => (
// // 										<option key={i} value={client.uuid}>
// // 											{client.name} - {client.deptname}
// // 										</option>
// // 									))}
// // 							</select>
// // 							{errors.client && (
// // 								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// // 									{errors.client}
// // 								</span>
// // 							)}
// // 						</div>
// // 					</div>

// // 					<div className="col-span-12 md:col-span-6 mb-4">
// // 						<select
// // 							className="modal-input"
// // 							name="category"
// // 							value={data.category || ""}
// // 							onChange={(e) => setdata({ ...data, category: e.target.value })}
// // 						>
// // 							<option value="" disabled>
// // 								Select Category
// // 							</option>
// // 							<option value="2">In Progress</option>
// // 							<option value="1">On Hold</option>
// // 							<option value="0">Completed</option>
// // 						</select>
// // 						{errors.category && (
// // 							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// // 								{errors.category}
// // 							</span>
// // 						)}
// // 					</div>



// // 					<div className="mb-3">
// // 						<textarea
// // 							id="permanent_address"
// // 							value={data.permanent_address}
// // 							required
// // 							name="permanent_address"
// // 							className="modal-input"
// // 							onChange={handlechangeinput}
// // 							rows="2"
// // 							placeholder="Project Details"
// // 						/>
// // 						{errors.permanent_address && (
// // 							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// // 								{errors.permanent_address}
// // 							</span>
// // 						)}
// // 					</div>

// // 					{/* 
// // 					<div className="mb-3">
// // 						<div className="">
// // 							<label>Status</label>
// // 							<div className="form-switch mt-2">
// // 								{loading ? (
// // 									<div className="text-center m-5 p-5">
// // 										<HashLoader
// // 											color="#5755d9"
// // 											size={30}
// // 											style={{
// // 												position:
// // 													"absolute",
// // 												right: "50%",
// // 											}}
// // 										/>
// // 									</div>
// // 								) : data &&
// // 									data.status === false ? (
// // 									<input
// // 										type="checkbox"
// // 										name="status"
// // 										onChange={handlechange}
// // 										className="form-check-input"
// // 									/>
// // 								) : (
// // 									<input
// // 										type="checkbox"
// // 										name="status"
// // 										onChange={handlechange}
// // 										value={data.status}
// // 										checked
// // 										className="form-check-input"
// // 									/>
// // 								)}
// // 							</div>
// // 						</div>
// // 					</div> */}
// // 					<button
// // 						type="button"
// // 						onClick={() => {
// // 							if (isEdit) {
// // 								console.log(data?.uuid, 'data?.uuid')
// // 								updateEmployee("");
// // 							}
// // 							savedata()

// // 						}}
// // 						className="modal-button-new"
// // 					>
// // 						Submit
// // 					</button>
// // 				</div>
// // 			</div>
// // 		</>
// // 	);
// // 	// const modalContent = (
// // 	// 	<div>
// // 	// 		<h3>{isEdit ? "Edit Project" : "Add Project"}</h3>
// // 	// 		<button onClick={() => handleOpen(null)}>Add Project</button>
// // 	// 		<div>
// // 	// 			<input
// // 	// 				type="text"
// // 	// 				name="name"
// // 	// 				placeholder="Project Name"
// // 	// 				value={data.name}
// // 	// 				onChange={(e) => setdata({ ...data, name: e.target.value })}
// // 	// 			/>
// // 	// 		</div>
// // 	// 		<div>
// // 	// 			<select
// // 	// 				name="department"
// // 	// 				value={data.department}
// // 	// 				onChange={(e) => setdata({ ...data, department: e.target.value })}
// // 	// 			>
// // 	// 				<option value="0">Select Department</option>
// // 	// 				{dept &&
// // 	// 					dept.map((item, i) => (
// // 	// 						<option key={i} value={item.department_id}>
// // 	// 							{item.deptname}
// // 	// 						</option>
// // 	// 					))}
// // 	// 			</select>
// // 	// 		</div>
// // 	// 		<div>
// // 	// 			<label>
// // 	// 				<input
// // 	// 					type="checkbox"
// // 	// 					name="status"
// // 	// 					checked={data.status}
// // 	// 					onChange={(e) => setdata({ ...data, status: e.target.checked })}
// // 	// 				/>
// // 	// 				Active
// // 	// 			</label>
// // 	// 		</div>
// // 	// 		<button onClick={saveData}>{isEdit ? "Update" : "Save"}</button>
// // 	// 		<button onClick={handleClose}>Cancel</button>
// // 	// 	</div>
// // 	// );

// // 	return (
// // 		<div className="intro-y box lg:mt-5">
// // 			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
// // 				<h2 className="font-medium text-base mr-auto">All Projects  </h2>
// // 				{/* {master === 1 &&
// // 					<button
// // 						type="button"
// // 						onClick={() => addorg()}
// // 						className="btn btn-primary w-50 mr-2"
// // 					>
// // 						Add Organization
// // 					</button>
// // 				} */}
// // 				{/* {add === 0 ? ( */}
// // 				<button
// // 					type="button"
// // 					onClick={() => handleOpen(null)}
// // 					className="btn w-50"
// // 					style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'black' }}
// // 				>
// // 					Add Project
// // 				</button>

// // 				{/* ) : null} */}
// // 			</div>
// // 			{tabtype ? <Masteradmin getData={getData} /> : null}

// // 			<div className="p-5">
// // 				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
// // 					{isadd === true ? (
// // 						<div className="flex-1 mt-6 xl:mt-0">
// // 							<form>
// // 								<div className="grid grid-cols-12 gap-x-5">
// // 									<div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
// // 										<div>
// // 											<label
// // 												htmlFor="update-profile-form-1"
// // 												className="form-label"
// // 											>
// // 												Project Name{" "}
// // 												<span className="text-danger">
// // 													(*)
// // 												</span>
// // 											</label>
// // 											<input
// // 												id="update-profile-form-1"
// // 												value={data.name}
// // 												required
// // 												type="text"
// // 												name="name"
// // 												className="form-control"
// // 												onChange={handlechange}
// // 											/>
// // 										</div>
// // 									</div>
// // 									<div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
// // 										<label
// // 											htmlFor="update-profile-form-2"
// // 											className="form-label"
// // 										>
// // 											Select Department
// // 										</label>
// // 										<select
// // 											className="form-select"
// // 											name="department"
// // 											onChange={handlechange}
// // 											value={data.department}
// // 										>
// // 											<option key={-1} value="0">
// // 												All department
// // 											</option>
// // 											{dept &&
// // 												dept.length > 0 &&
// // 												dept.map((item, i) => {
// // 													return (
// // 														<option
// // 															key={i}
// // 															value={
// // 																item.department_id
// // 															}
// // 														>
// // 															{item.deptname}
// // 														</option>
// // 													);
// // 												})}
// // 										</select>
// // 									</div>

// // 									<div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
// // 										<div className="">
// // 											<label>Status</label>
// // 											<div className="form-switch mt-2">
// // 												{loading ? (
// // 													<div className="text-center m-5 p-5">
// // 														<HashLoader
// // 															color="#5755d9"
// // 															size={30}
// // 															style={{
// // 																position:
// // 																	"absolute",
// // 																right: "50%",
// // 															}}
// // 														/>
// // 													</div>
// // 												) : data &&
// // 													data.status === false ? (
// // 													<input
// // 														type="checkbox"
// // 														name="status"
// // 														onChange={handlechange}
// // 														className="form-check-input"
// // 													/>
// // 												) : (
// // 													<input
// // 														type="checkbox"
// // 														name="status"
// // 														onChange={handlechange}
// // 														value={data.status}
// // 														checked
// // 														className="form-check-input"
// // 													/>
// // 												)}
// // 											</div>
// // 										</div>
// // 									</div>
// // 								</div>
// // 								<button
// // 									type="button"
// // 									onClick={() => savedata()}
// // 									className="btn btn-primary w-20 mt-3"
// // 								>
// // 									Save
// // 								</button>
// // 								<button
// // 									type="button"
// // 									onClick={() => adddata(1)}
// // 									className="btn btn-danger w-20 mt-3 ml-2"
// // 								>
// // 									Cancel
// // 								</button>
// // 								<br />
// // 								<br />
// // 								<span
// // 									className={
// // 										msg.type === "success"
// // 											? "text-success"
// // 											: "text-danger"
// // 									}
// // 								>
// // 									{msg.msg ? msg.msg : null}
// // 								</span>
// // 							</form>
// // 						</div>
// // 					) : null}
// // 				</div>
// // 				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
// // 					<div className="flex mt-5 sm:mt-0">
// // 						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
// // 							<input
// // 								id="tabulator-html-filter-value"
// // 								type="text"
// // 								onChange={filterdata}
// // 								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
// // 								placeholder="Search..."
// // 							/>
// // 						</div>
// // 					</div>
// // 					{/* {master == true ? ( */}
// // 					<div className="flex mt-5 sm:mt-0">
// // 						<select
// // 							className="form-select"
// // 							name="department"
// // 							onChange={(e) => getUserData(e.target.value)}
// // 							value={seledept}
// // 						// value={dept && dept[0].department_id}
// // 						>
// // 							<option key={-1} value="0">
// // 								All department
// // 							</option>
// // 							{dept &&
// // 								dept.length > 0 &&
// // 								dept.map((item, i) => {
// // 									return (
// // 										<option
// // 											key={i}
// // 											value={item.department_id}
// // 										>
// // 											{item.deptname}
// // 										</option>
// // 									);
// // 								})}
// // 						</select>
// // 					</div>
// // 					{/* ) : null} */}
// // 					<div className="hidden md:block ml-auto text-slate-500">
// // 						Showing {itemOffset + 1} to{" "}
// // 						{userdata.length > itemOffset + 10
// // 							? itemOffset + 10
// // 							: userdata.length}{" "}
// // 						of {userdata.length} entries
// // 					</div>
// // 				</div>
// // 				<div className="overflow-x-auto">
// // 					{loading ? (
// // 						<HashLoader
// // 							color="#5755d9"
// // 							size={30}
// // 							style={{ position: "absolute", right: "50%" }}
// // 						/>
// // 					) : (
// // 						<div
// // 							style={{
// // 								border: "1px solid #FC9A30",
// // 								borderTopLeftRadius: "10px",
// // 								borderTopRightRadius: "10px",
// // 							}}
// // 						>
// // 							<div
// // 								style={{
// // 									backgroundColor: "#FC9A30",
// // 									color: "#fff",
// // 									padding: "5px 10px",
// // 									textAlign: "start",
// // 									borderTopLeftRadius: "10px",
// // 									borderTopRightRadius: "10px",
// // 								}}
// // 							>
// // 								<h3>Projects</h3>
// // 							</div>
// // 							<div
// // 								className="table-outlineBorder"
// // 								style={{
// // 									border: "1px solid #FC9A30",
// // 								}}
// // 							>
// // 								<table className="employee-table" style={{ width: "100%" }}>
// // 									<thead>
// // 										<tr>
// // 											<th style={{ width: "20%" }} className="whitespace-nowrap">
// // 												Project Name
// // 											</th>
// // 											<th style={{ width: "30%" }} className="whitespace-nowrap">
// // 												Category
// // 											</th>
// // 											<th className="whitespace-nowrap">Client</th>
// // 											<th className="whitespace-nowrap">Status</th>
// // 										</tr>
// // 									</thead>
// // 									<tbody>
// // 										{currentItems && currentItems.length === 0 && (
// // 											<tr>
// // 												<td colSpan="4">No data available</td>
// // 											</tr>
// // 										)}
// // 										{currentItems &&
// // 											currentItems.length > 0 &&
// // 											currentItems.map((item, i) => {
// // 												return (
// // 													<tr key={i}>
// // 														<td>
// // 															<Link to={"/admin/22/" + userdata[item].project_id}>
// // 																<u>{userdata[item].name}</u>
// // 															</Link>
// // 														</td>
// // 														<td data-tooltip={userdata[item].permanent_address}>
// // 															{userdata[item].permanent_address}
// // 														</td>
// // 														<td>{userdata[item].client_uuid}</td>
// // 														<td className="text-left">
// // 															<div className="flex items-center relative">
// // 																<span>{userdata[item].active === 1 ? "Active" : "Inactive"}</span>
// // 																<button
// // 																	className="ml-2 bg-gray-200 text-gray-700 hover:bg-gray-300 p-1 rounded"
// // 																	onClick={() => toggleDropdown(userdata[item].uuid)}
// // 																>
// // 																	▼
// // 																</button>
// // 																{openDropdown === userdata[item].uuid && (
// // 																	<div className="absolute top-full mt-2 w-20 bg-white border border-gray-300 rounded shadow-lg z-10">
// // 																		{userdata[item].type !== 0 || master === 1 ? (
// // 																			<>
// // 																				<div
// // 																					style={{ fontSize: "16px", cursor: "pointer" }}
// // 																					className="text-right mr-2"
// // 																					onClick={closeDropdown}
// // 																				>
// // 																					x
// // 																				</div>
// // 																				<button
// // 																					className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center"
// // 																					onClick={() => {
// // 																						handleEdit(userdata[item].uuid);
// // 																						closeDropdown();
// // 																					}}
// // 																				>
// // 																					<FaEdit className="mr-2" style={{ color: "gray" }} />
// // 																				</button>
// // 																				{master === 1 && (
// // 																					<>
// // 																						<button
// // 																							className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600 flex items-center"
// // 																							onClick={() => {
// // 																								deletedata(userdata[item].uuid);
// // 																								closeDropdown();
// // 																							}}
// // 																						>
// // 																							<FaTrashAlt className="mr-2" />
// // 																							Delete
// // 																						</button>

// // 																					</>
// // 																				)}
// // 																			</>
// // 																		) : (
// // 																			<span className="block px-4 py-2 text-gray-400">No Actions Available</span>
// // 																		)}
// // 																	</div>
// // 																)}
// // 															</div>
// // 														</td>

// // 													</tr>
// // 												);
// // 											})}
// // 									</tbody>
// // 								</table>
// // 							</div>
// // 						</div>
// // 					)}

// // 					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
// // 						<br />
// // 						<nav className="w-full sm:w-auto sm:mr-auto">
// // 							{!loading ? (
// // 								<div className="row">
// // 									<div className="col-md-3"></div>
// // 									<div className="col-md-4">
// // 										<ReactPaginate
// // 											pageCount={pageCount}
// // 											pageRangeDisplayed={10}
// // 											marginPagesDisplayed={1}
// // 											onPageChange={handlePageClick}
// // 											containerClassName="pagination"
// // 											activeClassName="active"
// // 											pageLinkClassName="page-link"
// // 											breakLinkClassName="page-link"
// // 											nextLinkClassName="page-link"
// // 											previousLinkClassName="page-link"
// // 											pageClassName="page-item"
// // 											breakClassName="page-item"
// // 											nextClassName="page-item"
// // 											previousClassName="page-item"
// // 											previousLabel={<>&laquo;</>}
// // 											nextLabel={<>&raquo;</>}
// // 										/>
// // 									</div>
// // 									<div className="col-md-4"></div>
// // 								</div>
// // 							) : (
// // 								""
// // 							)}
// // 						</nav>
// // 					</div>
// // 				</div>


// // 			</div>
// // 			<CommonModal isOpen={isOpen} content={modalContent} />
// // 		</div>
// // 	);
// // };

// // export default Departments;




// import React, { useState, useEffect } from "react";
// import { SITEURL, BASEURL } from "../BASEURL";
// import "react-toastify/dist/ReactToastify.css";
// import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
// import swal from "sweetalert";
// import HashLoader from "react-spinners/HashLoader";
// import ReactPaginate from "react-paginate";
// import { Link } from "react-router-dom";

// import Masteradmin from "../Masteradmin";
// import CommonModal from "./CommonModal";
// import {
// 	FaSignInAlt,
// 	FaTrashAlt,
// 	FaEdit,
// 	FaToggleOff,
// 	FaToggleOn,
// 	FaTrash,
// } from "react-icons/fa";

// const Departments = () => {
// 	const [userdata, setuserdata] = useState([]);
// 	const [dept, setdept] = useState(null);
// 	const [bkpdata, setbkpdata] = useState([]);
// 	const [errors, setErrors] = useState({});
// 	const [pageCount, setPageCount] = useState(0);
// 	const [clients, setClients] = useState(0);
// 	const [itemOffset, setItemOffset] = useState(0);
// 	const [tooltipData, setTooltipData] = useState({ visible: false, text: "", x: 0, y: 0 });
// 	const [currentItems, setCurrentItems] = useState(null);
// 	const [projects, setProjects] = useState([]);
// 	// const [clients, setClients] = useState([]);
// 	const [mappedProjects, setMappedProjects] = useState([]);
// 	const [isOpen, setIsOpen] = useState(false); // Modal state
// 	const [isEdit, setIsEdit] = useState(false);
// 	const [items, setitems] = useState([]);
// 	const [add, setadd] = useState(2);
// 	const [dept_selected, setdept_selected] = useState();

// 	let [tabtype, settabtype] = useState(false);
// 	let [seledept, setseledept] = useState("");
// 	// populate the category dropdown
// 	const [categories, setCategories] = useState([]);
// 	const [showDropdown, setShowDropdown] = useState(null);
// 	const [loadingCategories, setLoadingCategories] = useState(false); // Loading state
// 	const [getdata, setgetdata] = useState([]);
// 	// const [data, setdata] = useState({
// 	// 	id: "",
// 	// 	name: "",
// 	// 	department: "0",
// 	// 	status: true,
// 	// 	uuid: ``,
// 	// });
// 	const [openDropdown, setOpenDropdown] = useState(null);
// 	const [data, setdata] = useState({
// 		id: "",
// 		name: "",
// 		department: "0",
// 		client: "0",
// 		status: true,
// 		permanent_address: "",
// 		category: "", // New field
// 	});

// 	const handleMouseEnter = (text, e) => {
// 		console.log("Funcation called")
// 		setTooltipData({
// 			visible: true,
// 			text,
// 			x: e.clientX,
// 			y: e.clientY,
// 		});
// 	};

// 	const handleMouseLeave = () => {
// 		setTooltipData({ visible: false, text: "", x: 0, y: 0 });
// 	};
// 	const handleEdit = (project) => {
// 		if (project) {
// 			setdata({
// 				id: project.project_id,
// 				name: project.project_name,
// 				department: project.department,
// 				client: project.client_uuid || "0",
// 				status: project.status === 1,
// 				permanent_address: project.permanent_address || "",
// 				category: project.category_id || "", // Set the category ID
// 			});
// 			setIsEdit(true); // Editing mode
// 			setIsOpen(true); // Open modal
// 		}
// 	};


// 	// const handleEdit = (project) => {
// 	// 	if (project) {
// 	// 		setdata({
// 	// 			id: project.project_id,
// 	// 			name: project.name,
// 	// 			department: project.department,
// 	// 			client: project.client_uuid || "0",
// 	// 			status: project.status === 1,
// 	// 			permanent_address: project.permanent_address || "",
// 	// 			category: project.category || "", // Ensure category is set
// 	// 		});
// 	// 		setIsEdit(true);
// 	// 	} else {
// 	// 		resetForm(); // Reset the form for adding a new project
// 	// 	}
// 	// 	setIsOpen(true);
// 	// };
// 	const handleDropdownToggle = (index) => {
// 		setShowDropdown(showDropdown === index ? null : index); // toggle the dropdown
// 	};

// 	const toggleDropdown = (id) => {
// 		setOpenDropdown(openDropdown === id ? null : id);
// 	};

// 	const closeDropdown = () => {
// 		setOpenDropdown(null);
// 	};


// 	const [isadd, setisadd] = useState(false);
// 	let [loading, setLoading] = useState(false);
// 	let [msg, setmsg] = useState({
// 		type: "",
// 		msg: "",
// 	});

// 	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

// 	useEffect(() => {
// 		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
// 		const endOffset = itemOffset + 10;
// 		setCurrentItems(items.slice(itemOffset, endOffset));
// 		setPageCount(Math.ceil(items.length / 10));
// 		getdepartments();
// 		getData();
// 	}, []);

// 	useEffect(() => {
// 		const endOffset = itemOffset + 10;
// 		setCurrentItems(items.slice(itemOffset, endOffset));
// 		setPageCount(Math.ceil(items.length / 10));
// 	}, [itemOffset]);

// 	const getdepartments = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `getAllDepartments/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
// 					.uuid,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					// console.log(res.data);
// 					if (userdata.role == 2) {
// 						let dept = res.data.filter(
// 							(item) => item.department_id === userdata.department
// 						);
// 						setdept(dept);
// 						getUserData(dept && dept[0].department_id);
// 					} else {
// 						setdept(res.data);
// 						getUserData(res.data && res.data[0].department_id);
// 					}

// 					//   setdept_selected(res.data[0].department_id)
// 					// console.log(res.data);
// 				}
// 				setLoading(false);
// 			})
// 			.catch((err) => console.log(err));
// 	};

// 	const getData = async () => {
// 		settabtype(false);
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata != null) {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		let data = await fetch(BASEURL + `addorganization`, {
// 			method: "GET",
// 			headers: myHeaders,
// 		});
// 		let data2 = await data.json();
// 		setgetdata(data2.data);
// 		setLoading(false);
// 	};

// 	//  get Category funcation
// 	const getCategories = async () => {
// 		setLoadingCategories(true);
// 		const myHeaders = new Headers();
// 		const userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata) {
// 			myHeaders.append("Authorization", userdata.usertoken);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		try {
// 			const response = await fetch(BASEURL + "get-categories", {
// 				method: "GET",
// 				headers: myHeaders,
// 			});
// 			const res = await response.json();
// 			if (res.status === 200) {
// 				setCategories(res.data);
// 				console.log("Categories funcation response", categories)
// 			} else {
// 				console.error("No categories found:", res.error);
// 				setCategories([]);
// 			}
// 		} catch (error) {
// 			console.error("Error fetching categories:", error);
// 		} finally {
// 			setLoadingCategories(false); // End loading
// 		}
// 	};

// 	useEffect(() => {
// 		getCategories()
// 	}, [])

// 	const handlePageClick = (event) => {
// 		const newOffset = (event.selected * 10) % items.length;

// 		setItemOffset(newOffset);
// 	};
// 	useEffect(() => {
// 		getClients()
// 	}, [])
// 	// const getClients = async () => {
// 	// 	setLoading(true);
// 	// 	const myHeaders = new Headers();
// 	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 	// 	if (userdata !== "00:00:00") {
// 	// 		let token = userdata.usertoken;
// 	// 		myHeaders.append("Authorization", token);
// 	// 	}
// 	// 	myHeaders.append("Content-Type", "application/json");
// 	// 	await fetch(BASEURL + `clients`, {
// 	// 		method: "POST",
// 	// 		headers: myHeaders,
// 	// 		body: JSON.stringify({
// 	// 			uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
// 	// 			listtype: 1, // Example payload, adjust as needed
// 	// 			role: 1,
// 	// 			master: 1,
// 	// 			isactive: 1
// 	// 		}),
// 	// 	})
// 	// 		.then((response) => response.json())
// 	// 		.then((res) => {
// 	// 			if (res.status === 201) {
// 	// 				setClients(res.data); // Store clients in state
// 	// 			}
// 	// 			setLoading(false);
// 	// 		})
// 	// 		.catch((err) => {
// 	// 			console.log(err);
// 	// 			setLoading(false);
// 	// 		});
// 	// };

// 	useEffect(() => {
// 		if (projects.length > 0 && clients.length > 0) {
// 			const updatedProjects = projects.map((project) => {
// 				const client = clients.find((c) => c.uuid === project.client_uuid);
// 				return {
// 					...project,
// 					clientName: client ? client.name : "-",
// 					categoryName: project.category_name || "Uncategorized", // Map category name
// 				};
// 			});
// 			setMappedProjects(updatedProjects); // Store mapped projects
// 		}
// 	}, [projects, clients]);



// 	const getUserData = async (e = "") => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		await fetch(BASEURL + `get-projects/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				department: e,
// 				listtype: 1,
// 				role: 1,
// 				uuid: userdata.companydata[0].uuid,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				console.log("Projects response:", res);
// 				if (res.status === 201) {
// 					setProjects(res.projects);
// 				}
// 			})
// 			.catch((err) => console.error(err));
// 		setLoading(false);
// 	};

// 	const getClients = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `clients`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				uuid: userdata.companydata[0].uuid,
// 				listtype: 1,
// 				role: 1,
// 				master: 1,
// 				isactive: 1
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				console.log("Clients response:", res); // Log response
// 				if (res.status === 201) {
// 					setClients(res.data); // Store clients in state
// 				}
// 			})
// 			.catch((err) => {
// 				console.error(err);
// 			});
// 		setLoading(false);
// 	};


// 	const handleStatusChange = (projectId, newStatus) => {
// 		setuserdata((prev) =>
// 			prev.map((project) =>
// 				project.project_id === projectId ? { ...project, status: newStatus } : project
// 			)
// 		);
// 		// Call an API to update status (not implemented)
// 	};

// 	const handlechange = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 		// console.log(data);
// 	};
// 	const savedata = async () => {
// 		if (!data.name || !data.department || !data.client || !data.category) {
// 			swal({
// 				title: "Error",
// 				text: "Please fill in all required fields.",
// 				icon: "error",
// 				timer: 2000,
// 			});
// 			return;
// 		}

// 		setLoading(true); // Show loading state
// 		const userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		const myHeaders = new Headers();
// 		myHeaders.append("Authorization", userdata.usertoken);
// 		myHeaders.append("Content-Type", "application/json");

// 		const payload = {
// 			company_uuid: userdata.companydata[0].uuid,
// 			company: userdata,
// 			data: { ...data },
// 		};

// 		try {
// 			const response = await fetch(`${BASEURL}add-project/`, {
// 				method: "POST",
// 				headers: myHeaders,
// 				body: JSON.stringify(payload),
// 			});
// 			const res = await response.json();

// 			if (res.status === 201) {
// 				swal({
// 					title: "Success",
// 					text: res.message,
// 					icon: "success",
// 					timer: 2000,
// 				});
// 				getUserData(data.department); // Refresh list
// 				resetForm();
// 				setIsOpen(false); // Close modal
// 			} else {
// 				swal({
// 					title: "Error",
// 					text: res.message,
// 					icon: "error",
// 					timer: 2000,
// 				});
// 			}
// 		} catch (error) {
// 			console.error("Error saving project:", error);
// 			swal({
// 				title: "Error",
// 				text: "Something went wrong. Please try again.",
// 				icon: "error",
// 				timer: 2000,
// 			});
// 		} finally {
// 			setLoading(false); // Stop loading
// 		}
// 	};



// 	// const savedata = async () => {
// 	// 	if (!data.name || !data.department || !data.client || !data.category) {
// 	// 		swal({
// 	// 			title: "Error",
// 	// 			text: "Please fill in all required fields.",
// 	// 			icon: "error",
// 	// 			timer: 2000,
// 	// 		});
// 	// 		return;
// 	// 	}

// 	// 	const selectedCategory = categories.find((cat) => cat.id.toString() === data.category);
// 	// 	const categoryName = selectedCategory ? selectedCategory.name : null;

// 	// 	if (!categoryName) {
// 	// 		swal({
// 	// 			title: "Error",
// 	// 			text: "Invalid category selection.",
// 	// 			icon: "error",
// 	// 			timer: 2000,
// 	// 		});
// 	// 		return;
// 	// 	}

// 	// 	setLoading(true);
// 	// 	const myHeaders = new Headers();
// 	// 	const userdata = JSON.parse(localStorage.getItem("isAuth"));

// 	// 	if (userdata) {
// 	// 		myHeaders.append("Authorization", userdata.usertoken);
// 	// 	}
// 	// 	myHeaders.append("Content-Type", "application/json");

// 	// 	const payload = {
// 	// 		company_uuid: userdata.companydata[0].uuid,
// 	// 		company: userdata,
// 	// 		data: {
// 	// 			...data,
// 	// 			category: categoryName, // Send category name instead of ID
// 	// 		},
// 	// 	};

// 	// 	try {
// 	// 		const response = await fetch(`${BASEURL}add-project/`, {
// 	// 			method: "POST",
// 	// 			headers: myHeaders,
// 	// 			body: JSON.stringify(payload),
// 	// 		});
// 	// 		const res = await response.json();

// 	// 		if (res.status === 201) {
// 	// 			swal({
// 	// 				title: "Success",
// 	// 				text: res.message,
// 	// 				icon: "success",
// 	// 				timer: 2000,
// 	// 			});
// 	// 			getUserData(data.department); // Refresh project list
// 	// 			resetForm();
// 	// 			setIsOpen(false);
// 	// 		} else {
// 	// 			swal({
// 	// 				title: "Error",
// 	// 				text: res.message,
// 	// 				icon: "error",
// 	// 				timer: 2000,
// 	// 			});
// 	// 		}
// 	// 	} catch (error) {
// 	// 		console.error("Error saving project:", error);
// 	// 		swal({
// 	// 			title: "Error",
// 	// 			text: "Something went wrong. Please try again.",
// 	// 			icon: "error",
// 	// 			timer: 2000,
// 	// 		});
// 	// 	} finally {
// 	// 		setLoading(false);
// 	// 	}
// 	// };

// 	const editdata = (data) => {
// 		setisadd(true);
// 		let status;
// 		if (data.status === 1) {
// 			status = true;
// 		} else {
// 			status = false;
// 		}
// 		// console.log(data.companyuuid);
// 		setdata({
// 			id: data.project_id,
// 			name: data.name,
// 			department: data.department,
// 			status: status,
// 		});
// 	};

// 	const adddata = (data) => {
// 		// console.log(11);
// 		settabtype(false);
// 		setisadd(!isadd);
// 		setdata({
// 			id: "",
// 			name: "",
// 			department: 0,
// 			status: true,
// 		});
// 	};

// 	const filterdata = (event) => {
// 		if (event.target.value === "") {
// 			loading ? <HashLoader /> : setuserdata(bkpdata);

// 			let arr = [];
// 			for (const key in bkpdata) {
// 				arr.push(key);
// 			}
// 			setitems(arr);

// 			setCurrentItems(arr);
// 			setPageCount(Math.ceil(arr.length / 10));
// 		} else {
// 			var matches = bkpdata.filter(function (s) {
// 				return s.name.toLowerCase().includes(`${event.target.value}`);
// 			});

// 			let arr = [];
// 			for (const key in matches) {
// 				arr.push(key);
// 			}
// 			setitems(arr);

// 			const newOffset = (matches.length * 10) % matches.length;
// 			setItemOffset(newOffset);
// 			setuserdata(matches);
// 			const endOffset = itemOffset + 10;
// 			setCurrentItems(arr.slice(itemOffset, endOffset));
// 			setPageCount(Math.ceil(matches.length / 10));
// 		}
// 	};

// 	const deletedata = (id) => {
// 		swal({
// 			title: "Delete",
// 			text: `Please confirm if you want to Delete Project?`,
// 			buttons: ["No", "Yes"],
// 			showCloseButton: true,
// 		}).then((willDelete) => {
// 			if (willDelete) {
// 				setLoading(true);
// 				const myHeaders = new Headers();
// 				let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 				if (userdata != null) {
// 					let token = userdata.usertoken;
// 					myHeaders.append("Authorization", token);
// 				}
// 				myHeaders.append("Content-Type", "application/json");
// 				fetch(BASEURL + `delete-project/${id}`, {
// 					method: "DELETE",
// 					headers: myHeaders,
// 				})
// 					.then((response) => response.json())
// 					.then((res) => {
// 						getUserData(seledept);
// 						swal({
// 							title: "Success",
// 							text: res.msg,
// 							icon: "success",
// 							timer: 2000,
// 						});
// 					})
// 					.catch((err) => console.log(err));
// 				setLoading(false);
// 			}
// 		});
// 	};
// 	const updateEmployee = async (uuid) => {
// 		// e.preventDefault();
// 		const userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		const myHeaders = new Headers();
// 		if (userdata) {
// 			myHeaders.append("Authorization", userdata.usertoken);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		const payload = { uuid };
// 		// if (data.name !== employeeData.name) payload.name = data.name;
// 		// if (data.department !== employeeData.department)
// 		// 	payload.department = data.department;
// 		// if (data.hod !== employeeData.type) payload.hod = data.hod;
// 		// if (data.contactno !== employeeData.contact_no)
// 		// 	payload.contactno = data.contactno;
// 		// if (data.email !== employeeData.email) payload.email = data.email;
// 		// if (data.dob !== employeeData.dob) payload.dob = data.dob;
// 		// if (data.date_of_joining !== employeeData.date_of_joining)
// 		// 	payload.date_of_joining = data.date_of_joining;
// 		// if (data.status !== employeeData.status) payload.status = data.status;

// 		try {
// 			const response = await fetch(BASEURL + `update-employee`, {
// 				method: "POST",
// 				headers: myHeaders,
// 				body: JSON.stringify(payload),
// 			});
// 			const res = await response.json();

// 			if (res.status === 201) {
// 				swal({
// 					title: "Success",
// 					text: res.message,
// 					icon: "success",
// 					timer: 2000,
// 				}).then(() => {
// 					// navigate("/admin/3");
// 				});
// 			} else {
// 				swal({
// 					title: "Error",
// 					text: "Please Enter Required Values",
// 					icon: "error",
// 					timer: 2000,
// 				});
// 			}
// 		} catch (error) {
// 			console.error("Error updating employee:", error);
// 		} finally {
// 			setIsOpen(false)
// 		}
// 	};
// 	const handlechangeinput = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 	};

// 	const handleCloseDropdown = () => {
// 		setShowDropdown(null);
// 	};

// 	const handleOpen = (project) => {
// 		if (project) {
// 			setdata({
// 				id: project.project_id,
// 				name: project.name,
// 				department: project.department,
// 				status: project.status === 1,
// 			});
// 			setIsEdit(true);
// 		} else {
// 			resetForm();
// 		}
// 		setIsOpen(true);
// 	};

// 	const handleClose = () => {
// 		setIsOpen(false);
// 		resetForm();
// 	};
// 	const resetForm = () => {
// 		setdata({
// 			id: "",
// 			name: "",
// 			department: "0",
// 			client: "0",
// 			status: true,
// 			permanent_address: "",
// 			category: "",
// 		});
// 		setIsEdit(false);
// 	};


// 	// const resetForm = () => {
// 	// 	setdata({
// 	// 		id: "",
// 	// 		name: "",
// 	// 		department: "0",
// 	// 		client: "0",
// 	// 		status: true,
// 	// 		permanent_address: "",
// 	// 		category: "",
// 	// 	});
// 	// 	setIsEdit(false);
// 	// };
// 	const modalContent = (
// 		<>
// 			<div>
// 				<div style={{
// 					textAlign: 'center',
// 					fontSize: "20px",
// 					fontWeight: "600",
// 					display: 'flex',
// 					justifyContent: 'space-between',
// 				}}>

// 					<div></div>
// 					<h2>
// 						Add Project
// 					</h2>

// 					<div className="cursor-pointer" onClick={handleClose}>
// 						x
// 					</div>
// 				</div>
// 				<div className="my-2 mt-5 ">

// 					<div className="mb-3">
// 						<input
// 							type="text"
// 							name="name"
// 							placeholder="Project Name"
// 							value={data.name}
// 							onChange={(e) => setdata({ ...data, name: e.target.value })}
// 							className="modal-input"
// 						/>

// 					</div>
// 					<div className="mb-3">
// 						<div className="col-span-12 md:col-span-6 mb-4">
// 							<select
// 								className="modal-input"
// 								name="department"
// 								value={data.department || ""}
// 								onChange={(e) => setdata({ ...data, department: e.target.value })}
// 							>
// 								<option value="" disabled>
// 									Select Department
// 								</option>
// 								<option key={-1} value="0">
// 									All Departments
// 								</option>
// 								{dept && dept.length > 0 &&
// 									dept.map((item, i) => (
// 										<option key={i} value={item.department_id}>
// 											{item.deptname}
// 										</option>
// 									))
// 								}
// 							</select>
// 							{errors.department && (
// 								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 									{errors.department}
// 								</span>
// 							)}
// 						</div>
// 					</div>
// 					<div className="mb-3">
// 						<div className="col-span-12 md:col-span-6 mb-4">
// 							<select
// 								className="modal-input"
// 								name="client"
// 								onChange={handlechangeinput}
// 								value={data.client || ""}
// 							>
// 								<option value="" disabled>
// 									Select a client
// 								</option>
// 								<option key={-1} value="0">
// 									All clients
// 								</option>
// 								{clients &&
// 									clients.length > 0 &&
// 									clients.map((client, i) => (
// 										<option key={i} value={client.uuid}>
// 											{client.name} - {client.deptname}
// 										</option>
// 									))}
// 							</select>
// 							{errors.client && (
// 								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 									{errors.client}
// 								</span>
// 							)}
// 						</div>
// 					</div>
// 					<select
// 						className="modal-input category-select"
// 						name="category"
// 						value={data.category || ""}
// 						onChange={(e) => setdata({ ...data, category: e.target.value })}
// 					>
// 						<option value="" disabled className="default-option">
// 							Select Category
// 						</option>
// 						{loadingCategories ? (
// 							<option disabled className="default-option">
// 								Loading...
// 							</option>
// 						) : categories.length > 0 ? (
// 							categories.map((category) => (
// 								<option key={category.id} value={category.id} className="category-option">
// 									{category.name}
// 								</option>
// 							))
// 						) : (
// 							<option disabled className="default-option">
// 								No categories available
// 							</option>
// 						)}
// 					</select>
// 					<br />
// 					<div className="mb-3">
// 						<textarea
// 							id="permanent_address"
// 							value={data.permanent_address}
// 							required
// 							name="permanent_address"
// 							className="modal-input"
// 							onChange={handlechangeinput}
// 							rows="2"
// 							placeholder="Project Details"
// 						/>
// 						{errors.permanent_address && (
// 							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 								{errors.permanent_address}
// 							</span>
// 						)}
// 					</div>
// 					{/* <button
// 						type="button"
// 						onClick={() => {
// 							if (isEdit) {
// 								console.log(data?.uuid, 'data?.uuid')
// 								updateEmployee("");
// 							}
// 							savedata()

// 						}}
// 						className="modal-button-new"
// 					>
// 						Submit
// 					</button> */}
// 					<button
// 						type="button"
// 						onClick={() => {
// 							if (isEdit) {
// 								console.log("Editing project:", data.id);
// 							}
// 							savedata(); // Call the save function
// 						}}
// 						className="modal-button-new"
// 					>
// 						{isEdit ? "Update" : "Save"}
// 					</button>
// 				</div>
// 			</div>
// 		</>
// 	);


// 	// get the name of client 

// 	useEffect(() => {
// 		if (projects.length > 0 && clients.length > 0) {
// 			const updatedProjects = projects.map((project) => {
// 				const client = clients.find((c) => c.uuid === project.client_uuid);
// 				return {
// 					...project,
// 					clientName: client ? client.name : "-",
// 				};
// 			});
// 			setMappedProjects(updatedProjects); // Store mapped projects
// 		}
// 	}, [projects, clients]);


// 	return (
// 		<div className="intro-y box lg:mt-5">
// 			<div className="flex items-center p-2  dark:border-darkmode-400">

// 			</div>
// 			{tabtype ? <Masteradmin getData={getData} /> : null}

// 			<div className="p-3">

// 				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
// 					<div className="flex mt-5 sm:mt-0">
// 						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
// 							<input
// 								id="tabulator-html-filter-value"
// 								type="text"
// 								onChange={filterdata}
// 								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
// 								placeholder="Search..."
// 							/>
// 						</div>
// 						<div className="flex mt-5 sm:mt-0">
// 							<select
// 								className="form-select"
// 								name="department"
// 								onChange={(e) => getUserData(e.target.value)}
// 								value={seledept}
// 							>
// 								<option key={-1} value="0">
// 									All department
// 								</option>
// 								{dept && dept.length > 0 &&
// 									dept.map((item, i) => (
// 										<option key={i} value={item.department_id}>
// 											{item.deptname}
// 										</option>
// 									))}
// 							</select>
// 						</div>
// 					</div>

// 					{/* Add Project Button */}
// 					<div className="flex mt-5 sm:mt-0 sm:ml-auto">
// 						<button
// 							type="button"
// 							onClick={() => handleOpen(null)}
// 							className="btn w-50 add-emp-btn"
// 							style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
// 						>
// 							Add Project
// 						</button>

// 					</div>
// 				</div>
// 				<div className="overflow-x-auto">
// 					{loading ? (
// 						<HashLoader
// 							color="#5755d9"
// 							size={30}
// 							style={{ position: "absolute", right: "50%" }}
// 						/>
// 					) : (
// 						<div
// 							style={{
// 								// border: "1px solid #FC9A30",
// 								// padding: "10px",
// 								borderTopLeftRadius: "8px",
// 								borderTopRightRadius: "8px",
// 							}}
// 						>
// 							<div
// 								style={{
// 									backgroundColor: "#FC9A30",
// 									color: "#fff",
// 									padding: "12px",
// 									textAlign: "start",
// 									borderTopLeftRadius: "8px",
// 									borderTopRightRadius: "8px",
// 								}}
// 							>
// 								<h3>Projects </h3>
// 							</div>
// 							<div
// 								className="table-outlineBorder"
// 								style={{
// 									border: "1px solid #FC9A30",
// 								}}
// 							>
// 								<table className="employee-table" style={{ width: "100%" }}>
// 									<thead>
// 										<tr>
// 											<th style={{ width: "20%" }} className="whitespace-nowrap">
// 												Project Name
// 											</th>
// 											<th style={{ width: "30%" }} className="whitespace-nowrap">
// 												Category
// 											</th>
// 											<th className="whitespace-nowrap">Client</th>
// 											<th className="whitespace-nowrap">Status</th>
// 										</tr>
// 									</thead>

// 									{tooltipData.visible && (
// 										<div
// 											className="absolute bg-gray-800 text-white text-xs rounded-md px-2 py-1"
// 											style={{
// 												top: tooltipData.y + 10, // Add offset
// 												left: tooltipData.x + 10, // Add offset
// 												position: "fixed", // Fixed position to handle scrolling
// 												zIndex: 1000,
// 											}}
// 										>
// 											{tooltipData.text}
// 										</div>
// 									)}


// 									<tbody>
// 										{mappedProjects.length > 0 ? (
// 											mappedProjects.map((item, i) => (
// 												<tr key={i}>
// 													<td>
// 														<div className="flex items-center justify-between">
// 															<span>{item.project_name}</span>
// 															<div className="icon ml-2" data-tooltip={item.permanent_address || "None"}>
// 																{item.permanent_address ? (
// 																	<svg
// 																		width="15"
// 																		height="15"
// 																		viewBox="0 0 24 24"
// 																		fill="none"
// 																		xmlns="http://www.w3.org/2000/svg"
// 																	>
// 																		{/* Circle background */}
// 																		<circle
// 																			cx="12"
// 																			cy="12"
// 																			r="10"
// 																			stroke={showDropdown === i ? "#FC9A30" : "#47525D"} // Color change based on dropdown state
// 																			fill="none"
// 																			strokeWidth="2"
// 																		/>

// 																		{/* Exclamation Mark */}
// 																		<text
// 																			x="12"
// 																			y="16"
// 																			fontSize="12"
// 																			fontWeight="bold"
// 																			textAnchor="middle"
// 																			fill={showDropdown === i ? "#FC9A30" : "#47525D"} // Color change based on dropdown state
// 																			fontFamily="Arial, sans-serif"
// 																		>
// 																			!
// 																		</text>
// 																	</svg>
// 																) : (
// 																	<span className="text-gray-500">None</span> // Display "None" if `permanent_address` is empty or undefined
// 																)}
// 															</div>

// 														</div>
// 													</td>
// 													<td>{item.category_name}</td>
// 													<td>{item.clientName || "-"}</td>
// 													<td>
// 														<div className="flex items-center justify-between">
// 															<span className="text-sm font-medium">
// 																{item.status === 1 ? "Active" : "Inactive"}
// 															</span>

// 															<div
// 																className={`relative inline-block text-left ml-2 ${showDropdown === i ? "bg-[#f5f5f5]" : ""
// 																	}`}
// 															>
// 																<button
// 																	onClick={() => handleDropdownToggle(i)}
// 																	className="flex items-center text-gray-600 text-sm"
// 																>
// 																	▼
// 																</button>
// 																{showDropdown === i && (
// 																	<div className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 shadow-lg rounded-md">
// 																		<button
// 																			onClick={handleCloseDropdown}
// 																			className="absolute top-1 right-1 text-gray-500 hover:text-gray-700 focus:outline-none"
// 																		>
// 																			<AiOutlineClose size={14} />
// 																		</button>
// 																		<ul className="py-1">
// 																			<li
// 																				onClick={() => handleEdit(item)}
// 																				className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 rounded-md"
// 																			>
// 																				<FaEdit className="text-gray-600 mr-2" />
// 																				Edit
// 																			</li>
// 																			<li className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
// 																				Progress
// 																			</li>
// 																			<li className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
// 																				On Hold
// 																			</li>
// 																			<li className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
// 																				Completed
// 																			</li>
// 																			<li className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 rounded-md">

// 																				<FaTrash className="text-red-600 mr-2" />
// 																				Delete
// 																			</li>
// 																		</ul>
// 																	</div>
// 																)}
// 															</div>
// 														</div>
// 													</td>
// 												</tr>
// 											))
// 										) : (
// 											<tr>
// 												<td colSpan="4">No projects available</td>
// 											</tr>
// 										)}
// 									</tbody>

// 								</table>
// 							</div>
// 						</div>
// 					)}

// 					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
// 						<br />
// 						<nav className="w-full sm:w-auto sm:mr-auto">
// 							{!loading ? (
// 								<div className="row">
// 									<div className="col-md-3"></div>
// 									<div className="col-md-4">
// 										<ReactPaginate
// 											pageCount={pageCount}
// 											pageRangeDisplayed={10}
// 											marginPagesDisplayed={1}
// 											onPageChange={handlePageClick}
// 											containerClassName="pagination"
// 											activeClassName="active"
// 											pageLinkClassName="page-link"
// 											breakLinkClassName="page-link"
// 											nextLinkClassName="page-link"
// 											previousLinkClassName="page-link"
// 											pageClassName="page-item"
// 											breakClassName="page-item"
// 											nextClassName="page-item"
// 											previousClassName="page-item"
// 											previousLabel={<>&laquo;</>}
// 											nextLabel={<>&raquo;</>}
// 										/>
// 									</div>
// 									<div className="col-md-4"></div>
// 								</div>
// 							) : (
// 								""
// 							)}
// 						</nav>
// 					</div>
// 				</div>


// 			</div>
// 			<CommonModal isOpen={isOpen} content={modalContent} />
// 		</div>
// 	);
// };

// export default Departments;





// import React, { useState, useEffect } from "react";
// import { SITEURL, BASEURL } from "../BASEURL";
// import "react-toastify/dist/ReactToastify.css";
// import swal from "sweetalert";
// import HashLoader from "react-spinners/HashLoader";
// import ReactPaginate from "react-paginate";
// import { Link } from "react-router-dom";
// import Masteradmin from "../Masteradmin";
// import CommonModal from "./CommonModal";
// import {
// 	FaSignInAlt,
// 	FaTrashAlt,
// 	FaEdit,
// 	FaToggleOff,
// 	FaToggleOn,
// } from "react-icons/fa";

// const Departments = () => {
// 	const [userdata, setuserdata] = useState([]);
// 	const [dept, setdept] = useState(null);
// 	const [bkpdata, setbkpdata] = useState([]);
// 	const [errors, setErrors] = useState({});
// 	const [pageCount, setPageCount] = useState(0);
// 	const [clients, setClients] = useState(0);
// 	const [itemOffset, setItemOffset] = useState(0);
// 	const [currentItems, setCurrentItems] = useState(null);
// 	const [isOpen, setIsOpen] = useState(false); // Modal state
// 	const [isEdit, setIsEdit] = useState(false);
// 	const [items, setitems] = useState([]);
// 	const [add, setadd] = useState(2);
// 	const [dept_selected, setdept_selected] = useState();
// 	let [tabtype, settabtype] = useState(false);
// 	let [seledept, setseledept] = useState("");

// 	const [getdata, setgetdata] = useState([]);
// 	// const [data, setdata] = useState({
// 	// 	id: "",
// 	// 	name: "",
// 	// 	department: "0",
// 	// 	status: true,
// 	// 	uuid: ``,
// 	// });
// 	const [openDropdown, setOpenDropdown] = useState(null);
// 	const [data, setdata] = useState({
// 		id: "",
// 		name: "",
// 		department: "0",
// 		client: "0",
// 		status: true,
// 		permanent_address: "",
// 		category: "", // New field
// 	});
// 	const handleEdit = (uuid) => {
// 		if (uuid) {
// 			setIsEdit(true)
// 			// setUid(uuid)
// 		}
// 		setIsOpen(true);
// 		// getEmployeeData(uuid)

// 	}
// 	const toggleDropdown = (id) => {
// 		setOpenDropdown(openDropdown === id ? null : id);
// 	};

// 	const closeDropdown = () => {
// 		setOpenDropdown(null);
// 	};


// 	const [isadd, setisadd] = useState(false);
// 	let [loading, setLoading] = useState(false);
// 	let [msg, setmsg] = useState({
// 		type: "",
// 		msg: "",
// 	});

// 	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

// 	useEffect(() => {
// 		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
// 		const endOffset = itemOffset + 10;
// 		setCurrentItems(items.slice(itemOffset, endOffset));
// 		setPageCount(Math.ceil(items.length / 10));
// 		getdepartments();
// 		getData();
// 	}, []);

// 	useEffect(() => {
// 		const endOffset = itemOffset + 10;
// 		setCurrentItems(items.slice(itemOffset, endOffset));
// 		setPageCount(Math.ceil(items.length / 10));
// 	}, [itemOffset]);

// 	const getdepartments = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `getAllDepartments/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
// 					.uuid,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					// console.log(res.data);
// 					if (userdata.role == 2) {
// 						let dept = res.data.filter(
// 							(item) => item.department_id === userdata.department
// 						);
// 						setdept(dept);
// 						getUserData(dept && dept[0].department_id);
// 					} else {
// 						setdept(res.data);
// 						getUserData(res.data && res.data[0].department_id);
// 					}

// 					//   setdept_selected(res.data[0].department_id)
// 					// console.log(res.data);
// 				}
// 				setLoading(false);
// 			})
// 			.catch((err) => console.log(err));
// 	};

// 	const getData = async () => {
// 		settabtype(false);
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata != null) {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		let data = await fetch(BASEURL + `addorganization`, {
// 			method: "GET",
// 			headers: myHeaders,
// 		});
// 		let data2 = await data.json();
// 		setgetdata(data2.data);
// 		setLoading(false);
// 	};

// 	const handlePageClick = (event) => {
// 		const newOffset = (event.selected * 10) % items.length;

// 		setItemOffset(newOffset);
// 	};
// 	useEffect(() => {
// 		getClients()
// 	}, [])
// 	const getClients = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `clients`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
// 				listtype: 1, // Example payload, adjust as needed
// 				role: 1,
// 				master: 1,
// 				isactive: 1
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					setClients(res.data); // Store clients in state
// 				}
// 				setLoading(false);
// 			})
// 			.catch((err) => {
// 				console.log(err);
// 				setLoading(false);
// 			});
// 	};



// 	const getUserData = async (e = "") => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));

// 		if (userdata !== "00:00:00") {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		await fetch(BASEURL + `get-projects/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				department: e,
// 				listtype: 1,
// 				role: 1,
// 				uuid: userdata.companydata[0].uuid,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					setuserdata(res.projects);
// 					setCurrentItems(Object.keys(res.projects).slice(0, 10)); // Show initial 10 items
// 				}
// 			})
// 			.catch((err) => console.error(err));

// 		setLoading(false);
// 	};

// 	const handleStatusChange = (projectId, newStatus) => {
// 		setuserdata((prev) =>
// 			prev.map((project) =>
// 				project.project_id === projectId ? { ...project, status: newStatus } : project
// 			)
// 		);
// 		// Call an API to update status (not implemented)
// 	};

// 	const handlechange = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 		// console.log(data);
// 	};


// 	const savedata = async () => {
// 		if (
// 			data.name === "" ||
// 			data.department === "" ||
// 			data.client === "" ||
// 			data.category === ""
// 		) {
// 			swal({
// 				title: "Error",
// 				text: "Please fill in all required fields.",
// 				icon: "error",
// 				timer: 2000,
// 			});
// 			return;
// 		}

// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata != null) {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		await fetch(BASEURL + `add-project/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				company_uuid: userdata.companydata[0].uuid,
// 				company: userdata,
// 				data, // Includes `data.category`
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					getUserData(data.department);
// 					setmsg({ type: res.type, msg: res.message });
// 					setdata({
// 						id: "",
// 						name: "",
// 						department: "0",
// 						client: "0",
// 						status: true,
// 						permanent_address: "",
// 						category: "", // Reset category
// 					});

// 					swal({
// 						title: "Success",
// 						text: res.message,
// 						icon: "success",
// 						timer: 2000,
// 					});
// 				} else {
// 					swal({
// 						title: "Error",
// 						text: res.message,
// 						icon: "error",
// 						timer: 2000,
// 					});
// 				}
// 			})
// 			.catch((err) => console.log(err))
// 			.finally(() => setLoading(false));
// 	};


// 	// const savedata = async (elm) => {
// 	// 	if (data.name === "" || data.department === "" || data.client === "") {
// 	// 		swal({
// 	// 			title: "Error",
// 	// 			text: "Please fill in all required fields.",
// 	// 			icon: "error",
// 	// 			timer: 2000,
// 	// 		});
// 	// 		return;
// 	// 	}
// 	// 	// console.log(data); return
// 	// 	setLoading(true);
// 	// 	const myHeaders = new Headers();
// 	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 	// 	if (userdata != null) {
// 	// 		let token = userdata.usertoken;
// 	// 		myHeaders.append("Authorization", token);
// 	// 	}
// 	// 	myHeaders.append("Content-Type", "application/json");
// 	// 	await fetch(BASEURL + `add-project/`, {
// 	// 		method: "POST",
// 	// 		headers: myHeaders,
// 	// 		body: JSON.stringify({
// 	// 			company_uuid: userdata.companydata[0].uuid,
// 	// 			company: userdata,
// 	// 			data,
// 	// 		}),
// 	// 	})
// 	// 		.then((response) => response.json())
// 	// 		.then((res) => {
// 	// 			if (res.status === 201) {
// 	// 				getUserData(data.department);
// 	// 				userdata.companydata[0].name = data.name;
// 	// 				localStorage.setItem("isAuth", JSON.stringify(userdata));
// 	// 				setmsg({
// 	// 					type: res.type,
// 	// 					msg: res.message,
// 	// 				});
// 	// 				setdata({
// 	// 					id: "",
// 	// 					name: "",
// 	// 					department: 1,
// 	// 					status: true,
// 	// 				});

// 	// 				swal({
// 	// 					title: "Success",
// 	// 					text: res.message,
// 	// 					icon: "success",
// 	// 					timer: 2000,
// 	// 				});
// 	// 				setTimeout(() => {
// 	// 					setmsg({
// 	// 						type: "",
// 	// 						msg: "",
// 	// 					});
// 	// 					setisadd(!isadd);
// 	// 				}, 1000);
// 	// 			} else {
// 	// 				swal({
// 	// 					title: "Error",
// 	// 					text: res.message,
// 	// 					icon: "error",
// 	// 					timer: 2000,
// 	// 				});
// 	// 			}
// 	// 		})
// 	// 		.catch((err) => console.log(err));
// 	// 	setLoading(false);
// 	// };

// 	const editdata = (data) => {
// 		setisadd(true);
// 		let status;
// 		if (data.status === 1) {
// 			status = true;
// 		} else {
// 			status = false;
// 		}
// 		// console.log(data.companyuuid);
// 		setdata({
// 			id: data.project_id,
// 			name: data.name,
// 			department: data.department,
// 			status: status,
// 		});
// 	};

// 	const adddata = (data) => {
// 		// console.log(11);
// 		settabtype(false);
// 		setisadd(!isadd);
// 		setdata({
// 			id: "",
// 			name: "",
// 			department: 0,
// 			status: true,
// 		});
// 	};

// 	const filterdata = (event) => {
// 		if (event.target.value === "") {
// 			loading ? <HashLoader /> : setuserdata(bkpdata);

// 			let arr = [];
// 			for (const key in bkpdata) {
// 				arr.push(key);
// 			}
// 			setitems(arr);

// 			setCurrentItems(arr);
// 			setPageCount(Math.ceil(arr.length / 10));
// 		} else {
// 			var matches = bkpdata.filter(function (s) {
// 				return s.name.toLowerCase().includes(`${event.target.value}`);
// 			});

// 			let arr = [];
// 			for (const key in matches) {
// 				arr.push(key);
// 			}
// 			setitems(arr);

// 			const newOffset = (matches.length * 10) % matches.length;
// 			setItemOffset(newOffset);
// 			setuserdata(matches);
// 			const endOffset = itemOffset + 10;
// 			setCurrentItems(arr.slice(itemOffset, endOffset));
// 			setPageCount(Math.ceil(matches.length / 10));
// 		}
// 	};

// 	const deletedata = (id) => {
// 		swal({
// 			title: "Delete",
// 			text: `Please confirm if you want to Delete Project?`,
// 			buttons: ["No", "Yes"],
// 			showCloseButton: true,
// 		}).then((willDelete) => {
// 			if (willDelete) {
// 				setLoading(true);
// 				const myHeaders = new Headers();
// 				let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 				if (userdata != null) {
// 					let token = userdata.usertoken;
// 					myHeaders.append("Authorization", token);
// 				}
// 				myHeaders.append("Content-Type", "application/json");
// 				fetch(BASEURL + `delete-project/${id}`, {
// 					method: "DELETE",
// 					headers: myHeaders,
// 				})
// 					.then((response) => response.json())
// 					.then((res) => {
// 						getUserData(seledept);
// 						swal({
// 							title: "Success",
// 							text: res.msg,
// 							icon: "success",
// 							timer: 2000,
// 						});
// 					})
// 					.catch((err) => console.log(err));
// 				setLoading(false);
// 			}
// 		});
// 	};
// 	const updateEmployee = async (uuid) => {
// 		// e.preventDefault();
// 		const userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		const myHeaders = new Headers();
// 		if (userdata) {
// 			myHeaders.append("Authorization", userdata.usertoken);
// 		}
// 		myHeaders.append("Content-Type", "application/json");

// 		const payload = { uuid };
// 		// if (data.name !== employeeData.name) payload.name = data.name;
// 		// if (data.department !== employeeData.department)
// 		// 	payload.department = data.department;
// 		// if (data.hod !== employeeData.type) payload.hod = data.hod;
// 		// if (data.contactno !== employeeData.contact_no)
// 		// 	payload.contactno = data.contactno;
// 		// if (data.email !== employeeData.email) payload.email = data.email;
// 		// if (data.dob !== employeeData.dob) payload.dob = data.dob;
// 		// if (data.date_of_joining !== employeeData.date_of_joining)
// 		// 	payload.date_of_joining = data.date_of_joining;
// 		// if (data.status !== employeeData.status) payload.status = data.status;

// 		try {
// 			const response = await fetch(BASEURL + `update-employee`, {
// 				method: "POST",
// 				headers: myHeaders,
// 				body: JSON.stringify(payload),
// 			});
// 			const res = await response.json();

// 			if (res.status === 201) {
// 				swal({
// 					title: "Success",
// 					text: res.message,
// 					icon: "success",
// 					timer: 2000,
// 				}).then(() => {
// 					// navigate("/admin/3");
// 				});
// 			} else {
// 				swal({
// 					title: "Error",
// 					text: "Please Enter Required Values",
// 					icon: "error",
// 					timer: 2000,
// 				});
// 			}
// 		} catch (error) {
// 			console.error("Error updating employee:", error);
// 		} finally {
// 			setIsOpen(false)
// 		}
// 	};
// 	const handlechangeinput = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 	};
// 	// const savedata = async () => {
// 	// 	if (data.name === "" || data.department === "" || data.client === "") {
// 	// 		swal({
// 	// 			title: "Error",
// 	// 			text: "Please fill in all required fields.",
// 	// 			icon: "error",
// 	// 			timer: 2000,
// 	// 		});
// 	// 		return;
// 	// 	}

// 	// 	setLoading(true);
// 	// 	const myHeaders = new Headers();
// 	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 	// 	if (userdata != null) {
// 	// 		let token = userdata.usertoken;
// 	// 		myHeaders.append("Authorization", token);
// 	// 	}
// 	// 	myHeaders.append("Content-Type", "application/json");

// 	// 	await fetch(BASEURL + `add-project/`, {
// 	// 		method: "POST",
// 	// 		headers: myHeaders,
// 	// 		body: JSON.stringify({
// 	// 			company_uuid: userdata.companydata[0].uuid,
// 	// 			company: userdata,
// 	// 			data, // Ensure `data.client` and `data.permanent_address` are set
// 	// 		}),
// 	// 	})
// 	// 		.then((response) => response.json())
// 	// 		.then((res) => {
// 	// 			if (res.status === 201) {
// 	// 				getUserData(data.department);
// 	// 				setmsg({ type: res.type, msg: res.message });
// 	// 				setdata({
// 	// 					id: "",
// 	// 					name: "",
// 	// 					department: "0",
// 	// 					client: "0",
// 	// 					status: true,
// 	// 					permanent_address: "",
// 	// 				});

// 	// 				swal({
// 	// 					title: "Success",
// 	// 					text: res.message,
// 	// 					icon: "success",
// 	// 					timer: 2000,
// 	// 				});
// 	// 			} else {
// 	// 				swal({
// 	// 					title: "Error",
// 	// 					text: res.message,
// 	// 					icon: "error",
// 	// 					timer: 2000,
// 	// 				});
// 	// 			}
// 	// 		})
// 	// 		.catch((err) => console.log(err))
// 	// 		.finally(() => setLoading(false));
// 	// };


// 	const handleOpen = (project) => {
// 		if (project) {
// 			setdata({
// 				id: project.project_id,
// 				name: project.name,
// 				department: project.department,
// 				status: project.status === 1,
// 			});
// 			setIsEdit(true);
// 		} else {
// 			resetForm();
// 		}
// 		setIsOpen(true);
// 	};

// 	const handleClose = () => {
// 		setIsOpen(false);
// 		resetForm();
// 	};

// 	const resetForm = () => {
// 		setdata({
// 			id: "",
// 			name: "",
// 			department: "0",
// 			status: true,
// 		});
// 		setIsEdit(false);
// 	};

// 	const modalContent = (
// 		<>
// 			<div>
// 				<div style={{
// 					textAlign: 'center',
// 					fontSize: "20px",
// 					fontWeight: "600",
// 					display: 'flex',
// 					justifyContent: 'space-between',
// 				}}>

// 					<div></div>
// 					<h2>
// 						Add Project
// 					</h2>

// 					<div className="cursor-pointer" onClick={handleClose}>
// 						x
// 					</div>
// 				</div>
// 				<div className="my-2 mt-5 ">

// 					<div className="mb-3">
// 						<input
// 							type="text"
// 							name="name"
// 							placeholder="Project Name"
// 							value={data.name}
// 							onChange={(e) => setdata({ ...data, name: e.target.value })}
// 							className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
// 						/>

// 					</div>
// 					<div className="mb-3">
// 						<div className="col-span-12 md:col-span-6 mb-4">
// 							<select
// 								className="modal-input"
// 								name="department"
// 								value={data.department || ""}
// 								onChange={(e) => setdata({ ...data, department: e.target.value })}
// 							>
// 								<option value="" disabled>
// 									Select Department
// 								</option>
// 								<option key={-1} value="0">
// 									All Departments
// 								</option>
// 								{dept && dept.length > 0 &&
// 									dept.map((item, i) => (
// 										<option key={i} value={item.department_id}>
// 											{item.deptname}
// 										</option>
// 									))
// 								}
// 							</select>
// 							{errors.department && (
// 								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 									{errors.department}
// 								</span>
// 							)}
// 						</div>
// 					</div>
// 					<div className="mb-3">
// 						<div className="col-span-12 md:col-span-6 mb-4">
// 							<select
// 								className="modal-input"
// 								name="client"
// 								onChange={handlechangeinput}
// 								value={data.client || ""}
// 							>
// 								<option value="" disabled>
// 									Select a client
// 								</option>
// 								<option key={-1} value="0">
// 									All clients
// 								</option>
// 								{clients &&
// 									clients.length > 0 &&
// 									clients.map((client, i) => (
// 										<option key={i} value={client.uuid}>
// 											{client.name} - {client.deptname}
// 										</option>
// 									))}
// 							</select>
// 							{errors.client && (
// 								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 									{errors.client}
// 								</span>
// 							)}
// 						</div>
// 					</div>

// 					<div className="col-span-12 md:col-span-6 mb-4">
// 						<select
// 							className="modal-input"
// 							name="category"
// 							value={data.category || ""}
// 							onChange={(e) => setdata({ ...data, category: e.target.value })}
// 						>
// 							<option value="" disabled>
// 								Select Category
// 							</option>
// 							<option value="2">In Progress</option>
// 							<option value="1">On Hold</option>
// 							<option value="0">Completed</option>
// 						</select>
// 						{errors.category && (
// 							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 								{errors.category}
// 							</span>
// 						)}
// 					</div>



// 					<div className="mb-3">
// 						<textarea
// 							id="permanent_address"
// 							value={data.permanent_address}
// 							required
// 							name="permanent_address"
// 							className="modal-input"
// 							onChange={handlechangeinput}
// 							rows="2"
// 							placeholder="Project Details"
// 						/>
// 						{errors.permanent_address && (
// 							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
// 								{errors.permanent_address}
// 							</span>
// 						)}
// 					</div>

// 					{/* 
// 					<div className="mb-3">
// 						<div className="">
// 							<label>Status</label>
// 							<div className="form-switch mt-2">
// 								{loading ? (
// 									<div className="text-center m-5 p-5">
// 										<HashLoader
// 											color="#5755d9"
// 											size={30}
// 											style={{
// 												position:
// 													"absolute",
// 												right: "50%",
// 											}}
// 										/>
// 									</div>
// 								) : data &&
// 									data.status === false ? (
// 									<input
// 										type="checkbox"
// 										name="status"
// 										onChange={handlechange}
// 										className="form-check-input"
// 									/>
// 								) : (
// 									<input
// 										type="checkbox"
// 										name="status"
// 										onChange={handlechange}
// 										value={data.status}
// 										checked
// 										className="form-check-input"
// 									/>
// 								)}
// 							</div>
// 						</div>
// 					</div> */}
// 					<button
// 						type="button"
// 						onClick={() => {
// 							if (isEdit) {
// 								console.log(data?.uuid, 'data?.uuid')
// 								updateEmployee("");
// 							}
// 							savedata()

// 						}}
// 						className="modal-button-new"
// 					>
// 						Submit
// 					</button>
// 				</div>
// 			</div>
// 		</>
// 	);
// 	// const modalContent = (
// 	// 	<div>
// 	// 		<h3>{isEdit ? "Edit Project" : "Add Project"}</h3>
// 	// 		<button onClick={() => handleOpen(null)}>Add Project</button>
// 	// 		<div>
// 	// 			<input
// 	// 				type="text"
// 	// 				name="name"
// 	// 				placeholder="Project Name"
// 	// 				value={data.name}
// 	// 				onChange={(e) => setdata({ ...data, name: e.target.value })}
// 	// 			/>
// 	// 		</div>
// 	// 		<div>
// 	// 			<select
// 	// 				name="department"
// 	// 				value={data.department}
// 	// 				onChange={(e) => setdata({ ...data, department: e.target.value })}
// 	// 			>
// 	// 				<option value="0">Select Department</option>
// 	// 				{dept &&
// 	// 					dept.map((item, i) => (
// 	// 						<option key={i} value={item.department_id}>
// 	// 							{item.deptname}
// 	// 						</option>
// 	// 					))}
// 	// 			</select>
// 	// 		</div>
// 	// 		<div>
// 	// 			<label>
// 	// 				<input
// 	// 					type="checkbox"
// 	// 					name="status"
// 	// 					checked={data.status}
// 	// 					onChange={(e) => setdata({ ...data, status: e.target.checked })}
// 	// 				/>
// 	// 				Active
// 	// 			</label>
// 	// 		</div>
// 	// 		<button onClick={saveData}>{isEdit ? "Update" : "Save"}</button>
// 	// 		<button onClick={handleClose}>Cancel</button>
// 	// 	</div>
// 	// );

// 	return (
// 		<div className="intro-y box lg:mt-5">
// 			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
// 				<h2 className="font-medium text-base mr-auto">All Projects  </h2>
// 				{/* {master === 1 &&
// 					<button
// 						type="button"
// 						onClick={() => addorg()}
// 						className="btn btn-primary w-50 mr-2"
// 					>
// 						Add Organization
// 					</button>
// 				} */}
// 				{/* {add === 0 ? ( */}
// 				<button
// 					type="button"
// 					onClick={() => handleOpen(null)}
// 					className="btn w-50"
// 					style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'black' }}
// 				>
// 					Add Project
// 				</button>

// 				{/* ) : null} */}
// 			</div>
// 			{tabtype ? <Masteradmin getData={getData} /> : null}

// 			<div className="p-5">
// 				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
// 					{isadd === true ? (
// 						<div className="flex-1 mt-6 xl:mt-0">
// 							<form>
// 								<div className="grid grid-cols-12 gap-x-5">
// 									<div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
// 										<div>
// 											<label
// 												htmlFor="update-profile-form-1"
// 												className="form-label"
// 											>
// 												Project Name{" "}
// 												<span className="text-danger">
// 													(*)
// 												</span>
// 											</label>
// 											<input
// 												id="update-profile-form-1"
// 												value={data.name}
// 												required
// 												type="text"
// 												name="name"
// 												className="form-control"
// 												onChange={handlechange}
// 											/>
// 										</div>
// 									</div>
// 									<div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
// 										<label
// 											htmlFor="update-profile-form-2"
// 											className="form-label"
// 										>
// 											Select Department
// 										</label>
// 										<select
// 											className="form-select"
// 											name="department"
// 											onChange={handlechange}
// 											value={data.department}
// 										>
// 											<option key={-1} value="0">
// 												All department
// 											</option>
// 											{dept &&
// 												dept.length > 0 &&
// 												dept.map((item, i) => {
// 													return (
// 														<option
// 															key={i}
// 															value={
// 																item.department_id
// 															}
// 														>
// 															{item.deptname}
// 														</option>
// 													);
// 												})}
// 										</select>
// 									</div>

// 									<div className="lg:col-span-6 md:col-span-6 2xl:col-span-2 col-span-12 mt-3 mb-3">
// 										<div className="">
// 											<label>Status</label>
// 											<div className="form-switch mt-2">
// 												{loading ? (
// 													<div className="text-center m-5 p-5">
// 														<HashLoader
// 															color="#5755d9"
// 															size={30}
// 															style={{
// 																position:
// 																	"absolute",
// 																right: "50%",
// 															}}
// 														/>
// 													</div>
// 												) : data &&
// 													data.status === false ? (
// 													<input
// 														type="checkbox"
// 														name="status"
// 														onChange={handlechange}
// 														className="form-check-input"
// 													/>
// 												) : (
// 													<input
// 														type="checkbox"
// 														name="status"
// 														onChange={handlechange}
// 														value={data.status}
// 														checked
// 														className="form-check-input"
// 													/>
// 												)}
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 								<button
// 									type="button"
// 									onClick={() => savedata()}
// 									className="btn btn-primary w-20 mt-3"
// 								>
// 									Save
// 								</button>
// 								<button
// 									type="button"
// 									onClick={() => adddata(1)}
// 									className="btn btn-danger w-20 mt-3 ml-2"
// 								>
// 									Cancel
// 								</button>
// 								<br />
// 								<br />
// 								<span
// 									className={
// 										msg.type === "success"
// 											? "text-success"
// 											: "text-danger"
// 									}
// 								>
// 									{msg.msg ? msg.msg : null}
// 								</span>
// 							</form>
// 						</div>
// 					) : null}
// 				</div>
// 				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
// 					<div className="flex mt-5 sm:mt-0">
// 						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
// 							<input
// 								id="tabulator-html-filter-value"
// 								type="text"
// 								onChange={filterdata}
// 								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
// 								placeholder="Search..."
// 							/>
// 						</div>
// 					</div>
// 					{/* {master == true ? ( */}
// 					<div className="flex mt-5 sm:mt-0">
// 						<select
// 							className="form-select"
// 							name="department"
// 							onChange={(e) => getUserData(e.target.value)}
// 							value={seledept}
// 						// value={dept && dept[0].department_id}
// 						>
// 							<option key={-1} value="0">
// 								All department
// 							</option>
// 							{dept &&
// 								dept.length > 0 &&
// 								dept.map((item, i) => {
// 									return (
// 										<option
// 											key={i}
// 											value={item.department_id}
// 										>
// 											{item.deptname}
// 										</option>
// 									);
// 								})}
// 						</select>
// 					</div>
// 					{/* ) : null} */}
// 					<div className="hidden md:block ml-auto text-slate-500">
// 						Showing {itemOffset + 1} to{" "}
// 						{userdata.length > itemOffset + 10
// 							? itemOffset + 10
// 							: userdata.length}{" "}
// 						of {userdata.length} entries
// 					</div>
// 				</div>
// 				<div className="overflow-x-auto">
// 					{loading ? (
// 						<HashLoader
// 							color="#5755d9"
// 							size={30}
// 							style={{ position: "absolute", right: "50%" }}
// 						/>
// 					) : (
// 						<div
// 							style={{
// 								border: "1px solid #FC9A30",
// 								borderTopLeftRadius: "10px",
// 								borderTopRightRadius: "10px",
// 							}}
// 						>
// 							<div
// 								style={{
// 									backgroundColor: "#FC9A30",
// 									color: "#fff",
// 									padding: "5px 10px",
// 									textAlign: "start",
// 									borderTopLeftRadius: "10px",
// 									borderTopRightRadius: "10px",
// 								}}
// 							>
// 								<h3>Projects</h3>
// 							</div>
// 							<div
// 								className="table-outlineBorder"
// 								style={{
// 									border: "1px solid #FC9A30",
// 								}}
// 							>
// 								<table className="employee-table" style={{ width: "100%" }}>
// 									<thead>
// 										<tr>
// 											<th style={{ width: "20%" }} className="whitespace-nowrap">
// 												Project Name
// 											</th>
// 											<th style={{ width: "30%" }} className="whitespace-nowrap">
// 												Category
// 											</th>
// 											<th className="whitespace-nowrap">Client</th>
// 											<th className="whitespace-nowrap">Status</th>
// 										</tr>
// 									</thead>
// 									<tbody>
// 										{currentItems && currentItems.length === 0 && (
// 											<tr>
// 												<td colSpan="4">No data available</td>
// 											</tr>
// 										)}
// 										{currentItems &&
// 											currentItems.length > 0 &&
// 											currentItems.map((item, i) => {
// 												return (
// 													<tr key={i}>
// 														<td>
// 															<Link to={"/admin/22/" + userdata[item].project_id}>
// 																<u>{userdata[item].name}</u>
// 															</Link>
// 														</td>
// 														<td data-tooltip={userdata[item].permanent_address}>
// 															{userdata[item].permanent_address}
// 														</td>
// 														<td>{userdata[item].client_uuid}</td>
// 														<td className="text-left">
// 															<div className="flex items-center relative">
// 																<span>{userdata[item].active === 1 ? "Active" : "Inactive"}</span>
// 																<button
// 																	className="ml-2 bg-gray-200 text-gray-700 hover:bg-gray-300 p-1 rounded"
// 																	onClick={() => toggleDropdown(userdata[item].uuid)}
// 																>
// 																	▼
// 																</button>
// 																{openDropdown === userdata[item].uuid && (
// 																	<div className="absolute top-full mt-2 w-20 bg-white border border-gray-300 rounded shadow-lg z-10">
// 																		{userdata[item].type !== 0 || master === 1 ? (
// 																			<>
// 																				<div
// 																					style={{ fontSize: "16px", cursor: "pointer" }}
// 																					className="text-right mr-2"
// 																					onClick={closeDropdown}
// 																				>
// 																					x
// 																				</div>
// 																				<button
// 																					className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center"
// 																					onClick={() => {
// 																						handleEdit(userdata[item].uuid);
// 																						closeDropdown();
// 																					}}
// 																				>
// 																					<FaEdit className="mr-2" style={{ color: "gray" }} />
// 																				</button>
// 																				{master === 1 && (
// 																					<>
// 																						<button
// 																							className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600 flex items-center"
// 																							onClick={() => {
// 																								deletedata(userdata[item].uuid);
// 																								closeDropdown();
// 																							}}
// 																						>
// 																							<FaTrashAlt className="mr-2" />
// 																							Delete
// 																						</button>

// 																					</>
// 																				)}
// 																			</>
// 																		) : (
// 																			<span className="block px-4 py-2 text-gray-400">No Actions Available</span>
// 																		)}
// 																	</div>
// 																)}
// 															</div>
// 														</td>

// 													</tr>
// 												);
// 											})}
// 									</tbody>
// 								</table>
// 							</div>
// 						</div>
// 					)}

// 					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
// 						<br />
// 						<nav className="w-full sm:w-auto sm:mr-auto">
// 							{!loading ? (
// 								<div className="row">
// 									<div className="col-md-3"></div>
// 									<div className="col-md-4">
// 										<ReactPaginate
// 											pageCount={pageCount}
// 											pageRangeDisplayed={10}
// 											marginPagesDisplayed={1}
// 											onPageChange={handlePageClick}
// 											containerClassName="pagination"
// 											activeClassName="active"
// 											pageLinkClassName="page-link"
// 											breakLinkClassName="page-link"
// 											nextLinkClassName="page-link"
// 											previousLinkClassName="page-link"
// 											pageClassName="page-item"
// 											breakClassName="page-item"
// 											nextClassName="page-item"
// 											previousClassName="page-item"
// 											previousLabel={<>&laquo;</>}
// 											nextLabel={<>&raquo;</>}
// 										/>
// 									</div>
// 									<div className="col-md-4"></div>
// 								</div>
// 							) : (
// 								""
// 							)}
// 						</nav>
// 					</div>
// 				</div>


// 			</div>
// 			<CommonModal isOpen={isOpen} content={modalContent} />
// 		</div>
// 	);
// };

// export default Departments;




import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import Swal from 'sweetalert2';
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import Masteradmin from "../Masteradmin";
import CommonModal from "./CommonModal";
import {
	FaSignInAlt,
	FaTrashAlt,
	FaEdit,
	FaToggleOff,
	FaToggleOn,
	FaTrash,
} from "react-icons/fa";

const Departments = () => {
	const [userdata, setuserdata] = useState([]);

	const [dept, setdept] = useState(null);
	const [bkpdata, setbkpdata] = useState([]);
	const [errors, setErrors] = useState({});
	const [pageCount, setPageCount] = useState(0);
	const [clients, setClients] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [tooltipData, setTooltipData] = useState({ visible: false, text: "", x: 0, y: 0 });
	const [currentItems, setCurrentItems] = useState(null);
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	// const [clients, setClients] = useState([]);
	const [mappedProjects, setMappedProjects] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [items, setitems] = useState([]);
	const [add, setadd] = useState(2);
	const [dept_selected, setdept_selected] = useState();

	let [tabtype, settabtype] = useState(false);
	let [seledept, setseledept] = useState(0);
	// populate the category dropdown
	const [categories, setCategories] = useState([]);
	const [showDropdown, setShowDropdown] = useState(null);
	const [loadingCategories, setLoadingCategories] = useState(false); // Loading state
	const [getdata, setgetdata] = useState([]);
	// const [data, setdata] = useState({
	// 	id: "",
	// 	name: "",
	// 	department: "0",
	// 	status: true,
	// 	uuid: ``,
	// });
	const [openDropdown, setOpenDropdown] = useState(null);
	const [dropdownIndex, setDropdownIndex] = useState(null);
	const [dropdownIndex2, setDropdownIndex2] = useState(null);

	const toggleDropdown = (index) => {
		setDropdownIndex(dropdownIndex === index ? null : index);
	};
	const toggleDropdown2 = (index) => {
		setDropdownIndex2(dropdownIndex === index ? null : index);
	};
	const [statusDropdownIndex, setStatusDropdownIndex] = useState(null);
	const [actionsDropdownIndex, setActionsDropdownIndex] = useState(null);

	const toggleStatusDropdown = (index) => {
		setStatusDropdownIndex(statusDropdownIndex === index ? null : index);

	};

	const toggleActionsDropdown = (index) => {
		setActionsDropdownIndex(actionsDropdownIndex === index ? null : index);
	};


	const [data, setdata] = useState({
		id: "",
		name: "",
		department: "0",
		client: "0",
		status: true,
		permanent_address: "",
		category: "", // New field
	});

	const handleMouseEnter = (text, e) => {
		console.log("Funcation called")
		setTooltipData({
			visible: true,
			text,
			x: e.clientX,
			y: e.clientY,
		});
	};

	const handleMouseLeave = () => {
		setTooltipData({ visible: false, text: "", x: 0, y: 0 });
	};
	const handleEdit = (project) => {
		if (project) {
			setdata({
				id: project.project_id,
				name: project.project_name,
				department: project.department,
				client: project.client_uuid || "0",
				status: project.status === 1,
				permanent_address: project.permanent_address || "",
				category: project.category_id || "",
			});
			setIsEdit(true);
			setIsOpen(true);
		}
	};
	const handleDelete = async (projectId) => {
		if (!projectId) return;

		// Show confirmation modal using swal
		const confirmDelete = await swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this project!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		});

		// If user cancels, exit the function
		if (!confirmDelete) return;

		try {
			const myHeaders = new Headers();
			const userdata = JSON.parse(localStorage.getItem("isAuth"));

			if (userdata !== "00:00:00") {
				const token = userdata.usertoken;
				myHeaders.append("Authorization", token);
			}

			myHeaders.append("Content-Type", "application/json");

			const response = await fetch(`${BASEURL}delete-project/${projectId}`, {
				method: "DELETE", // Correct method for delete
				headers: myHeaders,
			});

			const result = await response.json();

			if (response.ok && result.status === 201) {
				swal({
					title: "Success",
					text: "Project deleted successfully!",
					icon: "success",
					timer: 2000,
				});
				// Optionally refresh the project list
				// fetchProjects();
			} else {
				swal({
					title: "Error",
					text: result.msg || "Failed to delete the project.",
					icon: "error",
					timer: 2000,
				});
			}
		} catch (error) {
			console.error("Error deleting project:", error);
			swal({
				title: "Error",
				text: "An error occurred while deleting the project.",
				icon: "error",
				timer: 2000,
			});
		}
	};



	// const handleEdit = (project) => {
	// 	if (project) {
	// 		setdata({
	// 			id: project.project_id,
	// 			name: project.name,
	// 			department: project.department,
	// 			client: project.client_uuid || "0",
	// 			status: project.status === 1,
	// 			permanent_address: project.permanent_address || "",
	// 			category: project.category || "", // Ensure category is set
	// 		});
	// 		setIsEdit(true);
	// 	} else {
	// 		resetForm(); // Reset the form for adding a new project
	// 	}
	// 	setIsOpen(true);
	// };
	const handleDropdownToggle = (index) => {
		setShowDropdown(showDropdown === index ? null : index); // toggle the dropdown
	};



	const closeDropdown = () => {
		setOpenDropdown(null);
	};

	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});

	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

	useEffect(() => {
		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getdepartments();
		getData();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
	}, [itemOffset]);

	const getdepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllDepartments/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					// console.log(res.data);
					if (userdata.role == 2) {
						let dept = res.data.filter(
							(item) => item.department_id === userdata.department
						);
						setdept(dept);
						getUserData(dept && dept[0].department_id);
					} else {
						setdept(res.data);
						getUserData(res.data && res.data[0].department_id);
					}

					//   setdept_selected(res.data[0].department_id)
					// console.log(res.data);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	const getData = async () => {
		settabtype(false);
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		let data = await fetch(BASEURL + `addorganization`, {
			method: "GET",
			headers: myHeaders,
		});
		let data2 = await data.json();
		setgetdata(data2.data);
		setLoading(false);
	};

	//  get Category funcation
	const getCategories = async () => {
		setLoadingCategories(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(BASEURL + "get-categories", {
				method: "GET",
				headers: myHeaders,
			});
			const res = await response.json();
			if (res.status === 200) {
				setCategories(res.data);
				console.log("Categories funcation response", categories)
			} else {
				console.error("No categories found:", res.error);
				setCategories([]);
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
		} finally {
			setLoadingCategories(false);
		}
	};

	useEffect(() => {
		getCategories()
	}, [])

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 10) % items.length;

		setItemOffset(newOffset);
	};
	useEffect(() => {
		getClients()
	}, [])
	// const getClients = async () => {
	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata !== "00:00:00") {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `clients`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
	// 			listtype: 1, // Example payload, adjust as needed
	// 			role: 1,
	// 			master: 1,
	// 			isactive: 1
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				setClients(res.data); // Store clients in state
	// 			}
	// 			setLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 			setLoading(false);
	// 		});
	// };

	useEffect(() => {
		if (projects.length > 0 && clients.length > 0) {
			const updatedProjects = projects.map((project) => {
				const client = clients.find((c) => c.uuid === project.client_uuid);
				return {
					...project,
					clientName: client ? client.name : "-",
					categoryName: project.category_name || "Uncategorized",
				};
			});
			setMappedProjects(updatedProjects);
			setFilteredProjects(updatedProjects);
		}
	}, [projects, clients]);

	const getUserData = async (departmentId) => {
		setseledept(departmentId); // Update selected department state
		setLoading(true); // Optional: Show a loader while fetching

		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		const myHeaders = new Headers();
		myHeaders.append("Authorization", userdata.usertoken);
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(`${BASEURL}get-projects/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					department: departmentId,
					uuid: userdata.companydata[0].uuid,
				}),
			});
			const res = await response.json();

			if (res.status === 201) {
				setProjects(res.projects || []);
			} else {
				setProjects([]); // Handle empty projects
			}
		} catch (error) {
			console.error("Error fetching projects:", error);
		} finally {
			setLoading(false); // Stop loader
		}
	};

	const getClients = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `clients`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: userdata.companydata[0].uuid,
				listtype: 1,
				role: 1,
				master: 1,
				isactive: 1
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				console.log("Clients response:", res); // Log response
				if (res.status === 201) {
					setClients(res.data); // Store clients in state
				}
			})
			.catch((err) => {
				console.error(err);
			});
		setLoading(false);
	};





	const handleStatusConfirmation = (projectId, newStatus) => {
		Swal.fire({
			title: '<strong>Status Change</strong>',
			text: 'Are you sure you want to change the status?',
			showCancelButton: true,
			confirmButtonText: '<strong>Yes</strong>',
			cancelButtonText: '<strong>Cancel</strong>',
			customClass: {
				popup: 'custom-swal-popup',
				title: 'custom-swal-title',
				htmlContainer: 'custom-swal-html',
				confirmButton: 'custom-swal-confirm-btn',
				cancelButton: 'custom-swal-cancel-btn',
			},
			buttonsStyling: false,
		}).then((result) => {
			if (result.isConfirmed) {
				handleStatusChange(projectId, newStatus);
			}
		});
	};

	const handleStatusChange = async (projectId, newStatus) => {
		const userdata = JSON.parse(localStorage.getItem('isAuth'));
		const myHeaders = new Headers();
		myHeaders.append('Authorization', userdata.usertoken);
		myHeaders.append('Content-Type', 'application/json');

		try {
			const response = await fetch(`${BASEURL}update-project-status/`, {
				method: 'POST',
				headers: myHeaders,
				body: JSON.stringify({ id: projectId, status: newStatus }),
			});

			const res = await response.json();
			if (res.status === 200) {
				Swal.fire('Success', 'Status updated successfully!', 'success');
				getUserData();
				setStatusDropdownIndex(null);
			} else {
				Swal.fire('Error', res.error, 'error');
			}
		} catch (error) {
			console.error('Error updating status:', error);
			Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
		}
	};

	// const handleStatusConfirmation = (projectId, newStatus) => {
	// 	swal({
	// 		title: "Are you sure?",
	// 		text: "Do you want to change the project status?",
	// 		icon: "warning",
	// 		buttons: ["Cancel", "Yes"],
	// 		dangerMode: true,
	// 	}).then((willChange) => {
	// 		if (willChange) {
	// 			handleStatusChange(projectId, newStatus);
	// 		}
	// 	});
	// };
	// const handleStatusChange = async (projectId, newStatus) => {
	// 	const userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	const myHeaders = new Headers();
	// 	myHeaders.append("Authorization", userdata.usertoken);
	// 	myHeaders.append("Content-Type", "application/json");

	// 	try {
	// 		const response = await fetch(`${BASEURL}update-project-status/`, {
	// 			method: "POST",
	// 			headers: myHeaders,
	// 			body: JSON.stringify({ id: projectId, status: newStatus }),
	// 		});

	// 		const res = await response.json();
	// 		if (res.status === 200) {
	// 			swal("Success", "Status updated successfully!", "success");
	// 			getUserData();
	// 			setStatusDropdownIndex(null);

	// 		} else {
	// 			swal("Error", res.error, "error");
	// 		}
	// 	} catch (error) {
	// 		console.error("Error updating status:", error);
	// 		swal("Error", "Something went wrong. Please try again.", "error");
	// 	}
	// };



	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
		// console.log(data);
	};
	const savedata = async () => {
		if (!data.name || !data.department || !data.category) {
			swal({
				title: "Error",
				text: "Please fill in all required fields.",
				icon: "error",
				timer: 2000,
			});
			return;
		}
		setLoading(true);
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		const myHeaders = new Headers();
		myHeaders.append("Authorization", userdata.usertoken);
		myHeaders.append("Content-Type", "application/json");

		const payload = {
			company_uuid: userdata.companydata[0].uuid,
			company: userdata,
			data: { ...data },
		};
		try {
			const response = await fetch(`${BASEURL}add-project/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(payload),
			});
			const res = await response.json();

			if (res.status === 201) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					timer: 2000,
				});
				getUserData(data.department);
				resetForm();
				setIsOpen(false);
			} else {
				swal({
					title: "Error",
					text: res.message,
					icon: "error",
					timer: 2000,
				});
			}
		} catch (error) {
			console.error("Error saving project:", error);
			swal({
				title: "Error",
				text: "Something went wrong. Please try again.",
				icon: "error",
				timer: 2000,
			});
		} finally {
			setLoading(false);
		}
	};



	// const savedata = async () => {
	// 	if (!data.name || !data.department || !data.client || !data.category) {
	// 		swal({
	// 			title: "Error",
	// 			text: "Please fill in all required fields.",
	// 			icon: "error",
	// 			timer: 2000,
	// 		});
	// 		return;
	// 	}

	// 	const selectedCategory = categories.find((cat) => cat.id.toString() === data.category);
	// 	const categoryName = selectedCategory ? selectedCategory.name : null;

	// 	if (!categoryName) {
	// 		swal({
	// 			title: "Error",
	// 			text: "Invalid category selection.",
	// 			icon: "error",
	// 			timer: 2000,
	// 		});
	// 		return;
	// 	}

	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	const userdata = JSON.parse(localStorage.getItem("isAuth"));

	// 	if (userdata) {
	// 		myHeaders.append("Authorization", userdata.usertoken);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");

	// 	const payload = {
	// 		company_uuid: userdata.companydata[0].uuid,
	// 		company: userdata,
	// 		data: {
	// 			...data,
	// 			category: categoryName, // Send category name instead of ID
	// 		},
	// 	};

	// 	try {
	// 		const response = await fetch(`${BASEURL}add-project/`, {
	// 			method: "POST",
	// 			headers: myHeaders,
	// 			body: JSON.stringify(payload),
	// 		});
	// 		const res = await response.json();

	// 		if (res.status === 201) {
	// 			swal({
	// 				title: "Success",
	// 				text: res.message,
	// 				icon: "success",
	// 				timer: 2000,
	// 			});
	// 			getUserData(data.department); // Refresh project list
	// 			resetForm();
	// 			setIsOpen(false);
	// 		} else {
	// 			swal({
	// 				title: "Error",
	// 				text: res.message,
	// 				icon: "error",
	// 				timer: 2000,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		console.error("Error saving project:", error);
	// 		swal({
	// 			title: "Error",
	// 			text: "Something went wrong. Please try again.",
	// 			icon: "error",
	// 			timer: 2000,
	// 		});
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const editdata = (data) => {
		setisadd(true);
		let status;
		if (data.status === 1) {
			status = true;
		} else {
			status = false;
		}
		// console.log(data.companyuuid);
		setdata({
			id: data.project_id,
			name: data.name,
			department: data.department,
			status: status,
		});
	};

	const adddata = (data) => {
		// console.log(11);
		settabtype(false);
		setisadd(!isadd);
		setdata({
			id: "",
			name: "",
			department: 0,
			status: true,
		});
	};

	const filterdata = (event) => {
		const searchValue = event.target.value.toLowerCase();
		console.log("Search Value:", searchValue);
		if (!searchValue) {
			setFilteredProjects(projects);
		} else {
			const filtered = projects.filter((project) =>
				project.project_name.toLowerCase().includes(searchValue)
			);
			setFilteredProjects(filtered);
			console.log("Filtered Projects:", filtered);
		}
	};

	const deletedata = (id) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Project?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-project/${id}`, {
					method: "DELETE",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						getUserData(seledept);
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};
	const updateEmployee = async (uuid) => {
		// e.preventDefault();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		const myHeaders = new Headers();
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		const payload = { uuid };
		// if (data.name !== employeeData.name) payload.name = data.name;
		// if (data.department !== employeeData.department)
		// 	payload.department = data.department;
		// if (data.hod !== employeeData.type) payload.hod = data.hod;
		// if (data.contactno !== employeeData.contact_no)
		// 	payload.contactno = data.contactno;
		// if (data.email !== employeeData.email) payload.email = data.email;
		// if (data.dob !== employeeData.dob) payload.dob = data.dob;
		// if (data.date_of_joining !== employeeData.date_of_joining)
		// 	payload.date_of_joining = data.date_of_joining;
		// if (data.status !== employeeData.status) payload.status = data.status;

		try {
			const response = await fetch(BASEURL + `update-employee`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(payload),
			});
			const res = await response.json();

			if (res.status === 201) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					timer: 2000,
				}).then(() => {
					// navigate("/admin/3");
				});
			} else {
				swal({
					title: "Error",
					text: "Please Enter Required Values",
					icon: "error",
					timer: 2000,
				});
			}
		} catch (error) {
			console.error("Error updating employee:", error);
		} finally {
			setIsOpen(false)
		}
	};
	const handlechangeinput = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
	};

	const handleCloseDropdown = () => {
		setShowDropdown(null);
	};

	const handleOpen = (project) => {
		if (project) {
			setdata({
				id: project.project_id,
				name: project.name,
				department: project.department,
				status: project.status === 1,
			});
			setIsEdit(true);
		} else {
			resetForm();
		}
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
		resetForm();
	};
	const resetForm = () => {
		setdata({
			id: "",
			name: "",
			department: "0",
			client: "0",
			status: true,
			permanent_address: "",
			category: "",
		});
		setIsEdit(false);
	};


	// const resetForm = () => {
	// 	setdata({
	// 		id: "",
	// 		name: "",
	// 		department: "0",
	// 		client: "0",
	// 		status: true,
	// 		permanent_address: "",
	// 		category: "",
	// 	});
	// 	setIsEdit(false);
	// };
	const modalContent = (
		<>
			<div className="px-4 py-4">
				<div style={{
					textAlign: 'center',
					fontSize: "20px",
					fontWeight: "600",
					display: 'flex',
					justifyContent: 'space-between',
				}}>

					<div></div>
					<h2>{isEdit ? "Update Project" : "Add Project"}</h2>
					<div className="cursor-pointer" onClick={handleClose}>
						x
					</div>
				</div>
				<div className="my-2 mt-5 ">

					<div className="mb-3">
						<input
							type="text"
							name="name"
							placeholder="Project Name"
							value={data.name}
							onChange={(e) => setdata({ ...data, name: e.target.value })}
							className="modal-input"
						/>

					</div>
					<div className="mb-3">
						<div className="col-span-12 md:col-span-6 mb-4">
							<select
								className="modal-input"
								name="department"
								onChange={(e) => getUserData(e.target.value)}
								value={seledept}
							>
								<option key={-1} value="0">
									All department
								</option>
								{dept && dept.length > 0 &&
									dept.map((item, i) => (
										<option key={i} value={item.department_id}>
											{item.deptname}
										</option>
									))}
							</select>
							{errors.department && (
								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
									{errors.department}
								</span>
							)}
						</div>
					</div>
					<div className="mb-3">
						<div className="col-span-12 md:col-span-6 mb-4">
							<select
								className="modal-input"
								name="client"
								onChange={handlechangeinput}
								value={data.client || ""}
							>
								<option value="" disabled>
									Select a client
								</option>
								<option key={-1} value="0">
									All clients
								</option>
								{clients &&
									clients.length > 0 &&
									clients.map((client, i) => (
										<option key={i} value={client.uuid}>
											{client.name} - {client.deptname}
										</option>
									))}
							</select>
							{errors.client && (
								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
									{errors.client}
								</span>
							)}
						</div>
					</div>
					<div className="mb-3">
						<div className="col-span-12 md:col-span-6 mb-4">
							<select
								className="modal-input category-select"
								name="category"
								value={data.category || ""}
								onChange={(e) => setdata({ ...data, category: e.target.value })}
							>
								<option value="" disabled className="default-option">
									Select Category
								</option>
								{loadingCategories ? (
									<option disabled className="default-option">
										Loading...
									</option>
								) : categories.length > 0 ? (
									categories.map((category) => (
										<option key={category.id} value={category.id} className="category-option">
											{category.name}
										</option>
									))
								) : (
									<option disabled className="default-option">
										No categories available
									</option>
								)}
							</select></div></div>
					<div className="mb-3">
						<textarea
							id="permanent_address"
							value={data.permanent_address}
							required
							name="permanent_address"
							className="modal-input"
							onChange={handlechangeinput}
							rows="2"
							placeholder="Project Details"
						/>
						{errors.permanent_address && (
							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
								{errors.permanent_address}
							</span>
						)}
					</div>
					{/* <button
						type="button"
						onClick={() => {
							if (isEdit) {
								console.log(data?.uuid, 'data?.uuid')
								updateEmployee("");
							}
							savedata()

						}}
						className="modal-button-new"
					>
						Submit
					</button> */}
					<button
						type="button"
						onClick={() => {
							if (isEdit) {
								console.log("Editing project:", data.id);
							}
							savedata(); // Call the save function
						}}
						className="modal-button-new"
					>
						{isEdit ? "Update" : "Save"}
					</button>
				</div>
			</div>
		</>
	);
	const getStatusText = (status) => {
		switch (status) {
			case 1:
				return "Active";
			case 2:
				return "In Progress";
			case 3:
				return "On Hold";
			case 4:
				return "Completed";
			default:
				return "Unknown";
		}
	};


	// get the name of client 

	useEffect(() => {
		if (projects.length > 0 && clients.length > 0) {
			const updatedProjects = projects.map((project) => {
				const client = clients.find((c) => c.uuid === project.client_uuid);
				return {
					...project,
					clientName: client ? client.name : "-",
				};
			});
			setMappedProjects(updatedProjects); // Store mapped projects
		}
	}, [projects, clients]);
	useEffect(() => {
		setFilteredProjects(mappedProjects); // Sync filteredProjects with mappedProjects
	}, [mappedProjects]);

	return (
		<div className="intro-y">
			<div className="flex items-center  dark:border-darkmode-400">

			</div>
			{tabtype ? <Masteradmin getData={getData} /> : null}

			<div className="p-3">
				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex mt-5 sm:mt-0">
						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
						<div className="flex mt-5 sm:mt-0">
							<select
								className="form-select"
								name="department"
								onChange={(e) => getUserData(e.target.value)}
								value={seledept}
							>
								<option key={-1} value="0">
									All department
								</option>
								{dept && dept.length > 0 &&
									dept.map((item, i) => (
										<option key={i} value={item.department_id}>
											{item.deptname}
										</option>
									))}
							</select>
						</div>
					</div>

					{/* Add Project Button */}
					<div className="flex mt-5 sm:mt-0 sm:ml-auto">
						<button
							type="button"
							onClick={() => handleOpen(null)}
							className="btn w-50 add-emp-btn"
							style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
						>
							Add Project
						</button>

					</div>
				</div>
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<div
							style={{
								// border: "1px solid #FC9A30",
								// padding: "10px",
								borderTopLeftRadius: "8px",
								borderTopRightRadius: "8px",
							}}
						>
							<div
								style={{
									backgroundColor: "#FC9A30",
									color: "#fff",
									padding: "12px",
									textAlign: "start",
									borderTopLeftRadius: "8px",
									borderTopRightRadius: "8px",
								}}
							>
								<h3>Projects </h3>
							</div>
							<div
								className="table-outlineBorder"
								style={{
									border: "1px solid #FC9A30",
								}}
							>

								<table className="employee-table" style={{ width: "100%" }}>
									<thead>
										<tr>
											<th style={{ width: "20%" }} className="whitespace-nowrap">
												Project Name
											</th>
											<th style={{ width: "30%" }} className="whitespace-nowrap">
												Category
											</th>
											<th className="whitespace-nowrap">Client</th>
											<th className="whitespace-nowrap">Status</th>
											<th className="whitespace-nowrap">Actions</th>
										</tr>
									</thead>
									<tbody>
										{filteredProjects.length > 0 ? (
											filteredProjects.map((item, i) => (
												<tr key={i}>

													<td>
														<div className="flex items-center justify-between">
															<span className="text-[#f5f5f5]">{item.project_name}</span>
															<div className="icon ml-2 text-[#f5f5f5]" data-tooltip={item.permanent_address || "None"}>
																{item.permanent_address ? (
																	<svg
																		width="15"
																		height="15"
																		viewBox="0 0 24 24"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>

																		<circle
																			cx="12"
																			cy="12"
																			r="10"
																			stroke={showDropdown === i ? "#FC9A30" : "#47525D"}
																			fill="none"
																			strokeWidth="2"
																		/>
																		<text
																			x="12"
																			y="16"
																			fontSize="12"
																			fontWeight="bold"
																			className="text-[#f5f5f5]"
																			textAnchor="middle"
																			fill={showDropdown === i ? "#FC9A30" : "#47525D"}
																			fontFamily="Arial, sans-serif"
																		>
																			!
																		</text>
																	</svg>
																) : (
																	<span className="text-[#f5f5f5]">None</span>
																)}
															</div>
														</div>
													</td>

													<td>{item.category_name}</td>
													<td>{item.clientName || "-"}</td>
													{/* <td>
														<div className="relative">
															<select
																value={item.status}
																onChange={(e) => handleStatusConfirmation(item.project_id, e.target.value)}
																className="form-select border-0"
															>
																<option value="1">Active</option>
																
																<option value="2">In Progress</option>
																<option value="3">On Hold</option>
																<option value="4">Completed</option>
															</select>
														</div>
													
													</td> */}
													{/* <td>
														<div className="relative">
															
															<button
																onClick={() => toggleDropdown(i)}
																className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200 flex items-center justify-between w-32"
															>
																
																{getStatusText(item.status)}
																
																<span>{dropdownIndex === i ? "▲" : "▼"}</span>
															</button>

														
															{dropdownIndex === i && (
																<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
																	<ul className="py-1">
																	
																		<li
																			onClick={() => handleStatusConfirmation(item.project_id, "1")}
																			className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																		>
																			Active
																		</li>

																	
																		<li
																			onClick={() => handleStatusConfirmation(item.project_id, "2")}
																			className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																		>
																			In Progress
																		</li>

																		
																		<li
																			onClick={() => handleStatusConfirmation(item.project_id, "3")}
																			className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																		>
																			On Hold
																		</li>

																	
																		<li
																			onClick={() => handleStatusConfirmation(item.project_id, "4")}
																			className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																		>
																			Completed
																		</li>
																	</ul>
																</div>
															)}
														</div>
													</td> */}
													{/* <td>
														<div className="relative">
															<button
																onClick={() => toggleStatusDropdown(i)}
																className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200 flex items-center justify-between w-32"
															>
																{getStatusText(item.status)}
																<span className="ml-2">{statusDropdownIndex === i ? "▲" : "▼"}</span> 
															</button>

															{statusDropdownIndex === i && (
																<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
																	<ul className="py-1">
																		<li onClick={() => handleStatusConfirmation(item.project_id, "1")} className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
																			Active
																		</li>
																		<li onClick={() => handleStatusConfirmation(item.project_id, "2")} className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
																			In Progress
																		</li>
																		<li onClick={() => handleStatusConfirmation(item.project_id, "3")} className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
																			On Hold
																		</li>
																		<li onClick={() => handleStatusConfirmation(item.project_id, "4")} className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
																			Completed
																		</li>
																	</ul>
																</div>
															)}
														</div>
													</td> */}
													<td>
														<div className="relative">
															<button
																onClick={() => toggleStatusDropdown(i)}
																className="text-sm bg-white border-gray-300 p-2 rounded-md shadow-sm flex items-center justify-between w-36 text-gray-700 text-left"
															>
																{getStatusText(item.status)}
																<span className="ml-2">{statusDropdownIndex === i ? "▲" : "▼"}</span>
															</button>
															{statusDropdownIndex === i && (
																<div className="absolute left-0 mt-1 w-40 bg-white border-gray-200 shadow-lg rounded-md z-10">
																	<ul className="py-1">
																		{[
																			{ status: "1", label: "Active" },
																			{ status: "2", label: "In Progress" },
																			{ status: "3", label: "On Hold" },
																			{ status: "4", label: "Completed" },
																		].map((option) => (
																			<li
																				key={option.status}
																				onClick={() => handleStatusConfirmation(item.project_id, option.status)}
																				className={`px-4 py-2 text-sm cursor-pointer text-left ${String(item.status) === String(option.status)
																					? "text-white font-semibold"
																					: "text-gray-700 hover:bg-gray-100"
																					}`}
																				style={{
																					backgroundColor: String(item.status) === String(option.status) ? "#fc9a30" : "",
																					borderRadius: "5px",
																					margin: "0 auto",
																					width: "90%",
																				}}
																			>
																				{option.label}
																			</li>
																		))}
																	</ul>
																</div>
															)}
														</div>
													</td>

													<td>
														<div className="relative">
															<button
																onClick={() => toggleActionsDropdown(i)}
																className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200"
															>
																{actionsDropdownIndex === i ? "▲" : "▼"}
															</button>

															{actionsDropdownIndex === i && (
																<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
																	<ul className="py-1">
																		<li
																			onClick={() => {
																				handleEdit(item);
																				setActionsDropdownIndex(null);
																			}}
																			className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																		>
																			<FaEdit className="text-gray-600 mr-2" />
																			Edit
																		</li>
																		<li
																			onClick={() => {
																				handleDelete(item.project_id);
																				setActionsDropdownIndex(null);
																			}}
																			className="flex items-center px-4 py-2 text-sm text-red-700 cursor-pointer hover:bg-red-100"
																		>
																			<FaTrash className="mr-2 text-red-600" />
																			Delete
																		</li>
																	</ul>
																</div>
															)}
														</div>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="5" className="text-center">
													No projects available
												</td>
											</tr>
										)}
									</tbody>

								</table>
							</div>
						</div>
					)}
					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={10}
											marginPagesDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : (
								""
							)}
						</nav>
					</div>
				</div>
			</div>
			<CommonModal isOpen={isOpen} content={modalContent} />
		</div>
	);
};

export default Departments;



