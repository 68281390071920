import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { FaCalendar, FaEdit, FaTrash } from "react-icons/fa";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import "react-month-picker/css/month-picker.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import Modal from "react-modal";
import { FaTrashAlt } from "react-icons/fa";
import swal from "sweetalert";
import CommonModal from "./admin/CommonModal";
import { parseISO, format } from 'date-fns';
import DatePicker from "react-date-picker";
import moment from "moment";
Modal.setAppElement("#root");

const Leaves = () => {
	const [showForm, setShowForm] = useState(false);
	const [currdate] = useState(new Date());
	const [datevalue, onChange] = useState([
		new Date(),
		new Date(),
	]);
	const [stocks, setStocks] = useState()
	const [isOpen, setIsOpen] = useState(false);
	const [showHalfDayOptions, setShowHalfDayOptions] = useState(false);
	const [leaveRequests, setLeaveRequests] = useState([]);
	const [dept, setdept] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(100); // Number of items per page
	const [totalPages, setTotalPages] = useState(1); // Total number of pages
	const [dateChange, setDateRange] = useState(null)
	const [errors, setErrors] = useState({});
	const [paidLeave, setPaidLeave] = useState("0")
	const [loading, setLoading] = useState(false)
	const [statusDropdownIndex, setStatusDropdownIndex] = useState(null);
	const [actionsDropdownIndex, setActionsDropdownIndex] = useState(null);
	const user_data = JSON.parse(localStorage.getItem("isAuth")); // Parse the user data first
	const userUuid = user_data?.uuid; // Extract uuid from the parsed data
	useEffect(() => {
		// Calculate total pages

		setTotalPages(Math.ceil(leaveRequests.length / pageSize));
	}, [leaveRequests, pageSize]);

	useEffect(() => {
		// Fetch initial leave requests
		fetchLeaveRequests();
	}, []);



	const toggleStatusDropdown = (index) => {
		setStatusDropdownIndex(statusDropdownIndex === index ? null : index);

	};

	const toggleActionsDropdown = (index) => {
		setActionsDropdownIndex(actionsDropdownIndex === index ? null : index);
	};


	const handleOpen = async (project) => {
		if (project) {
			try {



			} catch (err) {
				console.error("Error fetching task details:", err.message);
				swal("Error!", "Something went wrong.", "error");
			}
		} else {
			// Reset form for new task creation
			resetForm();
		}
		setIsOpen(true);
	};

	// const fetchLeaveRequests = async () => {
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata != null) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	try {
	// 		// Fetch leave requests from API
	// 		const response = await fetch(BASEURL + `leave_request_new/ `, {
	// 			method: "GET",
	// 			headers: myHeaders,
	// 		});
	// 		if (!response.ok) {
	// 			throw new Error("Failed to fetch leave requests");
	// 		}
	// 		const data = await response.json();
	// 		setLeaveRequests(data);
	// 	} catch (error) {
	// 		console.error("Error fetching leave requests:", error);
	// 	}
	// };

	const fetchLeaveRequests = async () => {
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		const usetrId = userdata?.id
		try {
			const response = await fetch(BASEURL + `leave-requests/${userUuid}/${usetrId} `, {
				method: "get",
			});
			if (!response.ok) {
				throw new Error("Failed to fetch leave requests");
			}
			const responsedata = await response.json();
			const paidleaveData = responsedata?.leavesData[0]?.PL
			// console.log(responsedata?.leavesData[0], "responsedata")
			const leavesStock = responsedata?.leavesData[0]
			setStocks(leavesStock)
			setPaidLeave(paidleaveData)
			// Filter leave requests for the logged-in user
			const filteredLeaveRequests = responsedata?.data.filter(request => request.user_id === userdata.id);

			setLeaveRequests(filteredLeaveRequests);
		} catch (error) {
			console.error("Error fetching leave requests:", error);
		}
	};

	// const fetchLeaveRequests = async () => {
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata != null) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	try {
	// 		const payload = {
	// 			uuid: userUuid
	// 		}
	// 		// Fetch leave requests from API
	// 		const response = await fetch(BASEURL + `leave-requests/${userUuid} `, {
	// 			method: "get",
	// 		});
	// 		if (!response.ok) {
	// 			throw new Error("Failed to fetch leave requests");
	// 		}
	// 		const responsedata = await response.json();

	// 		// Filter leave requests for the logged-in user
	// 		const filteredLeaveRequests = responsedata?.data.filter(request => request.user_id === userdata.id);

	// 		setLeaveRequests(filteredLeaveRequests);
	// 	} catch (error) {
	// 		console.error("Error fetching leave requests:", error);
	// 	}
	// };

	// ####################################### delete api ########################################

	const handleDelete = async (requestId) => {
		console.log("Deleting request with ID:", requestId);
		// Show SweetAlert confirmation dialog
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this request!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then(async (willDelete) => {
			if (willDelete) {
				try {
					const response = await fetch(
						BASEURL + `leave-request-delete/${requestId}`,
						{
							method: "DELETE",
						}
					);
					if (!response.ok) {
						throw new Error("Failed to delete leave request");
					}
					// Remove the deleted leave request from the state
					setLeaveRequests((prevLeaveRequests) =>
						prevLeaveRequests.filter(
							(request) => request.request_id !== requestId
						)
					);

					// Show success message with SweetAlert
					swal(
						"Success!",
						"Leave request deleted successfully!",
						"success"
					);
				} catch (error) {
					console.error("Error deleting leave request:", error);
					// Show error message with SweetAlert
					swal("Error!", "Failed to delete leave request", "error");
				}
			} else {
				// User clicked cancel, do nothing
			}
		});
	};

	const [startDate, setStartDate] = useState(
		new Date(currdate.getFullYear(), currdate.getMonth(), 1)
	);
	const [endDate, setEndDate] = useState(
		new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0)
	);

	const handledate = (e) => {
		onChange(e);
		setStartDate(e[0]); // Set the start date
		setEndDate(e[1]); // Set the end date
	};

	const handleApplyLeave = () => {
		setShowForm(true);
		setEditMode(false); // Ensure edit mode is false when applying for leave
	};

	const handleBack = () => {
		setShowForm(false);
	};

	const [reason, setReason] = useState("");
	const [selectedDay, setSelectedDay] = useState("");
	const [isFullDay, setIsFullDay] = useState(true); // State to track full day option
	const [isHalfDay, setIsHalfDay] = useState(false); // State to track half day option
	const [isFirstHalfSelected, setIsFirstHalfSelected] = useState(false);
	const [isSecondHalfSelected, setIsSecondHalfSelected] = useState(false);
	const [selectedTable, setSelectedTable] = useState();
	const [isEdit, setIsEdit] = useState(false);

	const [data, setdata] = useState({
		request_id: "",
		id: "",
		noofdays: "",
		day: "",
		project: "0",
		status: true,
		halfDayType: "",
		leaveType: "",
		category: "",
		reason: "",
		startDate: new Date(),
		endDate: new Date(),
	});
	const isMultipleDays = data.day === "Multiple Day";
	const handleChange = (event) => {
		setSelectedDay(event.target.value);
	};

	const handleFullDayChange = () => {
		setShowHalfDayOptions(false);
		setIsFullDay(true);
		setIsHalfDay(false); // Deselect half day option
	};

	const handleHalfDayChange = (e) => {
		const isChecked = e.target.checked;
		setShowHalfDayOptions(isChecked);
		setIsHalfDay(isChecked); // Update isHalfDay state
		setIsFullDay(false); // Deselect full day option
		setIsFirstHalfSelected(false); // Deselect first half
		setIsSecondHalfSelected(false); // Deselect second half
	};

	const handleFirstHalfChange = () => {
		setIsFirstHalfSelected(true);
		setIsSecondHalfSelected(false); // Deselect second half
	};

	const handleSecondHalfChange = () => {
		setIsSecondHalfSelected(true);
		setIsFirstHalfSelected(false); // Deselect first half
	};

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();

	// 	const userData = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (!userData) {
	// 		console.error("User data not found");
	// 		return;
	// 	}
	// 	// Find the company UUID from the companydata array
	// 	const company_uuid = userData.companydata[0].uuid;

	// 	// Calculate leave day count based on the selected date range and leave type
	// 	var leaveDayCount = 0;
	// 	if (isHalfDay) {
	// 		// If half day, count as 0.5 day
	// 		leaveDayCount = 0.5;
	// 	} else {
	// 		// If full day, count the number of days in the date range
	// 		const diffInDays = Math.ceil(
	// 			(endDate - startDate) / (1000 * 60 * 60 * 24)
	// 		);
	// 		leaveDayCount = diffInDays + 1; // Add 1 to include both start and end dates
	// 	}

	// 	const formData = {
	// 		user_id: userData.id,
	// 		uuid: userData.uuid,
	// 		department: userData.department,
	// 		reason: reason,
	// 		startDate: startDate,
	// 		endDate: endDate,
	// 		day: isHalfDay ? "Half Day" : "Full Day",
	// 		halfDayType: isHalfDay
	// 			? isFirstHalfSelected
	// 				? "first-half"
	// 				: "second-half"
	// 			: "",
	// 		noofdays: leaveDayCount,
	// 		request_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
	// 		leaveday: selectedDay,
	// 		company_uuid: company_uuid, // Add company_uuid to formData
	// 	};
	// 	console.log("login user data: ", userData)

	// 	console.log("user inserted data by user : ", userData)
	// 	const myHeaders = new Headers();
	// 	// Append authorization token to headers
	// 	if (userData) {
	// 		myHeaders.append("Authorization", userData.usertoken);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");

	// 	try {
	// 		const response = await fetch(BASEURL + "leave-requests/", {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 			},
	// 			body: JSON.stringify(formData),
	// 		});

	// 		if (!response.ok) {
	// 			throw new Error("Failed to submit form");
	// 		}


	// 		// Update the leaveRequests state with the new leave request data
	// 		setLeaveRequests(prevLeaveRequests => [...prevLeaveRequests, formData]);

	// 		const data = await response.json();
	// 		console.log(data);
	// 	} catch (error) {
	// 		console.error("Error submitting form:", error);
	// 	}
	// 	setShowForm(false);
	// };

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	const userData = JSON.parse(localStorage.getItem("isAuth"));

	// 	if (!userData) {
	// 		alert("User data not found.");
	// 		setLoading(false);
	// 		return;
	// 	}

	// 	const company_uuid = userData.companydata?.[0]?.uuid || "";
	// 	const isHalfDaySelected = data.day === "Half Day";
	// 	const leaveDayCount = isHalfDaySelected
	// 		? 0.5
	// 		: Math.max(1, Math.ceil((new Date(data.endDate) - new Date(data.startDate)) / (1000 * 60 * 60 * 24)));

	// 	const formData = {
	// 		user_id: userData.id,
	// 		uuid: userData.uuid,
	// 		department: userData.department,
	// 		reason: data.reason,
	// 		startDate: data.startDate,
	// 		endDate: data.endDate,
	// 		day: isHalfDaySelected ? "Half Day" : "Full Day",
	// 		halfDayType: isHalfDaySelected ? (data.halfDayType === "First Half" ? "first-half" : "second-half") : "",
	// 		noofdays: leaveDayCount,
	// 		request_date: new Date().toISOString(),
	// 		company_uuid: company_uuid,
	// 		leaveday: data.day === "Full Day" ? "1 Day" : data.day === "Half Day" ? data.halfDayType : "Multiple Days",
	// 	};

	// 	try {
	// 		const response = await fetch(BASEURL + "leave-requests", {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				Authorization: `Bearer ${userData.usertoken}`,
	// 			},
	// 			body: JSON.stringify(formData),
	// 		});

	// 		if (!response.ok) {
	// 			const errorData = await response.json();
	// 			throw new Error(errorData.error || "Failed to submit leave request");
	// 		}

	// 		const responseData = await response.json();
	// 		console.log("Response from server:", responseData);

	// 		setLeaveRequests((prev) => [...prev, responseData]);
	// 		setShowForm(false);
	// 	} catch (error) {
	// 		console.error("Error submitting form:", error);
	// 		alert(error.message);
	// 	} finally {
	// 		setLoading(false);
	// 		setIsOpen(false);
	// 		resetForm();
	// 		fetchLeaveRequests();
	// 	}
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();

		const userData = JSON.parse(localStorage.getItem("isAuth"));
		if (!userData) {
			console.error("User data not found");
			return;
		}

		const company_uuid = userData.companydata[0].uuid;
		let leaveDayCount;
		// Ensure startDate and endDate exist
		if (data.startDate && data.endDate) {
			const start = new Date(data.startDate); // Convert string to Date object
			const end = new Date(data.endDate);

			// Ensure both are valid dates before performing the calculation
			if (!isNaN(start) && !isNaN(end)) {
				const diffInMs = end - start; // Difference in milliseconds
				const diffInDays = diffInMs / (1000 * 60 * 60 * 24); // Convert to days

				leaveDayCount = diffInDays + 1; // Including both start and end dates
			} else {
				console.error("Invalid startDate or endDate:", data.startDate, data.endDate);
			}
		}

		// let leaveDayCount = data.day === "Half Day"
		// 	? 0.5
		// 	: data.day === "Full Day"
		// 		? 1
		// 		: Math.ceil((data.endDate - data.startDate) / (1000 * 60 * 60 * 24)) + 1;

		// let leaveDayCount = data.day === "Half Day" ? 0.5 : data.day === "Full Day" ? 1 : Math.ceil((data.endDate - data.startDate) / (1000 * 60 * 60 * 24)) + 1;
		const formData = {
			user_id: userData.id,
			uuid: userData.uuid,
			department: userData.department,
			reason: data.reason,  // Use data.reason here, not reason directly
			startDate: data.startDate, // Make sure you use data.startDate
			endDate: data.endDate, // Make sure you use data.endDate
			day: data.day,
			leaveType: data.leaveType,
			halfDayType: data.day === "Half Day" ? data.halfDayType : "",
			noofdays: leaveDayCount,
			request_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
			leaveday: selectedDay,
			company_uuid: company_uuid,
		};

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", `Bearer ${userData.usertoken}`);

		try {
			const url = isEdit ? `${BASEURL}leave-requests/${data.request_id}` : `${BASEURL}leave-requests/`;
			const method = isEdit ? "PUT" : "POST";

			const response = await fetch(url, {
				method: method,
				headers: myHeaders,
				body: JSON.stringify(formData),
			});

			if (!response.ok) {
				throw new Error(isEdit ? "Failed to update request" : "Failed to create request");
			}

			const updatedData = await response.json();

			setLeaveRequests(prevLeaveRequests =>
				isEdit
					? prevLeaveRequests.map(lr => (lr.request_id === data.request_id ? updatedData : lr))
					: [...prevLeaveRequests, updatedData]
			);


		} catch (error) {
			console.error("Error submitting form:", error);
		}
		finally {
			fetchLeaveRequests();
			setIsOpen(false)
		}
		setShowForm(false);
	};


	// const handleDateChange = (range) => {
	// 	console.log(range, "range");

	// 	// Ensure range values are valid
	// 	if (!range || !range[0] || !range[1]) {
	// 		console.error("Invalid date range!");
	// 		return;
	// 	}

	// 	// Parse dates safely
	// 	const start = new Date(range[0]);
	// 	const end = new Date(range[1]);

	// 	if (isNaN(start.getTime()) || isNaN(end.getTime())) {
	// 		console.error("Invalid date format!");
	// 		return;
	// 	}

	// 	// Ensure correct time adjustments
	// 	start.setHours(0, 0, 0, 0);
	// 	end.setHours(23, 59, 59, 999);

	// 	console.log("Formatted Start Date:", start.toLocaleDateString("en-GB")); // "01/02/2025"
	// 	console.log("Formatted End Date:", end.toLocaleDateString("en-GB"));   // "03/02/2025"

	// 	// Update state
	// 	setdata((prevData) => ({
	// 		...prevData,
	// 		startDate: start,
	// 		endDate: end,
	// 	}));
	// };

	// const handleDateChange = (range) => {
	// 	if (isMultipleDays) {
	// 		// Handle Date Range
	// 		if (!range || !range[0] || !range[1]) {
	// 			console.error("Invalid date range!");
	// 			return;
	// 		}

	// 		const start = new Date(range[0]);
	// 		const end = new Date(range[1]);

	// 		start.setHours(0, 0, 0, 0);
	// 		end.setHours(23, 59, 59, 999);

	// 		setdata((prevData) => ({
	// 			...prevData,
	// 			startDate: start,
	// 			endDate: end,
	// 		}));
	// 	} else {
	// 		// Handle Single Date
	// 		if (!range) {
	// 			console.error("Invalid date!");
	// 			return;
	// 		}

	// 		const selectedDate = new Date(range);
	// 		selectedDate.setHours(0, 0, 0, 0);

	// 		setdata((prevData) => ({
	// 			...prevData,
	// 			startDate: selectedDate,
	// 			endDate: selectedDate, // Same start and end for single-day selection
	// 		}));
	// 	}
	// };

	const handleDateChange = (range) => {
		if (isMultipleDays) {
			if (!range || !range[0] || !range[1]) {
				console.error("Invalid date range!");
				return;
			}

			const startDate = moment(range[0]).format("YYYY-MM-DD");
			const endDate = moment(range[1]).format("YYYY-MM-DD");

			setdata((prevData) => ({
				...prevData,
				startDate: startDate,
				endDate: endDate,
			}));
		} else {
			if (!range) {
				console.error("Invalid date!");
				return;
			}

			const selectedDate = moment(range).startOf("day");

			setdata((prevData) => ({
				...prevData,
				startDate: selectedDate,
				endDate: selectedDate,
			}));
		}
	};


	const handleReasonChange = (e) => {
		setReason(e.target.value);
	};

	const [editMode, setEditMode] = useState(false); // State to track whether the form is in edit mode

	// Function to handle when the user clicks the Edit button in the table
	const handleEdit = (requestId) => {
		// Find the leave request by requestId

		console.log(
			" this is my data which we are fetching by requestId ",
			requestId
		);
		const leaveRequest = leaveRequests.find(
			(request) => request.request_id === requestId
		);

		// Populate the form fields with the leave request data
		console.log("my id data from database :", leaveRequest);

		setReason(leaveRequest.reason);
		setStartDate(new Date(leaveRequest.start_date));
		setEndDate(new Date(leaveRequest.start_date));
		setSelectedDay(leaveRequest.selectedDay);
		setShowForm(true); // Show the form
		setEditMode(true); // Set edit mode to true
	};

	// const handleUpdate = async (requestId) => {
	// 	// console.log(requestId);return
	// 	// Prepare the updated data
	// 	const updatedData = {
	// 		reason: reason,
	// 		startDate: startDate,
	// 		endDate: endDate,
	// 		day: isHalfDay ? "Half Day" : "Full Day",
	// 		halfDayType: isHalfDay
	// 			? isFirstHalfSelected
	// 				? "first-half"
	// 				: "second-half"
	// 			: "",
	// 		noofdays: 0,
	// 		leaveday: selectedDay,
	// 	};

	// 	console.log("this is my new updated form data", updatedData);
	// 	try {
	// 		const response = await fetch(
	// 			BASEURL + `leave_requests_update/${requestId}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 				},
	// 				body: JSON.stringify(updatedData),
	// 			}
	// 		);
	// 		if (!requestId) {
	// 			console.error("Error: Request ID is undefined");
	// 			return;
	// 		}
	// 		if (!response.ok) {
	// 			throw new Error("Failed to update leave request");
	// 		}

	// 		// Optionally, you can handle success response here
	// 		// For example, display a success message or update the UI
	// 	} catch (error) {
	// 		console.error("Error updating leave request:", error);
	// 	}
	// 	setShowForm(false);
	// };

	const handleUpdate = async (requestId) => {
		const updatedData = {
			reason: reason,
			startDate: startDate,
			endDate: endDate,
			day: isHalfDay ? "Half Day" : "Full Day",
			halfDayType: isHalfDay ? (isFirstHalfSelected ? "first-half" : "second-half") : "",
			noofdays: 0,
			leaveday: selectedDay,
		};

		try {
			const response = await fetch(BASEURL + `leave_requests_update/${requestId}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(updatedData),
			});

			if (!requestId) {
				console.error("Error: Request ID is undefined");
				return;
			}

			if (!response.ok) {
				throw new Error("Failed to update leave request");
			}

			// Fetch the updated data again from the server
			await fetchLeaveRequests();

			// Optionally, you can handle success response here
			// For example, display a success message or update the UI
		} catch (error) {
			console.error("Error updating leave request:", error);
		}

		setShowForm(false);
	};

	const handlechangeinput = (e) => {
		const { name, value } = e.target;
		setdata(prevData => ({
			...prevData,
			[name]: value,
		}));
	};


	const resetForm = () => {
		const today = new Date().toISOString().split("T")[0];

		setdata({
			id: "",
			noofdays: "",
			day: "",
			project: "",
			status: null,
			category: "",
			reason: "",
			startDate: today,
			endDate: today,
			leaveType: ""
		});
		setIsEdit(false);
	};


	const handleClose = () => {
		setIsOpen(false);
		resetForm();
	};

	// #region modal content
	const modalContent = (
		<>
			<div className="px-4 py-4">
				<div style={{
					textAlign: 'center',
					fontSize: "20px",
					fontWeight: "600",
					display: 'flex',
					justifyContent: 'space-between',
				}}>

					<div></div>
					<h2>
						{isEdit ? "Edit" : "Create"} Leave Request
					</h2>

					<div className="cursor-pointer" onClick={handleClose}>
						x
					</div>
				</div>
				<div className="my-2 mt-5 ">

					<div className="mb-3">
						<div className="col-span-12 md:col-span-6 mb-4">
							{/* <select
								className="modal-input"
								name="day"
								value={data.day || ""}
								onChange={(e) => {
									const day = e.target.value;
									setdata({ ...data, day });
								}}
							>
								<option value="" disabled>
									Leave Type
								</option>
								<option value="Full Day">
									Full Day
								</option>
								<option value="Half Day">
									Half Day
								</option>
								<option value="Multiple Day">
									Multiple Day
								</option>
							</select> */}
							<select
								className="modal-input"
								name="day"
								value={data.day || ""}
								onChange={(e) => {
									setdata({ ...data, day: e.target.value });
								}}
							>
								<option value="" disabled>Select Leave</option>
								<option value="Full Day">Full Day</option>
								<option value="Half Day">Half Day</option>
								<option value="Multiple Day">Multiple Day</option>
							</select>

							{errors.leaveType && (
								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
									{errors.leaveType}
								</span>
							)}
						</div>
					</div>
					{data.day === "Half Day" && (
						<div className="mb-3">
							<div className="col-span-12 md:col-span-6 mb-4">
								<select
									className="modal-input"
									name="halfDayType"
									value={data.halfDayType || ""}  // Make sure this reflects data.halfDayType
									onChange={(e) => {
										const halfDayType = e.target.value;
										setdata({ ...data, halfDayType });
									}}
								>
									<option value="First Half">First Half</option>
									<option value="Second Half">Second Half</option>
								</select>
							</div>
						</div>
					)}

					<div className="mb-3">
						<div className="col-span-12 md:col-span-6 mb-4">
							<select
								className="modal-input"
								name="leaveType"
								value={data.leaveType || ""}
								onChange={(e) => {
									const leaveType = e.target.value;
									setdata({ ...data, leaveType });
								}}
							>
								<option value="" disabled>Leave Type</option>

								<option
									className={`${stocks?.LWP === "0" ? "bg-gray-400 cursor-not-allowed" : ""}`}
									value="LWP"
									disabled={stocks?.LWP === "0"}
								>
									LWP - Leave Without Pay
								</option>

								<option
									className={`${stocks?.CL === "0" ? "bg-gray-400 cursor-not-allowed" : ""}`}
									value="CL"
									disabled={stocks?.CL === "0"}
								>
									CL - Casual Leave
								</option>

								<option
									className={`${stocks?.SL === "0" ? "bg-gray-400 cursor-not-allowed" : ""}`}
									value="SL"
									disabled={stocks?.SL === "0"}
								>
									SL - Sick Leave
								</option>

								<option
									className={`${stocks?.PL === "0" ? "bg-gray-400 cursor-not-allowed" : ""}`}
									value="PL"
									disabled={stocks?.PL === "0"}
								>
									PL - Paid Leave
								</option>

								<option
									className={`${stocks?.PTL === "0" ? "bg-gray-400 cursor-not-allowed" : ""}`}
									value="PTL"
									disabled={stocks?.PTL === "0"}
								>
									PTL - Privileged Leave
								</option>

								<option
									className={`${stocks?.MTL === "0" ? "bg-gray-400 cursor-not-allowed" : ""}`}
									value="MTL"
									disabled={stocks?.MTL === "0"}
								>
									MTL - Maternity Leave
								</option>
							</select>
						</div>
					</div>

					{/* {
						data.day === "Half Day" && (

							<div className="mb-3">
								<div className="col-span-12 md:col-span-6 mb-4">
									<select
										className="modal-input"
										name="day"
										value={data.day || ""}  // Make sure this reflects data.day
										onChange={(e) => {
											const day = e.target.value;
											setdata({ ...data, day });
										}}
									>
										<option value="" disabled>
											Leave Type
										</option>
										<option value="Full Day">Full Day</option>
										<option value="Half Day">Half Day</option>
										<option value="Multiple Day">Multiple Day</option>
									</select>

									{errors.leaveType && (
										<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
											{errors.leaveType}
										</span>
									)}
								</div>
							</div>
						)
					} */}

					{/* <div className="mb-3">
						<input
							type="text"
							name="noofdays"
							placeholder="#No of Leave"
							value={data.noofdays}
							onChange={(e) => setdata({ ...data, noofdays: e.target.value })}
							className="modal-input"
						/>
					</div> */}

					<div className="mb-3">
						{isMultipleDays ? (
							<DateRangePicker
								clearIcon=""
								calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
								className="modal-input"
								onChange={handleDateChange}
								value={[data.startDate, data.endDate]}
								minDate={moment().add(1, "days").toDate()}
							/>
						) : (
							<DatePicker
								clearIcon={null}
								calendarIcon={<FaCalendar className="text-blue-700" />}
								className="w-full"
								style={{ color: "#000" }}
								onChange={handleDateChange}
								value={data.startDate}
								minDate={moment().add(1, "days").toDate()} // Disables past dates & today
							/>
						)}

					</div>

					<div className="mb-3">
						<textarea
							id="reason"
							value={data.reason}
							required
							name="reason"
							className="modal-input"
							onChange={handlechangeinput}
							rows="2"
							placeholder="Reason"
						/>
						{errors.reason && (
							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
								{errors.reason}
							</span>
						)}
					</div>

					<button
						type="button"
						onClick={handleSubmit}
						className="modal-button-new"
						disabled={loading}
					>
						{loading && "Loading.."}	{isEdit ? "Save" : "Create"}
					</button>
				</div>
			</div>
		</>
	);

	return (
		<>
			{showForm ? (
				<div className="mt-5 p-4 pt-0 pb-2 rounded" style={{ "border": "1px solid #232d4467" }}>
					<form method="post">
						<div className="container">
							<div className="form-group flex">
								<div className="form-check p-3">
									<input
										className="form-check-input"
										type="checkbox"
										id="fullDayCheckbox"
										value="full-day"
										onChange={handleFullDayChange}
										checked={isFullDay}
									/>
									<label
										className="form-check-label"
										htmlFor="fullDayCheckbox leaveDays"
									>
										Full Day
									</label>
								</div>
								<div className="form-check p-3">
									<input
										className="form-check-input"
										type="checkbox"
										id="halfDayCheckbox"
										onChange={handleHalfDayChange}
										checked={isHalfDay}
									/>
									<label
										className="form-check-label"
										htmlFor="halfDayCheckbox leaveDays "
									>
										Half Day
									</label>
								</div>
							</div>
							{showHalfDayOptions && (
								<div className="form-group flex">
									<div className="form-check p-3">
										<input
											className="form-check-input"
											type="checkbox"
											id="firstHalfCheckbox"
											value="first-half"
											onChange={handleFirstHalfChange}
											checked={isFirstHalfSelected}
										/>
										<label
											className="form-check-label"
											htmlFor="firstHalfCheckbox"
										>
											First Half
										</label>
									</div>
									<div className="form-check p-3">
										<input
											className="form-check-input"
											type="checkbox"
											id="secondHalfCheckbox"
											value="second-half"
											onChange={handleSecondHalfChange}
											checked={isSecondHalfSelected}
										/>
										<label
											className="form-check-label"
											htmlFor="secondHalfCheckbox"
										>
											Second Half
										</label>
									</div>
								</div>
							)}
						</div>
						<div className="form-group p-3">
							<label htmlFor="leaveDateRange" className="flex">
								Leave Date Range:
							</label>
							<div className="input-group">
								<div>
									<DateRangePicker
										clearIcon=""
										calendarIcon={
											<FaCalendar
												style={{
													color: "rgb(30 64 175)",
												}}
											/>
										}
										className="form-control"
										onChange={handledate}
										value={datevalue}
										onApply={handleDateChange}
									/>
								</div>
							</div>
						</div>
						<div className="form-group p-3">
							<label htmlFor="reason" className="flex">
								Reason for Leave:
							</label>
							<textarea
								className="form-control"
								id="reason"
								rows="3"
								value={reason}
								onChange={handleReasonChange}
								placeholder="Enter reason for leave"
							></textarea>
						</div>
						<div className=" flex p-2">
							{editMode ? (
								// If in edit mode, show the Update button
								<button
									type="button"
									name="updateLeave"
									className="btn btn-primary waves-effect waves-light ml-2"
									onClick={() =>
										handleUpdate(selectedTable.request_id)
									}
								>
									Update
								</button>
							) : (
								// If not in edit mode, show the Submit button
								<button
									onClick={handleSubmit}
									type="button"
									name="addleave"
									className="btn btn-primary waves-effect waves-light ml-2"
								>
									Submit
								</button>
							)}

							<button
								type="submit"
								name="addleave"
								class="btn btn-primary waves-effect waves-light ml-2"
								onClick={handleBack}
							>
								Back
							</button>
						</div>
					</form>
				</div>
			) : (
				<div className="intro-y  p-5 pt-0 mt-5">
					<div className="flex flex-col  sm:flex-row sm:items-end xl:items-start">
						<h2 className="font-medium text-base mr-auto text-left p-2 pt-0">
							<span style={{ color: '#1D46AC', fontSize: "20px" }}>Leave Balance</span> <br />
							<span className="text-muted-300" style={{ fontSize: "14px" }}>PL: {paidLeave}</span>
						</h2>
						<div className="flex sm:items-end mt-5 mb-4 sm:mt-0">
							<button
								className="btn w-50 add-emp-btn"
								style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
								// onClick={handleApplyLeave}
								onClick={() => handleOpen(null)}
							>
								Create Leave Request
							</button>
						</div>
					</div>
					<div style={{
						// border: "1px solid #FC9A30",
						// padding: "10px",
						borderTopLeftRadius: "8px",
						borderTopRightRadius: "8px",

					}}>
						<div
							style={{
								backgroundColor: "#FC9A30",
								color: "#fff",
								padding: "12px",
								textAlign: "start",
								borderTopLeftRadius: "8px",
								borderTopRightRadius: "8px",
							}}
						>
							<h3>Leave Details </h3>
						</div>
						<div className="table-outlineBorder"
							style={{
								border: "1px solid #FC9A30",
							}}>
							<table className="employee-table">
								<thead className="">
									<tr>
										{/* <th
											style={{
												borderRight: "1px solid white",
											}}
										>
											EMP ID
										</th> */}
										<th

										>
											Leave Date
										</th>
										<th

										>
											#No of Days
										</th>
										<th

										>
											Leave Type
										</th>
										<th

										>
											Reason
										</th>

										{/* <th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Start Date
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											End Date
										</th> */}
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Status
										</th>
										{/* <th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Action
										</th> */}
									</tr>
								</thead>
								<tbody>
									{leaveRequests.map((table, i) => (
										<tr key={table.user_id}>
											<td>
												{table.start_date && table.end_date ? (
													table.start_date === table.end_date ? (
														format(parseISO(table.start_date), "dd/MM/yyyy")
													) : (
														`${format(parseISO(table.start_date), "dd/MM/yyyy")} to ${format(parseISO(table.end_date), "dd/MM/yyyy")}`
													)
												) : (
													"N/A" // Fallback text if the dates are missing
												)}
											</td>

											<td>{table.noofdays}</td>
											<td>{table.leave_type ? table.leave_type : "-"}</td>
											<td>{table.reason}</td>
											<td>
												<div className="flex justify-between">
													<div>
														{table.hrstatus === 0 ? (
															<span style={{ color: "#FC9A30" }}>Pending</span>
														) : table.hrstatus === 1 ? (
															<span style={{ color: "#24A184" }}>Approved</span>
														) : (
															<span style={{ color: "#FB7474" }}>Rejected</span>
														)}

													</div>
													{table.hrstatus === 0 &&
														<button
															onClick={() => toggleActionsDropdown(i)}
															className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200 ml-2"
														>
															{actionsDropdownIndex === i ? "▲" : "▼"}
														</button>}

												</div>
												{actionsDropdownIndex === i && (
													<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
														<ul className="py-1">
															<li
																onClick={() => {
																	if (table.hrstatus === 0) {
																		setIsEdit(true); // Set edit mode
																		setdata({
																			request_id: table.request_id,
																			noofdays: table.noofdays || "",
																			day: table.day || "",  // Ensure the day is properly set here
																			project: table.project || "0",
																			status: table.status ?? true,
																			category: table.category || "",
																			reason: table.reason || "",
																			startDate: table.start_date ? parseISO(table.start_date) : new Date(),
																			endDate: table.end_date ? parseISO(table.end_date) : new Date(),
																			halfDayType: table.halfdaytype || "", // Ensure this is set correctly
																		});

																		setIsOpen(true); // Open modal
																	}
																}}
																className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
															>
																<FaEdit className="text-gray-600 mr-2" />
																Edit
															</li>
															<li
																onClick={() =>
																	table.hrstatus === 0 && (
																		handleDelete(
																			table.request_id
																		)
																	)

																}
																className="flex items-center px-4 py-2 text-sm text-red-700 cursor-pointer hover:bg-red-100"
															>
																<FaTrash className="mr-2 text-red-600" />
																Delete
															</li>
														</ul>
													</div>
												)}
											</td>
											{/* <td>
												<tr className="flex">
													{table.hrstatus === 0 ? (
														<>
															<FaEdit
																className={` ${table.hrstatus === 0 ? "text-primary cursor-pointer" : "text-muted cursor-none"} `}
																onClick={() => {
																	if (table.hrstatus === 0) {
																		setIsEdit(true); // Set edit mode
																		setdata({
																			request_id: table.request_id,
																			noofdays: table.noofdays || "",
																			day: table.day || "",  // Ensure the day is properly set here
																			project: table.project || "0",
																			status: table.status ?? true,
																			category: table.category || "",
																			reason: table.reason || "",
																			startDate: table.start_date ? parseISO(table.start_date) : new Date(),
																			endDate: table.end_date ? parseISO(table.end_date) : new Date(),
																			halfDayType: table.halfdaytype || "", // Ensure this is set correctly
																		});

																		setIsOpen(true); // Open modal
																	}
																}}
															/>

															<FaTrashAlt
																onClick={() =>
																	table.hrstatus === 0 && (
																		handleDelete(
																			table.request_id
																		)
																	)

																}
																className={` ${table.hrstatus === 0 ? "text-danger cursor-pointer" : "text-muted cursor-none"}   ml-2`}
															/>
														</>
													) : "-"}
												</tr>
											</td> */}
										</tr>
									))}
									{leaveRequests.length === 0 && (
										<tr><td colSpan="5">No data available</td></tr>
									)}
								</tbody>
							</table>
						</div>
					</div>

					{/* <div className="container mt-5  flex ">

						<button
							onClick={() =>
								setCurrentPage((prevPage) =>
									Math.max(prevPage - 1, 1)
								)
							}
							disabled={currentPage === 1}
							className="btn btn-primary btn ml-2 btn-xs btn-light"
						>
							Previous
						</button>
						<button
							onClick={() =>
								setCurrentPage((prevPage) =>
									Math.min(prevPage + 1, totalPages)
								)
							}
							className="btn btn-primary btn btn-xs btn-light ml-3"
							disabled={currentPage === totalPages}
						>
							Next
						</button>

					</div> */}
				</div>
			)}
			<CommonModal isOpen={isOpen} content={modalContent} />
		</>
	);
};
export default Leaves;
