import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import DatePicker from "react-date-picker";
import { FaCalendar } from "react-icons/fa";

import SummaryApplications from "./SummaryApplications";
import SidebarChart from "./appChart";
import ChartMostAppUsed from "./ChartMostAppUsed";
import HashLoader from "react-spinners/HashLoader";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useLoadingContext } from "react-router-loading";
import reload from '../../reload.png'
import Select from "react-select";

const Applications = React.memo(props => {
  // const Applications = () => {
  const loadingContext = useLoadingContext();
  let [loading, setLoading] = useState(false);
  let [loading1, setLoading1] = useState(false);
  const [userdata, setuserdata] = useState([]);

  const [bkpdata, setbkpdata] = useState([]);
  // JSON.parse(localStorage.getItem("isAuth")).name
  // JSON.parse(localStorage.getItem("isAuth")).username
  const [name, setname] = useState();
  const [username, setusername] = useState();
  const [appdata, setappdata] = useState([]);
  const [mostapp, setmostapp] = useState([]);
  const [mostapphrs, setmostapphrs] = useState([]);
  const [date, setdate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [currdate, setcurrdate] = useState(new Date());
  const [orgs, setorgs] = React.useState([]);
  const [master,] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);
  const [orgval, setorgval] = React.useState([]);

  useEffect(() => {
    // let userdata = JSON.parse(localStorage.getItem("isAuth"));
    // setusername(userdata.username);
    // setname(userdata.name);
    if (master === 1) {
      getOrganizations();
    }

    getUserData();
    // getChartdata(date, username);

  }, []);

  const getorg = async (org) => {
    setorgval(org);
    org = org.value

    setselectedorg(org);
    getUserData(org);
  }

  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };


  const reloadFun = () => {
    getChartdata(date, username);
  }

  const setUsername = async (username, name = "") => {
    setname(name);
    setusername(username);
    getChartdata(date, username);
  };

  const getChartdata = async (paramdate, username) => {
    setLoading1(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-AppChartData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date: paramdate,
        username,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setmostapp(res.apphourwisearr);
          setmostapphrs(res.hourarr);
          ChartMostAppUsedfun();
        } else {
          setmostapphrs([]);
          setmostapp([]);
        }
        setLoading1(false);
      })
      .catch((err) => console.log(err));

  };

  const getappdata = (data) => {
    setappdata(data);
    getAppChart();
  };

  const handledate = (e) => {
    let date;
    if (e !== null) {
      setcurrdate(e);
      date = format(new Date(e), "yyyy-MM-dd");
    } else {
      date = format(new Date(), "yyyy-MM-dd");
      setcurrdate(new Date());
    }

    setdate(date);
    setusername(username);
    getChartdata(date, username);
    getSummaryApplications();
    getAppChart();
  };

  const nexTDay = () => {
    let tomorrow = currdate;
    let date2 = new Date(date);

    tomorrow.setDate(date2.getDate() + 1);
    setcurrdate(tomorrow);
    setdate(format(new Date(tomorrow), "yyyy-MM-dd"));
    let date3 = format(new Date(tomorrow), "yyyy-MM-dd");
    setusername(username);
    getChartdata(date3, username);
    getSummaryApplications();
    getAppChart();
  };

  const prevDay = () => {
    let tomorrow = currdate;
    let date2 = new Date(date);

    tomorrow.setDate(date2.getDate() - 1);
    setcurrdate(tomorrow);
    setdate(format(new Date(tomorrow), "yyyy-MM-dd"));
    let date3 = format(new Date(tomorrow), "yyyy-MM-dd");
    setusername(username);
    getChartdata(date3, username);
    getSummaryApplications();
    getAppChart();
  };

  const getUserData = async (org = "") => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 1,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        uuid: org !== '' ? org : selectedorg,
        usertype: JSON.parse(localStorage.getItem("isAuth")).type,
        // department:JSON.parse(localStorage.getItem("isAuth")).department,
        department: "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let finalusers = [];
          res.data.map((item, i) => {
            let isactive = res.alluser.filter(
              (itm) => Number(itm.username) === Number(item.username)
            );
            let obj = item;
            finalusers.push(obj);
            if (isactive.length > 0) {
              finalusers[i].isactive = 1;
            } else {
              finalusers[i].isactive = 0;
            }
          });

          let filterusers = finalusers.sort(function (a, b) {
            return b.isactive - a.isactive;
          });

          setuserdata(filterusers);

          setbkpdata(filterusers);
        }
        setLoading(false);
        loadingContext.done();
      })
      .catch((err) => console.log(err));
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      setuserdata(bkpdata);
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });
      setuserdata(matches);
    }
  };
  const getSummaryApplications = () => {
    return (
      <SummaryApplications
        getappdata={getappdata}
        username={username}
        date={date}
      />
    );
  };

  const getAppChart = () => {
    let bkpappdata = [...appdata];
    let bkpappdata1 = [...appdata];
    let finaldata = bkpappdata.slice(0, 3);
    // console.log(bkpappdata); return
    let otherdata = bkpappdata1.splice(3);
    let othertotal = otherdata.reduce((a, b) => a + Number(b.usage), 0);
    let first = finaldata.map((a, b) => Number(a.usage));
    let appname = finaldata.map((a, b) => a.appname);
    first.push(othertotal);
    appname.push("Other");
    // console.log(appname);
    // console.log(first); return
    let data = [{ appname: appname, value: first }];

    return <SidebarChart data={data} />;
  };

  const ChartMostAppUsedfun = () => {
    return <ChartMostAppUsed hrs={mostapphrs} data={mostapp} />;
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Applications</h2>
        <div className="h-auto">
          <div className="text-left">
            {master === 1 &&
              <Select
                value={orgval}
                className="basic-single mr-2"
                onChange={(e) => {
                  getorg(e);
                }}
                options={orgs}
                // getOptionLabel={(option) =>
                //   ` ${option.active}   ${option.label}`
                // }
                styles={{
                  option: (base) => ({
                    ...base,
                    // height: "100%",
                    display: "flex",
                    textAlign: "left",
                  }),
                }}
              />
            }

            <button className="mx-2" onClick={prevDay}>
              <GrPrevious />
            </button>
            <DatePicker
              clearIcon=""
              calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
              onChange={handledate}
              value={currdate}
            />
            <button className="mx-2" onClick={nexTDay}>
              <GrNext />
            </button>
            <button className="ml-1 btn btn-default">
              <img width={15} onClick={reloadFun} src={reload} alt="" srcset="" />
            </button>

          </div>
        </div>
      </div>

      <div className="intro-y chat grid grid-cols-12 gap-3 mt-2">
        <div className="col-span-12 lg:col-span-3 2xl:col-span-2">
          <div className="tab-content">
            <div
              id="chats"
              className="tab-pane active"
              role="tabpanel"
              aria-labelledby="chats-tab"
            >
              <div className="">
                <div className="box px-5 pt-5 pb-5 lg:pb-0 mt-0">
                  <div className="relative text-slate-500 pb-5">
                    <input
                      type="text"
                      className="form-control py-3 px-4 border-transparent bg-slate-100 "
                      placeholder="Search for users..."
                      onChange={(e) => filterdata(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="chat__chat-list overflow-y-auto scrollbar-hidden ">
                {userdata &&
                  userdata.map((item, i) => {
                    return (
                      <div
                        className={
                          name === item.name
                            ? "intro-x cursor-pointer box relative flex items-center mt-1 p-2 activeuser"
                            : "intro-x cursor-pointer box relative flex items-center mt-1 p-2"
                        }
                        onClick={() => setUsername(item.username, item.name)}
                        key={i}
                      >
                        <div className="w-12 h-12 flex-none image-fit mr-1 usericon">
                          {
                            <>
                              <div className="rounded-full w-3 bg-blue">
                                {item.name.slice(0, 1)}
                              </div>
                            </>
                          }
                        </div>

                        <div className="ml-2 overflow-hidden">
                          <div className="flex items-center">
                            <div className="font-medium text-left d-flex">
                              {item.name}
                            </div>
                          </div>
                        </div>
                        <div className="ml-2 overflow-hidden">
                          <div className="flex items-center">
                            <div className="font-medium text-left d-flex">
                              {item.isactive === 1 ?
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    background: "#0ff10f",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                ></div>
                                :
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    background: "red",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                ></div>
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="intro-y col-span-12 lg:col-span-9 2xl:col-span-10">
          <div className="text-left mb-2">
            <span>
              <b>{name}</b>
            </span>
          </div>
          <div className="box">
            <div className="intro-y flex flex-col sm:flex-row items-center">
              <strong className="p-3">
                3 Most Used Application Statistics
              </strong>
            </div>
            <div className="p-5 pb-5">
              {loading1 ? (
                <div className="text-center m-5 p-5">
                  <HashLoader
                    color="#5755d9"
                    size={30}
                    style={{ position: "absolute", right: "50%" }}
                  />
                </div>
              ) : mostapp && mostapp.length > 0 ? (
                ChartMostAppUsedfun()
              ) : (
                "No Data"
              )}
            </div>
          </div>

          <div className="mt-2">
            <div className="intro-y flex flex-col sm:flex-row items-center">
              <strong className="p-3 ml-0">Applications Stats</strong>
            </div>

            {loading ? (
              <div className="text-center m-5 p-5">
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              </div>
            ) : (
              getSummaryApplications()
            )}
          </div>

          <div className="intro-y col-span-12 lg:col-span-3 2xl:col-span-3 mt-5">
            <div className="box">
              <div className="intro-y flex flex-col sm:flex-row items-center">
                <strong className="p-3">Top 3 Used Applications</strong>
              </div>
              <div className="intro-y grid grid-cols-12 gap-1 mt-2">
                <div className="col-span-12 md:col-span-6 2xl:col-span-4">
                  {loading ? (
                    <div className="text-center m-5 p-5">
                      <HashLoader
                        color="#5755d9"
                        size={30}
                        style={{ position: "absolute", right: "50%" }}
                      />
                    </div>
                  ) : appdata && appdata.length > 0 ? (
                    getAppChart()
                  ) : (
                    "No Data"
                  )}

                  {/* <div className="intro-y box cardborder">
                                <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    <small>
                                        Other
                                    </small>
                                    <small className='ml-auto'>
                                    <img src="" style={{width:'20px'}} alt="" srcset="" /> 
                                    </small>
                                </div>
                                
                                <div id="progressbar-height" className="p-1">
                                    <div className="preview" >
                                        <div className="progress h-3 mb-1 mt-0">
                                            <div className="progress-bar w-3/4" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"> {otherusage} %</div>
                                        </div>                                        
                                    </div>
                                    <small className='text-left d-flex'> hh:mm:ss</small>
                                </div>
                            </div>                        */}
                </div>

                <div className="col-span-12 md:col-span-6 2xl:col-span-8">
                  {loading ? (
                    <div className="text-center m-5 p-5">
                      <HashLoader
                        color="#5755d9"
                        size={30}
                        style={{ position: "absolute", right: "50%" }}
                      />
                    </div>
                  ) : (
                    appdata &&
                    appdata.map((item, i) => {
                      var date = new Date(0);
                      date.setSeconds(item.time);
                      var timeString = date.toISOString().substring(11, 19);
                      if (i < 3) {
                        return (
                          <div className="intro-y box cardborder" key={i}>
                            <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                              <small>{item.appname}</small>
                              <small className="ml-auto">
                                <img
                                  src={item.icon}
                                  style={{ width: "20px" }}
                                  alt=""
                                  srcSet=""
                                />
                              </small>
                            </div>

                            <div id="progressbar-height" className="p-1">
                              <div className="preview">
                                <div className="progress h-3 mb-1 mt-0">
                                  <div
                                    className="progress-bar w-3/4"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    {item.usage} %
                                  </div>
                                </div>
                              </div>
                              <small className="text-left d-flex">
                                {timeString} hh:mm:ss
                              </small>
                            </div>
                          </div>
                        );
                      }
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
});

export default Applications;
