import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import moment from "moment";

const Profile = () => {
  let [tabtype, settabtype] = useState(0);
  const [data, setdata] = useState([]);
  const params = useParams();
  const is_auth = JSON.parse(localStorage.getItem("isAuth"))
  console.log(is_auth, 'is_auth')
  useEffect(() => {
    getData("");
  }, []);

  const getData = async (date) => {
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    console.log(params.uuid);
    await fetch(BASEURL + `getUserDetails/${params.user_id}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        type: 0,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setdata(res.userdata);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e, field) => {
    setdata((prevData) => {
      const newData = [...prevData]; // Copy state
      if (newData.length > 0) {
        newData[0][field] = e.target.value; // Update the field
      }
      return newData;
    });
  };

  const handleSave = async () => {
    try {
      let userdata = JSON.parse(localStorage.getItem("isAuth"));
      if (!userdata) {
        return;
      }

      let token = userdata?.usertoken;
      let uuid = userdata?.uuid;

      if (!uuid) {
        alert("UUID not found.");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");


      const payload = {
        name: data[0]?.name,
        username: data[0]?.username,
        dob: moment.utc(data[0].dob).format("YYYY-MM-DD"),
        date_of_joining: moment.utc(data[0].date_of_joining).format("YYYY-MM-DD"),
        email: data[0]?.email,
        contact_no: data[0]?.contact_no,
        permanent_address: data[0]?.permanent_address,
        blood_group: data[0]?.blood_group,
        height: data[0]?.height,
        weight: data[0]?.weight,
        uuid: uuid,
        type: data[0]?.type,
        shift_time: Number(data[0]?.shift_time),
      };

      console.log(payload, 'payload')
      const response = await fetch(`${BASEURL}update-profile/${uuid}`, {
        method: "PUT", // Changed to PUT
        headers: myHeaders,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === 200) {
        getData("")
      } else {
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };


  const handleNext = (type) => {
    if (type < 2) {
      settabtype(type + 1);
    } else {
      handleSave();
    }
  };
  console.log(data, 'data')
  const handleBack = (type) => {
    if (type > 0) {
      settabtype(type - 1);
    }
  };

  return (
    <>
      <div style={{
        display: "flex",
        justifyContent: "start"
      }} className="flex justify-content">
        <div style={{
          width: "600px",
        }} className="profile-info">
          <div>
            <div
              className="py-5 flex"
              style={{ cursor: "pointer" }}
            >
              <div
                className={
                  tabtype === 0
                    ? "first-tab-active  px-2"
                    : "first-tab px-2"
                }
                onClick={() => settabtype(0)}
              >
                Personal Details{" "}
              </div>
              <div
                className={
                  tabtype === 1
                    ? "middle-tab-active px-2"
                    : "middle-tab px-2"
                }
                onClick={() => settabtype(1)}
              >
                Contact Details{" "}
              </div>
              <div
                className={
                  tabtype === 2
                    ? "last-tab-active px-2"
                    : "last-tab px-2"
                }
                onClick={() => settabtype(2)}
              >
                Health Details{" "}
              </div>

            </div>
          </div>

          <div className="grid grid-cols-12 gap-2 mt-4">
            {tabtype === 0 && (
              <>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    type="text"
                    className="form-control"
                    value={data[0]?.name || ""}
                    disabled={is_auth?.role !== 2}
                    onChange={(e) => handleInputChange(e, "name")}
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-6"
                    type="text"
                    className="form-control"
                    value={data.length > 0 && data[0].username}
                    disabled
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <select
                    id="update-profile-form-8"
                    className="form-select"
                    disabled={is_auth?.role !== 2}
                  >
                    <option value={0}>Male</option>
                    <option value={1}>Female</option>
                    <option value={2}>Other</option>
                  </select>
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-7"
                    type="text"
                    onChange={(e) => handleInputChange(e, "dob")}
                    disabled={is_auth?.role !== 2}
                    className="form-control"
                    value={
                      data.length > 0
                        ? new Date(data[0].dob).toISOString().split("T")[0]
                        : ""
                    }
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">

                  <input
                    id="update-profile-form-7"
                    type="text"
                    onChange={(e) => handleInputChange(e, "date_of_joining")}
                    className="form-control"
                    disabled={is_auth?.role !== 2}
                    value={
                      data.length > 0
                        ? format(
                          new Date(data[0].date_of_joining),
                          "yyyy-MM-dd"
                        )
                        : null
                    }
                  />
                </div>

                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <select
                    id="update-profile-form-8"
                    className="form-select"
                    value={data.length > 0 ? data[0].type : null}
                    disabled
                  >
                    <option value={2}>Employee</option>
                    <option value={1}>Manager</option>
                  </select>
                </div>

                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <select
                    id="update-profile-form-8"
                    className="form-select"
                    value={data.length > 0 ? data[0].shift_time : ""}
                    onChange={(e) => handleInputChange(e, "shift_time")}
                    disabled={is_auth?.role !== 2}
                  >
                    <option value={0}>08:30 - 17:30</option>
                    <option value={1}>09:00 - 18:00</option>
                    <option value={2}>10:00 - 19:00</option>
                  </select>
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">

                </div>
              </>
            )}
            {tabtype === 1 && (
              <>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-6"
                    type="text"
                    onChange={(e) => handleInputChange(e, "email")}
                    className="form-control"
                    value={data.length > 0 && data[0].email}
                    disabled={is_auth?.role !== 2}
                    placeholder="Email"
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-6"
                    type="text"
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "contact_no")}
                    value={data.length > 0 && data[0].contact_no}
                    placeholder="Number"
                    disabled={is_auth?.role !== 2}
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <textarea
                    id="update-profile-form-5"
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "permanent_address")}
                    disabled={is_auth?.role !== 2}
                  >
                    {data.length > 0 ? data[0].permanent_address : null}
                  </textarea>
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">

                </div>
              </>
            )}
            {tabtype === 2 && (
              <>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-7"
                    type="text"
                    placeholder="Blood group"
                    disabled={is_auth?.role !== 2}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "blood_group")}
                    value={data.length > 0 ? data[0].blood_group : null}
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-7"
                    type="text"
                    placeholder="Height"
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "height")}
                    disabled={is_auth?.role !== 2}
                    value={data.length > 0 ? data[0].height : null}
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">
                  <input
                    id="update-profile-form-7"
                    type="text"
                    placeholder="Weight"
                    onChange={(e) => handleInputChange(e, "Weight")}
                    disabled={is_auth?.role !== 2}
                    className="form-control"
                    value={data.length > 0 ? data[0].weight : null}
                  />
                </div>
                <div className="col-span-12 lg:col-span-6 2xl:col-span-6">

                </div>
              </>
            )}

            {
              tabtype !== 0 &&
              <div className="col-span-12 lg:col-span-6 2xl:col-span-6 profile-cancle" onClick={() => handleBack(tabtype)}>
                <button >
                  Back
                </button>
              </div>
            }
            {
              tabtype === 0 &&
              <div className="col-span-12 lg:col-span-6 2xl:col-span-6">

              </div>
            }
            <div className="col-span-12 lg:col-span-6 2xl:col-span-6 profile-next" onClick={() => handleNext(tabtype)}>
              <button >
                {
                  tabtype == 2 ? "Save" : "Next"
                }
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Profile