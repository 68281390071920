import React, { useEffect, useState } from "react";
import { BASEURL } from "./../BASEURL";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import DatePicker from "react-date-picker";
import Chart from "./Chart";
import HashLoader from "react-spinners/HashLoader";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import reload from '../../reload.png'


const Attendance = () => {
  const loadingContext = useLoadingContext();
  const [userdata, setuserdata] = useState(null);
  const [attdata, setattdata] = useState(null);
  let [loading, setLoading] = useState(false);
  const [date, setdate] = useState(new Date());
  const [dateval, setdateval] = useState(new Date());
  const [username, setusername] = useState();
  const [columns, setcolumn] = useState([]);
  const [alluser, setalluser] = useState(null);
  const [orgs, setorgs] = React.useState([]);
  const [master, ] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);
  const [orgval, setorgval] = React.useState([]);

  useEffect(() => {
    getOrganizations();
    getUserData();
    setorgval({value: selectedorg, label: JSON.parse(localStorage.getItem("isAuth")).companydata[0].name});
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      // setusername(userdata.username);

      getData(format(new Date(), "yyyy-MM-dd"), "");
    }
    const loading = async () => {
      loadingContext.done();
    };

    loading();
  }, []);

  const reloadFun = ()=> {
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      // setusername(userdata.username);

      getData(format(new Date(), "yyyy-MM-dd"), "");
    }
  }


  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders    
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };


  const getorg = async (org) => {
    setorgval(org);
    org = org.value
    setselectedorg(org);
    getUserData(org);
  }

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.name}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  const getData = async (date, username) => {
    // console.log(date, username);
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-timeline`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        username,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
        department:JSON.parse(localStorage.getItem("isAuth")).department,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          const columns = [
            {
              title: "Name",
              field: "name",
              formatter: reactFormatter(<DetailVal />),
              width: 250,
            },
            { title: "Date", field: "date", width: 120 },
            { title: "In", field: "in", width: 100 },
            { title: "Out", field: "out", width: 100 },
            { title: "Duration", field: "duration", width: 100 },
          ];
          setattdata(res.attdata);

          let hrsarr = [];
          let indarr = [];
          res.attdata.forEach((element2) => {
            element2.timearr.forEach((element1, ind) => {
              if (element1.length > 0) {
                let hours;
                if (element1[0].hours < 10) {
                  hours = "0" + element1[0].hours + " AM";
                } else {
                  if (element1[0].hours < 12) {
                    hours = element1[0].hours + " AM";
                  } else {
                    hours = element1[0].hours + " PM";
                  }
                }
                if (element1 && element1[0].starttime !== "") {
                  hrsarr.push(hours);
                  indarr.push(ind);
                }
              }
            });
          });

          const letters = new Set(hrsarr);
          let count = 0;
          letters.forEach((element) => {
            columns.push({
              title: element,
              field: "0",
              width: 200,
              formatter: reactFormatter(<AppUsage type={indarr[count]} />),
            });
            count++;
          });

          setcolumn(columns);
        } else {
          setattdata([]);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getUserData = async (org="") => {
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type:1,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        uuid: org!=='' ? org : selectedorg,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
        // department:JSON.parse(localStorage.getItem("isAuth")).department,
        department:"",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setuserdata(res.data);

          setalluser(res.alluser);
        }
      })
      .catch((err) => console.log(err));
  };

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = (arr1 ? arr1 : []).filter((el) => {
      return !(arr2 ? arr2 : []).find((element) => {
        return Number(element.username) === Number(el.username);
      });
    });
    return res;
  };

  const filterByReference2 = (arr1, arr2) => {
    let res = [];
    res = (arr1 ? arr1 : []).filter((el) => {
      return (arr2 ? arr2 : []).find((element) => {
        return Number(element.username) === Number(el.username);
      });
    });
    return res;
  };
  let filterarr = filterByReference(userdata, alluser);
  let filterarr2 = filterByReference2(userdata, alluser);

  if (filterarr2 && filterarr2.length > 0) {
    for (let i = 0; i < filterarr2.length; i++) {
      filterarr2[i].active2 = true;
    }
  }

  let finalarr3 = filterarr2.concat(filterarr);
  let data = [{ value: "", label: "Select All", active: "" }];
  for (let i = 0; i < finalarr3.length; i++) {
    data.push({
      value: finalarr3[i].username,
      label: finalarr3[i].name,
      active: finalarr3[i].active2 ? "🟢" : "🔴",
    });
  }

  const options = {
    pagination: "local",
    paginationSize: 10,
  };

  function AppUsage(props) {
    const rowData = props.cell._cell.row.data;
    let data = rowData.timearr[props.type];
    return (
      <>
        <Chart rowData={data} rows={rowData}></Chart>
      </>
    );
  }

  const handledate = (e) => {
    let date;
    if (e !== null) {
      setdate(e);
      date = format(new Date(e), "yyyy-MM-dd");
      setdateval(new Date(e));
    } else {
      date = format(new Date(), "yyyy-MM-dd");
      setdateval(new Date());
    }
    getData(date, username);
  };

  const nexTDay = () => {
    let tomorrow = date;
    tomorrow.setDate(date.getDate() + 1);

    setdate(tomorrow);
    setdateval(new Date(tomorrow));
    let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
    getData(date2, username);
  };

  const prevDay = () => {
    let tomorrow = date;
    tomorrow.setDate(date.getDate() - 1);

    setdate(tomorrow);
    setdateval(new Date(tomorrow));
    let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
    getData(date2, username);
  };

  const handleuser = (e) => {
    setusername(e);
    getData(format(new Date(date), "yyyy-MM-dd"), e);
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <div className="text-lg font-medium mr-auto text-left"></div>
        <div className="">
          
        {master === 1 &&
            <Select
              value={orgval}
              className="basic-single mr-2"
              onChange={(e) => {
                getorg(e);
              }}
              options={orgs}
              // getOptionLabel={(option) =>
              //   ` ${option.active}   ${option.label}`
              // }
              styles={{
                option: (base) => ({
                  ...base,
                  // height: "100%",
                  display: "flex",
                  textAlign: "left",
                }),
              }}
            />
          }

          <Select
              // defaultValue={data[0]}
              className="basic-single mr-2"
              onChange={(e) => {
                handleuser(e.value);
              }}
              options={data}
              getOptionLabel={(option) => `${option.active} ${option.label}`}
              styles={{
                option: (base) => ({
                  ...base,

                  height: "100%",
                  display: "flex",
                }),
              }}
            />

        
          <DatePicker
            clearIcon=""
            calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
            onChange={handledate}
            value={dateval}
          />
          <button className="ml-1 btn btn-default">
                  <img width={15} onClick={reloadFun} src={reload} alt="" srcset="" />
                 </button>

            <button className="mx-2" onClick={prevDay}>
            <GrPrevious />
          </button>{" "}
          <button className="mx-2" onClick={nexTDay}>
            <GrNext />
          </button>{" "}
          {/* <div
            className="ml-2"
            style={{
              color: "hsl(0, 0%, 40%)",
              display: "inline-block",
              fontSize: 12,
              width: "250px",
            }}
          >
      
          </div> */}
        </div>
      </div>
      <div className="intro-y box p-5 mt-5">
        <h2 className="text-lg font-medium mr-auto text-left">
          All Employees Timeline
        </h2>
        <div className="overflow-x-auto scrollbar-hidden timelinetable">
          {loading === true ? (
            <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
              <HashLoader color="#5755d9" size={30} />
            </div>
          ) : (
            <div>
              {attdata && attdata.length > 0 ? (
                <ReactTabulator
                  columns={columns}
                  data={attdata}
                  options={options}
                  className="mt-5 table-report table-report--tabulator"
                />
              ) : (
                <div className="text-danger text-left mt-5 mb-5">
                  No Data Available
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Attendance;
