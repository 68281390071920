
import React, { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField, Select, MenuItem, Button } from "@mui/material";
import { BASEURL } from "./BASEURL";
import { CSVLink } from "react-csv"; // Import CSVLink from react-csv
import { FaCalendar, FaFileCsv, FaDownload, FaTimes, FaCalendarAlt } from "react-icons/fa";
import { HashLoader } from "react-spinners"; // Import the HashLoader
import Modal from "react-modal";
import { useLoadingContext } from "react-router-loading";
import moment from "moment";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		padding: "0%",
		borderRadius: "5px",
	},
};

const headers = [
	{ label: "Date", key: "date" },
	{ label: "In Time", key: "start_time" },
	{ label: "Late", key: "late_coming" },
	{ label: "Lunch HRS", key: "lunch_break_duration" },
	{ label: "Out Time", key: "end_time" },
	{ label: "Pending HRS", key: "pending_time" },
	{ label: "Total HRS", key: "total_working_hours" },
	{ label: "Other Break", key: "other_break_duration" },
	{ label: "After Office HRS", key: "after_office_hours" },
	{ label: "1st/2nd Half", key: "half_day_type" },
]
const AttendanceReport = () => {
	const [departments, setDepartments] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [selectedDepartment, setSelectedDepartment] =
		useState("All department");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
	const user_data = JSON.parse(localStorage.getItem("isAuth")); // Parse the user data first
	const userUuid = user_data?.uuid; // Extract uuid from the parsed data
	const userType = user_data?.type;
	const [attendanceData, setAttendanceData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [departmentLoading, setdepartmentLoading] = useState(false);
	const [attdata, setAttdata] = useState([]);
	const [pendingHours, setPendingHours] = useState();
	const [totalHours, setTotalHours] = useState();
	const [shiftStartTime, setshiftStartTime] = useState();
	const [shiftEndTime, setshiftEndTime] = useState();
	const [totalAfterHours, setTotalAfterHours] = useState(0);
	const [selectedEmpName, setSelectedEmpName] = useState("");
	const [totalAfterOfficeHours, setTotalAfterOfficeHours] = useState("00:00:00");
	const [isLoad, setIsLoad] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const [attendanceSummary, setAttendanceSummary] = useState({
		presentDays: 0,
		halfDays: 0,
		absentDays: 0,
		holidays: 0,
		totalWorkingDays: 0,
		isSixMonthsCompleted: false,
		isEligibleForPL: false,
	});
	const [userWorkTypeModal, setUserWorkTypeModal] = useState()
	function formatDurationToTime(duration, baseTime = "00:00:00") {
		// Parse hours and minutes from the duration string
		const hoursMatch = duration.match(/(\d+)h/);
		const minutesMatch = duration.match(/(\d+)m/);

		const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
		const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;

		// Convert baseTime to a Date object
		const [baseHours, baseMinutes, baseSeconds] = baseTime.split(":").map(Number);
		const date = new Date();
		date.setHours(baseHours, baseMinutes, baseSeconds);

		// Add the parsed hours and minutes to the base time
		date.setHours(date.getHours() + hours);
		date.setMinutes(date.getMinutes() + minutes);

		// Format the result as HH:mm:ss
		const formattedTime = date.toTimeString().split(" ")[0];
		return formattedTime;
	}
	const [openRow, setOpenRow] = useState(null);

	const toggleRow = (index) => {
		setOpenRow(openRow === index ? null : index);
	};
	useEffect(() => {
		if (userType === 2) {
			getMonthWiseData(userUuid, selectedDate.getMonth() + 1, selectedDate.getFullYear())
		}
	}, [])

	function formatTime(time) {
		if (!time || typeof time !== "string") return "";
		return time.slice(0, 2) + ":" + time.slice(3, 5);
	}

	const formatTotalHours = (totalMinutes) => {
		const hours = Math.floor(totalMinutes / 60)
			.toString()
			.padStart(2, "0"); // Zero-padded hours
		const minutes = (totalMinutes % 60).toString().padStart(2, "0"); // Zero-padded minutes
		return `${hours}:${minutes}`;
	};

	const getMonthWiseData = async (uuid, month, year) => {
		// if (!uuid) {
		// 	console.error("UUID is missing");
		// 	return;
		// }
		setIsLoad(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");
		try {
			const response = await fetch(`${BASEURL}get-monthwise-attendance/${uuid}`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					// month: selectedDate.getMonth() + 1,
					// year: selectedDate.getFullYear(),
					month: month,
					year: year,
					company_uuid: selectedorg,
				}),
			});
			if (!response.ok) {
				throw new Error(`Error: ${response.status} - ${response.statusText}`);
			}
			const result = await response.json();
			setPendingHours(result?.total_pending_time)
			setTotalHours(result?.totalTime?.totalWorkingHours)
			setshiftEndTime(result?.shiftEndTime)
			setshiftStartTime(result?.shiftStartTime)
			setAttendanceSummary(result?.attendance_summary);
			setUserWorkTypeModal(result?.userWorkType)
			const total = result?.attendance
				?.flatMap((row) => row?.after_office_hours || []) // Flatten after office hours arrays
				.reduce((sum, afterHours) => {
					const duration = afterHours?.duration || "0h 0m"; // Fallback for missing duration
					const [hoursPart, minutesPart] = duration.split("h").map((part) => part.trim());

					const hours = parseInt(hoursPart) || 0; // Parse hours, default to 0
					const minutes = parseInt(minutesPart?.replace("m", "")) || 0; // Parse minutes, default to 0

					return sum + hours * 60 + minutes; // Convert hours to minutes and add to total
				}, 0);

			setTotalAfterHours(total || 0);
			// console.log(total, 'total')
			setSelectedEmpName(result?.attendance?.[0]?.name || "NA");
			setAttdata(result.attendance);
		} catch (err) {
			console.error("Error fetching month-wise data:", err.message);
		} finally {
			setIsLoad(false);
		}
	};
	const csvData = attdata.map((row) => ({
		date: row?.date ? moment(row.date).format("DD/MM/YY") : "-",
		start_time: row?.start_time || "-",
		late_coming: row?.late_coming || "-",
		lunch_break_duration: row?.lunch_break_duration || "-",
		end_time: row?.end_time || "-",
		pending_time: row?.pending_time || "-",
		total_working_hours: row?.total_working_hours || "-",
		other_break_duration: row?.other_break_duration || "-",
		after_office_hours: row?.after_office_hours?.length
			? row.after_office_hours.map(session => session.duration).join(", ")
			: "-",
		half_day_type: row?.on_leave
			? "A" // If on leave, show A
			: row?.half_day_type === "First Half"
				? "A/P" // If First Half, show A/P
				: row?.half_day_type === "Second Half"
					? "P/A" // If Second Half, show P/A
					: "P/P", // Default: P/P
	}));

	const footerRow = {
		date: "Total",
		start_time: "-",
		late_coming: "-",
		lunch_break_duration: "-",
		end_time: "-",
		pending_time: pendingHours, // Replace with your calculated pending hours
		total_working_hours: totalHours, // Replace with your calculated total hours
		other_break_duration: "-",
		after_office_hours: formatTotalHours(totalAfterHours), // Replace with your formatted total after-hours
		half_day_type: "-", // Placeholder for the last column
	};

	// Append the footer row to the csvData array
	csvData.push(footerRow);

	const showMonthPicker = (e) => {
		e.preventDefault();
		setIsVisible(true); // Show the month picker
	};

	const getMonthValue = () => {
		// Format selected month and year for display in the input
		return `${selectedMonth.month}/${selectedMonth.year}`;
	};

	const handleOnChange = async (year, month) => {
		setSelectedMonth({ year, month });
		setIsVisible(false);
		getMonthWiseData(userUuid, month, year)
		// await fetchAttendanceData(selectedDepartment, month, year)
	};

	const handleOnDismiss = () => {
		setIsVisible(false); // Close the picker without selection
	};
	const convertToMinutes = (duration) => {
		const match = duration.match(/(\d+)h\s*(\d+)?m?/);
		if (!match) return 0;
		const hours = parseInt(match[1], 10) || 0;
		const minutes = parseInt(match[2], 10) || 0;
		return hours * 60 + minutes;
	};
	useEffect(() => {
		if (attdata?.length > 0) {
			const totalMinutes = attdata.reduce((total, row) => {
				return total + (row.after_office_hours?.reduce(
					(sum, session) => sum + convertToMinutes(session.duration),
					0
				) || 0);
			}, 0);

			// Convert minutes back to hh:mm
			const formattedTotal = `${Math.floor(totalMinutes / 60)
				.toString().padStart(2, "0")}:${(totalMinutes % 60).toString().padStart(2, "0")}`;

			setTotalAfterOfficeHours(formattedTotal);
		}
	}, [attdata]);

	const hasAfterOfficeHours = attdata?.some(row => row?.after_office_hours?.length > 0);

	return (
		<div className="intro-y lg:mt-1">
			<div className="flex items-center justify-between text-lg font-medium w-full bg-white px-4 py-4">
				<div className="flex flex-wrap items-center space-x-8 w-full">
					<div style={{ padding: "15px 30px 10px 10px" }} className="text-left flex-0.5">
						<span style={{ color: "#68ba6f", fontSize: "15px" }} className="text-green-500 block">Working Days</span>
						<span style={{ color: "#68ba6f", fontSize: "15px" }} className="text-gray-700 font-semibold">
							{attendanceSummary?.totalWorkingDays || 0}
						</span>
					</div>
					<div className="border-l-2 h-12"></div>
					{/* Sandwich Leave */}
					<div style={{ padding: "15px 20px 10px 10px" }} className="text-left flex-0.5">
						<span style={{ fontSize: "15px" }} className="text-red-500 block">Sandwich Leave</span>
						<span style={{ fontSize: "15px" }} className="text-red-500 font-semibold">{attendanceSummary?.sandwichLeave || 0}</span>
					</div>
					<div className="border-l-2 h-12"></div>
					{/* Present Day */}
					<div style={{ padding: "15px 20px 10px 10px" }} className="text-left flex-0.5">
						<span style={{ color: "#44a7f1", fontSize: "15px" }} className="text-blue-500 block">Present Day</span>
						<span style={{ color: "#44a7f1", fontSize: "15px" }} className="text-gray-700 font-semibold">
							{attendanceSummary?.totalPresentDays || 0}
						</span>
					</div>
					<div className="border-l-2 h-12"></div>
					{/* Month Leaves */}
					<div style={{ padding: "15px 20px 10px 10px" }} className="text-left flex-0.5">
						<span style={{ color: "#fe6f49", fontSize: "15px" }} className="text-[#fe6f49] block">Month Leaves</span>
						<span style={{ color: "#fe6f49", fontSize: "15px" }} className="text-gray-700 font-semibold">
							{attendanceSummary?.monthLeaves || 0}
						</span>
					</div>
					<div className="border-l-2 h-12"></div>
					<div style={{ padding: "15px 20px 10px 10px" }} className="text-left flex-0.5">
						<span style={{ color: "#46b1a7", fontSize: "15px" }} className="text-[#fe6f49] block">Month Days</span>
						<span style={{ color: "#46b1a7", fontSize: "15px" }} className="text-gray-700 font-semibold">
							{attendanceSummary?.totalDays || 0}
						</span>
					</div>
					<div className="border-l-2 h-12"></div>
					{/* Non Working Days */}
					<div style={{ padding: "15px 20px 10px 10px" }} className="text-left flex-0.5">
						<span style={{ color: "#abbac0", fontSize: "15px" }} className="text-[#abbac0] block">Non Working Days</span>
						<span style={{ color: "#abbac0", fontSize: "15px" }} className="text-gray-700 font-semibold">
							{attendanceSummary?.holidays || 0}
						</span>
					</div>
					<div className="border-l-2 h-12"></div>
					{/* Eligible for PL */}
					<div style={{ padding: "15px 20px 10px 10px" }} className="text-left">
						<span style={{ color: "#52d1ea", fontSize: "15px" }} className="text-[#52d1ea] block">Eligible for PL</span>
						<span style={{ color: "#52d1ea", fontSize: "15px" }} className="text-gray-700 font-semibold">
							{attendanceSummary?.isEligibleForPL ? "YES" : "NO"}
						</span>
					</div>
					<div className="flex items-center border p-2 space-x-2 bg-white flex-1">
						<input
							type="text"
							onFocus={(e) => showMonthPicker(e)}
							value={getMonthValue()}
							className="text-gray-700 p-1 text-center border-none focus:outline-none"
							style={{ fontSize: "15px", width: "120px" }}
							readOnly
						/>
						<button
							className="p-2 text-gray-600 hover:text-gray-800 flex items-center justify-center"
							style={{ width: "40px", height: "40px" }}
							onClick={(e) => showMonthPicker(e)}
						>
							<FaCalendarAlt style={{ fontSize: "25px" }} />
						</button>
						<ReactMonthPicker
							show={isVisible}
							lang={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
							value={selectedMonth}
							onChange={handleOnChange}
							onDismiss={handleOnDismiss}
						/>
					</div>
				</div>

			</div>
			{isLoad ? (
				<div className="flex justify-center items-center" style={{ height: "300px" }}>
					<HashLoader color="#5755d9" size={20} style={{ marginTop: "140px" }} />
				</div>
			) : (
				<div className="intro-y overflow-x-auto" style={{ border: '1px solid #fc9a30', borderRadius: "7px" }}>
					<div
						style={{ backgroundColor: "#fc9a30" }}
						className="flex justify-between p-2 items-center text-gray-700 bg-[#fc9a30] px-1"
					>
						<div className="flex justify-center content-center">
							<div>
								<h2 className="text-lg text-white font-semibold pl-3">{selectedEmpName}</h2>
							</div>
							<div className="ml-3 content-center">
								<CSVLink
									data={csvData}
									headers={headers}
									style={{ backgroundColor: "#24a184" }}
									filename={`${selectedEmpName || "Employee"}_Attendance_Report.csv`}
									className="bg-green text-white content-center"
								>
									<div
										className="flex items-center justify-center px-2 py-1 rounded bg-green-500"
										style={{ border: "1px solid #fff", borderRadius: "5px", fontWeight: "bold" }}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="#fff"
											className="bi bi-download"
											viewBox="0 0 16 16"
										>
											<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
											<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
										</svg>
									</div>
								</CSVLink>
							</div>
						</div>
						<div className="ml-auto pr-2 flex content-center">
							<h2 className="text-white content-center mt-1">
								{userWorkTypeModal === 1 ? "Shift Time: Hourly" : `Shift Time: ${formatTime(shiftStartTime)} - ${formatTime(shiftEndTime)}`}
							</h2>
						</div>
					</div>
					{userWorkTypeModal === 1 ? (
						<>
							<table className="table-auto w-full text-center border-collapse border border-gray-300"
								style={{
									borderRadius: "10px 10px 0 0",
									width: "100%",
									overflow: "hidden",
									backgroundColor: "#ffffff",
								}}>
								<thead>
									<tr className="bg-gray-100 text-gray-200 text-lg text-left">
										{[
											"Date",
											"In Time",
											"Out Time",
											"Total HRS",
											"More"
										].filter(Boolean).map((header, idx) => (
											<th key={idx} className="border border-gray-300 p-2" style={{ fontSize: "14px" }}>
												{header}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{attdata?.map((row, index) => {
										const hasAfterOfficeHours = row?.after_office_hours?.length > 0;
										const isExpanded = openRow === index;
										return (
											<React.Fragment key={index}>
												<tr className="bg-white hover:bg-gray-100 text-gray-700 border-b">
													{[
														row?.date,
														row?.start_time || "-",
														row?.end_time || "-",
														row?.total_working_hours || "-",
														<button
															onClick={() => toggleRow(index)}
															className="px-2 py-1 flex items-center bg-blue-500 rounded hover:bg-blue-600"
														>
															More
															<span className="ml-2">{isExpanded ? "▲" : "▼"}</span>
														</button>,
													].map((cell, idx) => (
														<td
															key={idx}
															className="border border-gray-300 p-2 text-left"
															style={{ fontWeight: 500, fontSize: "13px", color: "#8F8F8F" }}
														>
															{cell}
														</td>
													))}
												</tr>
												{isExpanded && (
													<tr className="relative z-10">
														<td colSpan={11} className="border border-gray-300 text-right">
															<div className="absolute top-2 right-0 bg-white shadow-sm rounded border border-gray-200 z-20 w-25">
																<div className="p-2 space-y-2">
																	<div>
																		<div className="text-xs font-medium text-gray-500">Other Break</div>
																		<div style={{ color: "gray" }} className="text-sm font-medium text-gray-900">
																			{row?.other_break_duration || "0"}
																		</div>
																	</div>
																	<div>
																		<div className="text-xs font-medium text-gray-500">After Office HRS</div>
																		<div style={{ color: "gray" }} className="text-sm font-medium text-gray-900">
																			{hasAfterOfficeHours ? (
																				row.after_office_hours.map((session, i) => (
																					<div key={i}>
																						{formatDurationToTime(session.duration)}
																					</div>
																				))
																			) : (
																				"0"
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												)}
											</React.Fragment>
										);
									})}
								</tbody>
								<tfoot style={{ backgroundColor: "#fc9a30" }}>
									<tr className="bg-orange-500 text-white text-left">
										{[
											"Total",
											"-",
											"-",
											totalHours,
											"-",
										].filter(Boolean).map((footer, idx) => (
											<td key={idx} className="border border-gray-300 p-2"
												style={{
													fontWeight: 500,
													fontSize: "16px",
													color: "white",
													textAlign: "center",
												}}>
												{footer}
											</td>
										))}
									</tr>
								</tfoot>
							</table>
						</>

					) : (
						<>
							<table className="table-auto w-full text-center border-collapse border border-gray-300"
								style={{
									borderRadius: "10px 10px 0 0",
									width: "100%",
									overflow: "hidden",
									backgroundColor: "#ffffff",
								}}>
								<thead>
									<tr className="bg-gray-100 text-gray-200 text-lg text-left">
										{[
											"Date",
											"In Time",
											"Late",
											"Lunch HRS",
											"Out Time",
											"Pending HRS",
											"Total HRS",
											// "Other Break",
											// hasAfterOfficeHours ? "After Office HRS" : null,  
											"1st/2nd Half",
											"More"
										].filter(Boolean).map((header, idx) => (
											<th key={idx} className="border border-gray-300 p-2" style={{ fontSize: "14px" }}>
												{header}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{attdata?.map((row, index) => {
										const hasAfterOfficeHours = row?.after_office_hours?.length > 0;
										const isExpanded = openRow === index;

										return (
											<React.Fragment key={index}>

												<tr className="bg-white hover:bg-gray-100 text-gray-700 border-b">
													{[
														row?.date,
														row?.start_time || "-",
														row?.late_coming || "-",
														row?.lunch_break_duration || "-",
														row?.end_time || "-",
														row?.pending_time || "-",
														row?.total_working_hours || "-",

														row?.on_leave
															? "A"
															: row?.half_day_type === "First Half"
																? "A/P"
																: row?.half_day_type === "Second Half"
																	? "P/A"
																	: "P/P",
														<button
															onClick={() => toggleRow(index)}
															className="px-2 py-1 flex items-center bg-blue-500 rounded hover:bg-blue-600"
														>
															More
															<span className="ml-2">{isExpanded ? "▲" : "▼"}</span>
														</button>,
													].map((cell, idx) => (
														<td
															key={idx}
															className="border border-gray-300 p-2 text-left"
															style={{ fontWeight: 500, fontSize: "13px", color: "#8F8F8F" }}
														>
															{cell}
														</td>
													))}
												</tr>

												{isExpanded && (
													<tr className="relative z-10">
														<td colSpan={11} className="border border-gray-300 text-right">
															<div className="absolute top-2 right-0 bg-white shadow-sm rounded border border-gray-200 z-20 w-25">
																<div className="p-2 space-y-2">
																	<div>
																		<div className="text-xs font-medium text-gray-500">Other Break</div>
																		<div style={{ color: "gray" }} className="text-sm font-medium text-gray-900">
																			{row?.other_break_duration || "0"}
																		</div>
																	</div>
																	<div>
																		<div className="text-xs font-medium text-gray-500">After Office HRS</div>
																		<div style={{ color: "gray" }} className="text-sm font-medium text-gray-900">
																			{hasAfterOfficeHours ? (
																				row.after_office_hours.map((session, i) => (
																					<div key={i}>
																						{formatDurationToTime(session.duration)}
																					</div>
																				))
																			) : (
																				"0"
																			)}
																		</div>
																	</div>
																</div>
															</div>
														</td>
													</tr>
												)}
											</React.Fragment>
										);
									})}
								</tbody>



								<tfoot style={{ backgroundColor: "#fc9a30" }}>
									<tr className="bg-orange-500 text-white text">
										{[
											"Total",
											"-",
											"-",
											"-",
											"-",
											pendingHours,
											totalHours,
											"-",
											// hasAfterOfficeHours ? totalAfterOfficeHours : null, // Hide if no data
											"-",
										].filter(Boolean).map((footer, idx) => (
											<td key={idx} className="border border-gray-300 p-2"
												style={{
													fontWeight: 500,
													fontSize: "16px",
													color: "white",
													textAlign: "center",
												}}>
												{footer}
											</td>
										))}
									</tr>
								</tfoot>
							</table>
						</>
					)}


				</div>
			)}
		</div >

	);
};

export default AttendanceReport;
