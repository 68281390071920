// export const BASEURL = "http://localhost:5000/";
// export const SITEURL = "http://localhost:3000/";
export const BASEURL = "https://trackapi.rayvat.com/";
export const SITEURL = "https://track.rayvat.com/";

// export const SITEURL = "https://trackapi.rayvat.com/";
// export const BASEURL = "https://portal1.rayvat.com/api/";
// export const SITEURL = "https://portal1.rayvat.com/api/";
export const PATHURL = "https://portal.rayvat.com/api";
export const API_BASEURL = "https://webphone-api.voizcall.com/api/";

export const sipConfig = {
	_logEnable: true,
	_iceServer: "stun:stun.l.google.com:19302",
};

export const VAPID_KEY =
	"BA6UflCSncBvyLDW3EXyEQTIjnU0Ov_zQVdJWuhrSu2Jw2WO_K0L2lWWlrkNHUrtklO_VOOWyW9v6c7SbeNx-HA";

// const domains = {
//     baseApiPort: "",
//     baseApiVersion: "/v1.27.2",
//     wssPort: ":7443",
//     xmppWssPort: ":7443/ws",
//     xmppConference: "conference.",

//     // ==== Local server ==============================
//     // baseApiUrl: "http://localhost",
//     baseApiUrl: "wss://billing.tmsuit.com",
//     // wssServer: "wss://s1.netcitrus.com",
//     wssServer: "wss://billing.tmsuit.com",
//     xmppDomain: "",
//     // ======================================================
// };

export const _accountCreds = {
	_sipExtension: "1150",
	_sipPassword: "Ghanshyam@Suthar$jenya",
	_sipServer: "billing.tmsuit.com",
	_sipUsernameWithInstance: "1150",
	_wssServer: "wss://billing.tmsuit.com:7443",
	_extNumber: "1150",
	_instanceId: "ddddaun8b3rw13s0ixmky6ntmgkpprl31s93",
	_sipAor: "1150@billing.tmsuit.com",
};

export const wssServer = "wss://billing.tmsuit.com:7443";
export const appName = "Jenya-Web 1.1.0";
