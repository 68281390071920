import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { useParams } from "react-router-dom";
import { RxAvatar } from "react-icons/rx";
import {
	FaPhone,
	FaCalendar,
	FaEnvelope,
	FaMapMarker,
	FaUser,
	FaTimes,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import { format } from "date-fns";
import Modal from "react-modal";
import MonthlyAttandance from "./empdetails/MonthlyAttandance";
import Leaves from "./empdetails/Leaves";
import LeaveSummary from "./empdetails/LeaveSummary";
import Activity from "./empdetails/Activity";
import Feeds from "./empdetails/Feeds";
import ManagerDetails from "./empdetails/ManagerDetails";
import Documents from "./empdetails/Documents";
import Profile from "./empdetails/Profile";
import { FaUserCircle } from "react-icons/fa";
import { onCurrentEmp } from "../redux/actions/Action";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ReactComponent as TaskIcon } from '../assets/Task.svg';
import { Rating, Stack, Tooltip } from "@mui/material";
import CommonModal from "./admin/CommonModal";

Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "10px",
		border: "1px solid rgb(187, 181, 181)",
		borderRadius: "14px",
		overflowX: "hidden",
		overflowY: "visible",
		width: "600px",
	},
};

const Employees_details = () => {
	const { regState } = useSelector((state) => state.sipjs);
	const { uuid } = useParams()
	const [userdata, setuserdata] = useState(null);
	const [attdata, setattdata] = useState(null);
	let [IsOpen, setIsOpen] = useState(false);
	const [isOpenP, setIsOpenP] = useState(false);
	const [type, setType] = useState();
	const [rating, setRating] = useState(0);
	const [ratingC, setRatingC] = useState(0);

	let [isAuth, setisAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const params = useParams();

	let [isTab, setisTab] = useState(7);
	const [attendanceRating, setattendanceRating] = useState(0)
	const [productivityRating, setProductivityRating] = useState(0)
	const [communicationRating, setCommunationRating] = useState(0)
	const dispatch = useDispatch();

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getUserDetails/${params.user_id}`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 0,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setattendanceRating(res?.AttendenceRating)
					setProductivityRating(res?.ProductivityRating)
					setCommunationRating(res?.CommunicationRating)
					setuserdata(res.userdata)
					dispatch(onCurrentEmp(res.userdata[0].name));
					let attarr = [];
					if (res.attdata.length > 0) {
						res.attdata.forEach((element) => {
							let date1 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
								" " +
								element.start_time
							);
							let date2 = new Date(
								format(new Date(element.date), "yyyy-MM-dd") +
								" " +
								element.end_time
							);
							let diff = date2.getTime() - date1.getTime();
							let msec = diff;
							let hh = Math.floor(msec / 1000 / 60 / 60);
							msec -= hh * 1000 * 60 * 60;

							let mm = Math.floor(msec / 1000 / 60);
							msec -= mm * 1000 * 60;
							let ss = Math.floor(msec / 1000);
							msec -= ss * 1000;

							let mmm = mm >= 10 ? mm : "0" + mm;
							let hhh = hh >= 10 ? hh : "0" + hh;
							let sss = ss >= 10 ? ss : "0" + ss;

							let data = {
								date: format(
									new Date(element.date),
									"yyyy-MM-dd"
								),
								in: element.start_time,
								out: element.end_time,
								duration:
									element.end_time !== "00:00:00"
										? hhh + ":" + mmm + ":" + sss
										: "-",
								details: "More Details",
							};

							attarr.push(data);
						});
					}

					setattdata(attarr);
				}
			})
			.catch((err) => console.log(err));
	};

	let user = JSON.parse(localStorage.getItem("isAuth"));
	const RatingApi = async (payload) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + 'add-rating', {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(payload),
		})
			.then((response) => response.json())
			.then((res) => {
				getData()
			})
			.catch((err) => console.log(err));
	};

	const columns = [
		{ title: "Date", field: "date", width: 150 },
		{ title: "In", field: "in" },
		{ title: "Out", field: "out" },
		{ title: "Duration", field: "duration" },
	];

	const options = {
		pagination: "local",
		paginationSize: 50,
	};

	function closeModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		setRating(productivityRating / 20);
		setRatingC(communicationRating / 20);
	}, [productivityRating, communicationRating]);

	const handleRatingChange = async (event, newValue) => {
		if (user?.uuid === params?.user_id) {
			alert("You cannot rate yourself.");
			return;
		}
		if (newValue !== null) {
			setRating(newValue);
			const payload = {
				username: params?.user_id,
				type: 1,
				rating: newValue,
			};
			try {
				await RatingApi(payload);
			} catch (error) {
				console.error("Error submitting rating:", error);
				alert("Failed to submit rating. Please try again.");
			}
		}
	};


	const handleRatingChangeC = async (event, newValue) => {
		if (user?.uuid === params?.user_id) {
			alert("You cannot rate yourself.");
			return;
		}
		if (newValue !== null) {
			setRatingC(newValue);
			const payload = {
				username: params?.user_id,
				type: 2,
				rating: newValue,
			};
			try {
				await RatingApi(payload);
			} catch (error) {
				console.error("Error submitting rating:", error);
				alert("Failed to submit rating. Please try again.");
			}
		}
	};

	let Attendance = () => {
		return (
			<>
				{/* <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
					<h2 className="text-lg font-medium mr-auto">Attandance</h2>
				</div> */}
				{/* <div className="intro-y box">
					<div className="scrollbar-hidden">
						<ReactTabulator
							columns={columns}
							data={attdata}
							options={options}
							className="mt-5 table-report table-report--tabulator"
						/>
					</div>
				</div> */}
				<div className="intro-y">
					<div
						style={{
							backgroundColor: "#FC9A30",
							color: "#fff",
							padding: "12px",
							textAlign: "start",
							borderTopLeftRadius: "8px",
							borderTopRightRadius: "8px",
						}}
					>
						<h3>Attendance </h3>
					</div>
					<div
						className="table-outlineBorder"
						style={{
							border: "1px solid #FC9A30",
						}}
					>
						<table className="employee-table" style={{ width: "100%" }}>
							<thead>
								<tr>
									<th className="whitespace-nowrap">Date</th>
									<th className="whitespace-nowrap">In</th>
									<th className="whitespace-nowrap">Out</th>
									<th className="whitespace-nowrap">Duration</th>
								</tr>
							</thead>
							<tbody>
								{attdata?.map((item) => (
									<tr>
										<td className="text-left whitespace-nowrap">{item.date}</td>
										<td className="text-left whitespace-nowrap">{item.in}</td>
										<td className="text-left whitespace-nowrap">{item.out}</td>
										<td className="text-left whitespace-nowrap">{item.duration}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</>
		);
	};
	return (
		<>
			<div className="intro-y flex items-center justify-between mt-0">
				<h2 className="text-lg font-medium mr-auto">Profile</h2>
				{isAuth.role === 0 && (
					<div className="text-left ">
						<Link
							to="/employees"
							style={{
								backgroundColor: "#fc9a30",
								color: "#fff",
								padding: "5px 10px",
								borderRadius: "4px",
								cursor: "pointer",
								transition: "all 0.3s ease-in-out",
							}}
						// className="btn btn-primary btn-sm text-white"
						>
							Back to All Employees
						</Link>
					</div>
				)}
			</div>

			<div className="intro-y box px-5 pt-5 mt-1"
				style={{
					border: "1px solid rgb(0 2 0 / 13%)",
					boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)"
				}} >
				{/* <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
					<div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
						<div className="">
							<FaUserCircle
								className="text-5xl"
								style={{ color: "#1d45ab" }}
							/>
						</div>
						<div className="ml-5 text-left">
							<div className="w-30 sm:w-60 truncate sm:whitespace-normal font-medium text-lg d-flex">
								{userdata && userdata[0].name} (
								{userdata && userdata[0].username})
							</div>
							<div className="text-slate-500">
								{userdata && userdata[0].deptname}
							</div>
							{isAuth.role !== 0 && (
								<div className="text-slate-500">
									Edit Profile
								</div>
							)}
						</div>
					</div>
					<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
						<div className="font-medium text-center lg:text-left lg:mt-3">
							Contact Details
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaPhone />
								</i>
								{userdata && userdata[0].contact_no} |
								{userdata && userdata[0].alternate_no}
							</div>

							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaEnvelope />
								</i>
								{userdata && userdata[0].email}
							</div>
						</div>

						<div className="font-medium text-center lg:text-left lg:mt-3">
							Date of Joining
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaCalendar />
								</i>
								{userdata &&
									format(
										new Date(userdata[0].date_of_joining),
										"yyyy-MM-dd"
									)}
							</div>
						</div>
					</div>

					<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
						<div className="font-medium text-center lg:text-left lg:mt-3">
							Date of Birth
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaCalendar />
								</i>
								{userdata &&
									format(
										new Date(userdata[0].dob),
										"yyyy-MM-dd"
									)}
							</div>

							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaMapMarker />
								</i>
								{userdata && userdata[0].permanent_address}
							</div>
						</div>

						<div className="font-medium text-center lg:text-left lg:mt-3">
							Height | Weight | Blood Group
						</div>
						<div className="flex flex-col justify-center items-center lg:items-start mt-2">
							<div className="truncate sm:whitespace-normal flex items-center">
								<i data-lucide="mail" className="w-4 h-4 mr-2">
									<FaUser />
								</i>
								{userdata &&
									userdata[0].height +
										" | " +
										userdata[0].weight +
										" | " +
										userdata[0].blood_group}
							</div>
						</div>
					</div>

					<div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
						<div className="font-medium text-center lg:text-left lg:mt-5">
							System Information
						</div>
						<div className="flex items-center justify-center lg:justify-start mt-2">
							<div className="mr-2 w-30 flex">
								Last Seen:{" "}
								<span className="ml-3 font-medium ">+23%</span>
							</div>
						</div>
						<div className="flex items-center justify-center lg:justify-start">
							<div className="mr-2 w-20 flex">
								System:{" "}
								<span className="ml-3 font-medium">win32</span>
							</div>
						</div>
					</div>
				</div> */}
				<div className="grid grid-cols-12 gap-2 mb-3">
					<div className="col-span-12 lg:col-span-8 2xl:col-span-3">
						<div className="grid grid-cols-12 gap-2">
							<div className="col-span-12 lg:col-span-3 2xl:col-span-3">
								<div style={{
									width: "80px",
									height: "100px",
									// borderRadius: "50%"
								}}>
									{isAuth && isAuth.avatar !== "" && isAuth.avatar !== null && localStorage.getItem('isclient') !== "1" ? (
										<div className="icon-badge-container">
											<img
												alt="icon"
												style={{ width: "120px", height: "80px", borderRadius: "50%", objectFit: "cover" }}
												src={`https://portal.rayvat.com/assets/uploads/avatar/${isAuth.avatar}`}
											/>
											{localStorage.getItem('isclient') !== "1" ? regState === "Registered" ?
												<div className="icon-badge bg-success"></div>
												: <div className="icon-badge bg-danger"></div> : ""}
										</div>
									) : (
										<RxAvatar style={{ height: "100%", width: "100%" }} />
									)}
								</div>
							</div>
							<div className="col-span-12 lg:col-span-8 2xl:col-span-3">
								<div className="font-bold" style={{
									paddingLeft: "10px"
								}}>
									<h1 className="text-left">
										Name : {userdata && userdata[0].name}
									</h1>
									<h1 className="text-left">
										Designation : {userdata && userdata[0].deptname}
									</h1>
									<h1 className="text-left">
										EMP Code : {userdata && userdata[0].username}
									</h1>
									<h1 className="text-left">
										Date of Join : {userdata && moment(userdata[0]?.date_of_joining).format("DD-MM-YYYY")}
									</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 lg:col-span-4 2xl:col-span-3">
						<div className="text-right font-bold">
							<h1>Rating</h1>

							<h1 className="flex" style={{ justifyContent: "end" }}>
								<span className="mr-2">Attendance</span>
								<Tooltip title={attendanceRating}>
									<div className="flex">
										<Stack spacing={1}>
											<Rating
												name="half-rating"
												value={attendanceRating / 20}
												precision={0.5}
												readOnly
											/>
										</Stack>
									</div>
								</Tooltip>
							</h1>

							<h1 className="flex" style={{
								justifyContent: "end"
							}}>
								<span className="mr-2">
									Productivity{"  "}
								</span>
								<Tooltip title={rating}>
									<Stack spacing={1}>
										<Rating
											name="half-rating"
											value={rating}
											precision={0.5}
											onChange={handleRatingChange}
										/>
									</Stack>
								</Tooltip>
							</h1>

							<h1 className="flex" style={{
								justifyContent: "end"
							}}>
								<span className="mr-2">
									Communication{"  "}
								</span>
								<Tooltip title={rating}>
									<Stack spacing={1}>
										<Rating
											name="half-rating"
											value={ratingC}
											precision={0.5}
											onChange={handleRatingChangeC}
										/>
									</Stack>
								</Tooltip>
							</h1>
						</div>
					</div>
				</div>
				<ul
					className="nav nav-link-tabs  flex-wrap flex-col sm:flex-row justify-center sm:justify-start text-center"
					role="tablist"
					style={{ cursor: "pointer" }}

				>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 7
									? "nav-link py-4 active flex"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(7)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="user" class="lucide lucide-user stroke-1.5 mr-2 h-4 w-4"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
							{"  "} Profile
						</div>
					</li>

					{/* <li
						id="dashboard-tab"
						className="nav-item"
						role="presentation"
					>
						<div
							className={
								isTab === 0
									? "nav-link py-4 active flex"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(0)}
							data-tw-target="#dashboard"
							aria-controls="dashboard"
							aria-selected="true"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="layout" class="lucide lucide-layout stroke-1.5 w-5 h-5 mx-auto block"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"></rect><line x1="3" x2="21" y1="9" y2="9"></line><line x1="9" x2="9" y1="21" y2="9"></line></svg>
							<span className="ml-2">Attendence</span>
						</div>
					</li> */}

					{/* <li
						id="dashboard-tab"
						className="nav-item"
						role="presentation"
					>
						<div
							className={
								isTab === 1
									? "nav-link py-4 active flex"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(1)}
							data-tw-target="#dashboard"
							aria-controls="dashboard"
							aria-selected="true"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="calendar" class="lucide lucide-calendar stroke-1.5 w-5 h-5 mx-auto block"><rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect><line x1="16" x2="16" y1="2" y2="6"></line><line x1="8" x2="8" y1="2" y2="6"></line><line x1="3" x2="21" y1="10" y2="10"></line></svg>
							<span className="ml-2">
								Monthly Attendence
							</span>
						</div>
					</li> */}

					{/* <li
						id="activities-tab"
						className="nav-item"
						role="presentation"
					>
						<div
							className={
								isTab === 2
									? "nav-link py-4 active flex"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(2)}
							data-tw-target="#activities"
							aria-selected="false"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="log-out" class="lucide lucide-log-out stroke-1.5 w-5 h-5 mx-auto block"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" x2="9" y1="12" y2="12"></line></svg>
							<span className="ml-2">
								Leave
							</span>
						</div>
					</li> */}

					{/* <li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 3
									? "nav-link py-4 flex active"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(3)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="file-text" class="lucide lucide-file-text stroke-1.5 w-5 h-5 mx-auto block"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" x2="8" y1="13" y2="13"></line><line x1="16" x2="8" y1="17" y2="17"></line><line x1="10" x2="8" y1="9" y2="9"></line></svg>
							<span className="ml-2">
								Leave summary
							</span>
						</div>
					</li> */}

					{/* <li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 6
									? "nav-link py-4 flex active"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(6)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="activity" class="lucide lucide-activity stroke-1.5 w-5 h-5 mx-auto block"><path d="M22 12h-4l-3 9L9 3l-3 9H2"></path></svg>
							<span className="ml-2">
								Activity
							</span>
						</div>
					</li> */}

					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 8
									? "nav-link py-4 flex active"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(8)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="users" class="lucide lucide-users stroke-1.5 w-5 h-5 mx-auto block"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M22 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
							<span className="ml-2">
								Managers
							</span>
						</div>
					</li>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 9
									? "nav-link py-4 flex active"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(9)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="layers" class="lucide lucide-layers stroke-1.5 w-5 h-5 mx-auto block"><path d="m12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83Z"></path><path d="m22 17.65-9.17 4.16a2 2 0 0 1-1.66 0L2 17.65"></path><path d="m22 12.65-9.17 4.16a2 2 0 0 1-1.66 0L2 12.65"></path></svg>
							<span className="ml-2">
								Documents
							</span>
						</div>
					</li>
					<li id="tasks-tab" className="nav-item" role="presentation">
						<div
							className={
								isTab === 10
									? "nav-link py-4 flex active"
									: "nav-link py-4 flex"
							}
							onClick={() => setisTab(10)}
							data-tw-target="#tasks"
							aria-selected="false"
							role="tab"
						>

							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="inbox" class="lucide lucide-inbox stroke-1.5 w-5 h-5 mx-auto block"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>
							<span className="ml-2">
								Feeds
							</span>
						</div>
					</li>
				</ul>
			</div>

			<div className="intro-y tab-content mt-5">
				<div
					id="dashboard"
					className="tab-pane active"
					role="tabpanel"
					aria-labelledby="dashboard-tab"
				>
					{isTab === 0 ? (
						<Attendance />
					) : isTab === 1 ? (
						<MonthlyAttandance />
					) : isTab === 2 ? (
						<Leaves />
					) : isTab === 3 ? (
						<LeaveSummary />
					) : isTab === 6 ? (
						<Activity />
					) : isTab === 7 ? (
						<Profile />
					) : isTab === 8 ? (
						<ManagerDetails />
					) : isTab === 9 ? (
						<Documents />
					) : isTab === 10 ? (
						<Feeds />
					) : null}
				</div>
			</div>

			<Modal isOpen={IsOpen} style={customStyles} contentLabel="">
				<div
					className="row"
					style={{
						marginRight: "10px",
						marginTop: "0px",
						paddingBottom: "5px",
						marginLeft: "3px",
						borderBottom: "1px solid #f5ebeb",
					}}
				>
					<div className="flex items-center">
						<h2 className="font-medium text-base mr-auto">
							More details
						</h2>
						<button
							className="btn btn-outline-secondary hidden sm:flex"
							onClick={() => closeModal()}
						>
							<FaTimes />
						</button>
					</div>
				</div>

				<div
					className="wrapper-grey pt-1 overflow-auto"
					style={{ marginRight: "10px" }}
				>
					<div className="dialoag-container_2TwSP">
						<div className="title_1ce7i">Punch In Details</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								IP&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">202.131.97.66</span>
						</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								Device&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">
								Mozilla/5.0 (Windows NT 6.3; WOW64)
								AppleWebKit/537.36 (KHTML, like Gecko)
								TrackWick/4.6.0 Chrome/66.0.3359.181
								Electron/3.1.9 Safari/537.36
							</span>
						</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								Platform&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">DESKTOP</span>
						</div>
						<div className="content_3dQ6a">
							<span className="key_2Wvze">
								Address&nbsp;&nbsp;:&nbsp;&nbsp;
							</span>
							<span className="value_1cs5S">
								Ahmedabad, GJ, India, 380009
							</span>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Employees_details;
