import React, { useEffect } from "react";
import { FaCalendar, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import Swal from "sweetalert2";
import DatePicker from "react-date-picker";
import HashLoader from "react-spinners/HashLoader";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import OtherBreakTicket from "./OtherBreakTicket";
import { AdminTicket, AdminTicketAfterOffice, AdminTicketOther, AdminTicketTimesheet, EmployeeBreakTicket, EmployeeTicket } from "../Export";
const PunchTicket = () => {
	const [showTicket, setShowticket] = useState([]);
	const [isLoad, setIsLoad] = useState(false);
	const [userdata] = useState(JSON.parse(localStorage.getItem("isAuth")));
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [activeTab, setActiveTab] = useState("attendance");
	const [data, setdata] = useState({
		date: format(new Date(), "yyyy-MM-dd"),
		punch_start_time: "00:00:00",
		punch_end_time: "00:00:00",
		lunch_start_time: "00:00:00",
		lunch_end_time: "00:00:00",
		reason: "",
		status: 0,
	});
	const handleDateChange = (date) => {
		setSelectedDate(date);
		setdata({ ...data, date: format(date, "yyyy-MM-dd") });
	};
	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};
	// handleSubmit funcation create ticket
	const GetempTicket = async () => {
		setIsLoad(true);
		try {
			const response = await fetch(BASEURL + "show-emp-ticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: userdata.username,
					role: userdata.role,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log("Response from the API:", data);
				const filteredTickets = data?.data?.filter(
					(ticket) =>
						ticket.username === parseInt(userdata.username, 10)
				);
				console.log("Filtered tickets:", filteredTickets);
				setShowticket(filteredTickets);
			} else {
				throw new Error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setIsLoad(false);
		}
	};

	useEffect(() => {
		GetempTicket();
	}, []);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataPerPage] = useState(10);
	const indexOfLastData = (currentPage + 1) * dataPerPage;
	const indexOfFirstData = indexOfLastData - dataPerPage;
	const currentData = showTicket.slice(indexOfFirstData, indexOfLastData);
	// const handlePageChange = ({ selected }) => {
	// 	setCurrentPage(selected);
	// };
	return (
		<div className="container">
			<ul className="nav nav-tabs">
				{[
					{ key: "attendance", label: "Attendance Ticket" },
					{ key: "break", label: "Other Break" },
					{ key: "timesheet", label: "Timesheet" },
					{ key: "after-hours", label: "After Office Hours Ticket" },
				].map((tab) => (
					<li className="nav-item" key={tab.key}>
						<button
							style={{
								backgroundColor: activeTab === tab.key ? "#38b2ac" : "#fff",
								color: activeTab === tab.key ? "#fff" : "#000",
								border: "1px solid #ccc",
								padding: "10px 20px",
								cursor: "pointer",
								// marginLeft: "10px",
							}}
							onClick={() => handleTabChange(tab.key)}
							onMouseEnter={(e) => {
								e.target.style.backgroundColor = "#38b2ac";
								e.target.style.color = "#fff";
							}}
							onMouseLeave={(e) => {
								if (activeTab !== tab.key) {
									e.target.style.backgroundColor = "#fff";
									e.target.style.color = "#000";
								}
							}}
						>
							{tab.label}
						</button>
					</li>
				))}
			</ul>

			{/* Add padding-top to the content container */}
			{activeTab === "attendance" && (
				<div >
					<AdminTicket />
				</div>
			)}
			{activeTab === "timesheet" && (
				<div >
					<AdminTicketTimesheet />
				</div>
			)}
			{activeTab === "break" && (
				<div >
					<AdminTicketOther />
				</div>
			)}
			{activeTab === "after-hours" && (
				<div >
					<AdminTicketAfterOffice />
				</div>
			)}
		</div>
	);
};
export default PunchTicket;