// import React, { useState, useEffect } from "react";
// import { BASEURL } from "./BASEURL";
// import "react-toastify/dist/ReactToastify.css";
// import swal from "sweetalert";
// import HashLoader from "react-spinners/HashLoader";
// import ReactPaginate from "react-paginate";
// import { format } from "date-fns";

// import { FaPlus } from "react-icons/fa";

// const Departments = () => {
// 	const [bkpdata, setbkpdata] = useState([]);
// 	const [pageCount, setPageCount] = useState(0);
// 	const [itemOffset, setItemOffset] = useState(0);
// 	const [currentItems, setCurrentItems] = useState(null);
// 	const [items, setitems] = useState([]);
// 	const [attdata, setattdata] = useState(null);
// 	const [punchtype, setpunchtype] = useState(0);

// 	const [data, setdata] = useState({
// 		id: "",
// 		start_time: "00:00:00",
// 		end_time: "00:00:00",
// 		description: "",
// 		date: "",
// 	});

// 	const [isadd, setisadd] = useState(false);
// 	let [loading, setLoading] = useState(false);
// 	let [msg, setmsg] = useState({
// 		type: "",
// 		msg: "",
// 	});

// 	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

// 	useEffect(() => {
// 		const endOffset = itemOffset + 10;
// 		setCurrentItems(items.slice(itemOffset, endOffset));
// 		setPageCount(Math.ceil(items.length / 10));
// 		getData();
// 	}, []);

// 	useEffect(() => {
// 		const endOffset = itemOffset + 10;
// 		setCurrentItems(items.slice(itemOffset, endOffset));
// 		setPageCount(Math.ceil(items.length / 10));
// 	}, [itemOffset]);

// 	const getData = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata != null) {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(
// 			BASEURL +
// 				`getUserDetails/` +
// 				JSON.parse(localStorage.getItem("isAuth")).uuid,
// 			{
// 				method: "POST",
// 				headers: myHeaders,
// 				body: JSON.stringify({
// 					type: 0,
// 					uuid: JSON.parse(localStorage.getItem("isAuth"))
// 						.companydata[0].uuid,
// 				}),
// 			}
// 		)
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					let attarr = [];
// 					if (res.attdata.length > 0) {
// 						res.attdata.forEach((element) => {
// 							// console.log(element);
// 							let date1 = new Date(
// 								format(new Date(element.date), "yyyy-MM-dd") +
// 									" " +
// 									element.start_time
// 							);
// 							let date2 = new Date(
// 								format(new Date(element.date), "yyyy-MM-dd") +
// 									" " +
// 									element.end_time
// 							);
// 							let diff = date2.getTime() - date1.getTime();
// 							let msec = diff;
// 							let hh = Math.floor(msec / 1000 / 60 / 60);
// 							msec -= hh * 1000 * 60 * 60;

// 							let mm = Math.floor(msec / 1000 / 60);
// 							msec -= mm * 1000 * 60;
// 							let ss = Math.floor(msec / 1000);
// 							msec -= ss * 1000;

// 							let mmm = mm >= 10 ? mm : "0" + mm;
// 							let hhh = hh >= 10 ? hh : "0" + hh;
// 							let sss = ss >= 10 ? ss : "0" + ss;

// 							let data = {
// 								id: element.id,
// 								uuid: element.uuid,
// 								name: element.name,
// 								user_id: element.username,
// 								date:
// 									element.date &&
// 									format(
// 										new Date(element.date),
// 										"yyyy-MM-dd"
// 									),
// 								attendance_id: element.attendance_id,
// 								ticketstatus: element.ticketstatus,
// 								// ticketStarttime: element.ticketStarttime!=null && format(new Date(element.ticketStarttime), "HH:mm:ss"),
// 								// ticketEndtime: element.ticketEndtime!=null && format(new Date(element.ticketEndtime), "HH:mm:ss"),
// 								ticketStarttime: element.ticketStarttime
// 									? element.ticketStarttime
// 									: "00:00:00",
// 								ticketEndtime: element.ticketEndtime
// 									? element.ticketEndtime
// 									: "00:00:00",
// 								in:
// 									element.start_time !== "00:00:00"
// 										? element.start_time
// 										: "00:00:00",
// 								out:
// 									element.end_time !== "00:00:00"
// 										? element.end_time
// 										: "00:00:00",
// 								duration:
// 									element.end_time !== "00:00:00"
// 										? hhh + ":" + mmm + ":" + sss
// 										: "00:00:00",
// 								details: "More Details",
// 							};
// 							// console.log(data);
// 							attarr.push(data);
// 						});
// 					}

// 					let arr = [];
// 					let arr1 = [];
// 					for (const key in attarr) {
// 						arr.push(key);
// 						if (key < 9) {
// 							arr1.push(key);
// 						}
// 					}

// 					setitems(arr);
// 					setCurrentItems(arr1);
// 					setPageCount(Math.ceil(arr.length / 10));

// 					setattdata(attarr);
// 					setbkpdata(attarr);
// 				}
// 			})
// 			.catch((err) => console.log(err));
// 		setLoading(false);
// 	};

// 	const handlechange = (e) => {
// 		let value = e.target.value;
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 	};

// 	const savedata = async (elm) => {
// 		// console.log(punchtype);return
// 		if (
// 			punchtype === "" ||
// 			// data.start_time === "" ||
// 			// data.end_time === "" ||
// 			data.description === ""
// 		) {
// 			swal({
// 				title: "Error",
// 				text: "Please Enter value Required value",
// 				icon: "error",
// 				timer: 2000,
// 			});
// 			return;
// 		}
// 		// console.log(data); return
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata != null) {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `create-ticket/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				company_uuid: userdata.companydata[0].uuid,
// 				user_uuid: userdata.uuid,
// 				data,
// 				punchtype,
// 				tickettype: 0,
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					userdata.companydata[0].name = data.name;
// 					userdata.companydata[0].support_access = data.supportaccess;
// 					localStorage.setItem("isAuth", JSON.stringify(userdata));
// 					setmsg({
// 						type: res.type,
// 						msg: res.message,
// 					});
// 					getData();
// 					setdata({
// 						id: "",
// 						start_time: "00:00:00",
// 						end_time: "00:00:00",
// 						description: "",
// 						date: "",
// 					});

// 					swal({
// 						title: "Success",
// 						text: res.message,
// 						icon: "success",
// 						timer: 2000,
// 					});
// 					setTimeout(() => {
// 						setmsg({
// 							type: "",
// 							msg: "",
// 						});
// 						setisadd(!isadd);
// 					}, 1000);
// 				} else {
// 					swal({
// 						title: "Error",
// 						text: res.message,
// 						icon: "error",
// 						timer: 2000,
// 					});
// 				}
// 			})
// 			.catch((err) => console.log(err));
// 		setLoading(false);
// 	};

// 	const editdata = (data) => {
// 		setisadd(true);
// 		setdata({
// 			id: data.id,
// 			start_time: data.in,
// 			end_time: data.out,
// 			description: "",
// 			date: data.date,
// 		});
// 	};

// 	const adddata = (data) => {
// 		setisadd(!isadd);
// 		setdata({
// 			id: "",
// 			start_time: "00:00:00",
// 			end_time: "00:00:00",
// 			description: "",
// 			date: "",
// 		});
// 	};

// 	const removeTicket = (id) => {
// 		swal({
// 			title: "Delete",
// 			text: `Please confirm if you want to Delete Ticket?`,
// 			buttons: ["No", "Yes"],
// 			showCloseButton: true,
// 		}).then((willDelete) => {
// 			if (willDelete) {
// 				setLoading(true);
// 				const myHeaders = new Headers();
// 				let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 				if (userdata != null) {
// 					let token = userdata.usertoken;
// 					myHeaders.append("Authorization", token);
// 				}
// 				myHeaders.append("Content-Type", "application/json");
// 				fetch(BASEURL + `delete-ticket/${id}`, {
// 					method: "DELETE",
// 					headers: myHeaders,
// 				})
// 					.then((response) => response.json())
// 					.then((res) => {
// 						getData();
// 						swal({
// 							title: "Success",
// 							text: res.msg,
// 							icon: "success",
// 							timer: 2000,
// 						});
// 					})
// 					.catch((err) => console.log(err));
// 				setLoading(false);
// 			}
// 		});
// 	};

// 	const filterdata = (event) => {
// 		if (event.target.value === "") {
// 			loading ? <HashLoader /> : setattdata(bkpdata);

// 			let arr = [];
// 			for (const key in bkpdata) {
// 				arr.push(key);
// 			}
// 			setitems(arr);

// 			setCurrentItems(arr);
// 			setPageCount(Math.ceil(arr.length / 10));
// 		} else {
// 			var matches = bkpdata.filter(function (s) {
// 				return s.date.includes(`${event.target.value}`);
// 			});

// 			let arr = [];
// 			for (const key in matches) {
// 				arr.push(key);
// 			}
// 			setitems(arr);

// 			const newOffset = (matches.length * 10) % matches.length;
// 			setItemOffset(newOffset);
// 			setattdata(matches);
// 			const endOffset = itemOffset + 10;
// 			setCurrentItems(arr.slice(itemOffset, endOffset));
// 			setPageCount(Math.ceil(matches.length / 10));
// 		}
// 	};

// 	const handlePageClick = (event) => {
// 		const newOffset = (event.selected * 10) % items.length;

// 		setItemOffset(newOffset);
// 	};

// 	return (
// 		<div className="intro-y box lg:mt-5">
// 			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
// 				<h2 className="font-medium text-base mr-auto">
// 					All Punch Data 
// 				</h2>
// 			</div>
// 			<div className="p-5">
// 				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
// 					{isadd === true ? (
// 						<div
// 							className="flex-1 mt-6 xl:mt-0 p-3 mb-2"
// 							style={{
// 								border: "1px solid lightgray",
// 								borderRadius: "5px",
// 							}}
// 						>
// 							<form>
// 								<div className="grid grid-cols-12 gap-x-5">
// 									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
// 										<label
// 											htmlFor="update-profile-form-2"
// 											className="form-label"
// 										>
// 											Select Punch Type{" "}
// 											<span className="text-danger">
// 												(*)
// 											</span>
// 										</label>
// 										<select
// 											className="form-select"
// 											name="department"
// 											onChange={(e) =>
// 												setpunchtype(e.target.value)
// 											}
// 											value={punchtype}
// 										>
// 											<option key={0} value="0">
// 												Punch In
// 											</option>
// 											<option key={1} value="1">
// 												Punch Out
// 											</option>
// 										</select>
// 									</div>
// 									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
// 										<div>
// 											<label
// 												htmlFor="update-profile-form-1"
// 												className="form-label"
// 											>
// 												Enter Start Time{" "}
// 												<span className="text-danger">
// 													(*)
// 												</span>
// 											</label>
// 											<input
// 												id="update-profile-form-1"
// 												value={data.start_time}
// 												type="text"
// 												required
// 												name="start_time"
// 												className="form-control"
// 												onChange={handlechange}
// 											/>
// 										</div>
// 									</div>

// 									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
// 										<div>
// 											<label
// 												htmlFor="update-profile-form-1"
// 												className="form-label"
// 											>
// 												Enter End Time{" "}
// 												<span className="text-danger">
// 													(*)
// 												</span>
// 											</label>
// 											<input
// 												id="update-profile-form-1"
// 												value={
// 													data.end_time !== "-"
// 														? data.end_time
// 														: "00:00:00"
// 												}
// 												type="text"
// 												required
// 												name="end_time"
// 												className="form-control"
// 												onChange={handlechange}
// 											/>
// 										</div>
// 									</div>

// 									<div className="col-span-12 2xl:col-span-3">
// 										<label
// 											htmlFor="update-profile-form-2"
// 											className="form-label"
// 										>
// 											Reason{" "}
// 											<span className="text-danger">
// 												(*)
// 											</span>
// 										</label>
// 										<br />
// 										<textarea
// 											name="description"
// 											className="form-control"
// 											cols="80"
// 											rows="2"
// 											onChange={handlechange}
// 											value={data && data.description}
// 										></textarea>
// 									</div>

// 									<div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
// 										<label
// 											htmlFor="update-profile-form-2"
// 											className="form-label"
// 										>
// 											Date
// 										</label>
// 										<input
// 											id="update-profile-form-1"
// 											value={data.date}
// 											readOnly
// 											required
// 											minLength={10}
// 											type="text"
// 											name="contactno"
// 											className="form-control"
// 											onChange={handlechange}
// 										/>
// 									</div>
// 								</div>

// 								<button
// 									type="button"
// 									onClick={() => savedata()}
// 									className="btn btn-primary w-20 mt-3"
// 								>
// 									Save
// 								</button>
// 								<button
// 									type="button"
// 									onClick={() => adddata(1)}
// 									className="btn btn-danger w-20 mt-3 ml-2"
// 								>
// 									Cancel
// 								</button>
// 								<br />
// 								<br />
// 								<span
// 									className={
// 										// msg.type === "success" ? "text-success" : "text-danger"
// 										"text-success"
// 									}
// 								>
// 									{msg.msg ? msg.msg : null}
// 								</span>
// 							</form>
// 						</div>
// 					) : null}
// 				</div>
// 				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
// 					<div className="flex mt-5 sm:mt-0">
// 						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
// 							<input
// 								id="tabulator-html-filter-value"
// 								type="text"
// 								onChange={filterdata}
// 								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
// 								placeholder="Search..."
// 							/>
// 						</div>
// 					</div>
// 					{/* <div className="hidden md:block ml-auto text-slate-500">
//             Showing {itemOffset + 1} to  {attdata.length > itemOffset + 10 ? itemOffset + 10 : attdata.length} of {attdata.length} entries
//           </div> */}
// 				</div>
// 				<div className="overflow-x-auto">
// 					{loading ? (
// 						<HashLoader
// 							color="#5755d9"
// 							size={30}
// 							style={{ position: "absolute", right: "50%" }}
// 						/>
// 					) : (
// 						<table
// 							className="table table-bordered"
// 							style={{ width: "100%" }}
// 						>
// 							<thead>
// 								<tr>
// 									<th className="whitespace-nowrap">Date</th>
// 									<th className="whitespace-nowrap">In</th>
// 									<th className="whitespace-nowrap">Out</th>
// 									<th className="whitespace-nowrap">
// 										Duration
// 									</th>
// 									<th className="whitespace-nowrap">
// 										Actions
// 									</th>
// 								</tr>
// 							</thead>
// 							<tbody>
// 								{currentItems && currentItems.length === 0 && (
// 									<tr>
// 										<td colSpan="8">No data available</td>
// 									</tr>
// 								)}
// 								{currentItems &&
// 									currentItems.length > 0 &&
// 									currentItems.map((item, i) => {
// 										return (
// 											<tr key={i}>
// 												<td>{attdata[item].date}</td>
// 												<td>{attdata[item].in}</td>
// 												<td>{attdata[item].out}</td>
// 												<td>
// 													{attdata[item].duration}
// 												</td>
// 												<td>
// 													<div className="flex lg:justify-center items-center cursor-pointer">
// 														<button
// 															className="btn btn-primary btn-sm mr-2"
// 															onClick={() =>
// 																editdata(
// 																	attdata[
// 																		item
// 																	]
// 																)
// 															}
// 														>
// 															<FaPlus
// 																title="Create Ticket"
// 																style={{
// 																	color: "white",
// 																}}
// 															/>
// 														</button>
// 														{attdata[item].id !==
// 															attdata[item]
// 																.attendance_id ||
// 														attdata[item]
// 															.ticketstatus ===
// 															3 ? (
// 															<></>
// 														) : attdata[item]
// 																.ticketstatus ===
// 														  0 ? (
// 															<button
// 																className="btn btn-warning btn-sm"
// 																title={
// 																	"Start Time: " +
// 																	attdata[
// 																		item
// 																	]
// 																		.ticketStarttime +
// 																	" || End Time: " +
// 																	attdata[
// 																		item
// 																	]
// 																		.ticketEndtime
// 																}
// 															>
// 																Pending{" "}
// 																<button
// 																	onClick={() =>
// 																		removeTicket(
// 																			attdata[
// 																				item
// 																			]
// 																				.attendance_id
// 																		)
// 																	}
// 																	title="Delete Ticket"
// 																	className="btn btn-danger btn-sm ml-2 p-2 pt-1 pb-0"
// 																>
// 																	x
// 																</button>
// 															</button>
// 														) : attdata[item]
// 																.ticketstatus ===
// 														  1 ? (
// 															<button className="btn btn-success text-white btn-sm">
// 																Approved
// 															</button>
// 														) : (
// 															<button className="btn btn-danger text-white btn-sm">
// 																Rejected
// 															</button>
// 														)}
// 														{/* <FaTrashAlt onClick={() => deletedata(userdata[item].uuid)} title="Delete" className="text-danger mr-2"/>                            
//                             <FaSignInAlt onClick={() => signindata(userdata[item])} title={"Sign In as "+ userdata[item].name} style={{color:'#1E40AF'}} className="mr-2"/> 
//                             </>:"-" } */}
// 													</div>
// 												</td>
// 											</tr>
// 										);
// 									})}
// 							</tbody>
// 						</table>
// 					)}

// 					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
// 						<br />
// 						<nav className="w-full sm:w-auto sm:mr-auto">
// 							{!loading ? (
// 								<div className="row">
// 									<div className="col-md-3"></div>
// 									<div className="col-md-4">
// 										<ReactPaginate
// 											pageCount={pageCount}
// 											pageRangeDisplayed={10}
// 											marginPagesDisplayed={1}
// 											onPageChange={handlePageClick}
// 											containerClassName="pagination"
// 											activeClassName="active"
// 											pageLinkClassName="page-link"
// 											breakLinkClassName="page-link"
// 											nextLinkClassName="page-link"
// 											previousLinkClassName="page-link"
// 											pageClassName="page-item"
// 											breakClassName="page-item"
// 											nextClassName="page-item"
// 											previousClassName="page-item"
// 											previousLabel={<>&laquo;</>}
// 											nextLabel={<>&raquo;</>}
// 										/>
// 									</div>
// 									<div className="col-md-4"></div>
// 								</div>
// 							) : null}
// 						</nav>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default Departments;


import React, { useEffect } from "react";
import { FaCalendar, FaEdit, FaPlus, } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import Swal from "sweetalert2";
import DatePicker from "react-date-picker";
import HashLoader from "react-spinners/HashLoader";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import CommonModal from "./admin/CommonModal";
import { FaTrashAlt } from "react-icons/fa";

const PunchTicket = () => {
	const [actionsDropdownIndex, setActionsDropdownIndex] = useState(null);
	const [addShow, setaddShow] = useState(false);
	const [showTicket, setShowticket] = useState([]);
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const [hidedate, setHideDate] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [userdata] = useState(JSON.parse(localStorage.getItem("isAuth")));
	const [updateShow, setUpdateShow] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [punchData, setPunchData] = useState([]);
	const [data, setdata] = useState({
		date: format(new Date(), "yyyy-MM-dd"),
		punch_start_time: "00:00:00",
		punch_end_time: "00:00:00",
		lunch_start_time: "00:00:00",
		lunch_end_time: "00:00:00",
		reason: "",
		status: 0,
	});
	const handleDateChange = (date) => {
		setSelectedDate(date);
		setdata({ ...data, date: format(date, "yyyy-MM-dd") });
		fetchPunchInData()
	};

	const handleOpenFilter = () => {
		setIsOpenFilter(true)
	};
	const handleCloseFilter = () => {
		setIsOpenFilter(false)
	};
	const handleChange = (e) => {
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value,
		});
	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		const isAuth = JSON.parse(localStorage.getItem("isAuth"));
		const { department, role, name } = isAuth || {};
		const formattedDate = format(selectedDate, "yyyy-MM-dd");
		const payload = {
			username: userdata.username,
			data: {
				date: formattedDate,
				punch_start_time: data.punch_start_time,
				punch_end_time: data.punch_end_time,
				lunch_start_time: data.lunch_start_time,
				lunch_end_time: data.lunch_end_time,
				reason: data.reason,
				status: 0,
				department,
				role,
				name,
			},
		};

		try {
			const headers = {
				"Content-Type": "application/json",
			};
			if (userdata?.usertoken) {
				headers["Authorization"] = userdata.usertoken;
			}
			let response, responseData;
			if (updateShow) {
				response = await fetch(
					BASEURL + `update-emp-ticket/${data.id}`,
					{
						method: "PUT",
						headers: headers,
						body: JSON.stringify(data),
					}
				);
			} else {
				response = await fetch(BASEURL + "add-emp-ticket", {
					method: "POST",
					headers: headers,
					body: JSON.stringify(payload),
				});
			}
			responseData = await response.json();
			if (response.ok) {
				swal("Success", responseData.message, "success");
				setShowForm(false);

				GetempTicket();
				handleCancel();
			} else {
				throw new Error(responseData.error || "Failed to save ticket");
			}
		} catch (error) {
			swal("Error", error.message, "error");
		}
	};

	const fetchPunchInData = async () => {
		setIsLoad(true)
		const formattedDate = format(selectedDate, "yyyy-MM-dd");
		try {
			const headers = {
				"Content-Type": "application/json",
			};
			if (userdata?.usertoken) {
				headers["Authorization"] = userdata.usertoken;
			}
			const response = await fetch(BASEURL + "get-punch-in-data", {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					date: formattedDate,
					username: userdata.username,
				}),
			});
			if (response.ok) {
				const responseData = await response.json();
				const attendance = responseData?.attendanceData?.[0] || {};
				const breakInfo = responseData?.breakData?.[0] || {};
				setdata({
					punch_start_time: attendance.start_time || "00:00:00",
					punch_end_time: attendance.end_time || "00:00:00",
					lunch_start_time: breakInfo.break_start_time || "00:00:00",
					lunch_end_time: breakInfo.break_end_time || "00:00:00",
					reason: "",
				});
				// swal(
				// 	"Success",
				// 	"Punch-in data fetched successfully!",
				// 	"success"
				// );
				setShowForm(true);
				setIsModalOpen(true)
				setIsLoad(false)
			} else {
				throw new Error("Failed to fetch punch-in data.");
			}
		} catch (error) {
			swal(
				"Error",
				"Could not fetch punch-in data. Try again later.",
				"error"
			);
			setIsLoad(false)
		}
	};
	const handleAdd = () => {
		setaddShow(true);
		setUpdateShow(false);
	};
	const handleCancel = () => {
		setaddShow(false);
		setUpdateShow(false);
		setdata({
			date: "",
			punch_start_time: "00:00:00",
			punch_end_time: "00:00:00",
			lunch_start_time: "00:00:00",
			lunch_end_time: "00:00:00",
			reason: "",
			status: 0,
		});
	};

	// handleSubmit funcation create ticket


	const GetempTicket = async () => {
		setIsLoad(true);
		try {
			const response = await fetch(BASEURL + "show-emp-ticket", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: userdata.username,
					role: userdata.role,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log("Response from the API:", data);
				const filteredTickets = data?.data?.filter(
					(ticket) =>
						ticket.username === parseInt(userdata.username, 10)
				);
				console.log("Filtered tickets:", filteredTickets);
				setShowticket(filteredTickets);
			} else {
				throw new Error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setIsLoad(false);
		}
	};
	const handleDelete = (id) => {
		swal({
			title: "Delete",
			text: `Are you sure you want to delete you ticket?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				fetch(BASEURL + `delete-emp-ticket/${id}`, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((response) => response.json())
					.then((res) => {
						GetempTicket();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
			}
		});
	};
	const editdata = (ticket) => {
		console.log("editing data of emp id", ticket);
		setaddShow(true);
		setUpdateShow(true);
		setdata({
			id: ticket.id,
			date: ticket.date,
			punch_start_time: ticket.punch_start_time,
			punch_end_time: ticket.punch_end_time,
			lunch_start_time: ticket.lunch_start_time,
			lunch_end_time: ticket.lunch_end_time,
			reason: ticket.reason,
			status: 0,
		});
	};
	const UpdateTicket = async (id) => {
		console.log("updating ticket id : " + id);
		try {
			const response = await fetch(BASEURL + `update-emp-ticket/${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const data = await response.json();
			if (response) {
				Swal.fire({
					icon: "success",
					title: "Ticket updated successfully",
					showConfirmButton: false,
					timer: 1000,
				});
				GetempTicket();
			} else {
				console.log("Error your leave request is not updated");
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};
	useEffect(() => {
		GetempTicket();
	}, []);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataPerPage] = useState(10);
	const indexOfLastData = (currentPage + 1) * dataPerPage;
	const indexOfFirstData = indexOfLastData - dataPerPage;
	const currentData = showTicket.slice(indexOfFirstData, indexOfLastData);
	const handlePageChange = ({ selected }) => {
		setCurrentPage(selected);
	};

	// const modalContent = (
	// 	<>
	// 		<div className="flex flex-col items-center p-6 bg-white shadow-xl rounded-lg w-[360px] relative mx-auto px-4 py-4">
	// 			{/* Date Picker Section */}
	// 			<div className="flex items-center w-full mb-6 gap-4">
	// 				<div className="w-3/4">
	// 					<DatePicker
	// 						value={selectedDate}
	// 						onChange={handleDateChange}
	// 						calendarIcon={<FaCalendar className="text-blue-700" />}
	// 						clearIcon=""
	// 						format="y-MM-dd"
	// 						className="form-control w-full"
	// 					/>
	// 				</div>
	// 				<button
	// 					onClick={fetchPunchInData}
	// 					className="btn w-20 text-white px-4 py-2 rounded-md"
	// 					style={{ backgroundColor: "#24a184", border: "#24a184" }}
	// 				>
	// 					Get
	// 				</button>
	// 				<div
	// 					className="cursor-pointer text-xl ml-5  font-semibold text-red-500 hover:text-red-700"
	// 					onClick={handleCloseFilter}
	// 				>
	// 					✖
	// 				</div>
	// 			</div>
	// 			{isModalOpen && (
	// 				<>

	// 					<div className="flex items-center p-4 border-b border-slate-200/60 dark:border-darkmode-400 mb-5">
	// 						<div className="flex items-center justify-between p-4 border-b border-slate-200/60 dark:border-darkmode-400 w-full">
	// 							<h2 className="font-semibold text-lg mr-5 ">Add Punch Ticket</h2>
	// 							{/* <div
	// 								className="cursor-pointer text-xl ml-5  font-semibold text-red-500 hover:text-red-700"
	// 								onClick={handleCloseFilter}
	// 							>
	// 								✖
	// 							</div> */}
	// 						</div>

	// 					</div>
	// 					<div className="w-full">
	// 						<form method="POST" onSubmit={handleSubmit}>
	// 							<div className="grid grid-cols-2 gap-4 mb-4">
	// 								{[
	// 									{ id: "punch_start_time", label: "Punch Start" },
	// 									{ id: "punch_end_time", label: "Punch End" },
	// 									{ id: "lunch_start_time", label: "Lunch Start" },
	// 									{ id: "lunch_end_time", label: "Lunch End" },
	// 								].map(({ id, label }) => (
	// 									<div className="flex flex-col" key={id}>
	// 										<label htmlFor={id} className="form-label">
	// 											{label} <span className="text-red-500">*</span>
	// 										</label>
	// 										<input
	// 											id={id}
	// 											type="text"
	// 											required
	// 											name={id}
	// 											className="form-control px-3 py-2 border rounded-md"
	// 											onChange={handleChange}
	// 											value={data[id]}
	// 										/>
	// 									</div>
	// 								))}
	// 							</div>

	// 							<div className="mb-4">
	// 								<label htmlFor="reason" className="form-label">
	// 									Reason <span className="text-red-500">*</span>
	// 								</label>
	// 								<textarea
	// 									name="reason"
	// 									className="form-control w-full px-3 py-2 border rounded-md"
	// 									required
	// 									rows="3"
	// 									onChange={handleChange}
	// 									value={data.reason}
	// 								></textarea>
	// 							</div>

	// 							<div className="flex justify-center gap-4">
	// 								{updateShow ? (
	// 									<button
	// 										type="submit"
	// 										className="btn bg-green-600 text-white px-4 py-2 rounded-md w-24"
	// 										onClick={UpdateTicket}
	// 										style={{ backgroundColor: "green" }}
	// 									>
	// 										Update
	// 									</button>
	// 								) : (
	// 									<button
	// 										type="submit"
	// 										className="btn bg-[#24a184] border-[#24a184] text-white px-2 py-1 rounded-md w-20"
	// 										style={{ backgroundColor: "#24a184", border: "#24a184" }}
	// 									>
	// 										Create
	// 									</button>
	// 								)}

	// 								<button
	// 									type="button"
	// 									onClick={handleCancel}
	// 									className="btn btn-danger text-white px-4 py-2 rounded-md w-24"
	// 								>
	// 									Cancel
	// 								</button>

	// 								{/* <button
	// 								type="button"
	// 								onClick={handleCloseFilter}
	// 								className="btn bg-gray-500 text-white px-4 py-2 rounded-md w-24"
	// 								style={{ backgroundColor: "gray" }}
	// 							>
	// 								Close
	// 							</button> */}
	// 							</div>
	// 						</form>
	// 					</div>
	// 				</>
	// 			)}
	// 		</div>
	// 	</>
	// );
	const modalContent = (<>
		<div>
			<div
				className="flex"
				style={{
					justifyContent: "space-between",
					backgroundColor: "#FC9A30",
					color: "#fff",
					padding: "5px",
					borderTopLeftRadius: "8px",
					borderTopRightRadius: "8px",
				}}
			>
				<div style={{ fontSize: "15px" }} className="ml-2 p-2 bold" >Add Punch Ticket
				</div>
				<div className="cursor-pointer mr-2" onClick={handleCloseFilter}>
					x
				</div>
			</div>
			<div className="flex flex-col items-center p-6 bg-white shadow-xl rounded-lg w-[360px] relative mx-auto px-4 py-4">
				{/* Date Picker Section */}
				<div className="flex items-center w-full mb-2 gap-1">
					<div className="w-full">
						<div className="flex flex-col">
							<label className="form-label text-sm font-semibold ">
								Get Attendance <span className="text-red-500">*</span>
							</label>
							<DatePicker
								value={selectedDate}
								onChange={handleDateChange}
								calendarIcon={<FaCalendar className="text-blue-700" />}
								clearIcon=""
								format="y-MM-dd"
								className="form-control w-full"
							/>
						</div>
					</div>
					{/* <button
						onClick={fetchPunchInData}
						className="btn w-20 text-white px-4 py-2 rounded-md"
						style={{ backgroundColor: "#24a184", border: "#24a184" }}
					>
						Get
					</button> */}

				</div>

				{isModalOpen && (
					<>
						{/* Modal Section */}
						<div className="w-full">
							{isLoad ? ( // Show loader while fetching data
								<div className="text-center p-5">
									<HashLoader
										color="#5755d9"
										size={30}
										style={{
											position: "absolute",
											right: "50%",
										}}
									/>
								</div>
							) : (
								<form method="POST" onSubmit={handleSubmit}>
									{/* Time Input Fields */}
									<div className="grid grid-cols-2 gap-4 mb-4">
										{[
											{ id: "punch_start_time", label: "Punch Start" },
											{ id: "punch_end_time", label: "Punch End" },
											{ id: "lunch_start_time", label: "Lunch Start" },
											{ id: "lunch_end_time", label: "Lunch End" },
										].map(({ id, label }) => (
											<div className="flex flex-col" key={id}>
												<label htmlFor={id} className="form-label text-sm font-semibold">
													{label} <span className="text-red-500">*</span>
												</label>
												<input
													id={id}
													type="text"
													required
													name={id}
													className="form-control px-3 py-2 border rounded-md text-sm"
													onChange={handleChange}
													value={data[id]}
												/>
											</div>
										))}
									</div>
									<div className="mb-4">
										<label htmlFor="reason" className="form-label text-sm font-semibold">
											Reason <span className="text-red-500">*</span>
										</label>
										<textarea
											name="reason"
											className="form-control w-full px-3 py-2 border rounded-md text-sm"
											required
											rows="3"
											onChange={handleChange}
											value={data.reason}
										></textarea>
									</div>

									{/* Buttons */}
									<div className="flex justify-center gap-4">
										{updateShow ? (
											<button
												type="submit"
												className="modal-button-new"
											>
												Update
											</button>
										) : (
											// <button
											// 	type="submit"
											// 	className="btn bg-[#24a184] border-[#24a184] text-white px-2 py-1 rounded-md "
											// 	style={{ backgroundColor: "#24a184", border: "#24a184" }}
											// >
											// 	Create
											// </button>
											<div className="text-center w-full">
												<button
													type="submit"
													className="modal-button-new"
													style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
												>
													Create Ticket
												</button>
											</div>
										)}
										{/* <button
											type="button"
											onClick={handleCancel}
											className="btn btn-danger text-white px-2 py-1 rounded-md w-24 hover:bg-red-600"
										>
											Cancel
										</button> */}
									</div>
								</form>
							)}
						</div>
					</>
				)}

			</div>

		</div>
	</>)

	return (
		<div className="intro-y box lg:mt-5">
			<div className="">
				<div className="flex justify-end p-1 m-1">
					<button
						type="button"
						// onClick={() => handleOpen(null)}
						onClick={() => handleOpenFilter()}
						className="btn w-50 add-emp-btn  "
						style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
					>
						Add Punch Ticket
					</button>
				</div>
				<div>
					{isLoad ? ( // Show loader while fetching data
						<div className="text-center ">
							<HashLoader
								color="#5755d9"
								size={30}
								style={{
									position: "absolute",
									right: "50%",
								}}
							/>
						</div>
					) : showTicket && showTicket.length > 0 ? (
						<div
							style={{
								// padding: "10px",
								borderTopLeftRadius: "10px",
								borderTopRightRadius: "10px",
							}}
						>
							<div
								style={{
									backgroundColor: "#FC9A30",
									color: "#fff",
									padding: "10px",
									textAlign: "start",
									borderTopLeftRadius: "10px",
									borderTopRightRadius: "10px",
								}}
							>
								<h3>
									Punch Ticket
								</h3>
							</div>


							<div className="table-outlineBorder" style={{
								border: "1px sold #FC9A30 !important",
							}}>
								<div className="employee-table-container">
									<table className="employee-table" style={{ width: "100%" }}>
										<thead>
											<tr>
												<th className="whitespace-nowrap">Date</th>
												<th className="whitespace-nowrap">Punch Start</th>
												<th className="whitespace-nowrap">Punch End</th>
												<th className="whitespace-nowrap">Lunch Start</th>
												<th className="whitespace-nowrap">Lunch End</th>
												<th className="whitespace-nowrap">Reason</th>
												<th className="whitespace-nowrap">Status</th>
												<th className="whitespace-nowrap">Action</th>
											</tr>
										</thead>
										<tbody>
											{currentData.map((ticket, index) => (
												<tr key={index}>
													<td>{format(new Date(ticket.date), "yyyy-MM-dd")}</td>
													<td>{ticket.punch_start_time}</td>
													<td>{ticket.punch_end_time}</td>
													<td>{ticket.lunch_start_time}</td>
													<td>{ticket.lunch_end_time}</td>
													<td>{ticket.reason}</td>
													<td>
														{ticket.status === 0 && (
															<button className="btn btn-warning btn-sm">Pending</button>
														)}
														{ticket.status === 1 && (
															<button className="btn btn-success text-white btn-sm">
																Approved
															</button>
														)}
														{ticket.status === 2 && (
															<button className="btn btn-danger btn-sm">Rejected</button>
														)}
													</td>
													{/* <td>
														<div className="flex">
															<FaEdit
																className="text-primary ml-2"
																onClick={() => editdata(ticket)}
															/>
															<FaTrashAlt
																className="text-danger ml-2"
																onClick={() => handleDelete(ticket.id)}
															/>
														</div>
													</td> */}
													<td>
														<div className="relative">
															{/* Dropdown Toggle Button */}
															<button
																onClick={() => setActionsDropdownIndex(actionsDropdownIndex === ticket.id ? null : ticket.id)}
																className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200"
															>
																{actionsDropdownIndex === ticket.id ? "▲" : "▼"}
															</button>

															{/* Dropdown Menu */}
															{actionsDropdownIndex === ticket.id && (
																<div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-10">
																	<ul className="py-1">
																		<li
																			onClick={() => {
																				editdata(ticket);
																				setActionsDropdownIndex(null);
																			}}
																			className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
																		>
																			<FaEdit className="text-gray-600 mr-2" />
																			Edit
																		</li>
																		<li
																			onClick={() => {
																				handleDelete(ticket.id);
																				setActionsDropdownIndex(null);
																			}}
																			className="flex items-center px-4 py-2 text-sm text-red-700 cursor-pointer hover:bg-red-100"
																		>
																			<FaTrashAlt className="mr-2 text-red-600" />
																			Delete
																		</li>
																	</ul>
																</div>
															)}
														</div>
													</td>

												</tr>
											))}
										</tbody>
									</table>
									<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
										<nav className="w-full sm:w-auto sm:mr-auto">
											<div className="row">
												<div className="col-md-3"></div>
												<div className="col-md-4">
													<ReactPaginate
														breakLabel={"..."}
														pageCount={Math.ceil(showTicket.length / dataPerPage)}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														onPageChange={handlePageChange}
														containerClassName={"pagination"}
														activeClassName={"active"}
														pageClassName={"page-item"}
														previousClassName={"page-item"}
														nextClassName={"page-item"}
														pageLinkClassName={"page-link"}
														previousLinkClassName={"page-link"}
														nextLinkClassName={"page-link"}
														breakClassName={"page-item"}
														breakLinkClassName={"page-link"}
														previousLabel={<>&laquo;</>}
														nextLabel={<>&raquo;</>}
													/>
												</div>
											</div>
										</nav>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="text-center m-5">
							<p>No tickets available</p>
						</div>
					)}
				</div>
			</div >
			<CommonModal isOpen={isOpenFilter} content={modalContent} />
		</div >
	);
};
export default PunchTicket;
