import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FaCalendar, FaTimes } from "react-icons/fa";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import Modal from "react-modal";
import { GrNext, GrPrevious } from "react-icons/gr";
// import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import ProductivityChart from "./ProductivityChart";
import AttendanceChart from "./AttendanceChart";
import reload from "../reload.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TicketsChart from "./report/TicketsChart";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		padding: "0%",
		borderRadius: "5px",
	},
};

const Home = (props) => {
	//  console.log(theme);
	// const loadingContext = useLoadingContext();
	let today = new Date();
	let month = [];
	month[0] = "1";
	month[1] = "2";
	month[2] = "3";
	month[3] = "4";
	month[4] = "5";
	month[5] = "6";
	month[6] = "7";
	month[7] = "8";
	month[8] = "9";
	month[9] = "10";
	month[10] = "11";
	month[11] = "12";

	let monthNumber = month[today.getMonth()];

	let [loading, setLoading] = useState(false);
	let [dateloading, setdateLoading] = useState(false);
	const [date, setdate] = useState(new Date());
	const [isVisible, setisVisible] = useState(false);
	const [holidayisVisible, setholidayisVisible] = useState(false);
	const [prodisVisible, setprodisVisible] = useState(false);
	const [monthYear, setmonthYear] = useState({});

	let currmonth = monthNumber;
	let curryear = today.getFullYear();
	const [selectedmonth, setselectedmonth] = useState({
		year: curryear,
		month: currmonth,
	});

	const holidaycurrmonth = monthNumber;
	const holidaycurryear = today.getFullYear();

	const prodcurrmonth = monthNumber;
	const prodcurryear = today.getFullYear();

	const [bdaydata, setbdaydata] = useState([]);
	const [holidaydata, setholidaydata] = useState([]);
	const [attdata, setattdata] = useState([]);
	console.log(attdata, 'attdata')
	const [proddata, setproddata] = useState([]);
	const [ticketData, setTicketData] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [absentmodalIsOpen, setabsentIsOpen] = React.useState(false);
	const [onleavemodalIsOpen, setleaveIsOpen] = React.useState(false);
	const [unknownAbsentModalIsOpen, setUnknownAbsentModalIsOpen] =
		React.useState(false);

	const [lateArrivedModalIsOpen, setLateArrivedIsOpen] =
		React.useState(false);

	const [orgs, setorgs] = React.useState([]);
	const [master] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).master
	);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const [orgval, setorgval] = React.useState([]);
	// console.log(props.isdark);
	// useEffect(() => {
	//   // let mode=props.isdarmode()
	//   console.log(props.toggle);
	//   // console.log(mode);
	//   // ahi update nai avti value ni
	// }, [props.toggle]);
	useEffect(() => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value ? value.month : format(new Date(), "MM");
		const year = value ? value.year : format(new Date(), "yyyy");
		setselectedmonth({ year: year, month: month });

		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		if (master === 1) {
			getOrganizations();
		}
		getBirthdayData({ year: year, month: month });
		getHolidayData({ year: year, month: month });
		getAttendanceData(format(new Date(), "yyyy-MM-dd"));
		getProdData({ year: year, month: month });
	}, []);

	const reloadFun = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value ? value.month : format(new Date(), "MM");
		const year = value ? value.year : format(new Date(), "yyyy");

		getOrganizations();
		getBirthdayData({ year: year, month: month });
		getHolidayData({ year: year, month: month });
		getAttendanceData(format(new Date(), "yyyy-MM-dd"));
		getProdData({ year: year, month: month });
	};

	function openModal() {
		setIsOpen(true);
	}
	function absentopenModal() {
		setabsentIsOpen(true);
		setIsOpen(false);
	}
	function leaveopenModal() {
		setleaveIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
		setabsentIsOpen(false);
		setleaveIsOpen(false);
	}

	function openLateArrivedModal() {
		setLateArrivedIsOpen(true);
	}

	function closeLateArrivedModal() {
		setLateArrivedIsOpen(false);
	}

	const getSummaryProductivity = () => {
		return <ProductivityChart proddata={proddata} />;
	};

	const getSummaryAttendance = () => {
		return <AttendanceChart attdata={attdata} />;
	};

	const getSummaryTicket = () => {
		return <TicketsChart ticketData={ticketData} />;
	};

	function openUnknownAbsentModal() {
		setUnknownAbsentModalIsOpen(true);
	}

	function closeUnknownAbsentModal() {
		setUnknownAbsentModalIsOpen(false);
	}

	const handledate = (e) => {
		let date;
		if (e !== null) {
			setdate(e);
			date = format(new Date(e), "yyyy-MM-dd");
		} else {
			date = format(new Date(), "yyyy-MM-dd");
		}
		getAttendanceData(date);
	};

	const nexTDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() + 1);

		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getAttendanceData(date2);
	};

	const prevDay = () => {
		let tomorrow = date;
		tomorrow.setDate(date.getDate() - 1);

		setdate(tomorrow);
		let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
		getAttendanceData(date2);
	};

	const getMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
				? monthYear.month
				: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
				? monthYear.year
				: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};

	const getHolidayMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
				? monthYear.month
				: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
				? monthYear.year
				: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};

	const getProdMonthValue = () => {
		let value = JSON.parse(localStorage.getItem("monthyear"));
		const month = value
			? value.monthname
			: monthYear && monthYear.month
				? monthYear.month
				: 0;
		const year = value
			? value.year
			: monthYear && monthYear.year
				? monthYear.year
				: 0;

		let date = new Date();
		let curryear = date.getFullYear();

		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];

		return month && year
			? `${month}-${year}`
			: monthNames[currmonth - 1] + "-" + curryear;
	};

	const handleOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		setisVisible(false);
		setprodisVisible(false);
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);
		setmonthYear({ year, month: monthname });
		setselectedmonth({ year, month: Number(month) });
		getBirthdayData({ year, month });
		getHolidayData({ year, month });
		getProdData({ year, month });
	};

	const handleHolidayOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		setholidayisVisible(false);
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);

		getBirthdayData({ year, month });
		getHolidayData({ year, month });
		getProdData({ year, month });
	};

	const handleProdOnChange = (year, month) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		let monthname = monthNames[month - 1];
		setisVisible(false);
		setprodisVisible(false);
		localStorage.setItem(
			"monthyear",
			JSON.stringify({ year, monthname, month: month })
		);

		getBirthdayData({ year, month });
		getHolidayData({ year, month });
		getProdData({ year, month });
	};

	const showMonthPicker = (e) => {
		setisVisible(!isVisible);
		e.preventDefault();
	};
	const hideMonthPicker = () => {
		setisVisible(false);
	};
	const showHolidayMonthPicker = (e) => {
		setholidayisVisible(true);
		e.preventDefault();
	};

	const showProdMonthPicker = (e) => {
		setprodisVisible(true);
		e.preventDefault();
	};
	const handleOnDismiss = () => {
		setisVisible(false);
		setholidayisVisible(false);
		setprodisVisible(false);
	};

	const getBirthdayData = async ({ year, month, org = "" }) => {
		setLoading(true);

		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		// console.log(selectedorg);
		await fetch(BASEURL + `get-birthdaydata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					function compare(a, b) {
						if (
							new Date(a.dob).toString().slice(8, 10) <
							new Date(b.dob).toString().slice(8, 10)
						) {
							return -1;
						}
						if (
							new Date(a.dob).toString().slice(8, 10) >
							new Date(b.dob).toString().slice(8, 10)
						) {
							return 1;
						}
						return 0;
					}

					setbdaydata(res.data.sort(compare));
				} else {
					setbdaydata([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getHolidayData = async ({ year, month, org = "" }) => {
		setLoading(true);

		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-holidaydata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
				// uuid: selectedorg
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setholidaydata(res.data);
				} else {
					setholidaydata([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getOrganizations = async () => {
		setLoading(true);

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-organizations/`, {
			method: "POST",
			headers: myHeaders,
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setorgs(res.data);
				} else {
					setorgs([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getorg = async (org) => {
		// console.log(org);
		setorgval(org);
		org = org.value;
		setselectedorg(org);
		let value = JSON.parse(localStorage.getItem("monthyear"));
		if (Object.keys(monthYear).length !== 0) {
			value = monthYear;
		}

		const month = value ? value.month : format(new Date(), "MM");
		const year = value ? value.year : format(new Date(), "yyyy");

		getBirthdayData({ year: year, month: month, org });

		getHolidayData({ year: year, month: month, org });

		getAttendanceData(format(new Date(), "yyyy-MM-dd"), org);

		getProdData({ year: year, month: month, org });
		// console.log(org);
	};

	const getAttendanceData = async (date, org = "") => {
		setdateLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-attendancedata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				date,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setattdata(res.arr[0]);
				} else {
					setattdata([]);
				}
			})
			.catch((err) => console.log(err));

		setdateLoading(false);
	};

	const getProdData = async ({ year, month, org = "" }) => {
		setLoading(true);
		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-monthwise-productivity/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
				// uuid: selectedorg
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				// uuid: setselectedorg!=="" ? selectedorg :JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setproddata(res.allarr[0]);
					setTicketData(res.ticketData)
				} else {
					setproddata([]);
					setTicketData()
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
		// loadingContext.done();
	};
	// console.log(orgs);
	return (
		<>
			<div className="col-span-12 lg:col-span-12 sm:col-span-12 mt-0">
				<div className="intro-y block ">
					<div className="sm:ml-auto sm:mt-0">
						<div className="flex flex-col justify-end sm:flex-row sm:items-end xl:items-start mb-1 mt-1">
							<div className="flex sm:mt-0">
								{master === 1 && (
									<>
										{/* <Select
											value={orgval}
											className="basic-single mr-2"
											onChange={(e) => {
												getorg(e);
											}}
											options={orgs}
											styles={{
												menu: (base) => ({
													...base,
													backgroundColor:
														"#ffffff", // Solid white background
													zIndex: 1, // High z-index to ensure it appears above other elements
													border: "1px solid #ddd", // Light border for clear boundaries
													boxShadow:
														"0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for better visibility
												}),
												option: (
													base,
													state
												) => ({
													...base,
													backgroundColor:
														state.isFocused
															? "#f0f0f0"
															: "#ffffff",
													color: "#000000",
												}),
												control: (
													base
												) => ({
													...base,
													backgroundColor:
														"#ffffff", // White background for the select control
													border: "1px solid #ddd", // Add a border to the dropdown control
												}),
											}}
										/> */}
										<div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
											<DatePicker
												clearIcon=""
												calendarIcon={
													<FaCalendar
														style={{
															color: "rgb(30 64 175)",
														}}
													/>
												}
												onChange={handledate}
												value={date}
											/>
										</div>
										{/* <div className="flex mt-2 sm:mt-0">
											<div className="input-group mr-2 calenderdate">
												<input
													type="text"
													style={{
														fontSize:
															"12px",
													}}
													onFocus={(e) =>
														showMonthPicker(
															e
														)
													}
													value={getMonthValue()}
													onDismiss={() =>
														hideMonthPicker(
															false
														)
													}
													className="form-control calenderdate1 "
													id="basic-url"
													aria-describedby="basic-addon3"
												/>
												<div className="input-group-append calendaricon">
													<FaCalendar
														style={{
															fontSize:
																"12px",
														}}
														onClick={(
															e
														) =>
															showMonthPicker(
																e
															)
														}
													/>
												</div>
											</div>
											<ReactMonthPicker
												show={isVisible}
												lang={[
													"Jan",
													"Feb",
													"Mar",
													"Apr",
													"May",
													"Jun",
													"Jul",
													"Aug",
													"Sep",
													"Oct",
													"Nov",
													"Dec",
												]}
												value={
													selectedmonth
												}
												onChange={
													handleOnChange
												}
												onDismiss={
													handleOnDismiss
												}
											/>
										</div> */}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="grid grid-cols-12 gap-1">
				<div className="col-span-6 2xl:col-span-6 sm:col-span-12 text-left p-1">
					<div className="col-span-12 sm:col-span-12 mt-2">
						<div
							style={{
								border: "1px solid #24A184",
								borderRadius: "5px",
							}}
						>
							<h2
								style={{
									background: "#24A184",
									borderRadius: "2px",
									fontSize: "15px",
									padding: "10px",
									fontWeight: 500,
									color: "#fff",
									textAlign: "start",
								}}
							>
								Monthly Productivity Statistics
							</h2>
							<div className="grid grid-cols-12">
								<div className="col-span-12 sm:col-span-12 p-2" >{getSummaryProductivity()}</div>
								<div className="col-span-12 sm:col-span-12 p-2" style={{
									display: "flex",
									alignItems: "center",
									justifyContent: 'center'
								}}>
									<div className="flex " style={{
										display: "flex",
										alignItems: "center",
										justifyContent: 'center'
									}}>
										<div>
											<div className="p-5" style={{
												fontSize: '16px'
											}}>
												<h1 className="mb-2">
													Working
												</h1>
												<h1 style={{
													color: "#1D46AC"
												}}>
													{loading || !proddata?.working ? (
														<div className="flex justify-center">
															<HashLoader
																color="#5755d9"
																size={30}
															/>
														</div>
													) : (
														<>
															<span
																style={{
																	color: "green",
																}}
															>
																{proddata.working ||
																	"00:00:00"}
															</span>
															<br />
															<span>hh:mm:ss</span>
														</>
													)}
												</h1>
											</div>
											<div className="p-5" style={{
												fontSize: '16px'
											}}>
												<h1 className="mb-2">
													Productive
												</h1>
												<h1 style={{
													color: "#24A184"
												}}>
													{loading || !proddata?.prodtime ? (
														<div className="flex justify-center">
															<HashLoader
																color="#5755d9"
																size={30}
															/>
														</div>
													) : (
														<>
															<span
																style={{
																	color: "green",
																}}
															>
																{proddata &&
																	proddata.prodtime
																	? proddata.prodtime
																	: "00:00:00"}
															</span>{" "}
															{/* <span style={{ fontSize: "11px" }}> */}
															<span>
																<br /> hh:mm:ss
															</span>
														</>
													)}
												</h1>
											</div>
											<div className="p-5" style={{
												fontSize: '16px'
											}}>
												<h1 className="mb-2">
													Idle
												</h1>
												<h1 style={{
													color: "#FB7474"
												}}>
													{loading || !proddata?.idletime ? (
														<div className="flex justify-center">
															<HashLoader
																color="#5755d9"
																size={30}
															/>
														</div>
													) : (
														<>
															<span
																style={{ color: "red" }}
															>
																{proddata &&
																	proddata.idletime
																	? proddata.idletime
																	: "00:00:00"}
															</span>{" "}
															<br />
															<span>hh:mm:ss</span>
														</>
													)}
												</h1>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-span-12 2xl:col-span-6 sm:col-span-12 mt-2">
						<div
							style={{
								border: "1px solid #1D46AC",
								borderRadius: "5px",
							}}
						>
							<h2
								style={{
									background: "#1D46AC",
									borderRadius: "2px",
									fontSize: "15px",
									padding: "10px",
									fontWeight: 500,
									color: "#fff",
									textAlign: "start",
								}}
							>
								Project
							</h2>
							<div className="intro-y mt-5 sm:mt-2 p-3">
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<thead style={{ backgroundColor: "#f8f9fa" }}>
										<tr>
											<th className="whitespace-nowrap p-3">Project Name</th>
											<th className="whitespace-nowrap p-3">Team Member</th>
											<th className="whitespace-nowrap p-3">Status</th>
										</tr>
									</thead>
									<tbody>
										<tr style={{ borderBottom: "1px solid #ccc" }}>
											<td className="" style={{ padding: "10px" }}>
												HR Project
											</td>
											<td className="" style={{ padding: "10px" }}>
												Vinit Shah
											</td>
											<td className="" style={{ padding: "10px" }}>
												Active
											</td>
										</tr>
										<tr style={{ borderBottom: "1px solid #ccc" }}>
											<td className="" style={{ padding: "10px" }}>
												TradeIQ
											</td>
											<td className="" style={{ padding: "10px" }}>
												Harsh Mistry
											</td>
											<td className="" style={{ padding: "10px" }}>
												Active
											</td>
										</tr>
										<tr>
											<td className="" style={{ padding: "10px" }}>
												Account
											</td>
											<td className="" style={{ padding: "10px" }}>
												Milan Kareliya
											</td>
											<td className="" style={{ padding: "10px" }}>
												Active
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="col-span-12 mt-2">
						<div
							style={{
								border: "1px solid #44A7F1",
								borderRadius: "5px",
							}}
						>
							<h2
								style={{
									background: "#44A7F1",
									borderRadius: "2px",
									fontSize: "15px",
									padding: "10px",
									fontWeight: 500,
									color: "#fff",
									textAlign: "start",
								}}
							>
								Birthday
							</h2>
							<div className="intro-y mt-5 sm:mt-2 p-3">
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<thead style={{ backgroundColor: "#f8f9fa" }}>
										<tr>
											<th className="whitespace-nowrap p-3">Employee Name</th>
											<th className="text-center whitespace-nowrap p-3">Date</th>
										</tr>
									</thead>
									<tbody>
										{bdaydata && bdaydata.length > 0 ? (
											bdaydata.map((item, i) => (
												<tr key={i}>
													<td className="p-3" style={{ padding: "10px" }}>
														<div className="text-slate-500 text-xs whitespace-nowrap mt-0.5 text-left">
															{item.name} 🎂
														</div>
													</td>
													<td className="text-center" style={{ padding: "10px" }}>
														{/* {format(new Date(item.dob), "dd MMM, yyyy")} */}
														{format(new Date(item.dob), "dd/MM/yyyy")}
													</td>
												</tr>
											))
										) : (
											<tr className="intro-x">
												<td colSpan={2}>
													<div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
														No data Found
													</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="col-span-6 2xl:col-span-6 text-left p-1">
					<div className="col-span-12 mt-2">
						<div
							style={{
								border: "1px solid #FC9A30",
								borderRadius: "5px",
							}}
						>
							<h2
								style={{
									background: "#FC9A30",
									borderRadius: "2px",
									fontSize: "15px",
									padding: "10px",
									fontWeight: 500,
									color: "#fff",
									textAlign: "start",
								}}
							>
								Daily Attendance Statistics
							</h2>
							<div className="grid grid-cols-12">
								<div className="col-span-12 p-2" >{getSummaryAttendance()}</div>
								{/* <div className="col-span-12 p-2" style={{
									display: "flex",
									alignItems: "start",
									justifyContent: 'center'
								}}>
									<div className="flex " style={{
										display: "flex",
										alignItems: "center",
										justifyContent: 'center',
										marginTop: "20px"
									}}>
										<div className="attendance-container">
											<h1 className="attendance-box">
												<span style={{ color: "#1D46AC" }}>Total Employee</span>
												{attdata && attdata.totalmembers}
											</h1>

											<h1 className="attendance-box">
												<span style={{ color: "#FC9A30" }}>Leave</span>
												{attdata && attdata.onleaveCount}
											</h1>

											<h1 className="attendance-box">
												<span style={{ color: "#24A184" }}>Present</span>
												{attdata && attdata.present}
											</h1>

											<h1 className="attendance-box">
												<span style={{ color: "#2EDCFE" }}>Late Arrived</span>
												{attdata && attdata.latearrived
													? attdata.latearrived
													: 0}
											</h1>

											<h1 className="attendance-box">
												<span style={{ color: "#FB7474" }}>Absent</span>
												{attdata && attdata.absent}
											</h1>

											<h1 className="attendance-box">
												<span style={{ color: "#745555" }}>Unknown/Absent</span>
												{attdata &&
													attdata.unknownAbsentUsers
													? `${attdata.unknownAbsentUsers.length}`
													: "0 Count"}
											</h1>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<div className="col-span-12 mt-2">
						<div
							style={{
								border: "1px solid #F97B6A",
								borderRadius: "5px",
							}}
						>
							<h2
								style={{
									background: "#F97B6A",
									borderRadius: "2px",
									fontSize: "15px",
									padding: "10px",
									fontWeight: 500,
									color: "#fff",
									textAlign: "start",
								}}
							>
								Late Arrived Details
							</h2>
							<div className="p-3">
								<table style={{ width: "100%", borderCollapse: "collapse" }}>
									<thead style={{ backgroundColor: "#f8f9fa" }}>
										<tr>
											<th style={{ padding: "10px", textAlign: "left" }}>EMP Name</th>
											<th style={{ padding: "10px", textAlign: "left" }}>Punch Time</th>
											<th style={{ padding: "10px", textAlign: "left" }}>Late Arrived</th>
										</tr>
									</thead>
									<tbody>
										{attdata?.latearrivedUsers?.map((item, index, array) => (
											<tr key={index} style={{
												borderBottom: index === array.length - 1 ? "none" : "1px solid #ccc",
											}}>
												<td style={{ padding: "10px" }}>
													{item?.name}
												</td>
												<td style={{ padding: "10px" }}>
													{item?.start_time}
												</td>
												<td style={{ padding: "10px" }}>
													{item?.late_minutes}
												</td>
											</tr>

											// console.log(item)

											// <tr
											// 	key={index}
											// 	style={{
											// 		borderBottom: index === array.length - 1 ? "none" : "1px solid #ccc",
											// 	}}
											// >
											// 	<td style={{ padding: "10px" }}>{name}</td>
											// 	<td style={{ padding: "10px" }}></td>
											// 	<td style={{ padding: "10px" }}></td>
											// </tr>
										))}
									</tbody>
								</table>

							</div>
						</div>
					</div>
					<div className="col-span-12 mt-2">
						<div
							style={{
								border: "1px solid #53A8CB",
								borderRadius: "5px",
							}}
						>
							<h2
								style={{
									background: "#53A8CB",
									borderRadius: "2px",
									fontSize: "15px",
									padding: "10px",
									fontWeight: 500,
									color: "#fff",
									textAlign: "start",
								}}
							>
								Tickets
							</h2>
							<div className="intro-y">
								{getSummaryTicket()}
							</div>
						</div>
					</div>
					{/* <div style={{ marginTop: "200px" }} className="flex items-center sm:ml-auto mt-5 pt-5 sm:mt-0">
						<DatePicker
							clearIcon=""
							calendarIcon={
								<FaCalendar
									style={{
										color: "rgb(30 64 175)",
									}}
								/>
							}
							onChange={handledate}
							value={date}
						/>
						<div className="flex mt-2 sm:mt-0">
							<div className="input-group mr-2 calenderdate">
								<input
									type="text"
									style={{
										fontSize:
											"12px",
									}}
									onFocus={(e) =>
										showMonthPicker(
											e
										)
									}
									value={getMonthValue()}
									onDismiss={() =>
										hideMonthPicker(
											false
										)
									}
									className="form-control calenderdate1 "
									id="basic-url"
									aria-describedby="basic-addon3"
								/>
								<div className="input-group-append calendaricon">
									<FaCalendar
										style={{
											fontSize:
												"12px",
										}}
										onClick={(
											e
										) =>
											showMonthPicker(
												e
											)
										}
									/>
								</div>
							</div>
							<ReactMonthPicker
								show={isVisible}
								lang={[
									"Jan",
									"Feb",
									"Mar",
									"Apr",
									"May",
									"Jun",
									"Jul",
									"Aug",
									"Sep",
									"Oct",
									"Nov",
									"Dec",
								]}
								value={
									selectedmonth
								}
								onChange={
									handleOnChange
								}
								onDismiss={
									handleOnDismiss
								}
							/>
						</div>
					</div> */}
				</div>
			</div >

		</>
	);
};
export default Home;
