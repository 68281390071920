import React, { useState } from "react";
import { BASEURL, _accountCreds } from "./BASEURL";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const [user_id, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loginmsg, setloginmsg] = useState("");
  const [loading, setloading] = useState(false);

  let navigate = useNavigate();

  const [emailerror, setEmailerror] = useState(false);
  const [passerror, setPasserror] = useState(false);

  const something = (event) => {
    if (event.keyCode === 13) {
      loginsubmit(event);
    }
  };

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const loginsubmit = async (e) => {
    setloading(true);
    if (user_id === "") {
      setEmailerror(true);
    }
    if (password === "") {
      setPasserror(true);
    }

    let type = 0;
    if (user_id && password) {
      const res = await fetch(BASEURL + "admin-signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type,
          user_id,
          password,
        }),
      });

      const data = await res.json();
      // console.log(data.username); return
      // console.log(_accountCreds); return

      if (data.status === 201) {
        localStorage.setItem("isAuth", JSON.stringify(data));
        localStorage.setItem("isAuth_bkp", JSON.stringify(data));

        const _accountCreds = {
          "_sipExtension": data.username,
          "_sipPassword": "Ghanshyam@Suthar$jenya",
          "_sipServer": "billing.tmsuit.com",
          "_sipUsernameWithInstance": data.username,
          "_wssServer": "wss://billing.tmsuit.com:7443",
          "_extNumber": data.username,
          "_instanceId": generateString(36),
          "_sipAor": data.username + "@billing.tmsuit.com"
        }
        // console.log(_accountCreds);return;
        localStorage.setItem("_accountCreds", JSON.stringify(_accountCreds));
        props.isLogin(true);
        navigate("/");
      } else {
        setloginmsg(data.error);
      }
      setloading(false);
    }
  };
  return (
    <>
      <div className="login">
        <div className="container ">
          <div className="block xl:grid grid-cols-2 gap-4">
            <div className="hidden xl:flex flex-col min-h-screen">
              <a href="" className="-intro-x flex items-center pt-5">
                <img
                  alt="Jenya Tracking"
                  className="w-6"
                  src="../images/logo.svg"
                />
                <span className="text-white text-lg ml-3">Jenya Tracking</span>
              </a>
              <div className="my-auto">
                <img
                  alt="Jenya Tracking"
                  className="-intro-x w-1/2 "
                  src="../images/illustration.svg"
                />
                <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                  A few more clicks to
                  <br />
                  sign in to your account.
                </div>
                <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
                  Manage all your tracking accounts in one place
                </div>
              </div>
            </div>

            <div className="h-screen xl:h-auto flex py-lg-5 xl:py-0 my-lg-10 xl:my-0">
              <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto" style={{
                border: "1px solid #ededed",
                padding: "57px"
              }}>
                <img
                  alt="Jenya Tracking"
                  width={200}
                  className="w-1"
                  src="../images/logo.png"
                />
                {/* <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                  Sign In
                </h2> */}
                <div className="intro-x mt-2 text-slate-400 xl:hidden text-center">
                  A few more clicks to
                  <br />
                  sign in to your account.
                </div>
                <div
                  className={
                    emailerror
                      ? "intro-x mt-8 has-error text-left"
                      : "intro-x mt-8 text-left"
                  }
                >
                  <input
                    type="text"
                    className="intro-x login__input form-control py-3 px-4 block "
                    placeholder="Username"
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  {emailerror ? (
                    <span className="text-danger errormsg">
                      Please Enter Username
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    passerror
                      ? "intro-x mt-8 has-error text-left"
                      : "intro-x mt-8 text-left"
                  }
                >
                  <input
                    type="password"
                    className="intro-x login__input form-control py-3 px-4 block mt-4"
                    placeholder="Password"
                    onKeyDown={(e) => something(e)}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                  {passerror ? (
                    <span className="text-danger errormsg">
                      Please Enter Password
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4">
                  <a href="">Forgot Password?</a>
                </div> */}

                <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left ">
                  {loading ?
                    <button
                      className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                    >
                      Submiting..
                    </button>
                    :
                    <button
                      className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
                      onClick={(e) => loginsubmit(e)}
                    >
                      Sign In
                    </button>
                  }
                  {/* <button className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">
                    Register
                  </button> */}
                </div>

                <p className=" intro-x mt-3 has-error text-left text-danger errormsg ">
                  {loginmsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
