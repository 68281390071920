import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const TicketsChart = ({ ticketData }) => {
    console.log(ticketData, 'ticketdata');

    const options = {
        chart: {
            type: "column",
            height: 250,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "Tickets",
        },
        xAxis: {
            categories: ["Total Ticket", "Approved", "Rejected", "Pending"],
        },
        yAxis: {
            title: {
                text: "Count",
            },
            tickInterval: 5,
            min: 0,
            max: 15,
        },
        series: [
            {
                name: "Tickets",
                data: [
                    ticketData?.total || 0,
                    ticketData?.approved || 0,
                    ticketData?.rejected || 0,
                    ticketData?.pending || 0
                ],
                colorByPoint: true,
            },
        ],
        colors: ["#53A8CB", "#24A184", "#FB7474", "#8F8F8F"],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TicketsChart;
