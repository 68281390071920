import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Masteradmin from "../Masteradmin";
import { FaSignInAlt, FaTrashAlt, FaEdit } from "react-icons/fa";
import CommonModal from "./CommonModal";

const Departments = () => {
	const navigate = useNavigate();
	const [userdata, setuserdata] = useState([]);
	const [dept, setdept] = useState(null);
	const [bkpdata, setbkpdata] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState(null);
	const [items, setitems] = useState([]);
	const [errors, setErrors] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false)
	const [uId, setUid] = useState(false)
	const [add, setadd] = useState(2);
	let [tabtype, settabtype] = useState(false);
	const [isWorkingAfterHours, setIsWorkingAfterHours] = useState(null);
	const [usWorkType, setUsWorkType] = useState(null);
	const [getdata, setgetdata] = useState([]);
	const [employeeData, setEmployeeData] = useState(null);
	const [data, setdata] = useState({
		name: "",
		department: 1,
		hod: 2,
		contactno: "",
		organization: "",
		email: "",
		password: "",
		status: true,
		uuid: ``,
		permanent_address: ``,
		is_allow_afterjobhrs: 0,
		userWorkType: 0,
	});

	const handleWorkTypeChange = (e) => {
		const value = e.target.value === "true" ? "1" : "0"; // Convert true/false to 1/0
		setUsWorkType(value);
	};

	const [openDropdown, setOpenDropdown] = useState(null);

	const toggleDropdown = (id) => {
		setOpenDropdown(openDropdown === id ? null : id);
	};

	const closeDropdown = () => {
		setOpenDropdown(null);
	};

	const handlechangeinput = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
	};


	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});
	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	useEffect(() => {
		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getUserData();
		getdepartments();
		getData();
	}, []);

	const handleValidation = () => {
		const newErrors = {};

		if (!data.dob) {
			newErrors.dob = "Please enter a valid date of birth.";
		}
		if (!data.date_of_joining) {
			newErrors.date_of_joining = "Please enter a valid joining date.";
		}

		setErrors(newErrors);
	};

	const validate = () => {
		let errors = {};

		// Existing validations
		if (!data.name) errors.name = "Employee name is required.";
		if (!data.contactno) errors.contactno = "Contact number is required.";
		if (data.contactno.length < 10)
			errors.contactno = "Contact number must be at least 10 digits.";
		if (!data.email) errors.email = "Email address is required.";
		if (!data.email.match(/\S+@\S+\.\S+/))
			errors.email = "Please enter a valid email address.";
		// if (!data.organization && !master)
		// 	errors.organization = "Organization is required.";


		if (!data.department || data.department === "")
			errors.department = "Please select a department";

		return errors;
	};

	const savedata = async () => {
		console.log("Saved data called")
		const validationErrors = validate();
		setErrors(validationErrors);
		if (Object.keys(validationErrors).length > 0) return;

		try {
			setLoading(true);
			// Prepare headers
			const myHeaders = new Headers();
			let userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (userdata) {
				myHeaders.append("Authorization", userdata.usertoken);
			}
			myHeaders.append("Content-Type", "application/json");

			// Default password setup
			const defaultPassword = data.password || "admin";

			// Ensure id is explicitly handled
			const userId = data.id || null;

			const organizationUUID = master
				? "abc3bab0-2b6e-4fb7-a453-4049c8157ac5"
				: userdata.companydata[0].uuid;
			console.log(data, 'first')
			const response = await fetch(BASEURL + `add-user/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					company_uuid: organizationUUID,
					company: userdata,
					data: {
						...data,
						// date_of_joining: "2025-01-08",
						// id: userId, // Explicitly pass id
						status: true,
						organization: organizationUUID,
						password: defaultPassword, // Default password
						company_uuid: organizationUUID,
					},
				}),
			});

			const res = await response.json();
			if (res.status === 201) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					timer: 2000,
				}).then(() => {
					navigate("/employeeList");
				});
				getUserData();
				setdata({
					name: "",
					department: 1,
					hod: 2,
					password: "",
					contactno: "",
					email: "",
					status: true,
					is_allow_afterjobhrs: "",
					userWorkType: "",
				});
				setTimeout(() => {
					setmsg({ type: "", msg: "" });
				}, 1000);
			} else {
				swal({
					title: "Error",
					text: res.message,
					icon: "error",
					timer: 2000,
				});
			}
		} catch (error) {
			console.error("Error saving data:", error);
			swal({
				title: "Error",
				text: "Something went wrong. Please try again later.",
				icon: "error",
				timer: 2000,
			});
		} finally {
			// Reset loading state
			setIsOpen(false)
			setLoading(false);
			setadd(false);
		}
	};

	const handleClose = () => {
		setadd(false);
		setIsOpen(false)
		resetForm()
		setIsEdit(false)
		setUid()
	}

	const resetForm = () => {
		setdata({
			name: "",
			dob: "",
			email: "",
			contactno: "",
			department: "",
			hod: "",
			permanent_address: "",
			is_allow_afterjobhrs: "",
			userWorkType: "",
		});
		setErrors({});
	};

	const handleSubmit = async () => {
		try {
			if (isEdit) {
				await updateEmployee(uId);
			} else {
				savedata()
			}
			await getUserData()
		} catch (err) {
			console.error(err)
		} finally {
			resetForm()
			setIsEdit(false)
		}
	}

	const modalContent = (
		<>
			<div className="px-4 py-4">
				<div style={{
					textAlign: 'center',
					fontSize: "20px",
					fontWeight: "600",
					display: 'flex',
					justifyContent: 'space-between',
				}}>

					<div></div>
					<h2>
						{isEdit ? "Edit" : "Add"} Employee
					</h2>

					<div className="cursor-pointer" onClick={handleClose}>
						x
					</div>
				</div>
				<div className="my-2 mt-5 ">

					<div className="mb-3">
						<input
							id="update-profile-form-1"
							value={data.name}
							placeholder="Name"
							required
							type="text"
							name="name"
							className="modal-input"
							onChange={handlechangeinput}
						/>
						{errors.name && (
							<span
								style={{
									color: "red",
									fontSize: "12px",
									marginTop: "5px",
								}}
							>
								{errors.name}
							</span>
						)}
					</div>
					<div className="mb-3">
						Dob
						<input
							id="date-of-birth"
							value={data.dob}
							required
							type="date"
							name="dob"
							className="modal-input"
							onChange={handlechangeinput}
							aria-describedby="dob-error"
							onFocus={(e) => (e.target.placeholder = "Date of Birth")}
							onBlur={(e) => (e.target.placeholder = "Date of Birth")}
						/>
						{errors.dob && (
							<span
								id="dob-error"
								className="text-danger"
							>
								Please enter a valid date of
								birth.
							</span>
						)}
					</div>
					<div className="mb-3">
						Date of joining
						<input
							id="date-of-joining"
							value={data.date_of_joining}
							required
							type="date"
							name="date_of_joining"
							className="modal-input"
							onChange={handlechangeinput}
							aria-describedby="date-of-joining-error"
						/>
						{/* Error message, conditionally rendered */}
						{errors.date_of_joining && (
							<span
								id="date-of-joining-error"
								className="text-danger"
							>
								Please enter a valid joining
								date.
							</span>
						)}
					</div>
					<div className="mb-3">
						<input
							id="update-profile-form-1"
							value={data.email}
							type="email"
							required
							name="email"
							placeholder="Email Address"
							className="modal-input"
							onChange={handlechangeinput}
						/>
					</div>

					<div className="mb-3">
						<input
							id="update-profile-form-1"
							value={data.contactno}
							required
							minLength={10}
							placeholder="Mobile Number"
							type="number"
							name="contactno"
							className="modal-input"
							onChange={handlechangeinput}
						/>
						{errors.contactno && (
							<span
								style={{
									color: "red",
									fontSize: "12px",
									marginTop: "5px",
								}}
							>
								{errors.contactno}
							</span>
						)}
					</div>

					{master !== true ? (
						<>
							<div className="col-span-12 md:col-span-6 mb-4">
								<select
									className="modal-input"
									name="department"
									onChange={handlechangeinput}
									value={data.department}
								>
									<option value="" disabled>
										Select a department
									</option>
									<option key={-1} value="0">
										All department
									</option>
									{dept &&
										dept.length > 0 &&
										dept.map((item, i) => {
											return (
												<option key={i} value={item.department_id}>
													{item.deptname}
												</option>
											);
										})}
								</select>
								{errors.department && (
									<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
										{errors.department}
									</span>
								)}
							</div>
						</>
					) : null}

					<div className="col-span-12 md:col-span-6 mb-4">
						<select
							className="modal-input"
							name="hod"
							onChange={handlechangeinput}
							value={data.hod}
						>
							<option value="" disabled>
								Select a role
							</option>
							<option value="2">Employee</option>
							<option value="1">Manager</option>
							{master && <option value="0">Admin</option>}
						</select>
						{errors.hod && (
							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
								{errors.hod}
							</span>
						)}
					</div>
					<div className="mb-3">
						<textarea
							id="permanent_address"
							value={data.permanent_address}
							required
							name="permanent_address"
							className="modal-input"
							onChange={handlechangeinput}
							rows="2"
							placeholder="Permanent Address"
						/>
						{errors.permanent_address && (
							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
								{errors.permanent_address}
							</span>
						)}
					</div>
					<div className="col-span-12 md:col-span-6 mb-4">
						<div className="mb-3">
							<div style={{ fontSize: "15px", marginBottom: "5px" }}>
								Is the employee working hour-wise?
							</div>
							<div className="flex gap-4">
								<div className="flex items-center gap-1">
									<input
										type="radio"
										name="workType"
										value="1"  // Fixed value for "Yes"
										checked={data.userWorkType === 1}
										onChange={(e) =>
											setdata((prev) => ({
												...prev,
												userWorkType: e.target.value, // Updating correctly
											}))
										}
									/>
									Yes
								</div>
								<div className="flex items-center gap-1">
									<input
										type="radio"
										name="workType"
										value="0"  // Fixed value for "No"
										checked={data.userWorkType === 0}
										onChange={(e) =>
											setdata((prev) => ({
												...prev,
												userWorkType: e.target.value, // Updating correctly
											}))
										}
									/>
									No
								</div>
							</div>
						</div>

					</div>

					{isEdit ? (
						<>
							<div className="mb-3">
								<div style={{ fontSize: '15px', gap: '10' }}>Is employee working after office hours?</div>
								<div className="flex gap-4">

									<div className="flex items-center gap-1">
										<input
											type="radio"
											name="afterHours"
											value="1"  // Fixed value for "Yes"
											checked={data.is_allow_afterjobhrs === 1}
											onChange={(e) =>
												setdata((prev) => ({
													...prev,
													is_allow_afterjobhrs: Number(e.target.value),
												}))
											}
										/>
										Yes
									</div>
									<div className="flex items-center gap-1">
										<input
											type="radio"
											name="afterHours"
											value="0"  // Fixed value for "No"
											checked={data.is_allow_afterjobhrs === 0}
											onChange={(e) =>
												setdata((prev) => ({
													...prev,
													is_allow_afterjobhrs: Number(e.target.value), // ✅ Another way to ensure it's a number
												}))
											}
										/>
										No
									</div>
								</div>
							</div>
						</>
					) : ""}

					<button
						type="button"
						onClick={handleSubmit}
						className="modal-button-new"
					>
						Submit
					</button>
				</div>
			</div>
		</>
	);

	useEffect(() => {
		const endOffset = itemOffset + 50; // Update to 50
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 50)); // Update to 50
	}, [items, itemOffset]);

	const getdepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllDepartments/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setdept(res.data);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	const getData = async () => {
		settabtype(false);
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		let data = await fetch(BASEURL + `addorganization`, {
			method: "GET",
			headers: myHeaders,
		});
		let data2 = await data.json();
		setgetdata(data2.data);
		setLoading(false);
	};

	const renderOrgName = (uuid) => {
		if (uuid === "94541385-dd89-4343-8a1d-14989a8546be") {
			return "Rayvatorg";
		} else if (uuid === "abc3bab0-2b6e-4fb7-a453-4049c8157ac5") {
			return "Jenya";
		}
		return "Unknown Organization";
	};
	const handlePageClick = (event) => {
		const newOffset = (event.selected * 50) % items.length;
		setItemOffset(newOffset);
	};

	const getUserData = async (e = "") => {
		console.log("getUserData called")
		let company_uuid;
		if (e && e.target && e.target.value) {
			company_uuid = e.target.value;
		} else {
			const storedData = JSON.parse(localStorage.getItem("isAuth"));
			company_uuid = storedData.companydata[0].uuid;
		}
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-Employee/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				isactive: 1,
				uuids: [company_uuid], // Pass the selected or default company UUID
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					setitems(arr);
					setCurrentItems(arr1);
					setPageCount(Math.ceil(arr.length / 10));
					setuserdata(res.data);
					console.log("Response", res.data);
					setbkpdata(res.data);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	// const getUserData = async (e = "") => {
	// 	let isactive = 1;
	// 	if (e !== "") {
	// 		isactive = e.target.value;
	// 	}

	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));

	// 	if (userdata !== "00:00:00") {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	// console.log(master);
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `admin-Employee/`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			listtype: 1,
	// 			role: 1,
	// 			// master,
	// 			isactive,
	// 			// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
	// 			// 	.uuid,
	// 			uuids: JSON.parse(
	// 				localStorage.getItem("isAuth")
	// 			).companydata.map((company) => company.uuid),
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				let arr = [];
	// 				let arr1 = [];
	// 				for (const key in res.data) {
	// 					arr.push(key);
	// 					if (key < 9) {
	// 						arr1.push(key);
	// 					}
	// 				}
	// 				setitems(arr);
	// 				setCurrentItems(arr1);
	// 				setPageCount(Math.ceil(arr.length / 10));
	// 				setuserdata(res.data);
	// 				console.log("response coming to the uuid", res.data.uuid);
	// 				console.log("Response", res.data);
	// 				setbkpdata(res.data);
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));

	// 	setLoading(false);
	// };

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setdata({
			...data,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
		// console.log(data);
	};

	const handleOpen = () => {

		setIsOpen(true);
	}

	const handleEdit = (uuid) => {
		if (uuid) {
			setIsEdit(true)
			setUid(uuid)
		}
		setIsOpen(true);
		getEmployeeData(uuid)

	}
	const updateEmployee = async (uuid) => {
		// e.preventDefault();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		const myHeaders = new Headers();
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		const payload = { uuid };
		if (data.name !== employeeData.name) payload.name = data.name;
		if (data.department !== employeeData.department)
			payload.department = data.department;
		if (data.hod !== employeeData.type) payload.hod = data.hod;
		if (data.contactno !== employeeData.contact_no)
			payload.contactno = data.contactno;
		if (data.email !== employeeData.email) payload.email = data.email;
		if (data.dob !== employeeData.dob) payload.dob = data.dob;
		if (data.date_of_joining !== employeeData.date_of_joining)
			payload.date_of_joining = data.date_of_joining;
		if (data.status !== employeeData.status) payload.status = data.status;
		if (data.permanent_address !== employeeData.permanent_address) payload.permanent_address = data.permanent_address;
		if (data.is_allow_afterjobhrs !== employeeData.is_allow_afterjobhrs) {
			payload.is_allow_afterjobhrs = data.is_allow_afterjobhrs;
		}
		if (data.userWorkType !== employeeData.userWorkType) {
			payload.userWorkType = data.userWorkType;
		}


		try {
			const response = await fetch(BASEURL + `update-employee`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(payload),
			});
			const res = await response.json();

			if (res.status === 201) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					timer: 2000,
				}).then(() => {
					navigate("/admin/3");
				});
			} else {
				swal({
					title: "Error",
					text: "Please Enter Required Values",
					icon: "error",
					timer: 2000,
				});
			}
		} catch (error) {
			console.error("Error updating employee:", error);
		} finally {
			setIsOpen(false)
			setIsEdit(false)
		}
	};

	const getEmployeeData = async (uuid) => {
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata && userdata.usertoken) {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		setLoading(true);
		try {
			const response = await fetch(BASEURL + `get-employee`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({ uuid }),
			});
			const res = await response.json();
			if (res.status === 201) {

				const empData = res.data[0];
				console.log(empData.dob, empData.date_of_joining);
				setEmployeeData(empData);
				setdata((prevData) => ({
					...prevData,
					name: empData.name,
					department: empData.department,
					hod: empData.type,
					contactno: empData.contact_no,
					email: empData.email,
					dob: empData.dob ? new Date(empData.dob).toLocaleDateString("en-CA") : "",
					date_of_joining: empData.date_of_joining ? new Date(empData.date_of_joining).toLocaleDateString("en-CA") : "",
					status: empData.status,
					permanent_address: empData.permanent_address,
					is_allow_afterjobhrs: String(empData.is_allow_afterjobhrs),
					userWorkType: Number(empData.userWorkType),
				}));
			} else {
				setEmployeeData(null);
				setErrors("No data available");
			}
		} catch (error) {
			console.error("Error fetching employee data:", error);
			setErrors("Error fetching data");
		} finally {
			setLoading(false);
		}
	};

	// const savedata = async (elm) => {
	// 	if (
	// 		data.name === "" ||
	// 		// data.department === "" ||
	// 		// data.hod === "" ||
	// 		data.contactno === "" ||
	// 		data.email === "" ||
	// 		data.organization === ""
	// 	) {
	// 		swal({
	// 			title: "Error",
	// 			text: "Please Enter value Required value",
	// 			icon: "error",
	// 			timer: 2000,
	// 		});
	// 		return;
	// 	}
	// 	// console.log(data); return
	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata != null) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `add-user/`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			company_uuid: userdata.companydata[0].uuid,
	// 			company: userdata,
	// 			data,
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				userdata.companydata[0].name = data.name;
	// 				userdata.companydata[0].support_access = data.supportaccess;
	// 				localStorage.setItem("isAuth", JSON.stringify(userdata));
	// 				setmsg({
	// 					type: res.type,
	// 					msg: res.message,
	// 				});
	// 				getUserData();
	// 				setdata({
	// 					name: "",
	// 					department: 1,
	// 					hod: 2,
	// 					password: "",
	// 					contactno: "",
	// 					email: "",
	// 					status: true,
	// 				});

	// 				swal({
	// 					title: "Success",
	// 					text: res.message,
	// 					icon: "success",
	// 					timer: 2000,
	// 				});
	// 				setTimeout(() => {
	// 					setmsg({
	// 						type: "",
	// 						msg: "",
	// 					});
	// 					setisadd(!isadd);
	// 				}, 1000);
	// 			} else {
	// 				swal({
	// 					title: "Error",
	// 					text: res.message,
	// 					icon: "error",
	// 					timer: 2000,
	// 				});
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));
	// 	setLoading(false);
	// };

	const editdata = (data) => {
		setisadd(true);
		let status;
		if (data.active === 1) {
			status = true;
		} else {
			status = false;
		}
		// console.log(data.companyuuid);
		setdata({
			id: data.user_id,
			name: data.name,
			department: data.department,
			organization: data.companyuuid,
			contactno: data.contact_no,
			email: data.email,
			hod: data.type,
			// password: data.password,
			status: status,
			permanent_address: data.permanent_address,
			is_allow_afterjobhrs: data.is_allow_afterjobhrs,
			userWorkType: data.userWorkType,
		});
	};

	// const addorg = () => {
	// 	settabtype(!tabtype);
	// 	setisadd(false);
	// };
	const adddata = (data) => {
		// console.log(11);
		settabtype(false);
		setisadd(!isadd);
		setdata({
			name: "",
			department: 1,
			hod: 2,
			password: "1",
			contactno: "",
			email: "",
			status: true,
			permanent_address: "",
			is_allow_afterjobhrs: "",
			userWorkType: "",
		});
	};
	const filterdata = (event) => {
		const inputValue = event.target.value.toLowerCase();
		if (inputValue === "") {
			loading ? <HashLoader /> : setuserdata(bkpdata);
			let arr = [];
			for (const key in bkpdata) {
				arr.push(key);
			}
			setitems(arr);
			setCurrentItems(arr);
			setPageCount(Math.ceil(arr.length / 10));
		} else {
			var matches = bkpdata.filter(function (s) {
				return s.name.toLowerCase().includes(`${inputValue}`);
			});

			let arr = [];
			for (const key in matches) {
				arr.push(key);
			}
			setitems(arr);

			const newOffset = (matches.length * 10) % matches.length;
			setItemOffset(newOffset);
			setuserdata(matches);
			const endOffset = itemOffset + 10;
			setCurrentItems(arr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(matches.length / 10));
		}
	};

	const signindata = async (data) => {
		swal({
			title: "Sign In as Admin",
			text: `Are you sure you want to Sign In as Admin?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				// console.log(data.companyuuid); return
				// window.open(SITEURL, '_blank', 'incognito=yes');
				let authdata = JSON.parse(localStorage.getItem("isAuth"));
				// console.log(authdata); return

				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}

				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `get-companydata/${data.company_uuid}`, {
					method: "GET",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						if (res.status === 201) {
							let obj = {
								status: 201,
								message: "Signin successful",
								usertoken: authdata.usertoken,
								id: data.user_id,
								uuid: data.uuid,
								username: data.username,
								name: data.name,
								avatar: data.avatar,
								role: data.type,
								timezone: "",
								dept: data.deptname,
								department: data.department,
								companydata: res.data,
								master: data.master,
								issigninbyadmin: 1,
								type: data.type,
							};
							// return
							localStorage.setItem("isAuth", JSON.stringify(obj));
							window.open(SITEURL, "_self");
							// 'incognito=yes'
						} else {
							swal({
								title: "Error",
								text: res.message,
								icon: "error",
								timer: 2000,
							});
						}
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	const deletedata = (id) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Employee?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-user/${id}`, {
					method: "DELETE",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						getUserData();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	// const selectoption = (e) => {
	// 	// console.log(e.target.value);
	// 	// let data = getdata[e.target.value];
	// 	// setdata({
	// 	//   name: data.organization_name,
	// 	//   email: data.email,
	// 	//   mobile: data.mobile_no,
	// 	//   user: data.user_count,
	// 	//   expiry: data.expiry_date,
	// 	//   id: data.id,
	// 	// });
	// 	// setupdate({ ...update, msg: "Update detail" });
	// };

	// Portal Logic


	return (
		<div className="intro-y lg:mt-1 p-0 m-0">
			<div style={{ justifyContent: 'space-between' }} className="flex items-center justify-between p-2 ">
				{/* <h2 className="font-medium text-base mr-auto">All Employee</h2> */}
				<div className="flex mt-5 sm:mt-0">
					<div className="sm:flex items-center sm:mr-4  xl:mt-0">
						<input
							id="tabulator-html-filter-value"
							type="text"
							onChange={filterdata}
							className="form-control sm:w-60 md:w-80 lg:w-96 xl:w-[400px] 2xl:w-full mt-2 sm:mt-0"
							placeholder="Search..."
						/>
					</div>
				</div>

				<div className="flex justify-right">
					<button
						type="button"
						onClick={handleOpen}
						// onClick={() => navigate("/employees/Addemployee")}
						className="add-emp-btn"
					>
						Add Employee
					</button>
				</div>

			</div>
			{tabtype ? <Masteradmin getData={getData} /> : null}
			<div>
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{isadd === true ? (
						<div className="flex-1 mt-6 xl:mt-0">
							<form>
								<div className="grid grid-cols-12 gap-x-5">
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Employee Name
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												value={data.name}
												required
												type="text"
												name="name"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>
									{master !== true ? (
										<>
											{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
											<div className="col-span-12 md:col-span-6 mt-3 mb-3">
												<label
													htmlFor="update-profile-form-2"
													className="form-label"
												>
													Select Department
												</label>
												<select
													className="form-select"
													name="department"
													onChange={handlechange}
													value={data.department}
												>
													<option key={-1} value="0">
														All department
													</option>
													{dept &&
														dept.length > 0 &&
														dept.map((item, i) => {
															return (
																<option
																	key={i}
																	value={
																		item.department_id
																	}
																>
																	{
																		item.deptname
																	}
																</option>
															);
														})}
												</select>
											</div>
										</>
									) : null}

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Role{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select"
											name="hod"
											onChange={handlechange}
											value={data.hod}
										>
											{/* {master === 0 && ( */}
											<>
												<option value="2">
													Employee
												</option>
												<option value="1">
													Manager
												</option>
											</>
											{/* )} */}
											<option value="0">Admin</option>
										</select>
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Contact Number{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.contactno}
											required
											minLength={10}
											type="number"
											name="contactno"
											className="form-control"
											onChange={handlechange}
										/>
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-birth"
											className="form-label"
										>
											Date of Birth{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-birth"
											value={data.dob}
											required
											type="date"
											name="dob"
											className="form-control"
											onChange={handlechange}
											aria-describedby="dob-error"
										/>
										{/* Error message, conditionally rendered */}
										{errors.dob && (
											<span
												id="dob-error"
												className="text-danger"
											>
												Please enter a valid date of
												birth.
											</span>
										)}
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-joining"
											className="form-label"
										>
											Date of Joining{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-joining"
											value={data.date_of_joining}
											required
											type="date"
											name="date_of_joining"
											className="form-control"
											onChange={handlechange}
											aria-describedby="date-of-joining-error"
										/>
										{/* Error message, conditionally rendered */}
										{errors.date_of_joining && (
											<span
												id="date-of-joining-error"
												className="text-danger"
											>
												Please enter a valid joining
												date.
											</span>
										)}
									</div>

									{/* <div className="col-span-12 xl:col-span-6 mt-4 flex flex-col items-start space-y-2">
										<label
											htmlFor="update-profile-form-1"
											className="form-label text-sm font-medium w-full text-left text-gray-700"
										>
											Select Department
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select p-3 rounded-md border-gray-300 focus:ring-2 focus:ring-indigo-500"
											name="department"
											onChange={handleChange}
											value={data.department}
										>
											<option key={-1} value="0">
												All Departments
											</option>
											{dept &&
												dept.length > 0 &&
												dept.map((item) => (
													<option
														key={item.department_id}
														value={
															item.department_id
														}
													>
														{item.deptname}
													</option>
												))}
										</select>
									</div> */}
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-joining"
											className="form-label"
										>
											Date of Joining{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-joining"
											value={data.date_of_joining}
											required
											type="date"
											name="date_of_joining"
											className="form-control"
											onChange={handlechange}
										/>
									</div> */}

									{master === true ? (
										<>
											<div className="col-span-12 md:col-span-6 mt-3 mb-3">
												<label
													htmlFor="update-profile-form-2"
													className="form-label"
												>
													Organization{" "}
													<span className="text-danger">
														(*)
													</span>{" "}
												</label>
												<select
													// style={{
													//   fontSize: "14px",
													//   width: "100%",
													//   padding: "8px",
													//   borderRadius: "8px",
													//   borderColor: "red",
													// }}
													name="organization"
													value={data.organization}
													required
													className="form-select"
													onChange={handlechange}
												>
													<option value="" key={-1}>
														Select Organization
													</option>
													{getdata.length > 0 &&
														getdata.map(
															(ele, i) => {
																return (
																	<option
																		value={
																			ele.uuid
																		}
																		key={i}
																	>
																		{
																			ele.name
																		}
																	</option>
																);
															}
														)}
												</select>
											</div>
										</>
									) : null}

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Email Address{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.email}
											type="email"
											required
											name="email"
											className="form-control"
											onChange={handlechange}
										/>
									</div>

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Password{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.password}
											type="password"
											required
											name="password"
											className="form-control"
											onChange={handlechange}
										/>
									</div> */}

									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<div className="">
											<label>Status</label>
											<div className="form-switch mt-2">
												{loading ? (
													<div className="text-center m-5 p-5">
														<HashLoader
															color="#5755d9"
															size={30}
															style={{
																position:
																	"absolute",
																right: "50%",
															}}
															HashLoader
														/>
													</div>
												) : data &&
													data.status === false ? (
													<input
														type="checkbox"
														name="status"
														onChange={handlechange}
														className="form-check-input"
													/>
												) : (
													<input
														type="checkbox"
														name="status"
														onChange={handlechange}
														value={data.status}
														checked
														className="form-check-input"
													/>
												)}
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => savedata()}
									className="btn btn-primary w-20 mt-3"
								>
									Save
								</button>
								<button
									type="button"
									onClick={() => adddata(1)}
									className="btn btn-danger w-20 mt-3 ml-2"
								>
									Cancel
								</button>
								<br />
								<br />
								<span
									className={
										msg.type === "success"
											? "text-success"
											: "text-danger"
									}
								>
									{msg.msg ? msg.msg : null}
								</span>
							</form>
						</div>
					) : null}
				</div>
				{/* <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex mt-5 sm:mt-0">
						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
					</div>
					{master === true ? (
						<div className="flex mt-5 sm:mt-0">
							<select
								name="uuid"
								onChange={handlechange}
								className="form-control"
								id=""
							>
								<option value="1">Active</option>
								<option value="2">In-Active</option>
							</select>
						</div>
					) : null}
					<div className="hidden md:block ml-auto text-slate-500">
						Showing {itemOffset + 1} to{" "}
						{userdata.length > itemOffset + 10
							? itemOffset + 10
							: userdata.length}{" "}
						of {userdata.length} entries
					</div>
				</div> */}
				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						<div
							style={{
								// border: "1px solid #FC9A30",
								// padding: "10px",
								borderTopLeftRadius: "8px",
								borderTopRightRadius: "8px",
							}}
						>
							<div
								style={{
									backgroundColor: "#FC9A30",
									color: "#fff",
									padding: "12px",
									textAlign: "start",
									borderTopLeftRadius: "8px",
									borderTopRightRadius: "8px",
								}}
							>
								<h3>
									Employees
								</h3>
							</div>
							<div className="table-outlineBorder" style={{
								border: "1px sold #FC9A30 !important",
							}}>
								<div className="employee-table-container">
									<table
										className="employee-table"
										// className="table table-bordered"
										style={{ width: "100%" }}
									>
										<thead>
											<tr>
												<th className="whitespace-nowrap">#</th>
												<th className="whitespace-nowrap">Name</th>
												<th className="whitespace-nowrap">
													Username
												</th>
												{/* <th className="whitespace-nowrap">Email</th>
												<th className="whitespace-nowrap">
													Contact No.
												</th> */}
												<th className="whitespace-nowrap">
													Contacts
												</th>
												<th className="whitespace-nowrap">
													{master !== 1
														? "Department"
														: "Organization"}
												</th>
												<th className="whitespace-nowrap">
													Status
												</th>
												{/* <th className="whitespace-nowrap">
													Actions
												</th> */}
											</tr>
										</thead>
										<tbody>
											{currentItems && currentItems.length === 0 && (
												<tr>
													<td colSpan="8">No data available</td>
												</tr>
											)}
											{currentItems &&
												currentItems.length > 0 &&
												currentItems.map((item, i) => {
													return (
														<tr key={i}>
															<td className="text-left">
																{userdata[item].user_id}
															</td>
															<td className="text-left">
																<Link
																	target="_blank"
																	to={
																		"/employees/" +
																		userdata[item].uuid
																	}
																>
																	<u>
																		{
																			userdata[item]
																				.name
																		}
																	</u>
																</Link>
															</td>
															<td className="text-left">
																{userdata[item].username}
															</td>
															{/* <td>{userdata[item].email}</td>
															<td>
																{userdata[item].contact_no}
															</td> */}
															<td className="text-left">
																<div className="icon-container">
																	<div className="icon" data-tooltip={userdata[item].email}>
																		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<rect x="2.5" y="4.5" width="19" height="15" rx="1.17" stroke="#FC9A30" />
																			<path d="M21 5L13 11.2C12.4 11.7 11.6 11.7 11 11.2L3 5" stroke="#FC9A30" />
																		</svg>
																	</div>
																	<div className="icon" data-tooltip={userdata[item].contact_no}>
																		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path fill-rule="evenodd" clip-rule="evenodd" d="M18.964 18.7165L15.363 15.1159L16.2995 14.1798L19.9004 17.7802C19.9004 17.7802 18.9642 18.7168 18.964 18.7165ZM17.6813 19.1921C16.8882 19.9851 15.4865 20.1161 13.7343 19.5609C11.8587 18.9666 9.85531 17.6691 8.09315 15.9072C6.33099 14.1453 5.03326 12.1422 4.43913 10.2672C3.88386 8.51528 4.01487 7.11354 4.80774 6.32028H4.808L4.90724 6.22079L7.7017 9.01508L7.44547 9.27154C7.44547 9.2718 7.4452 9.27206 7.44494 9.27206C6.38323 10.3349 7.65891 12.7226 9.46859 14.532C10.823 15.8862 12.5014 16.9415 13.706 16.9415C14.1111 16.9415 14.4623 16.822 14.7301 16.5548C14.7301 16.5545 14.7301 16.5545 14.7301 16.5545L14.9864 16.2983L17.7808 19.0926L17.6813 19.1921ZM6.21946 4.10234L9.82071 7.70276L8.88476 8.63961L8.88423 8.63909L8.87084 8.6257L7.08348 6.83888L5.28325 5.03867L6.21946 4.10234ZM17.0789 13.4002C16.6491 12.9705 15.9497 12.9705 15.5199 13.4002L14.5834 14.3365C14.2899 14.63 14.1972 15.0486 14.3043 15.4217L13.951 15.775C13.951 15.7752 13.951 15.7752 13.9507 15.7752C13.7 16.0265 12.1373 15.6411 10.2484 13.7527C8.35993 11.8643 7.97453 10.3022 8.22525 10.051L8.57889 9.69737C8.67892 9.72598 8.78183 9.74068 8.88475 9.74068C9.16724 9.74068 9.44947 9.63306 9.66449 9.41833L10.601 8.48227C11.0307 8.0523 11.0307 7.35326 10.601 6.92329L6.99972 3.32287C6.79153 3.11471 6.51481 3 6.22024 3C5.92567 3 5.64922 3.11471 5.44076 3.32287L4.50429 4.2592C4.21103 4.55267 4.11835 4.97162 4.22521 5.34463C4.22521 5.34463 3.92591 5.64677 3.91095 5.66593C2.91488 6.77288 2.72795 8.51613 3.38823 10.6001C4.03513 12.6408 5.42921 14.8022 7.31398 16.6866C9.19875 18.5708 11.3608 19.965 13.4015 20.6118C14.2214 20.8714 14.9883 21 15.6848 21C16.8266 21 17.7788 20.6538 18.4611 19.9715L18.6575 19.7749C18.7559 19.8035 18.8586 19.8185 18.9639 19.8185C19.2584 19.8185 19.5352 19.704 19.7434 19.4959L20.6798 18.5593C21.1096 18.1298 21.1096 17.4306 20.6798 17.0006L17.0789 13.4002Z" fill="#24A184" />
																			<path fill-rule="evenodd" clip-rule="evenodd" d="M17.1924 10.8233C17.1924 11.0783 17.4075 11.2853 17.6729 11.2853C17.9381 11.2853 18.1531 11.0783 18.1531 10.8233C18.1531 9.58082 17.65 8.41273 16.7365 7.53419C15.8229 6.65565 14.6083 6.1716 13.3164 6.1716C13.051 6.1716 12.8359 6.37842 12.8359 6.63364C12.8359 6.88865 13.051 7.09547 13.3164 7.09547C14.3516 7.09547 15.3249 7.48314 16.0572 8.18743C16.7893 8.8915 17.1924 9.82769 17.1924 10.8233Z" fill="#24A184" />
																			<path fill-rule="evenodd" clip-rule="evenodd" d="M18.4166 5.27623C16.8333 3.80836 14.7281 3 12.4893 3C12.1834 3 11.9355 3.23002 11.9355 3.51332C11.9355 3.79663 12.1834 4.02665 12.4893 4.02665C14.4325 4.0264 16.2595 4.72795 17.6338 6.00197C19.0078 7.27599 19.7645 8.96948 19.7645 10.771C19.7645 11.0548 20.0124 11.2843 20.3183 11.2843C20.6239 11.2843 20.8717 11.0548 20.8717 10.771C20.872 8.69546 19.9997 6.74409 18.4166 5.27623Z" fill="#24A184" />
																			<path fill-rule="evenodd" clip-rule="evenodd" d="M13.2693 8.76987C13.0299 8.76987 12.8359 8.95755 12.8359 9.1896C12.8361 9.42125 13.0301 9.60913 13.2693 9.60893H13.2695C13.626 9.60893 13.9351 9.73285 14.1875 9.9775C14.4361 10.2185 14.5676 10.5259 14.5676 10.8667C14.5676 11.0984 14.7615 11.2865 15.0009 11.2865C15.24 11.2865 15.434 11.0984 15.434 10.8667C15.4342 10.2985 15.2149 9.78602 14.7999 9.38388C14.3852 8.98213 13.8559 8.76987 13.2693 8.76987Z" fill="#24A184" />
																		</svg>

																	</div>
																	<div className="icon" data-tooltip={userdata[item].permanent_address}>
																		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																			<path d="M7 7.76521C7 5.13346 9.13346 3 11.7652 3H12.2348C14.8665 3 17 5.13346 17 7.76521V7.76521C17 8.57503 16.7936 9.37149 16.4003 10.0794L12.8742 16.4265C12.4932 17.1123 11.5068 17.1123 11.1258 16.4265L7.59967 10.0794C7.20638 9.37149 7 8.57503 7 7.76521V7.76521Z" stroke="#1D46AC" />
																			<path d="M8 16.4481C6.18652 16.8999 5 17.6505 5 18.5C5 19.8807 8.13401 21 12 21C15.866 21 19 19.8807 19 18.5C19 17.6505 17.8135 16.8999 16 16.4481" stroke="#1D46AC" />
																			<circle cx="12" cy="8" r="2" stroke="#1D46AC" />
																		</svg>
																	</div>
																</div>
															</td>
															<td className="text-left">
																{master !== 1
																	? userdata[item]
																		.deptname
																	: renderOrgName(
																		userdata[item]
																			.company_uuid ||
																		userdata[
																			item
																		].uuid
																	)}
															</td>
															{/* <td className="text-left">
																{userdata[item].active === 1
																	? "Active"
																	: "Inactive"} 
															</td> */}

															<td className="text-left">
																<div className="flex items-center relative">
																	<span>{userdata[item].active === 1 ? "Active" : "Inactive"}</span>
																	<button
																		className="ml-2 bg-gray-200 text-gray-700 hover:bg-gray-300 p-1 rounded"
																		onClick={() => toggleDropdown(userdata[item].uuid)}
																	>
																		▼
																	</button>
																	{openDropdown === userdata[item].uuid && (
																		<div
																			className="absolute top-full mt-2 w-25 bg-white border border-gray-300 rounded shadow-lg z-10"
																		>
																			{userdata[item].type !== 0 || master === 1 ? (
																				<>
																					<div style={{
																						fontSize: "16px",
																						cursor: "pointer"
																					}} className="text-right  mr-2" onClick={() => closeDropdown()}>
																						x
																					</div>
																					<button
																						className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center"
																						onClick={() => {
																							handleEdit(userdata[item].uuid);
																							closeDropdown();
																						}}
																					>
																						<FaEdit className="mr-2" style={{ color: "gray" }} /> Edit
																					</button>
																					{master === 1 && (
																						<button
																							className="w-full text-left px-4 py-2 hover:bg-gray-100 flex text-red-600 flex items-center"
																							onClick={() => {
																								deletedata(userdata[item].uuid);
																								closeDropdown();
																							}}
																						>
																							<FaTrashAlt className="mr-2" style={{ color: "gray" }} /> Delete																						</button>
																					)}
																					{master === 1 && (
																						<button
																							className="w-full text-left px-4 py-2 hover:bg-gray-100 flex text-blue-500 flex items-center"
																							onClick={() => {
																								signindata(userdata[item]);
																								closeDropdown();
																							}}
																						>
																							<FaSignInAlt
																								className="mr-2"
																								style={{ color: "gray" }}
																							/>
																							<span>Login</span>
																						</button>
																					)}
																				</>
																			) : (
																				<span className="block px-4 py-2 text-gray-400">
																					No Actions Available
																				</span>
																			)}
																		</div>
																	)}
																</div>
															</td>

															{/* <td className="text-left">
																<div className="flex lg:justify-start items-center cursor-pointer">
																	{userdata[item].type !==
																		0 ||
																		master === 1 ? (
																		<> */}
															{/* <FaEdit
																	title="Edit"
																	style={{
																		color: "gray",
																	}}
																	className="mr-2"
																	onClick={() =>
																		editdata(
																			userdata[
																				item
																			]
																		)
																	}
																/> */}
															{/* <FaEdit
																				title="Edit"
																				style={{
																					color: "gray",
																				}}
																				className="mr-2"
																				onClick={() => {
																					// setIsOpen(true)
																					handleEdit(userdata[item].uuid)
																					// const editUrl = `/employees/Editemployee/${userdata[item].uuid}`;
																					// window.location.href =
																					// 	editUrl;
																				}}
																			/>
																			{master ===
																				1 ? (
																				<FaTrashAlt
																					onClick={() => deletedata(userdata[item].uuid)}

																					title="Delete"
																					className="text-danger mr-2"
																				/>
																			) : null}
																			{master ===
																				1 ? (
																				<FaSignInAlt
																					onClick={() =>
																						signindata(
																							userdata[
																							item
																							]
																						)
																					}
																					title={
																						"Sign In as " +
																						userdata[
																							item
																						]
																							.name
																					}
																					style={{
																						color: "rgb(64 96 255)",
																					}}
																					className="mr-2"
																				/>
																			) : null}
																		</>
																	) : (
																		"-"
																	)}
																</div>
															</td> */}
														</tr>
													);
												})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}

					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={5} // Number of page buttons visible
											marginPagesDisplayed={2}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : (
								<HashLoader
									color="#5755d9"
									size={30}
									style={{
										position: "absolute",
										right: "50%",
									}}
								/>
							)}
						</nav>
					</div>
				</div>
			</div>
			<CommonModal isOpen={isOpen} content={modalContent} />
		</div>
	);
};

export default Departments;
