import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import {
	FaCheckCircle
} from "react-icons/fa";
import { NativeSelect } from "@mui/material";
const Departments = () => {
	const [showTicket, setShowTicket] = useState([]);
	const [attdata, setattdata] = useState(null);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState([]);
	const [userdata, setuserdata] = useState([]);
	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	useEffect(() => {
		// fetchData();
		fetchTickets();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 20;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 20));
	}, [itemOffset, items]);

	const getUserData = async (e = "") => {
		let isactive = 1;
		if (e !== "") {
			isactive = e.target.value;
		}

		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-Employee/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				master,
				isactive,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					setuserdata(res.data);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};
	useEffect(() => {
		getUserData();
	}, []);

	const handleApprove = async (ticket) => {
		console.log("Respone from the ticket", ticket)
		const willApprove = await swal({
			title: "Are you sure?",
			text: "Do you want to approve this ticket?",
			icon: "warning",
			buttons: true,
			dangerMode: false,
		});

		if (!willApprove) return;

		setLoading(true);
		try {
			const authData = JSON.parse(localStorage.getItem("isAuth"));
			const userToken = authData?.usertoken;
			console.log("user token", userToken)

			if (!userToken) {
				swal("Error", "Authorization token is missing", "error");
				setLoading(false);
				return;
			}

			const payload = {
				attendance_id: ticket.attendance_id,
				status: 1,
				start_time: ticket.start_time || "00:00:00",
				end_time: ticket.end_time || "00:00:00",
				company_uuid: ticket.company_uuid,
				user_uuid: ticket.user_uuid,
			};

			const response = await fetch(`${BASEURL}update-ticket-otherbreak`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userToken}`,
				},
				body: JSON.stringify(payload),
			});

			const result = await response.json();

			if (response.status === 200) {
				fetchTickets()
				swal("Success", result.message, "success");
				// fetchTickets();
			} else {
				swal("Error", result.error || "Failed to approve ticket", "error");
			}
		} catch (error) {
			console.error("Error:", error);
			swal("Error", "Something went wrong! Please try again.", "error");
		}

		setLoading(false);
	};

	const handleReject = async (ticket) => {
		// Show confirmation prompt using SweetAlert
		const willReject = await swal({
			title: "Are you sure?",
			text: "Do you want to reject this ticket?",
			icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					className: "swal-button-cancel",
				},
				confirm: {
					text: "Reject",
					value: true,
					visible: true,
					className: "swal-button-reject",
				},
			},
			className: "custom-swal",
			dangerMode: true,
		});

		if (!willReject) return;

		setLoading(true);

		try {
			const authData = JSON.parse(localStorage.getItem("isAuth"));
			const userToken = authData?.usertoken;

			if (!userToken) {
				swal("Error", "Authorization token is missing", "error");
				setLoading(false);
				return;
			}

			const payload = {
				attendance_id: ticket.attendance_id,
				status: 2, // Assuming 2 represents "Rejected"
			};

			const response = await fetch(`${BASEURL}update-ticket-otherbreak`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${userToken}`,
				},
				body: JSON.stringify(payload),
			});

			const result = await response.json();

			if (response.status === 200) {
				swal("Rejected", result.message, "success");
				fetchTickets();
			} else {
				swal("Error", result.error || "Failed to reject ticket", "error");
			}
		} catch (error) {
			console.error("Error rejecting ticket:", error);
			swal("Error", "An error occurred while processing the request", "error");
		}

		setLoading(false);
	};


	const getStatusText = (status) => {
		switch (status) {
			case 0:
				return "Pending";
			case 1:
				return "Resolved";
			case 2:
				return "Rejected";
			default:
				return "Unknown";
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case 0:
				return "badge bg-warning";
			case 1:
				return "badge bg-success";
			case 2:
				return "badge bg-danger";
			default:
				return "badge bg-secondary";
		}
	};

	const fetchTickets = async () => {
		setLoading(true);
		const userAuth = JSON.parse(localStorage.getItem("isAuth"));
		const token = userAuth?.usertoken;
		const companyUuid = userAuth.companydata[0]?.uuid;

		if (!token || !companyUuid) {
			setLoading(false);
			return;
		}

		try {
			const response = await fetch(BASEURL + "getTickets", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify({ uuid: companyUuid }),
			});

			const res = await response.json();
			if (response.status === 201) {
				setShowTicket(res.data);
			}
		} catch (err) {
			console.log("Error fetching tickets:", err);
		}
		setLoading(false);
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 20) % items.length;
		setItemOffset(newOffset);
	};

	return (
		<div className="intro-y ">
			<div className="mt-2">

				<div
					style={{
						// padding: "10px",
						borderTopLeftRadius: "10px",
						borderTopRightRadius: "10px",
					}}
				>
					<div
						style={{
							backgroundColor: "#FC9A30",
							color: "#fff",
							padding: "10px",
							textAlign: "start",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
						}}
					>
						<h3>
							Employees Other Break Ticket
						</h3>
					</div>
					<div className="table-outlineBorder" style={{
						border: "1px sold #FC9A30 !important",
					}}>
						{loading ? (
							<HashLoader color="#5755d9" size={30} />
						) : (
							<table
								className="employee-table"
								style={{ width: "100%" }}
							>
								<thead>
									<tr>
										<th>Name</th>
										<th>Date</th>
										<th>Break Start Time</th>
										<th>Break End Time</th>
										<th>Status</th>
										<th>Reason</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{showTicket.length === 0 ? (
										<tr>
											<td colSpan="11">No data available</td>
										</tr>
									) : (
										showTicket.map((ticket) => (
											<tr key={ticket.id}>


												{/* <td>name</td> */}
												<td>{ticket.user}</td>
												{/* <td>{ticket.id}</td>
												<td>{ticket.username}</td>
												<td>
													{new Date(
														ticket.created_at
													).toLocaleString()}
												</td> */}
												<td>
													{new Date(
														ticket.date
													).toLocaleDateString()}
												</td>
												<td>{ticket?.start_time}</td>
												<td>{ticket?.end_time}</td>


												<td>
													<span
														className={`px-2 py-1 rounded text-white ${getStatusColor(
															ticket.status
														)}`}
													>
														{getStatusText(
															ticket.status
														)}
													</span>
												</td>
												<td>{ticket?.message || "None"}</td>


												<td>
													<select
														defaultValue={ticket.status === 0 ? "On Hold" : ticket.status === 1 ? "Approved" : "Reject"}
														onChange={(e) => {
															const selectedValue = e.target.value;
															if (selectedValue === "Approved") {
																handleApprove(ticket);
															} else if (selectedValue === "Reject") {
																handleReject(ticket);
															}
														}}
													>
														<option value="Approved">Approved</option>
														<option value="On Hold">On Hold</option>
														<option value="Reject">Reject</option>
													</select>
												</td>
												{/* <td className="flex justify-center items-center">
													{ticket?.status === 0 ? (
														<>
															<div className="mr-4">
																<button
																	className="btn btn-primary text-white btn-sm"
																	onClick={() =>
																		handleApprove(
																			ticket.id,
																			ticket.punch_start_time,
																			ticket.punch_end_time,
																			ticket.lunch_start_time,
																			ticket.lunch_end_time,
																			ticket.break_start_time,
																			ticket.break_end_time,
																			ticket.date,
																			ticket.username,
																			JSON.parse(localStorage.getItem("isAuth")).companydata[0]?.uuid,
																			ticket.user_department,
																			ticket.user_type
																		)
																	}
																>
																	Approve
																</button>
															</div>
															<div>
																<button
																	className="btn btn-danger text-white btn-sm"
																	onClick={() => handleReject(ticket.id)}
																>
																	Reject
																</button>
															</div>
														</>
													) : (
														<div className="flex items-center justify-center text-green-500">
															<FaCheckCircle className="text-lg mr-2" />
															<span>Action Done</span>
														</div>
													)}
												</td> */}
											</tr>
										))
									)}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Departments;
