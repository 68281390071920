import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { BASEURL } from "./BASEURL";
import { CSVLink } from "react-csv";
import { FaCalendar, FaFileCsv, FaDownload, FaTimes } from "react-icons/fa";
import { HashLoader } from "react-spinners";
import Modal from "react-modal";
import { useLoadingContext } from "react-router-loading";
import moment from "moment";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		width: "60%",
		padding: "0%",
		borderRadius: "5px",
	},
};


const headers = [
	{ label: "Date", key: "date" },
	{ label: "In Time", key: "start_time" },
	{ label: "Late", key: "late_coming" },
	{ label: "Lunch HRS", key: "lunch_break_duration" },
	{ label: "Out Time", key: "end_time" },
	{ label: "Pending HRS", key: "pending_time" },
	{ label: "Total HRS", key: "total_working_hours" },
	{ label: "Other Break", key: "other_break_duration" },
	{ label: "After Office HRS", key: "after_office_hours" },
	{ label: "1st/2nd Half", key: "half_day_type" },
]

const headersM = [
	{ label: "Name", key: "name" },
	{ label: "Username", key: "username" },
	{ label: "Month Days", key: "totalDays" },
	{ label: "Non WD", key: "weekOffs" },
	{ label: "Absent", key: "absentDays" },
	{ label: "Working Days", key: "totalWorkingDays" },
	{ label: "Sandwich Days", key: "sandwichLeave" },
	{ label: "Present Days", key: "presentDays" },
	{ label: "Month Leaves", key: "monthLeaves" },
	{ label: "Eligible for PL", key: "isEligibleForPL" },
];


const AttendanceReport = () => {
	const [departments, setDepartments] = useState([]);
	// const [pendingHours, setPendingHours] = useState("00:00:00");
	// const [totalHours, setTotalHours] = useState("00:00:00");
	const [totalAfterOfficeHours, setTotalAfterOfficeHours] = useState("00:00:00");
	const loadingContext = useLoadingContext();
	const [alluser, setalluser] = useState(null);
	const [data, setData] = useState([
		{ value: "", label: "Select All", active: "", uuid: "" },
	]);
	const [selectedDepartment, setSelectedDepartment] =
		useState("All department");
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
	const [isVisible, setIsVisible] = useState(false);
	const [userWorkTypeModal, setUserWorkTypeModal] = useState()

	const handleOnChange = async (year, month) => {
		setSelectedMonth({ year, month });
		setIsVisible(false);
		await fetchAttendanceData(selectedDepartment, month, year)
		if (selectedval?.uuid) {
			await getMonthWiseData(selectedval.uuid, month, year);
		}
	};

	const showMonthPicker = (e) => {
		e.preventDefault();
		setIsVisible(true);
	};

	const handleOnDismiss = () => {
		setIsVisible(false);
	};

	const getMonthValue = () => {
		return `${selectedMonth.month}/${selectedMonth.year}`;
	};
	const [selectedButton, setSelectedButton] = useState(true)
	// const userType = user_data?.type;
	// const [selectedval, setselectedval] = useState(
	// 	userType !== 0 ? { uuid: userUuid } : ""
	// );
	// const userUuid = user_data?.uuid;
	// const userType = user_data?.type;
	const [activeTab, setActiveTab] = useState('Department'); // Track which tab is active
	const data1 = [
		{ uuid: '1', label: 'John Doe' },
		{ uuid: '2', label: 'Jane Smith' },
	];

	const departments1 = [
		{ department_id: '1', deptname: 'HR' },
		{ department_id: '2', deptname: 'Engineering' },
	];

	const user_data = JSON.parse(localStorage.getItem("isAuth"));
	const userUuid = user_data?.uuid;
	const userType = user_data?.type;

	const [selectedval, setselectedval] = useState(
		userType !== 0 ? { uuid: userUuid } : ""
	);

	const comp_uuid = user_data.companydata[0].uuid;
	// const [selectedval, setselectedval] = useState(
	// 	userType !== 0 ? { uuid: userUuid } : ""
	// );
	// const user_data = JSON.parse(localStorage.getItem("isAuth"));
	// const userUuid = user_data?.uuid;
	const [attendanceData, setAttendanceData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [username, setusername] = useState();
	const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
	const [departmentLoading, setdepartmentLoading] = useState(false);
	const [departmentChangeLoading, setdepartmentChangeLoading] = useState(false);
	const [showDepartmentSelectuser, setShowDepartmentSelectuser] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [newModalIsOpen, setnewModalIsOpen] = useState(false);
	const [attdata, setAttdata] = useState([]);
	const [pendingHours, setPendingHours] = useState();
	const [totalHours, setTotalHours] = useState();
	const [shiftStartTime, setshiftStartTime] = useState();
	const [shiftEndTime, setshiftEndTime] = useState();
	const [totalAfterHours, setTotalAfterHours] = useState(0);
	const [selectedEmpName, setSelectedEmpName] = useState("");
	const [isLoad, setIsLoad] = useState(false);
	const [userdatalist, setuserdata] = useState([]);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const closeModal = () => setnewModalIsOpen(false);

	const convertToMinutes = (duration) => {
		const match = duration.match(/(\d+)h\s*(\d+)?m?/);
		if (!match) return 0;
		const hours = parseInt(match[1], 10) || 0;
		const minutes = parseInt(match[2], 10) || 0;
		return hours * 60 + minutes;
	};
	useEffect(() => {
		if (attdata?.length > 0) {
			const totalMinutes = attdata.reduce((total, row) => {
				return total + (row.after_office_hours?.reduce(
					(sum, session) => sum + convertToMinutes(session.duration),
					0
				) || 0);
			}, 0);

			const formattedTotal = `${Math.floor(totalMinutes / 60)
				.toString().padStart(2, "0")}:${(totalMinutes % 60).toString().padStart(2, "0")}`;

			setTotalAfterOfficeHours(formattedTotal);
		}
	}, [attdata]);

	const totalAfterOfficeMinutes = attdata?.reduce((total, row) => {
		return (
			total +
			(row.after_office_hours?.reduce(
				(sum, session) => sum + convertToMinutes(session.duration),
				0
			) || 0)
		);
	}, 0);

	// const totalAfterOfficeHours = `${Math.floor(totalAfterOfficeMinutes / 60)
	// 	.toString()
	// 	.padStart(2, "0")}:${(totalAfterOfficeMinutes % 60).toString().padStart(2, "0")}`

	const handleViewDetails = async (uuid) => {
		setnewModalIsOpen(true);
		setIsLoad(true);

		try {
			await getMonthWiseData(uuid);
		} catch (err) {
			console.error("Error fetching details:", err.message);
			setModalIsOpen(false);
		} finally {
			setIsLoad(false);
		}
	};
	function formatTime(time) {
		if (!time || typeof time !== "string") return "";
		return time.slice(0, 2) + ":" + time.slice(3, 5);
	}
	const handleDepartmentChange = async (event) => {
		const selectedValue = event.target.value;
		setSelectedDepartment(selectedValue);

		try {
			getUserData("", selectedValue)
			await fetchAttendanceData(selectedValue);
		} catch (error) {
			console.error("Error fetching attendance data:", error.message);
		}
	};


	const getUserData = async (org = "", departmentId = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(BASEURL + `admin-getAllUsers/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					type: 1,
					uuid: org || selectedorg,
					usertype: userdata.type,
					department: departmentId || "",
				}),
			});

			if (!response.ok) {
				throw new Error(
					`Error: ${response.status} - ${response.statusText}`
				);
			}

			const res = await response.json();

			if (res.status === 201) {
				// Filter out users from department 34
				// const filteredData = res.data.filter((user) => user.department !== 34);
				const filteredData = res?.data.filter(
					(item) => item.department !== 34 && item.username !== "1119"
				);

				setuserdata(filteredData);
				setalluser(res.alluser);

				const userOptions = filteredData?.map((user) => ({
					value: user.username,
					label: user.name,
					active: user.active ? "🟢" : "🔴",
					uuid: user.uuid,
				}));

				userOptions.unshift({
					value: "",
					label: "Select Employee",
					active: "",
				});
				setData(userOptions);
				if (res.alluser.length > 0 && filteredData.length > 0) {
					setusername(res.alluser[0]["username"]);
				}
			}

			setLoading(false);
		} catch (err) {
			console.error("Error fetching user data:", err.message);
			setLoading(false);
		} finally {
			loadingContext.done();
		}
	};

	useEffect(() => {
		if (userType === 0) {
			getUserData();
		}
	}, []);

	const formatTotalHours = (totalMinutes) => {
		const hours = Math.floor(totalMinutes / 60)
			.toString()
			.padStart(2, "0");
		const minutes = (totalMinutes % 60).toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	};

	const getMonthWiseData = async (uuid, month = selectedMonth.month, year = selectedMonth.year) => {
		setIsLoad(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");
		try {
			const response = await fetch(`${BASEURL}get-monthwise-attendance/${uuid}`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					month,
					year,
					company_uuid: selectedorg,
				}),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status} - ${response.statusText}`);
			}

			const result = await response.json();
			setPendingHours(result?.total_pending_time);
			setTotalHours(result?.totalTime?.totalWorkingHours);
			setshiftEndTime(result?.shiftEndTime);
			setshiftStartTime(result?.shiftStartTime);
			setUserWorkTypeModal(result?.userWorkType)
			const total = result?.attendance
				?.flatMap((row) => row?.after_office_hours || [])
				.reduce((sum, afterHours) => {
					const duration = afterHours?.duration || "0h 0m";
					const [hoursPart, minutesPart] = duration.split("h").map((part) => part.trim());

					const hours = parseInt(hoursPart) || 0;
					const minutes = parseInt(minutesPart?.replace("m", "")) || 0;

					return sum + hours * 60 + minutes;
				}, 0);
			setTotalAfterHours(total || 0);
			setSelectedEmpName(result?.attendance?.[0]?.name || "NA");
			setAttdata(result.attendance);
		} catch (err) {
			console.error("Error fetching month-wise data:", err.message);
		} finally {
			setIsLoad(false);
		}
	};

	const csvData = attdata.map((row) => ({
		date: row?.date ? moment(row.date).format("DD/MM/YY") : "-",
		start_time: row?.start_time || "-",
		late_coming: row?.late_coming || "-",
		lunch_break_duration: row?.lunch_break_duration || "-",
		end_time: row?.end_time || "-",
		pending_time: row?.pending_time || "-",
		total_working_hours: row?.total_working_hours || "-",
		other_break_duration: row?.other_break_duration || "-",

		after_office_hours: row?.after_office_hours?.length
			? row.after_office_hours.map(session => session.duration).join(", ")
			: "-",

		half_day_type: row?.on_leave
			? "A"
			: row?.half_day_type === "First Half"
				? "A/P"
				: row?.half_day_type === "Second Half"
					? "P/A"
					: "P/P",
	}));

	const footerRow = {
		date: "Total",
		start_time: "-",
		late_coming: "-",
		lunch_break_duration: "-",
		end_time: "-",
		pending_time: pendingHours,
		total_working_hours: totalHours,
		other_break_duration: "-",
		after_office_hours: formatTotalHours(totalAfterHours),
		half_day_type: "-",
	};

	csvData.push(footerRow);

	useEffect(() => {
		const getDepartments = async () => {
			setLoading(true);
			const myHeaders = new Headers();
			let userdata = JSON.parse(localStorage.getItem("isAuth"));
			if (userdata) {
				let token = userdata.usertoken;
				myHeaders.append("Authorization", token);
			}
			myHeaders.append("Content-Type", "application/json");
			try {
				const response = await fetch(`${BASEURL}getAllDepartments/`, {
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify({
						uuid: userdata.companydata[0].uuid,
					}),
				});
				const res = await response.json();
				if (res.status === 201) {
					setDepartments(res.data);
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};
		getDepartments();
	}, []);

	const formattedDataM = attendanceData?.map(({ name, attendance_summary }) => ({
		name,
		...attendance_summary
	}));

	useEffect(() => {
		fetchAttendanceData();
	}, []);

	const fetchAttendanceData = async (departmentId = selectedDepartment, month = selectedMonth?.month, year = selectedMonth?.year) => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		const selectedMonthvalue = month;
		const selectedYear = year;
		setdepartmentLoading(true);

		try {
			const response = await fetch(
				`${BASEURL}get-monthwise-attendance/all`,
				{
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify({
						month: selectedMonthvalue,
						year: selectedYear,
						departmentId:
							departmentId === "All department" ? null : departmentId,
					}),
				}
			);
			const res = await response.json();
			if (res?.totalTime) {
				setPendingHours(res.totalTime.totalPendingTime || "00:00:00");
				setTotalHours(res.totalTime.totalWorkingHours || "00:00:00");
			}
			setTotalAfterOfficeHours(res.total_extra_time || "00:00:00");
			setAttendanceData(res.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
			setdepartmentLoading(false);
		}
	};

	const handleSearch = (event) => {
		setSearchQuery(event.target.value);
	};

	const filteredData = attendanceData?.filter((user) =>
		user.name?.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const formattedData = attendanceData?.map((user) => ({
		Name: user.name,
		Username: user.username,
		// "Month Days": user.attendance_summary.totalDays,
		// "Week Off": user.attendance_summary.weekOffs,
		// // "Public Holiday": user.attendance_summary.holidays,
		// "Public Holiday":0,
		// "Total Working Days": user.attendance_summary.totalWorkingDays,
		// "Present Days": user.attendance_summary.presentDays,
		// "Absent Days": user.attendance_summary.absentDays,
		// "Sandwich Days": user.attendance_summary.sandwichLeave,
		// "Month Leaves": user.attendance_summary.monthLeaves,
	}));

	const handleOpenModal = () => {
		setnewModalIsOpen(true)
	}

	return (
		<div className="intro-y  lg:mt-5 ">
			{/* Filters Section */}
			{/* <div className="filters-section flex items-center justify-between mb-5">
				<Select
					value={selectedDepartment}
					onChange={(e) => setSelectedDepartment(e.target.value)}
					size="small"
					style={{
						minWidth: "150px",
						marginRight: "1rem",
					}}
				>
					<MenuItem value="All department" style={{ color: "#24a184" }}>All Department</MenuItem>
					{departments.map((dept) => (
						<MenuItem key={dept.department_id} value={dept.department_id}>
							{dept.deptname}
						</MenuItem>
					))}
				</Select>

				<Button
					variant="contained"
					color="primary"
					onClick={fetchAttendanceData}
					style={{
						padding: "8px 20px",
						backgroundColor: "#1e40af",
						color: "#fff",
					}}
				>
					Get
				</Button>

				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						views={["month", "year"]}
						value={selectedDate}
						onChange={(newValue) => setSelectedDate(newValue)}
						renderInput={(params) => (
							<TextField
								{...params}
								size="small"
								sx={{
									minWidth: "180px",
									marginLeft: "auto",
								}}
							/>
						)}
					/>
				</LocalizationProvider>
			</div> */}

			<div className="filters-section flex items-center mb-5" style={{
				flexWrap: "wrap",
			}}>
				<div className="flex">
					<Button
						variant="contained"
						color=""
						onClick={() => {
							setActiveTab("Individual");
							setShowDepartmentSelectuser(false);
							setShowDepartmentSelect(true);
							setSelectedButton(true)
						}}
						style={{
							backgroundColor: selectedButton ? "#24A184" : "#fff",
							color: selectedButton ? "#fff" : "#000",
							width: "140px",
							padding: "3px 0px",
							fontSize: "12px",
							height: "30px",
							borderTopRightRadius: "0",
							borderBottomRightRadius: "0",
							// border: "1px solid #000"
						}}
						sx={{
							'.MuiSelect-select': {
								paddingTop: '2px',
								paddingBottom: '2px',
								fontSize: '0.875rem',
							},
							'.MuiMenuItem-root': {
								fontSize: '0.875rem',
								padding: '6px 10px',
							},
						}}
					>
						Individual
					</Button>

					<Button
						variant="contained"
						onClick={() => {
							setActiveTab("Individual");
							setShowDepartmentSelect(true);
							setShowDepartmentSelectuser((prev) => !prev);
							setSelectedButton(false)
						}}
						style={{
							backgroundColor: selectedButton ? "#fff" : "#24A184",
							color: selectedButton ? "#000" : "#fff",
							width: "140px",
							padding: "3px 0px",
							marginRight: "10px",
							fontSize: "12px",
							height: "30px",
							borderTopLeftRadius: "0",
							borderBottomLeftRadius: "0",
						}}
						sx={{
							'.MuiSelect-select': {
								paddingTop: '2px',
								paddingBottom: '2px',
								fontSize: '0.875rem',
							},
							'.MuiMenuItem-root': {
								fontSize: '0.875rem',
								padding: '6px 10px',
							},
						}}
					>
						ALl Department
					</Button>

					<CSVLink
						data={formattedDataM}
						headers={headersM}
						filename={"attendance_summary.csv"}
						className="btn btn-light"
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
							<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
							<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
						</svg>
					</CSVLink>
				</div>

				{/* Department Dropdown */}
				{/* {activeTab === "Individual" && showDepartmentSelectuser && (
					<Select
						value={selectedDepartment || "All department"}
						onChange={handleDepartmentChange} // Correctly pass the event
						size="small"
						style={{
							minWidth: "150px",
							marginRight: "1rem",
							padding: "0px",
							fontSize: "12px", // Reduced padding
						}}
					>
						<MenuItem value="All department" style={{ color: "#24a184" }}>
							All Department
						</MenuItem>
						{departments.map((dept) => (
							<MenuItem key={dept.department_id} value={dept.department_id}>
								{dept.deptname}
							</MenuItem>
						))}
					</Select>
				)} */}

				{activeTab === "Individual" && showDepartmentSelectuser && (
					<div>
						{/* <Select
							value={selectedval?.uuid || ""}
							onChange={(e) => {
								const selectedUser = data.find((item) => item.uuid === e.target.value);
								setselectedval(selectedUser || null);
							}}
							size="small"
							displayEmpty
							style={{
								minWidth: "150px",
								marginRight: "1rem",
							}}
						>
							<MenuItem value="" style={{ color: "#24a184" }}>
								Select Employee
							</MenuItem>
							{data.map((item) => (
								<MenuItem key={item.uuid} value={item.uuid}>
									{item.label}
								</MenuItem>
							))}
						</Select> */}
						{/* <Select
							value={selectedval?.uuid || ""}
							onChange={async (e) => {
								setnewModalIsOpen(true)
								handleOpenModal()
								const selectedUser = data.find((item) => item.uuid === e.target.value);
								setselectedval(selectedUser || null);
								if (selectedUser) {
									await getMonthWiseData(selectedUser.uuid);
									// setModalIsOpen(true);
								}
							}}
							size="small"
							displayEmpty
							style={{
								minWidth: "150px",
								marginRight: "1rem",
								padding: "0px",
								fontSize: "12px" // Reduced padding
							}}
						>
							<MenuItem value="" style={{ color: "#24a184" }}>
								Select Employee
							</MenuItem>
							{data.map((item) => (
								<MenuItem key={item.uuid} value={item.uuid}>
									{item.label}
								</MenuItem>
							))}
						</Select> */}


					</div>
				)}

				<div style={{ marginLeft: "auto" }}>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							views={["month", "year"]}
							value={selectedDate}
							onChange={(newValue) => setSelectedDate(newValue)}
							renderInput={(params) => (
								<TextField
									{...params}
									size="small"
									sx={{
										minWidth: "80px", // Reduced width
										"& .MuiInputBase-root": {
											fontSize: "10px", // Smaller font size
											padding: "0px 8px", // Reduced padding
										},
										"& .MuiSvgIcon-root": {
											fontSize: "12px", // Smaller icon size
										},
									}}
								/>
							)}
						/>
					</LocalizationProvider> */}

					{/* <button
						onClick={() => setIsVisible(true)} // Show the picker on button click
						style={{
							fontSize: "10px",
							padding: "4px 8px",
							minWidth: "80px",
						}}
					>
						{`${selectedMonth.month}/${selectedMonth.year}`}
					</button> */}
					<div className="flex">
						<div>
							<input
								type="text"
								className="mr-1"
								placeholder="Search"
								style={{
									fontSize: "12px",
									height: "37px",
									borderRadius: "5px",
									width: "200px",
								}}
								value={searchQuery}
								onChange={handleSearch}
							/>
						</div>
						<div className="input-group mr-2 calenderdate">
							<input
								type="text"
								style={{ fontSize: "12px" }}
								onFocus={(e) => showMonthPicker(e)}
								value={getMonthValue()}
								className="form-control calenderdate1"
								id="basic-url"
								aria-describedby="basic-addon3"
								onChange={() => { }}
							/>
							<div className="input-group-append calendaricon">
								<FaCalendar
									style={{
										fontSize: "12px",
									}}
									onClick={(e) => showMonthPicker(e)}
								/>
							</div>
							<ReactMonthPicker
								show={isVisible}
								lang={[
									"Jan",
									"Feb",
									"Mar",
									"Apr",
									"May",
									"Jun",
									"Jul",
									"Aug",
									"Sep",
									"Oct",
									"Nov",
									"Dec",
								]}
								value={selectedMonth}
								onChange={handleOnChange}
								onDismiss={handleOnDismiss}
							/>
						</div>
					</div>
				</div>
			</div>

			{departmentLoading ? (
				<div className="loader-container flex justify-center content-center items-center" style={{ height: "100vh" }}>
					<HashLoader color="#5755d9" className="mt-8" size={30} />
				</div>
			) : (
				<>
					<div className="attendance-table">
						<div
							className="rounded-t-lg"
							style={{
								backgroundColor: "#fc9a30",
								padding: "8px 16px",
								borderRadius: "10px 10px 0 0",
							}}
						>
							<h2 className="font-medium text-lg text-white text-left">Attendance Report  </h2>
						</div>
						{filteredData?.length > 0 ? (
							<table
								className="table-auto w-full border-collapse border border-gray-200 text-sm rounded-b-lg"
								style={{ borderRadius: "10px" }}
							>
								<thead className="bg-gray-100">
									<tr>
										<th className="border border-gray-200 p-2 text-left">Name</th>
										<th className="border border-gray-200 p-2 text-left">Working Days</th>
										<th className="border border-gray-200 p-2 text-left">Sandwich Days</th>
										<th className="border border-gray-200 p-2 text-left">Present Days</th>
										<th className="border border-gray-200 p-2 text-left">Month Leaves</th>
										<th className="border border-gray-200 p-2 text-left">Non WD</th>
										<th className="border border-gray-200 p-2 text-center">Eligible for PL</th>
										<th className="border border-gray-200 p-2 text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									{filteredData?.map((user, index) => (
										<tr
											key={user.uuid}
											className={`hover:bg-gray-50 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"
												}`}
										>
											<td className="border border-gray-200 p-2 text-left text-gray-700">
												{user.name || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-left text-gray-700">
												{user.attendance_summary?.totalWorkingDays || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-left text-gray-700">
												{user.attendance_summary?.sandwichLeave || "-"}
											</td>
											<td className="border border-gray-200 p-2  text-left text-gray-700">
												{user.attendance_summary?.totalPresentDays || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-left text-gray-700">
												{user.attendance_summary?.monthLeaves || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-left text-gray-700">
												{user.attendance_summary?.weekOffs +
													(user.attendance_summary?.holidays || 0) || "-"}
											</td>
											<td className="border border-gray-200 p-2 text-left  text-gray-700">
												Yes
											</td>
											<td className="border border-gray-200 p-2 text-left text-center">
												<Button
													variant="outlined"
													size="small"
													style={{
														color: "black",
														borderColor: "black",
													}}
													onClick={() => handleViewDetails(user.uuid)}
												>
													View Details
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p className="text-gray-500">No attendance data available</p>
						)}
					</div>
					<Modal
						isOpen={newModalIsOpen}
						onRequestClose={closeModal}
						style={{
							overlay: {
								backgroundColor: "rgba(0, 0, 0, 0.5)",
							},
							content: {
								top: "50%",
								left: "50%",
								right: "auto",
								bottom: "auto",
								marginRight: "-50%",
								transform: "translate(-50%, -50%)",
								maxWidth: "90%",
								maxHeight: "90%",
								width: "90%",
								height: "98%",
								padding: "0",
								borderRadius: "12px",
								backgroundColor: "#f9f9f9",
								boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
							},
						}}
					>
						{isLoad ? (
							<div className="flex justify-center items-center" style={{ height: "300px" }}>
								<HashLoader color="#5755d9" size={20} style={{ marginTop: "140px" }} />
							</div>
						) : (
							<div className="intro-y overflow-x-auto">
								<div
									style={{ backgroundColor: "#fc9a30" }}
									className="flex justify-between p-2 items-center text-gray-700 bg-[#fc9a30] px-1"
								>
									<div className="flex justify-center content-center">
										<div>
											<h2 className="text-lg text-white font-semibold pl-3">{selectedEmpName}</h2>
										</div>
										<div className="ml-3 content-center">
											<CSVLink
												data={csvData}
												headers={headers}
												style={{ backgroundColor: "#24a184" }}
												filename={`${selectedEmpName || "Employee"}_Attendance_Report.csv`}
												className="bg-green text-white content-center"
											>
												<div
													className="flex items-center justify-center px-2 py-1 rounded bg-green-500"
													style={{ border: "1px solid #fff", borderRadius: "5px", fontWeight: "bold" }}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="#fff"
														className="bi bi-download"
														viewBox="0 0 16 16"
													>
														<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
														<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
													</svg>
												</div>
											</CSVLink>
										</div>
									</div>
									<div className="ml-auto pr-2 flex content-center">
										<h2 className="text-white content-center mt-1">
											{userWorkTypeModal === 1 ? "Shift Time: Hourly" : `Shift Time: ${formatTime(shiftStartTime)} - ${formatTime(shiftEndTime)}`}
										</h2>
										<button
											className="  text-white bg-red-500 hover:bg-red-600 rounded-full focus:outline-none mx-2"
											onClick={closeModal}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												fill="currentColor"
												className="bi bi-x"
												viewBox="0 0 16 16"
											>
												<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
											</svg>
										</button>
									</div>
								</div>

								{/* Table Section */}
								{/* <table
									className="table-auto w-full text-center border-collapse border border-gray-300"
									style={{
										borderRadius: "10px 10px 0 0",
										width: "100%",
										overflow: "hidden",
										backgroundColor: "#ffffff",
									}}
								>
									<thead>
										<tr className="bg-gray-100 text-gray-700">
											{[
												"Date",
												"In Time",
												"Late",
												"Lunch HRS",
												"Out Time",
												"Pending HRS",
												"Total HRS",
												"Other Break",
												"After Office HRS",
												"1st/2nd Half",
											].map((header, idx) => (
												<th key={idx} className="border border-gray-300 p-2" style={{ fontSize: "12px" }}>
													{header}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{attdata?.map((row, index) => (
											<tr key={index} className="bg-white hover:bg-gray-100 text-gray-700">
												{[
													row?.date ? moment(row.date).format("DD/MM/YY") : "-",
													row?.start_time || "-",
													row?.late_coming || "-",
													row?.lunch_break_duration || "-",
													row?.end_time || "-",
													row?.pending_time || "-",
													row?.total_working_hours || "-",
													row?.other_break_duration || "-",
													row?.after_office_hours?.length
														? row.after_office_hours.map((session) => session.duration).join(", ")
														: "-",
													row?.on_leave
														? "A"
														: row?.half_day_type === "First Half"
															? "A/P"
															: row?.half_day_type === "Second Half"
																? "P/A"
																: "P/P",
												].map((cell, idx) => (
													<td
														key={idx}
														className="border border-gray-300 p-1"
														style={{
															fontWeight: 500,
															fontSize: "11px",
															color: "#8F8F8F",
															textAlign: "center",
														}}
													>
														{cell}
													</td>
												))}
											</tr>
										))}
									</tbody>
									<tfoot style={{ backgroundColor: "#fc9a30" }}>
										<tr className="bg-orange-500 text-white">
											{[
												"Total",
												"-",
												"-",
												"-",
												"-",
												pendingHours,
												totalHours,
												"-",
												"-",
												"-",
											].map((footer, idx) => (
												<td
													key={idx}
													className="border border-gray-300 p-2"
													style={{
														fontFamily: "Inter",
														fontWeight: 500,
														fontSize: "15px",
														lineHeight: "21.78px",
														color: "white",
														textAlign: "center",
													}}
												>
													{footer}
												</td>
											))}
										</tr>
									</tfoot>
								</table> */}

								{userWorkTypeModal === 1 ? (
									<>
										<table className="table-auto w-full text-center border-collapse border border-gray-300"
											style={{ borderRadius: "10px 10px 0 0", width: "100%", overflow: "hidden", backgroundColor: "#ffffff" }}>
											<thead>
												<tr className="bg-gray-100 text-gray-700">
													{["Date", "In Time", "Out Time", "Lunch HRS", "Other Break", "Total HRS"]
														.map((header, idx) => (
															<th key={idx} className="border border-gray-300 p-2" style={{ fontSize: "13px", textAlign: "left", paddingLeft: "20px" }}>
																{header}
															</th>
														))}
												</tr>
											</thead>
											<tbody>
												{attdata?.map((row, index) => (
													<tr key={index} className="bg-white hover:bg-gray-100 text-gray-700"
														style={{ fontSize: '20px' }}
													>
														{[
															row?.date ? moment(row.date).format("DD/MM/YY") : "-",
															row?.start_time || "-",
															row?.lunch_break_duration || "-",
															row?.other_break_duration || "-",
															row?.end_time || "-",
															row?.total_working_hours || "-",
														].map((cell, idx) => (
															<td
																key={idx}
																className="border border-gray-300 p-1"
																style={{
																	fontWeight: 500,
																	fontSize: "12px",
																	color: "#8F8F8F",
																	textAlign: "left",
																	paddingLeft: "20px"
																}}
															>
																{cell}
															</td>
														))}
													</tr>
												))}
											</tbody>
											<tfoot style={{ backgroundColor: "#fc9a30" }}>
												<tr className="bg-orange-500 text-white">
													{[
														"Total",
														"-",
														"-",
														"-",
														"-",
														totalHours,

													].map((footer, idx) => (
														<td
															key={idx}
															className="border border-gray-300 p-2"
															style={{
																fontWeight: 500,
																fontSize: "15px",
																color: "white",
																textAlign: "left",
																paddingLeft: "20px" // Keep footer cells centered
															}}
														>
															{footer}
														</td>
													))}
												</tr>
											</tfoot>
										</table>
									</>
								) :
									(<>
										<table className="table-auto w-full text-center border-collapse border border-gray-300"
											style={{ borderRadius: "10px 10px 0 0", width: "100%", overflow: "hidden", backgroundColor: "#ffffff" }}>
											<thead>
												<tr className="bg-gray-100 text-gray-700">
													{["Date", "In Time", "Late", "Lunch HRS", "Out Time", "Pending HRS", "Total HRS", "Other Break", "After Office HRS", "1st/2nd Half"]
														.map((header, idx) => (
															<th key={idx} className="border border-gray-300 p-2" style={{ fontSize: "13px", textAlign: "left" }}>
																{header}
															</th>
														))}
												</tr>
											</thead>
											<tbody>
												{attdata?.map((row, index) => (
													<tr key={index} className="bg-white hover:bg-gray-100 text-gray-700"
														style={{ fontSize: '20px' }}
													>
														{[
															row?.date ? moment(row.date).format("DD/MM/YY") : "-",
															row?.start_time || "-",
															row?.late_coming || "-",
															row?.lunch_break_duration || "-",
															row?.end_time || "-",
															row?.pending_time || "-",
															row?.total_working_hours || "-",
															row?.other_break_duration || "-",
															row?.after_office_hours?.length
																? row.after_office_hours.map((session) => session.duration).join(", ")
																: "-",
															row?.on_leave
																? "A"
																: row?.half_day_type === "First Half"
																	? "A/P"
																	: row?.half_day_type === "Second Half"
																		? "P/A"
																		: "P/P",
														].map((cell, idx) => (
															<td
																key={idx}
																className="border border-gray-300 p-1"
																style={{
																	fontWeight: 500,
																	fontSize: "12px",
																	color: "#8F8F8F",
																	textAlign: "left"
																}}
															>
																{cell}
															</td>
														))}
													</tr>
												))}
											</tbody>
											<tfoot style={{ backgroundColor: "#fc9a30" }}>
												<tr className="bg-orange-500 text-white">
													{[
														"Total",
														"-",
														"-",
														"-",
														"-",
														pendingHours,
														totalHours,
														"-",
														totalAfterOfficeHours,
														"-",
													].map((footer, idx) => (
														<td
															key={idx}
															className="border border-gray-300 p-2"
															style={{
																fontWeight: 500,
																fontSize: "15px",
																color: "white",
																textAlign: "left",
																paddingLeft: "20px" // Keep footer cells centered
															}}
														>
															{footer}
														</td>
													))}
												</tr>
											</tfoot>
										</table>
									</>
									)}



								{/* Export Button */}
								{/* <div className="flex justify-center mt-4">
									<CSVLink
										data={csvData}
										headers={headers}
										style={{ backgroundColor: "#24a184" }}
										filename={`${selectedEmpName || "Employee"}_Attendance_Report.csv`}
										className="bg-green-500 text-white font-semibold px-4 py-2 rounded hover:bg-green-600"
									>
										Export Monthly Report
									</CSVLink>
								</div> */}
							</div>
						)}
					</Modal>
				</>
			)
			}
		</div >
	);
};

export default AttendanceReport;