import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import {
	FaCalendar,
	FaFileCsv,
	FaDownload,
	FaArrowLeft,
	FaArrowRight,
} from "react-icons/fa";
import { format } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import Select from "react-select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { C } from "sip.js/lib/core";
import { FaEye } from "react-icons/fa";
const Summary = () => {
	let today = new Date();
	let month = [];
	month[0] = "1";
	month[1] = "2";
	month[2] = "3";
	month[3] = "4";
	month[4] = "5";
	month[5] = "6";
	month[6] = "7";
	month[7] = "8";
	month[8] = "9";
	month[9] = "10";
	month[10] = "11";
	month[11] = "12";
	const loadingContext = useLoadingContext();
	let [loading, setLoading] = useState(false);
	const [attdata, setattdata] = useState(null);
	const [totalPendingTime, setTotalPendingTime] = useState(null);
	const [totalWorkingHours, setTotalWorkingHours] = useState(null);
	const [totalWorkingDays, setTotalWorkingDays] = useState(null);
	const [totalMonthDays, setTotalMonthDays] = useState(null);
	const [totalLeaveDays, setTotalLeaveDays] = useState(null);
	const [empName, setEmpName] = useState("NA");
	const [plEligibilityStatus, setPlEligibilityStatus] = useState("");
	const [isLoad, setIsLoad] = useState(false);
	const [attendanceSummary, setAttendanceSummary] = useState({
		presentDays: 0,
		halfDays: 0,
		absentDays: 0,
		holidays: 0,
		totalWorkingDays: 0,
		isSixMonthsCompleted: false,
		isEligibleForPL: false,
	});
	// const [totalTime, setTotalTime] = useState(null);
	const [totalTime, setTotalTime] = useState({});
	const [totalNonWorkingDays, setTotalNonWorkingDays] = useState(null);
	const [totalHolidays, setTotalHolidays] = useState(null);
	const [presentDays, setPresentDays] = useState(null);
	const [totalNeedTime, setTotalNeedTime] = useState(null);
	const [totalNeedHours, setTotalNeedHours] = useState("00:00:00");
	const [summary, setSummary] = useState([]);
	const [totalPresentDays, setTotalPresentDays] = useState(0);
	const [csvData, setcsvData] = useState([]);
	const [currdate] = useState(new Date());
	const [userdatalist, setuserdata] = useState([]);
	const [alluser, setalluser] = useState(null);
	const [username, setusername] = useState();
	const [orgval, setorgval] = React.useState([]);
	const [weeksummary, setWeekSummary] = useState([]);
	const [weekdata, setWeekData] = useState([]);
	const [monthYear, setmonthYear] = useState({});
	const [date, setdate] = useState(new Date());
	const [data, setData] = useState([
		{ value: "", label: "Select All", active: "", uuid: "" },
	]);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	const user_data = JSON.parse(localStorage.getItem("isAuth"));
	const userUuid = user_data?.uuid;
	const userType = user_data?.type;
	const comp_uuid = user_data.companydata[0].uuid;
	const [selectedval, setselectedval] = useState(
		userType !== 0 ? { uuid: userUuid } : ""
	);
	const [dept, setdept] = useState(null);
	const [departmentData, setDepartmentData] = useState({
		name: "",
		department: 1,
		hod: 2,
		contactno: "",
		organization: comp_uuid,
		email: "",
		password: "",
		status: true,
		uuid: ``,
	});
	const [selectedDate, setSelectedDate] = useState(new Date());
	// const handleDateChange = (date) => {
	// 	setSelectedDate(date);
	// };
	const handleDateChange = (date) => {
		setSelectedDate(date);
		if (date) {
			setselectedmonth({
				month: date.getMonth() + 1,
				year: date.getFullYear(),
			});
		}
	};

	// console.log(selectedval, "selectedval");
	let monthNumber = month[today.getMonth()];
	const [newColumns, setNewColumns] = useState([
		{ title: "Total Working Days", field: "workingDays", width: 140 },
		{
			title: "Total Sandwich Leave Days",
			field: "sandwichLeaveDays",
			width: 180,
		},
		{ title: "Present Days", field: "presentDays", width: 140 },
		{ title: "Month Leave", field: "monthLeave", width: 140 },
		{ title: "Non Working Days", field: "nonWorkingDays", width: 180 },
		{ title: "Total Month Days", field: "totalMonthDays", width: 160 },
		{ title: "Is Eligible for PL", field: "isEligiblePL", width: 180 },
	]);
	const [newTableData, setNewTableData] = useState([]);
	const currmonth = monthNumber;
	const curryear = today.getFullYear();
	const [value, onChange] = useState([
		new Date(currdate.getFullYear(), currdate.getMonth(), 1),
		new Date(),
	]);
	const [columns, setcolumn] = useState([]);
	const [selectedmonth, setselectedmonth] = useState({
		year: curryear,
		month: Number(currmonth),
	});
	const [totalAfterHours, setTotalAfterHours] = useState(0);
	const [department, setDepartment] = useState([]);
	// const[]

	// set data
	// useEffect(() => {
	// 	if (attData?.length > 0) {
	// 		const formattedData = attData.map((day) => ({
	// 			Date: day.date,
	// 			WeekOff: day.is_weekoff ? "Yes" : "No",
	// 			OnLeave: day.on_leave ? "Yes" : "No",
	// 			ShiftStartTime: day.shift?.shift_start_time || "N/A",
	// 			ShiftEndTime: day.shift?.shift_end_time || "N/A",
	// 			TotalWorkingHours: day.total_working_hours || "00:00:00",
	// 			PendingTime: day.pending_time || "00:00:00",
	// 		}));
	// 		setcsvData(formattedData);
	// 	} else {
	// 		setcsvData([]); // Default to empty array
	// 	}
	// }, [attData]);

	useEffect(() => {
		// console.log("Updated CSV Data: ", csvData);
	}, [csvData]);
	useEffect(() => {
		// Calculate total "after office hours" duration
		const total = attdata
			?.flatMap((row) => row.after_office_hours || []) // Flatten after office hours arrays
			.reduce((sum, afterHours) => {
				const [hours, minutes] = afterHours.duration
					.split("h")
					.map((part) => parseInt(part.trim().replace("m", "")) || 0);
				return sum + hours * 60 + minutes; // Convert hours to minutes and add to total
			}, 0);

		setTotalAfterHours(total || 0); // Set total in minutes or default to 0
	}, [attdata]);

	const formatTotalHours = (totalMinutes) => {
		const hours = Math.floor(totalMinutes / 60)
			.toString()
			.padStart(2, "0"); // Zero-padded hours
		const minutes = (totalMinutes % 60).toString().padStart(2, "0"); // Zero-padded minutes
		return `${hours}:${minutes}`;
	};

	const getdepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllDepartments/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setdept(res.data);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};
	const handleuser = (e) => {
		if (e && e.value) {
			// Handle <Select> input (React-Select library)
			setselectedval(e); // Full object
			setusername(e.value); // The selected value or UUID
		} else if (e && e.target) {
			// Handle native <select> input
			const selected = data.find((item) => item.uuid === e.target.value);
			setselectedval(selected || null); // Find the full object or set to null
			setusername(e.target.value || ""); // Set the selected value or empty string
		}
	};

	const calculateTotalNeedHours = (attendanceData) => {
		const totalSeconds = attendanceData.reduce((total, day) => {
			if (!day.is_weekoff && day.shift) {
				// Parse shift times
				const [shiftStartHours, shiftStartMinutes, shiftStartSeconds] =
					day.shift.shift_start_time.split(":").map(Number);
				const [shiftEndHours, shiftEndMinutes, shiftEndSeconds] =
					day.shift.shift_end_time.split(":").map(Number);
				const shiftStart =
					shiftStartHours * 3600 +
					shiftStartMinutes * 60 +
					shiftStartSeconds;
				const shiftEnd =
					shiftEndHours * 3600 +
					shiftEndMinutes * 60 +
					shiftEndSeconds;
				let workTimeInSeconds = shiftEnd - shiftStart;
				if (day.lunch_break_duration) {
					const [lunchHours, lunchMinutes, lunchSeconds] =
						day.lunch_break_duration.split(":").map(Number);
					workTimeInSeconds -=
						lunchHours * 3600 + lunchMinutes * 60 + lunchSeconds;
				}
				if (day.other_break_duration) {
					const [breakHours, breakMinutes, breakSeconds] =
						day.other_break_duration.split(":").map(Number);
					workTimeInSeconds -=
						breakHours * 3600 + breakMinutes * 60 + breakSeconds;
				}
				return total + workTimeInSeconds;
			}
			return total;
		}, 0);
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;

		return `${hours.toString().padStart(2, "0")}:${minutes
			.toString()
			.padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
	};
	const handlechangeDepartment = (e) => {
		console.log("Function Called");
		const departmentId = e.target.value; // Selected department ID
		setDepartmentData(departmentId); // Update department state
		getUserData(departmentId === "0" ? "" : selectedorg, departmentId); // Call API with selected department ID

		// Logging updated values
		// Assuming userData is a state variable
		console.log("Department Data:", departmentData); // Updated department state
	};

	const getUserData = async (org = "", departmentId = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(BASEURL + `admin-getAllUsers/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					type: 1,
					uuid: org || selectedorg, // Use `org` or default to `selectedorg`
					usertype: userdata.type,
					department: departmentId || "", // Use department ID or default to all
				}),
			});

			if (!response.ok) {
				throw new Error(
					`Error: ${response.status} - ${response.statusText}`
				);
			}

			const res = await response.json();

			if (res.status === 201) {
				// Filter out users from department 34
				// const filteredData = res.data.filter((user) => user.department !== 34);
				const filteredData = res.data.filter(
					(item) => item.department !== 34 && item.username !== "1119"
				);

				// Update user data state with filtered data
				setuserdata(filteredData); // Update user data state
				setalluser(res.alluser); // Update all users state

				// Prepare dropdown options
				const userOptions = filteredData.map((user) => ({
					value: user.username,
					label: user.name,
					active: user.active ? "🟢" : "🔴",
					uuid: user.uuid,
				}));

				// Add a default option at the beginning of the list
				userOptions.unshift({
					value: "",
					label: "Select Employee",
					active: "",
				});

				// Update select options
				setData(userOptions);

				// Set the default selected username if data is available
				if (res.alluser.length > 0 && filteredData.length > 0) {
					setusername(res.alluser[0]["username"]);
				}
			}

			setLoading(false);
		} catch (err) {
			console.error("Error fetching user data:", err.message);
			setLoading(false);
		} finally {
			loadingContext.done();
		}
	};

	// const getUserData = async (org = "", departmentId = "") => {
	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	const userdata = JSON.parse(localStorage.getItem("isAuth"));

	// 	if (userdata !== "00:00:00") {
	// 		const token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");

	// 	try {
	// 		const response = await fetch(BASEURL + `admin-getAllUsers/`, {
	// 			method: "POST",
	// 			headers: myHeaders,
	// 			body: JSON.stringify({
	// 				type: 1,
	// 				uuid: org || selectedorg, // Use `org` or default to `selectedorg`
	// 				usertype: userdata.type,
	// 				department: departmentId || "", // Use department ID or default to all
	// 			}),
	// 		});

	// 		if (!response.ok) {
	// 			throw new Error(
	// 				`Error: ${response.status} - ${response.statusText}`
	// 			);
	// 		}

	// 		const res = await response.json();

	// 		if (res.status === 201) {
	// 			setuserdata(res.data); // Update user data state
	// 			setalluser(res.alluser); // Update all users state

	// 			// Prepare dropdown options
	// 			const userOptions = res.data.map((user) => ({
	// 				value: user.username,
	// 				label: user.name,
	// 				active: user.active ? "🟢" : "🔴",
	// 				uuid: user.uuid,
	// 			}));
	// 			userOptions.unshift({
	// 				value: "",
	// 				label: "Select Employee",
	// 				active: "",
	// 			});

	// 			setData(userOptions); // Update select options
	// 			if (res.alluser.length > 0 && res.data.length > 0) {
	// 				setusername(res.alluser[0]["username"]);
	// 			}
	// 		}

	// 		setLoading(false);
	// 	} catch (err) {
	// 		console.error("Error fetching user data:", err.message);
	// 		setLoading(false);
	// 	} finally {
	// 		loadingContext.done();
	// 	}
	// };

	const csvHeaders = [
		{ label: "Date", key: "Date" },
		{ label: "Shift Start Time", key: "Shift_Start_Time" },
		{ label: "Shift In Time", key: "Shift_In_Time" },
		{ label: "Late Coming", key: "Late_Coming" },
		{ label: "Lunch Break Hrs", key: "Lunch_Break_Hrs" },
		{ label: "Other Break Hrs", key: "Other_Break_Hrs" },
		{ label: "After Office Hours", key: "After_Office_Hours" },
		{ label: "Shift End Time", key: "Shift_End_Time" },
		{ label: "Shift Out Time", key: "Shift_Out_Time" },
		{ label: "Pending Time", key: "Pending_Time" },
		{ label: "Total Working Hours", key: "Total_working_Hours" },
		{ label: "Half Day Type", key: "Half_Day_Type" },
		{ label: "Is Weekoff", key: "Is_Weekoff" },
		{ label: "On Leave", key: "On_Leave" },
	];
	// Handle "Next" button click
	const handleNext = () => {
		const currentIndex = data.findIndex(
			(option) => option.value === selectedval?.value
		);
		const nextIndex = (currentIndex + 1) % data.length;
		setselectedval(data[nextIndex]);
		setTimeout(() => getMonthWiseData(), 0);
	};
	const handlePrevious = () => {
		const currentIndex = data.findIndex(
			(option) => option.value === selectedval?.value
		);
		const previousIndex = (currentIndex - 1 + data.length) % data.length;
		setselectedval(data[previousIndex]);
		setTimeout(() => getMonthWiseData(), 0);
	};
	const handleKeyDown = (e) => {
		if (e.key === "Tab") {
			e.preventDefault();
			const currentIndex = data.findIndex(
				(option) => option.value === selectedval?.value
			);
			const nextIndex = (currentIndex + 1) % data.length;
			setselectedval(data[nextIndex]);
		}
	};
	useEffect(() => {
		if (userType === 0) {
			getdepartments();
			getUserData();
		}
	}, []);
	const options = {
		pagination: "local",
		paginationSize: 10,
	};
	function DetailVal(props) {
		const rowData = props.cell._cell.row.data;
		if (rowData.employee !== "") {
			return (
				<a
					href={"/employees/" + rowData.uuid}
					rel="noreferrer"
					target="_blank"
					alt=""
				>
					<u>{rowData.name}</u>
				</a>
			);
		} else {
			return "No data Available";
		}
	}
	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setDepartmentData(value);
	};
	const getMonthWiseData = async () => {
		if (!selectedDate || !(selectedDate instanceof Date)) {
			console.error("Invalid date selected");
			return;
		}
		const selectedMonth = selectedDate.getMonth() + 1;
		const selectedYear = selectedDate.getFullYear();
		if (!selectedval?.uuid) {
			console.error("UUID is missing");
			return;
		}
		if (!selectedorg) {
			console.error("Company UUID is missing");
			return;
		}
		setIsLoad(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");
		try {
			const response = await fetch(
				`${BASEURL}get-monthwise-attendance/${selectedval.uuid}`,
				{
					method: "POST",
					headers: myHeaders,
					body: JSON.stringify({
						month: selectedMonth,
						company_uuid: selectedorg,
						year: selectedYear,
					}),
				}
			);
			if (!response.ok) {
				throw new Error(
					`Error: ${response.status} - ${response.statusText}`
				);
			}
			const result = await response.json();
			setAttendanceSummary(result.attendance_summary);
			setTotalTime(result.totalTime);
			setEmpName(result?.attendance?.[1]?.name || "NA");
			const afterOfficeHours = result.attendance.filter(
				(day) => day.after_office_hours?.length > 0
			);
			const flattenedData = result.attendance.map((day) => ({
				Date: day.date || "NA",
				Shift_Start_Time: day.shift?.shift_start_time || "NA",
				Shift_In_Time: day.start_time || "NA",
				Late_Coming: day.late_coming || "NA",
				Lunch_Break_Hrs: day.lunch_break_duration || "00:00:00",
				Other_Break_Hrs: day.other_break_duration || "00:00:00",
				After_Office_Hours:
					day.after_office_hours?.duration || "00:00:00",
				Shift_End_Time: day.shift?.shift_end_time || "NA",
				Shift_Out_Time: day.end_time || "NA",
				Pending_Time: day.pending_time || "00:00:00",
				Total_working_Hours: day.total_working_hours || "00:00:00",
				Half_Day_Type: day.half_day_type || "NA",
				Is_Weekoff: day.is_weekoff ? "Yes" : "No",
				On_Leave: day.on_leave ? "Yes" : "No",
			}));
			setcsvData(flattenedData);
			setattdata(result.attendance);
		} catch (err) {
			console.error(
				"Error fetching month-wise data:",
				err.message || err
			);
		} finally {
			setIsLoad(false);
		}
	};
	const formatTime = (totalSeconds) => {
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = totalSeconds % 60;
		return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
			2,
			"0"
		)}:${String(seconds).padStart(2, "0")}`;
	};
	return (
		<>
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 2xl:col-span-12 bg-white p-0 rounded-lg shadow-lg">
					<h2 className="text-lg font-medium text-left p-2">
						Monthly Attendance Report
					</h2>
					<div className="intro-y flex flex-col sm:flex-row items-center ">
						<div className="flex items-center text-primary gap-4">
							<div className="mx-1 w-full max-w-[100px]">
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
								>
									<DatePicker
										slotProps={{
											textField: {
												size: "small",
											},
										}}
										sx={{
											"& .MuiInputBase-input": {
												height: "20px",
											},
										}}
										views={["month", "year"]}
										value={selectedDate}
										onChange={handleDateChange}
										renderInput={(params) => (
											<TextField {...params} fullWidth />
										)}
									/>
								</LocalizationProvider>
							</div>
							{userType !== 2 && (
								<>
									<select
										className="form-select"
										name="department"
										onChange={handlechangeDepartment}
										value={departmentData}
										style={{
											margin: "5px 5px",
										}}
									>
										<option key={-1} value="0">
											All department
										</option>
										{dept &&
											dept.map((item, i) => (
												<option
													key={i}
													value={item.department_id}
												>
													{item.deptname}
												</option>
											))}
									</select>
									<select
										className="form-select"
										name="user"
										onChange={(e) => {
											const selectedUser = data.find(
												(item) =>
													item.uuid === e.target.value
											);
											setselectedval(
												selectedUser || null
											);
										}}
										value={
											selectedval ? selectedval.uuid : ""
										}
									>
										{data &&
											data.map((item, i) => (
												<option
													key={i}
													value={item.uuid}
												>
													{item.label}
												</option>
											))}
									</select>

									<button
										className="btn btn-primary"
										onClick={getMonthWiseData}
										style={{ borderRadius: "5px" }}
									>
										Get
									</button>
								</>
							)}
							{userType !== 2 && (
								<CSVLink
									filename={`${empName}-Monthly-Attendance-${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1
										}.csv`}
									data={csvData}
									headers={csvHeaders}
								>
									<button
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											padding: "10px 18px",
											backgroundColor: "rgb(30 64 175)",
											color: "white",
											border: "none",
											borderRadius: "5px",
											fontSize: "16px",
											cursor: "pointer",
											boxShadow:
												"0 4px 8px rgba(0, 0, 0, 0.1)",
											transition:
												"background-color 0.3s ease",
										}}
										onMouseOver={(e) => {
											e.target.style.backgroundColor =
												"rgb(29, 53, 153)";
										}}
										onMouseOut={(e) => {
											e.target.style.backgroundColor =
												"rgb(30 64 175)";
										}}
									>
										<FaDownload />
									</button>
								</CSVLink>
							)}

							{userType !== 2 && (
								<button
									className="btn btn-primary"
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "10px 18px",
										backgroundColor: "rgb(30 64 175)",
										color: "white",
										border: "none",
										borderRadius: "5px",
										fontSize: "16px",
										cursor: "pointer",
										boxShadow:
											"0 4px 8px rgba(0, 0, 0, 0.1)",
										transition:
											"background-color 0.3s ease",
									}}
									onMouseOver={(e) => {
										e.target.style.backgroundColor =
											"rgb(29, 53, 153)";
									}}
									onMouseOut={(e) => {
										e.target.style.backgroundColor =
											"rgb(30 64 175)";
									}}
									onClick={handlePrevious}
								>
									&lt;
								</button>
							)}
							{userType !== 2 && (
								<button
									className="btn btn-primary"
									onClick={handleNext}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										padding: "10px 18px",
										backgroundColor: "rgb(30 64 175)",
										color: "white",
										border: "none",
										borderRadius: "5px",
										fontSize: "16px",
										cursor: "pointer",
										boxShadow:
											"0 4px 8px rgba(0, 0, 0, 0.1)",
										transition:
											"background-color 0.3s ease",
									}}
									onMouseOver={(e) => {
										e.target.style.backgroundColor =
											"rgb(29, 53, 153)";
									}}
									onMouseOut={(e) => {
										e.target.style.backgroundColor =
											"rgb(30 64 175)";
									}}
								>
									&gt;
								</button>
							)}
							{userType === 2 && (
								<button
									className="btn btn-primary"
									onClick={getMonthWiseData}
									style={{ borderRadius: "5px" }}
								>
									Get
								</button>
							)}
						</div>
					</div>
					{isLoad ? (
						<div className="text-center m-5 p-5">
							<HashLoader
								color="#5755d9"
								size={30}
								style={{
									position: "absolute",
									right: "50%",
								}}
								HashLoader
							/>
						</div>
					) : (
						<div>
							<div className="summary-container">
								<div className="summary-card working-days m-1">
									<span className="label">
										Working Days :{" "}
										{/* <span className="text-primary"> */}{" "}
										{attendanceSummary?.totalWorkingDays ||
											0}
										{/* </span> */}
									</span>
								</div>
								<div className="summary-card sandwich-leave">
									<span className="label">
										Sandwich Leave :{" "}
										{attendanceSummary?.sandwichLeave || 0}
									</span>
								</div>
								<div className="summary-card present-days">
									<span className="label">
										Present Days :{" "}
										{attendanceSummary?.totalPresentDays ||
											0}
									</span>
								</div>
								<div className="summary-card month-leaves">
									<span className="label">
										Month Leaves :{" "}
										{attendanceSummary?.monthLeaves || 0}
									</span>
								</div>
								<div className="summary-card total-month-days">
									<span className="label">
										Month Days :{" "}
										{attendanceSummary?.totalDays || 0}
									</span>
								</div>
								<div className="summary-card non-working-days">
									<span className="label">
										Non-Working Days :{" "}
										{attendanceSummary?.holidays || 0}
									</span>
								</div>
								<div className="summary-card eligible-pl">
									<span className="label">
										Eligible for PL :{" "}
										<span
											className={
												attendanceSummary?.isEligibleForPL
													? "text-warning"
													: "text-danger"
											}
										>
											{attendanceSummary?.isEligibleForPL
												? "YES"
												: "NO"}
										</span>
									</span>
								</div>
							</div>
							<div className="intro-y overflow-x-auto mt-2">
								<table className="table-auto w-full text-center border-collapse border border-gray-300">
									<thead>
										<tr className="monthly-report-header text-white">
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Date
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Shift Start Time
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Shift In Time
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Late Coming
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Lunch Break Hrs
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Other Break Hrs
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												After Office Hours
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Shift End Time
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Shift Out Time
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Shift Pending Hrs
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												Total Work Hrs
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												1st Half Att
											</th>
											<th
												className="border border-gray-300 p-1 py-2"
												style={{
													width: "100px",
													fontSize: "12px",
												}}
											>
												2nd Half Att
											</th>
										</tr>
									</thead>
									<tbody>
										{attdata?.map((row, index) => (
											<tr
												key={index}
												className={`bg-white ${row?.is_weekoff &&
													"weekOffColor"
													} ${row?.half_day_type ===
														"First Half" ||
														row?.half_day_type ===
														"Second Half"
														? ""
														: row?.on_leave
															? "bg-warning"
															: ""
													}`}
											>
												<td
													className="border border-gray-300 p-1"
													style={{ fontSize: "13px" }}
												>
													{row?.date
														? moment(
															row.date
														).format("YYYY-MM-DD")
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{
														backgroundColor:
															"#d7d5d5",
														fontSize: "13px",
													}}
												>
													{row?.shift
														?.shift_start_time
														? row?.shift
															?.shift_start_time
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{ fontSize: "13px" }}
												>
													{row?.start_time
														? row?.start_time
														: "-"}
												</td>
												<td
													className={`border border-gray-300 p-1 ${JSON.parse(
														localStorage.getItem(
															"isAuth"
														)
													)?.type === 2 &&
														row?.late_shift_in &&
														"danger"
														}`}
													style={{ fontSize: "13px" }}
												>
													{row?.late_coming
														? row?.late_coming
														: "-"}
												</td>
												<td
													className={`border border-gray-300 p-1 ${JSON.parse(
														localStorage.getItem(
															"isAuth"
														)
													)?.type === 2 &&
														row?.is_break_longer &&
														"danger"
														}`}
													style={{ fontSize: "13px" }}
												>
													{row?.lunch_break_duration
														? row?.lunch_break_duration
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{ fontSize: "13px" }}
												>
													{row?.other_break_duration
														? row?.other_break_duration
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{
														fontSize: "13px",
														textAlign: "center",
													}}
												>
													{row?.after_office_hours
														?.length > 0
														? row.after_office_hours
															.map(
																(
																	afterHours
																) => {
																	if (
																		afterHours.date ===
																		row.date
																	) {
																		// Parse duration in "2h 59m" format to total minutes
																		const [
																			hours,
																			minutes,
																		] =
																			afterHours.duration
																				.split(
																					"h"
																				)
																				.map(
																					(
																						part
																					) =>
																						parseInt(
																							part
																								.trim()
																								.replace(
																									"m",
																									""
																								)
																						) ||
																						0
																				);
																		const formattedHours =
																			hours
																				.toString()
																				.padStart(
																					2,
																					"0"
																				);
																		const formattedMinutes =
																			minutes
																				.toString()
																				.padStart(
																					2,
																					"0"
																				);
																		return (
																			<span
																				key={
																					afterHours.date
																				}
																			>
																				{
																					formattedHours
																				}

																				:
																				{
																					formattedMinutes
																				}{" "}
																			</span>
																		);
																	}
																	return null;
																}
															)
															.filter(Boolean)
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{
														backgroundColor:
															"#d7d5d5",
														fontSize: "13px",
													}}
												>
													{row?.shift?.shift_end_time
														? row?.shift
															?.shift_end_time
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{ fontSize: "13px" }}
												>
													{row?.end_time
														? row?.end_time
														: "-"}
												</td>
												<td
													className={`border border-gray-300 p-1 ${JSON.parse(
														localStorage.getItem(
															"isAuth"
														)
													)?.type === 2 &&
														row?.early_shift_out &&
														"danger"
														}`}
													style={{ fontSize: "13px" }}
												>
													{row?.pending_time
														? row?.pending_time
														: "-"}
												</td>
												<td
													className="border border-gray-300 p-1"
													style={{ fontSize: "13px" }}
												>
													{row?.total_working_hours
														? row?.total_working_hours
														: "-"}
												</td>
												<td
													className={`border border-gray-300 p-1 ${row?.is_weekoff
														? "weekOffColor"
														: row?.half_day_type ===
														"First Half" &&
														"bg-warning"
														}`}
													style={{ fontSize: "13px" }}
												>
													{row?.on_leave
														? "On Leave"
														: !row?.end_time
															? "-"
															: row?.half_day_type ===
																"First Half"
																? "A"
																: "P"}
												</td>
												<td
													className={`border border-gray-300 p-1 ${row?.is_weekoff
														? "weekOffColor"
														: row?.half_day_type ===
														"Second Half" &&
														"bg-warning"
														}`}
													style={{ fontSize: "13px" }}
												>
													{row?.on_leave
														? "On Leave"
														: !row?.end_time
															? "-"
															: row?.half_day_type ===
																"Second Half"
																? "A"
																: "P"}
												</td>
											</tr>
										))}
										<tr>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "13px" }}
											>
												Total:
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
											{/* <td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												{totalAfterHours > 0
													? formatTotalHours(
															totalAfterHours
													  )
													: "-"}
													
														<a
																						// href={
																						// 	url
																						// } // Use the dynamic URL
																						target="_blank" // Opens in new tab
																						rel="noopener noreferrer" // For security reasons
																						style={{
																							color: "blue",
																							textDecoration:
																								"underline",
																							display:
																								"inline-flex", 
																							alignItems:
																								"center", // Vertically centers the icon
																							justifyContent:
																								"center", // Horizontally center the icon (if needed)
																							marginLeft:
																								"5px", // Optional: space between time and the icon
																						}}
																					>
																						<FaEye />
																					</a>
											</td> */}
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												{totalAfterHours > 0 ? (
													<>
														{formatTotalHours(
															totalAfterHours
														)}
														<a
															// href={`http://localhost:3001/after-office-hours?company_uuid=${selectedorg}&month=${selectedmonth.month}&year=${selectedmonth.year}&employee_uuid=${selectedval?.uuid}`}
															href={`https://track.rayvat.com/after-office-hours?company_uuid=${selectedorg}&month=${selectedmonth.month}&year=${selectedmonth.year}&employee_uuid=${selectedval?.uuid}`}
															// href={`${BASEURL}after-office-hours?company_uuid=${selectedorg}&month=${selectedmonth.month}&year=${selectedmonth.year}&employee_uuid=${selectedval?.uuid}`}


															target="_blank"
															rel="noopener noreferrer"
															style={{
																color: "blue",
																textDecoration:
																	"underline",
																display:
																	"inline-flex",
																alignItems:
																	"center",
																justifyContent:
																	"center",
																marginLeft:
																	"5px",
															}}
														>
															<FaEye />
														</a>
													</>
												) : (
													"-"
												)}
											</td>

											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												Need:
												{totalTime?.totalShiftTime ||
													"-"}
											</td>
											<td
												className="border border-gray-300 p-1 text-center"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												Pending:
												{totalTime?.totalPendingTime ||
													0}
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												Achieved:
												<br />
												{totalTime?.totalWorkingHours ||
													"-"}
											</td>
											<td
												className="border border-gray-300 p-1"
												style={{ fontSize: "12px" }}
											>
												-
											</td>
										</tr>
									</tbody>
								</table>

								<div>
									{loading ? (
										<div
											className="mt-5"
											style={{ display: "inline-flex" }}
										>
											<HashLoader
												color="#5755d9"
												size={30}
											/>
										</div>
									) : (
										<div className="mt-5">
											<table className="table-auto w-full text-center border-collapse border border-gray-300"></table>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Summary;
