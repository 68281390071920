import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Masteradmin from "../Masteradmin";
import { FaSignInAlt, FaTrashAlt, FaEdit } from "react-icons/fa";
import CommonModal from "./CommonModal";

const Departments = () => {
	const [userdata, setuserdata] = useState([]);
	const [dept, setdept] = useState(null);
	const [bkpdata, setbkpdata] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [errors, setErrors] = useState({});
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [isEdit, setIsEdit] = useState(false);
	const [currentItems, setCurrentItems] = useState(null);
	const [items, setitems] = useState([]);
	const [add, setadd] = useState(2);
	let [tabtype, settabtype] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(null);
	const [getdata, setgetdata] = useState([]);
	const [data, setdata] = useState({
		name: "",
		department: 1,
		contactno: "",
		email: "",
		password: "",
		status: true,
		uuid: ``,
	});
	const toggleDropdown = (id) => {
		setOpenDropdown(openDropdown === id ? null : id);
	};

	const closeDropdown = () => {
		setOpenDropdown(null);
	};

	const [isadd, setisadd] = useState(false);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});

	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

	useEffect(() => {
		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getUserData();
		getdepartments();
		getData();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
	}, [itemOffset]);


	const renderOrgName = (uuid) => {
		if (uuid === "94541385-dd89-4343-8a1d-14989a8546be") {
			return "Rayvatorg";
		} else if (uuid === "abc3bab0-2b6e-4fb7-a453-4049c8157ac5") {
			return "Jenya";
		}
		return "Unknown Organization";
	};

	const getdepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllDepartments/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setdept(res.data);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	const getData = async () => {
		settabtype(false);
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		let data = await fetch(BASEURL + `addorganization`, {
			method: "GET",
			headers: myHeaders,
		});
		let data2 = await data.json();
		setgetdata(data2.data);
		setLoading(false);
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 10) % items.length;

		setItemOffset(newOffset);
	};

	const getUserData = async (e = "") => {
		let isactive = 1;
		if (e !== "") {
			isactive = e.target.value;
		}

		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		// console.log(master);
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `clients/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				master,
				isactive,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					setitems(arr);

					setCurrentItems(arr1);

					setPageCount(Math.ceil(arr.length / 10));

					setuserdata(res.data);
					setbkpdata(res.data);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
		// console.log(data);
	};


	const savedata = async () => {
		if (!data.name || !data.email || !data.department || !data.contactno) {
			swal({
				title: "Error",
				text: "Please fill in all required fields.",
				icon: "error",
				timer: 2000,
			});
			return;
		}

		setLoading(true);
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata) {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		const payload = {
			data: {
				id: data.id || null, // Use ID for updates
				name: data.name,
				department: data.department,
				password: data.password || null, // Only send password if provided
				contactno: data.contactno,
				email: data.email,
				status: data.status,
			},
		};

		try {
			const response = await fetch(`${BASEURL}add-client/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(payload),
			});

			const res = await response.json();

			if (res.status === 201) {
				swal({
					title: "Success",
					text: res.message,
					icon: "success",
					timer: 2000,
				});

				resetForm();
				getUserData(); // Refresh client list
				setIsOpen(false);
			} else {
				swal({
					title: "Error",
					text: res.message,
					icon: "error",
					timer: 2000,
				});
			}
		} catch (error) {
			console.error("Error saving client data:", error);
			swal({
				title: "Error",
				text: "Something went wrong. Please try again.",
				icon: "error",
				timer: 2000,
			});
		} finally {
			setLoading(false);
		}
	};


	// const savedata = async () => {
	// 	// if (!data.name || !data.email || !data.department || !data.password || !data.contactno || !data.organization) {
	// 	// 	swal({
	// 	// 		title: "Error",
	// 	// 		text: "Please fill in all required fields.",
	// 	// 		icon: "error",
	// 	// 		timer: 2000,
	// 	// 	});
	// 	// 	return;
	// 	// }

	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	const userdata = JSON.parse(localStorage.getItem("isAuth"));

	// 	if (userdata) {
	// 		const token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}

	// 	myHeaders.append("Content-Type", "application/json");

	// 	const payload = {
	// 		data: {
	// 			id: data.id || null,
	// 			name: data.name,
	// 			department: data.department,
	// 			password: data.password,
	// 			contactno: data.contactno,
	// 			email: data.email,
	// 			status: data.status,
	// 			organization: data.organization,
	// 		},
	// 	};

	// 	try {
	// 		// const response = await fetch(`https://trackapi.rayvat.com/add-client/`, {
	// 		const response = await fetch(BASEURL + `add-client/`, {

	// 			method: "POST",
	// 			headers: myHeaders,
	// 			body: JSON.stringify(payload),
	// 		});

	// 		const res = await response.json();

	// 		if (res.status === 201) {
	// 			swal({
	// 				title: "Success",
	// 				text: res.message,
	// 				icon: "success",
	// 				timer: 2000,
	// 			});

	// 			// Reset form and refresh data
	// 			setdata({
	// 				name: "",
	// 				department: 1,
	// 				password: "",
	// 				contactno: "",
	// 				email: "",
	// 				status: true,
	// 				organization: "",
	// 			});
	// 			getUserData();
	// 			setisadd(!isadd);
	// 		} else {
	// 			swal({
	// 				title: "Error",
	// 				text: res.message,
	// 				icon: "error",
	// 				timer: 2000,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		console.error("Error saving client data:", error);
	// 		swal({
	// 			title: "Error",
	// 			text: "Something went wrong. Please try again.",
	// 			icon: "error",
	// 			timer: 2000,
	// 		});
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };


	const editdata = (data) => {
		setisadd(true);
		let status;
		if (data.active === 1) {
			status = true;
		} else {
			status = false;
		}
		// console.log(data.companyuuid);
		setdata({
			id: data.user_id,
			name: data.name,
			department: data.department,
			organization: data.companyuuid,
			contactno: data.contact_no,
			email: data.email,
			hod: data.type,
			// password: data.password,
			status: status,
		});
	};

	const addorg = () => {
		settabtype(!tabtype);
		setisadd(false);
	};
	const adddata = (data) => {
		// console.log(11);
		settabtype(false);
		setisadd(!isadd);
		setdata({
			name: "",
			department: 1,
			password: "",
			contactno: "",
			email: "",
			status: true,
		});
	};

	const filterdata = (event) => {
		if (event.target.value === "") {
			loading ? <HashLoader /> : setuserdata(bkpdata);

			let arr = [];
			for (const key in bkpdata) {
				arr.push(key);
			}
			setitems(arr);

			setCurrentItems(arr);
			setPageCount(Math.ceil(arr.length / 10));
		} else {
			var matches = bkpdata.filter(function (s) {
				return s.name.toLowerCase().includes(`${event.target.value}`);
			});

			let arr = [];
			for (const key in matches) {
				arr.push(key);
			}
			setitems(arr);

			const newOffset = (matches.length * 10) % matches.length;
			setItemOffset(newOffset);
			setuserdata(matches);
			const endOffset = itemOffset + 10;
			setCurrentItems(arr.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(matches.length / 10));
		}
	};

	const signindata = async (data) => {
		swal({
			title: "Sign In as Admin",
			text: `Are you sure you want to Sign In as Admin?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				// console.log(data.companyuuid); return
				// window.open(SITEURL, '_blank', 'incognito=yes');
				let authdata = JSON.parse(localStorage.getItem("isAuth"));
				// console.log(authdata); return

				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}

				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `get-companydata/${data.company_uuid}`, {
					method: "GET",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						if (res.status === 201) {
							let obj = {
								status: 201,
								message: "Signin successful",
								usertoken: authdata.usertoken,
								id: data.user_id,
								uuid: data.uuid,
								username: data.username,
								name: data.name,
								avatar: data.avatar,
								role: data.type,
								timezone: "",
								dept: data.deptname,
								department: data.department,
								companydata: res.data,
								master: data.master,
								issigninbyadmin: 1,
								type: data.type,
							};
							// return
							localStorage.setItem("isAuth", JSON.stringify(obj));
							window.open(SITEURL, "_self");
							// 'incognito=yes'
						} else {
							swal({
								title: "Error",
								text: res.message,
								icon: "error",
								timer: 2000,
							});
						}
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	const deletedata = (id) => {
		swal({
			title: "Delete",
			text: `Please confirm if you want to Delete Client?`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				setLoading(true);
				const myHeaders = new Headers();
				let userdata = JSON.parse(localStorage.getItem("isAuth"));
				if (userdata != null) {
					let token = userdata.usertoken;
					myHeaders.append("Authorization", token);
				}
				myHeaders.append("Content-Type", "application/json");
				fetch(BASEURL + `delete-client/${id}`, {
					method: "DELETE",
					headers: myHeaders,
				})
					.then((response) => response.json())
					.then((res) => {
						getUserData();
						swal({
							title: "Success",
							text: res.msg,
							icon: "success",
							timer: 2000,
						});
					})
					.catch((err) => console.log(err));
				setLoading(false);
			}
		});
	};

	const selectoption = (e) => {
		// console.log(e.target.value);
		// let data = getdata[e.target.value];
		// setdata({
		//   name: data.organization_name,
		//   email: data.email,
		//   mobile: data.mobile_no,
		//   user: data.user_count,
		//   expiry: data.expiry_date,
		//   id: data.id,
		// });
		// setupdate({ ...update, msg: "Update detail" });
	};

	// const updateEmployee = async (uuid) => {
	// 	// e.preventDefault();
	// 	const userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	const myHeaders = new Headers();
	// 	if (userdata) {
	// 		myHeaders.append("Authorization", userdata.usertoken);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");

	// 	const payload = { uuid };
	// 	// if (data.name !== employeeData.name) payload.name = data.name;
	// 	// if (data.department !== employeeData.department)
	// 	// 	payload.department = data.department;
	// 	// if (data.hod !== employeeData.type) payload.hod = data.hod;
	// 	// if (data.contactno !== employeeData.contact_no)
	// 	// 	payload.contactno = data.contactno;
	// 	// if (data.email !== employeeData.email) payload.email = data.email;
	// 	// if (data.dob !== employeeData.dob) payload.dob = data.dob;
	// 	// if (data.date_of_joining !== employeeData.date_of_joining)
	// 	// 	payload.date_of_joining = data.date_of_joining;
	// 	// if (data.status !== employeeData.status) payload.status = data.status;

	// 	try {
	// 		const response = await fetch(BASEURL + `update-employee`, {
	// 			method: "POST",
	// 			headers: myHeaders,
	// 			body: JSON.stringify(payload),
	// 		});
	// 		const res = await response.json();

	// 		if (res.status === 201) {
	// 			swal({
	// 				title: "Success",
	// 				text: res.message,
	// 				icon: "success",
	// 				timer: 2000,
	// 			}).then(() => {
	// 				// navigate("/admin/3");
	// 			});
	// 		} else {
	// 			swal({
	// 				title: "Error",
	// 				text: "Please Enter Required Values",
	// 				icon: "error",
	// 				timer: 2000,
	// 			});
	// 		}
	// 	} catch (error) {
	// 		console.error("Error updating employee:", error);
	// 	} finally {
	// 		setIsOpen(false)
	// 	}
	// };
	const handlechangeinput = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
	};


	const handleOpen = (client) => {
		if (client) {
			setdata({
				id: client.user_id, // Use the client's user_id for updates
				name: client.name,
				department: client.department,
				contactno: client.contact_no,
				email: client.email,
				password: "", // Keep password empty for security; it will only change if entered
				status: client.active === 1, // Convert status to boolean
			});
			setIsEdit(true);
		} else {
			resetForm(); // Reset for a new client
		}
		setIsOpen(true);
	};


	const handleClose = () => {
		setIsOpen(false);
		resetForm();
	};

	const resetForm = () => {
		setdata({
			id: "",
			name: "",
			department: "0",
			contactno: "",
			email: "",
			password: "",
			status: true,
		});
		setIsEdit(false);
	};



	const modalContent = (
		<>
			<div className="px-4 py-4">
				<div style={{
					textAlign: 'center',
					fontSize: "20px",
					fontWeight: "600",
					display: 'flex',
					justifyContent: 'space-between',
				}}>

					<div></div>
					<h2>
						Add Client
					</h2>

					<div className="cursor-pointer" onClick={handleClose}>
						x
					</div>
				</div>
				<div className="my-2 mt-5 ">

					<div className="mb-3">
						<input
							type="text"
							name="name"
							placeholder="Client Name"
							value={data.name}
							onChange={(e) => setdata({ ...data, name: e.target.value })}
							className="modal-input"
						/>
						{/* {errors.name && (
							<span
								style={{
									color: "red",
									fontSize: "12px",
									marginTop: "5px",
								}}
							>
								{errors.name}
							</span>
						)} */}
					</div>
					<div className="mb-3">
						<input
							id="update-profile-form-1"
							value={data.contactno}
							required
							minLength={10}
							placeholder="Contact Number"
							type="number"
							name="contactno"
							className="modal-input"
							onChange={handlechangeinput}
						/>
						{errors.contactno && (
							<span
								style={{
									color: "red",
									fontSize: "12px",
									marginTop: "5px",
								}}
							>
								{errors.contactno}
							</span>
						)}
					</div>
					<div className="mb-3">
						<div className="col-span-12 md:col-span-6 mb-4">
							<select
								className="modal-input"
								name="department"
								value={data.department || ""}
								onChange={(e) => setdata({ ...data, department: e.target.value })}
							>
								<option value="" disabled>
									Select Department
								</option>
								<option key={-1} value="0">
									All Departments
								</option>
								{dept && dept.length > 0 &&
									dept.map((item, i) => (
										<option key={i} value={item.department_id}>
											{item.deptname}
										</option>
									))
								}
							</select>
							{errors.department && (
								<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
									{errors.department}
								</span>
							)}
						</div>
					</div>

					<div className="mb-3">
						<select
							// style={{
							//   fontSize: "14px",
							//   width: "100%",
							//   padding: "8px",
							//   borderRadius: "8px",
							//   borderColor: "red",
							// }}
							name="organization"
							value={data.organization}
							required
							// className="form-select"
							className="modal-input"
							onChange={handlechange}
						>
							<option value="" key={-1}>
								Select Organization
							</option>
							{getdata.length > 0 &&
								getdata.map(
									(ele, i) => {
										return (
											<option
												value={
													ele.uuid
												}
												key={i}
											>
												{
													ele.name
												}
											</option>
										);
									}
								)}
						</select>
					</div>
					<div className="mb-3">
						<input
							id="update-profile-form-1"
							value={data.email}
							type="email"
							required
							name="email"
							placeholder="Email Address"
							className="modal-input"
							onChange={handlechangeinput}
						/>
					</div>
					<div className="mb-3">
						<input
							id="update-profile-form-1"
							value={data.password}
							type="password"
							required
							name="password"
							placeholder="password"
							className="modal-input"
							onChange={handlechangeinput}
						/>
					</div>

					{/* <div className="mb-3">
						<select
							name="client"
							value={data.client}
							onChange={(e) => setdata({ ...data, client: e.target.value })}
							className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-400"
						>
							<option value="0">Select Client</option>
							{clients &&
								clients.map((item, i) => (
									<option key={i} value={item.client_id}>
										{item.clientname}
									</option>
								))}
						</select>
					</div> */}

					{/* {master !== true ? (
						<>
							<div className="col-span-12 md:col-span-6 mb-4">
								<select
									className="modal-input"
									name="department"
									onChange={handlechangeinput}
									value={data.department}
								>
									<option value="" disabled>
										Select a department
									</option>
									<option key={-1} value="0">
										All department
									</option>
									{dept &&
										dept.length > 0 &&
										dept.map((item, i) => {
											return (
												<option key={i} value={item.department_id}>
													{item.deptname}
												</option>
											);
										})}
								</select>
								{errors.department && (
									<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
										{errors.department}
									</span>
								)}
							</div>
						</>
					) : null}

					<div className="col-span-12 md:col-span-6 mb-4">
						<select
							className="modal-input"
							name="hod"
							onChange={handlechangeinput}
							value={data.hod}
						>
							<option value="" disabled>
								Select a role
							</option>
							<option value="2">Employee</option>
							<option value="1">Manager</option>
							<option value="0">Admin</option>
						</select>
						{errors.hod && (
							<span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
								{errors.hod}
							</span>
						)}
					</div> */}

					<button
						type="button"
						onClick={() => {
							if (isEdit) {
								console.log(data?.uuid, 'data?.uuid')
								// updateEmployee("");
							}
							savedata()

						}}
						className="modal-button-new"
					>
						Submit
					</button>
				</div>
			</div>
		</>
	);
	return (
		<div className="intro-y">
			<div className="flex items-center p-2  dark:border-darkmode-400">

			</div>
			{tabtype ? <Masteradmin getData={getData} /> : null}

			<div className="">

				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
					<div className="flex mt-5 sm:mt-0">
						<div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
							<input
								id="tabulator-html-filter-value"
								type="text"
								onChange={filterdata}
								className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
								placeholder="Search..."
							/>
						</div>
					</div>

					{master === true ? (
						<div className="flex mt-5 sm:mt-0">
							<select
								name="uuid"
								onChange={handlechange}
								className="form-control"
							>
								<option value="1">Active</option>
								<option value="2">In-Active</option>
							</select>
						</div>
					) : null}
					<div className="flex mt-5 sm:mt-0 ml-auto">
						<button
							type="button"
							onClick={() => handleOpen(null)}
							className="btn w-50"
							style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
						>
							Add Client
						</button>
					</div>

				</div>

				<div className="overflow-x-auto">
					{loading ? (
						<HashLoader
							color="#5755d9"
							size={30}
							style={{ position: "absolute", right: "50%" }}
						/>
					) : (
						// <table
						// 	className="table table-bordered"
						// 	style={{ width: "100%" }}
						// >
						// 	<thead>
						// 		<tr>
						// 			<th className="whitespace-nowrap">#</th>
						// 			<th className="whitespace-nowrap">Name</th>
						// 			<th className="whitespace-nowrap">Email</th>
						// 			<th className="whitespace-nowrap">
						// 				Contact No.
						// 			</th>
						// 			<th className="whitespace-nowrap">
						// 				{master !== 1
						// 					? "Department"
						// 					: "Organization"}{" "}
						// 			</th>
						// 			<th className="whitespace-nowrap">
						// 				Status
						// 			</th>
						// 			<th className="whitespace-nowrap">
						// 				Actions
						// 			</th>
						// 		</tr>
						// 	</thead>
						// 	<tbody>
						// 		{currentItems && currentItems.length === 0 && (
						// 			<tr>
						// 				<td colSpan="8">No data available</td>
						// 			</tr>
						// 		)}
						// 		{currentItems &&
						// 			currentItems.length > 0 &&
						// 			currentItems.map((item, i) => {
						// 				return (
						// 					<tr key={i}>
						// 						<td>
						// 							{userdata[item].user_id}
						// 						</td>
						// 						<td>{userdata[item].name}</td>
						// 						<td>{userdata[item].email}</td>
						// 						<td>
						// 							{userdata[item].contact_no}
						// 						</td>
						// 						<td>
						// 							{master !== 1
						// 								? userdata[item]
						// 									.deptname
						// 								: userdata[item]
						// 									.companyname}
						// 						</td>
						// 						<td>
						// 							{userdata[item].active === 1
						// 								? "Active"
						// 								: "Inactive"}
						// 						</td>
						// 						<td>
						// 							<div className="flex lg:justify-center items-center cursor-pointer">
						// 								{/* {userdata[item].type!==0 || master===1?<> */}
						// 								<FaEdit
						// 									title="Edit"
						// 									style={{
						// 										color: "gray",
						// 									}}
						// 									className="mr-2"
						// 									onClick={() =>
						// 										editdata(
						// 											userdata[
						// 											item
						// 											]
						// 										)
						// 									}
						// 								/>
						// 								<FaTrashAlt
						// 									onClick={() =>
						// 										deletedata(
						// 											userdata[
						// 												item
						// 											].user_id
						// 										)
						// 									}
						// 									title="Delete"
						// 									className="text-danger mr-2"
						// 								/>
						// 								{/* <FaSignInAlt onClick={() => signindata(userdata[item])} title={"Sign In as "+ userdata[item].name} style={{color:'#rgb(64 96 255)'}} className="mr-2"/>  */}
						// 								{/* </>:"-" } */}
						// 							</div>
						// 						</td>
						// 					</tr>
						// 				);
						// 			})}
						// 	</tbody>
						// </table>

						<div
							style={{
								// border: "1px solid #FC9A30",
								// padding: "10px",
								borderTopLeftRadius: "8px",
								borderTopRightRadius: "8px",
							}}
						>
							<div
								style={{
									backgroundColor: "#FC9A30",
									color: "#fff",
									padding: "12px",
									textAlign: "start",
									borderTopLeftRadius: "8px",
									borderTopRightRadius: "8px",
								}}
							>
								<h3>
									Clients
								</h3>
							</div>
							<div className="table-outlineBorder" style={{
								border: "1px sold #FC9A30 !important",
							}}>
								<div className="employee-table-container">
									<table
										className="employee-table"
										// className="table table-bordered"
										style={{ width: "100%" }}
									>
										<thead>
											<tr>
												<th className="whitespace-nowrap">#</th>
												<th className="whitespace-nowrap">Name</th>
												<th className="whitespace-nowrap">Email</th>
												<th className="whitespace-nowrap">
													Contact No.
												</th>
												{/* <th className="whitespace-nowrap">
													{master !== 1
														? "Department"
														: "Organization"}{" "}
												</th> */}
												{/* <th className="whitespace-nowrap">
													Status
												</th> */}
												<th className="whitespace-nowrap">
													Actions
												</th>
											</tr>
										</thead>
										<tbody>
											{currentItems && currentItems.length === 0 && (
												<tr>
													<td colSpan="8">No data available</td>
												</tr>
											)}
											{currentItems &&
												currentItems.length > 0 &&
												currentItems.map((item, i) => {
													return (
														<tr key={i}>
															<td>
																{userdata[item].user_id}
															</td>
															<td>{userdata[item].name}</td>
															<td>{userdata[item].email}</td>
															<td>
																{userdata[item].contact_no}
															</td>
															{/* <td>
																{master !== 1
																	? userdata[item]
																		.deptname
																	: userdata[item]
																		.companyname}
															</td> */}
															{/* <td>
																{userdata[item].active === 1
																	? "Active"
																	: "Inactive"}
															</td> */}
															<td className="text-left">
																<div className="flex items-center relative">
																	<span>
																		{userdata[item].active === 1
																			? "Active"
																			: "Inactive"}
																	</span>
																	<button
																		className="ml-2 bg-gray-200 text-gray-700 hover:bg-gray-300 p-1 rounded"
																		onClick={() => {
																			toggleDropdown(userdata[item].user_id)
																		}}
																	>
																		▼
																	</button>
																	{openDropdown === userdata[item].user_id && (
																		<div className="absolute top-full mt-2 w-25 bg-white border border-gray-300 rounded shadow-lg z-10">
																			{userdata[item].type !== 0 || master === 1 ? (
																				<>
																					<div
																						style={{ fontSize: "16px", cursor: "pointer" }}
																						className="text-right mr-2"
																						onClick={closeDropdown}
																					>
																						x
																					</div>
																					<button
																						className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center"
																						onClick={() => {
																							closeDropdown()
																							handleOpen(userdata[item])
																						}}
																					>
																						<FaEdit className="mr-2" style={{ color: "gray" }} /> Edit
																					</button>
																					{master === 1 && (
																						<>
																							<button
																								className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-600 flex items-center"
																								onClick={() => {
																									closeDropdown()
																									deletedata(
																										userdata[
																											item
																										].user_id
																									)
																								}
																								}
																							>
																								<FaTrashAlt className="mr-2" /> Delete
																								{/* Delete */}
																							</button>

																						</>
																					)}
																				</>
																			) : (
																				<span className="block px-4 py-2 text-gray-400">No Actions Available</span>
																			)}
																		</div>
																	)}
																</div>
															</td>

														</tr>
													);
												})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					)}

					<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
						<br />
						<nav className="w-full sm:w-auto sm:mr-auto">
							{!loading ? (
								<div className="row">
									<div className="col-md-3"></div>
									<div className="col-md-4">
										<ReactPaginate
											pageCount={pageCount}
											pageRangeDisplayed={10}
											marginPagesDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName="pagination"
											activeClassName="active"
											pageLinkClassName="page-link"
											breakLinkClassName="page-link"
											nextLinkClassName="page-link"
											previousLinkClassName="page-link"
											pageClassName="page-item"
											breakClassName="page-item"
											nextClassName="page-item"
											previousClassName="page-item"
											previousLabel={<>&laquo;</>}
											nextLabel={<>&raquo;</>}
										/>
									</div>
									<div className="col-md-4"></div>
								</div>
							) : (
								// <HashLoader
								// 	color="#5755d9"
								// 	size={30}
								// 	style={{
								// 		position: "absolute",
								// 		right: "50%",
								// 	}}
								// />
								""
							)}
						</nav>
					</div>
				</div>
			</div>
			<CommonModal isOpen={isOpen} content={modalContent} />
		</div>
	);
};

export default Departments;
