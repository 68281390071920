import React, { useEffect, useState } from "react";
import "./App.css";
import * as All from "./Export";
import Home from "./componunt/Home";
import HomeClient from "./componunt/HomeClient";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useNavigate,
} from "react-router-dom";
// import { topbar } from "react-router-loading";
// import { Routes, Route } from "react-router-loading";
import { useSelector, useDispatch } from "react-redux";
import { ToastProvider, useToasts } from "react-toast-notifications";
import Achivements from "./componunt/Achivements";
import PunchTicket from "./componunt/PunchTicket";
import OtherBreakTicket from "./componunt/OtherBreakTicket";
import Report from "./componunt/admin/Report";
import { onSwitchTheme } from "./redux/actions/Action";
import JobApplication from "./componunt/SecurityEntries";
import Departments from "./componunt/admin/JobApplications";
import JobNew from "./componunt/admin/JobApplicationsNew";
import AfterOfficeHours from "./componunt/report/AfterOfficeHours";
import AddEmployee from "./componunt/admin/AddEmployee.js";
// import UserLoginDetails from "./componunt/admin/UserLoginDetails";
import EditEmployee from "./componunt/admin/EditEmployee.js";
import Employee from "./componunt/admin/Employee.js";
import UpdatePunchTicket from "./componunt/report/UpdatePunchTicket.js";
import UserLoginDetails from "./componunt/admin/UserLogindata.js";

// import AfterOfficeHours from "./componunt/admin/AfterOfficeHours";

// topbar.config({
//   barThickness: 4,
//   barColors: {
//     0: "rgb(255, 99, 71)",
//     0.3: "rgb(255, 99, 71)",
//     1.0: "rgb(255, 99, 71)",
//   },
//   shadowBlur: 2,
//   shadowColor: "red",
//   className: "topbar",
// });

const Routing = (props) => {
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);

	const isdarmode = (mode) => {
		setisdark(mode);
	};
	const [, setisdark] = useState(props.isdark);
	const navigate = useNavigate();
	const { addToast } = useToasts();
	// useEffect(() => {
	//   setisdark(props.isdark)
	// }, [isdark]);
	// console.log(props.isdark);
	// jo ahi update thase

	// const logtimer = () => {
	// 	var now = new Date().getTime();
	// 	var setupTime = localStorage.getItem("isAuth");
	// 	if (setupTime != null) {
	// 		addToast("Session expired. Please login again", {
	// 			appearance: "warning",
	// 		});
	// 		localStorage.clear();
	// 		localStorage.removeItem("isAuth");
	// 		localStorage.removeItem("isAuth_bkp");
	// 		setTimeout(() => {
	// 			if (localStorage.getItem("isclient") !== "1") {
	// 				navigate("/login", { replace: true });
	// 			} else {
	// 				navigate("/client-login", { replace: true });
	// 			}
	// 			navigate(0);
	// 		}, 3000);
	// 	}
	// };
	// console.log(localStorage.getItem('isclient'));
	// setInterval(logtimer, 1000 * 60 * 60);

	return (
		<>
			<Routes>
				<Route
					exact
					path="/"
					element={
						localStorage.getItem("isclient") !== "1" ? (
							isAuth.role === 0 || isAuth.role === 1 ? (
								<Home toggle={props.isdark} />
							) : (
								// <All.HomeEmpliyee />
								<All.DesktopActivity />
							)
						) : (
							<HomeClient />
						)
					}
					loading
				/>

				<Route
					exact
					path="/appdetails/:id"
					element={<All.Application_details />}
					loading
				/>
				{/* <Route exact path="/job-application-general/:uuid" element={<All.JobAssessment />} loading /> */}
				{/* <Route exact path="/job-application-general/:uuid" element={<All.JobApplicationGeneral />} loading /> */}
				<Route
					exact
					path="/view-job-application-status/:uuid"
					element={<All.JobApplicationStatus />}
					loading
				/>
				<Route
					exact
					path="/job-application"
					element={<All.JobApplication />}
					loading
				/>
				<Route
					exact
					path="/security-entries"
					element={<All.SecurityEntries />}
					loading
				/>
				<Route
					exact
					path="/job-assessment/:id"
					element={<All.JobAssessment />}
					loading
				/>
				{/* <Route
					exact
					path="/job-assessment/:id"
					element={<All.JobAssessment />}
					loading
				/> */}
				{/* <Route exact path="/job-questionaries/:uuid" element={<All.jobQuestionaries />} loading /> */}

				<Route
					exact
					path="/employees"
					element={<All.Employees />}
					loading
				/>
				{/* <Route
					exact
					path="/employees"
					element={<All.Employees />}
					loading
				/> */}

				<Route
					exact
					// path="/employees/Addemployee/:uuid"
					path="/employees/Addemployee"
					// element={<All. />}
					element={<AddEmployee />}
					loading
				/>
				<Route
					exact
					// path="/employees/Addemployee/:uuid"
					path="/UserLoginDetails"
					// element={<All. />}
					element={<UserLoginDetails />}
					loading
				/>
				<Route
					exact
					path="/employees/Editemployee/:uuid"
					element={<EditEmployee />}
				/>
				<Route
					exact
					path="/employeeList"
					element={<Employee />}
				/>
				<Route
					exact
					path="update-emp-ticket/:id"
					element={<UpdatePunchTicket />}
				// element={<EditEmployee />}
				/>

				<Route
					exact
					path="/employees/:user_id"
					element={<All.EmployeesDetails />}
				/>
				<Route
					exact
					path="/punch-ticket"
					// element={<All.EmployeeTicket />}
					element={
						isAuth.role === 2 ? (
							<All.TicketAll />
						) : (
							<All.TicketAdmin />
						)
					}
					loading
				/>
				<Route
					exact
					path="/punch-ticket-other-break"
					// element={<All.EmployeeTicket />}
					element={
						isAuth.role === 2 ? (
							<All.EmployeeTicket />
						) : (
							<All.AdminTicketOther />
						)
					}
					loading
				/>
				<Route
					exact
					path="/punch-ticket-After-Office"
					// element={<All.EmployeeTicket />}
					element={
						isAuth.role === 2 ? (
							<All.EmployeeTicket />
						) : (
							<All.AdminTicketAfterOffice />
						)
					}
					loading
				/>

				<Route
					exact
					path="/break-ticket"
					// element={<All.EmployeeTicket />}
					element={
						isAuth.role === 2 ? (
							<All.EmployeeBreakTicket />
						) : (
							<All.AdminTicket />
						)
					}
					loading
				/>
				<Route
					exact
					path="/Achivements/"
					element={<Achivements />}
					loading
				/>
				<Route exact path="/ReportAll" element={<Report />} loading />
				<Route
					exact
					path="/Punchticket/"
					element={<PunchTicket />}
					loading
				/>
				<Route
					exact
					path="/Punchticket/"
					element={<PunchTicket />}
					loading
				/>
				<Route
					exact
					path="/OtherBreakTicket/"
					element={<OtherBreakTicket />}
					loading
				/>
				<Route
					exact
					path="/TimeSheetTicket/"
					element={<All.TimeSheetTicket />}
					loading
				/>
				<Route
					exact
					path="/timesheet/"
					element={<All.Timesheet />}
					loading
				/>
				<Route
					exact
					path="/leaves/"
					element={
						isAuth.role === 0 ? (
							<All.Leaves />
						) : (
							<All.LeavesEmployee />
						)
					}
					loading
				/>

				<Route
					exact
					path="/job-applications"
					element={isAuth.role === 0 && <Departments />}
					loading
				/>
				<Route
					exact
					path="/job-applications-new"
					element={isAuth.role === 0 && <JobNew />}
					loading
				/>
				{/* <Route
					exact
					path="/job-applications"
					element={
						isAuth.role === 0 ||
						(isAuth.role === 2 && isAuth.department === 12) ? (
							<Departments />
						) : null
					}
					loading
				/> */}
				{/* <Route
					exact
					path="/after-office-hours"
					element={isAuth.role === 2 && <AfterOfficeHours />}
					loading
				/> */}
				<Route
					exact
					path="/after-office-hours"
					element={
						isAuth.role === 2 ? (
							<AfterOfficeHours />
						) : isAuth.role === 1 ? (
							<AfterOfficeHours />
						) : isAuth.role === 0 ? (
							<AfterOfficeHours />
						) : (
							""
						)
					}
					loading
				/>

				<Route
					exact
					path="/attendance/attendance-details"
					element={
						isAuth.role === 0 ? (
							<All.Attendance />
						) : (
							<All.AttendanceEmployee />
						)
					}
					loading
				/>
				<Route
					exact
					path="/attendance/monthly-attendance-report"
					element={
						isAuth.role === 0 ? (
							<All.MonthlyAttendanceReport />
						) : (
							<All.MonthlyAttendanceReport />
						)
					}
					loading
				/>
				<Route
					exact
					path="/attendance/single-user-monthly-attendance-report"
					element={
						isAuth.role === 0 ? (
							<All.SingleUserAttendence />
						) : (
							<All.SingleUserAttendence />
						)
					}
					loading
				/>
				<Route
					exact
					path="/attendance/monthly-records"
					element={
						isAuth.role === 0 ? (
							<All.MonthlyAttendancedata />
						) : (
							<All.MonthlyAttendancedata />
						)
					}
					loading
				/>
				<Route exact path="/feeds" element={<All.Feeds />} loading />
				<Route
					exact
					path="/employee-activity"
					element={<All.EmployeeActivity />}
					loading
				/>

				<Route
					exact
					path="/insights/summary"
					element={<All.Summary />}
					loading
				/>
				<Route
					exact
					path="/insights/timeline"
					element={<All.Timeline />}
					loading
				/>
				<Route
					exact
					path="/insights/productivity"
					element={<All.TeamProductivity />}
					loading
				/>
				<Route
					exact
					path="/productivity"
					element={<All.Productivity />}
					loading
				/>
				<Route
					exact
					path="/insights/screenshots"
					element={<All.Screenshots />}
					loading
				/>
				<Route
					exact
					path="/insights/screenshotsAdmin"
					element={<All.ScreenshotsAdmin />}
					loading
				/>
				<Route
					exact
					path="/insights/applications"
					element={<All.Applications />}
					loading
				/>

				<Route
					exact
					path="/dashboard/summary"
					element={<All.DashboardSummary />}
					loading
				/>
				<Route
					exact
					path="/dashboard/app-usage"
					element={<All.AppUsage />}
					loading
				/>
				<Route
					exact
					path="/dashboard/desktop-activity"
					element={<All.DesktopActivity />}
					loading
				/>

				<Route
					exact
					path="/manage-project-hrs"
					element={<All.ProjectHrs />}
					loading
				/>

				<Route
					exact
					path="/manage-project"
					element={<All.Projects />}
					loading
				/>
				<Route
					exact
					path="/manage-task"
					element={<All.Task />}
					loading
				/>
				{isAuth.role == "2" && (
					<Route
						exact
						path="/admin/:type/:uuid"
						element={<All.Activity />}
						loading
					/>
				)}
				<Route
					exact
					path="/clients"
					element={<All.Clients />}
					loading
				/>

				<Route
					exact
					path="/monthlywork"
					element={<All.ClientMonthlyAttendance />}
					loading
				/>

				<Route
					exact
					path="/assign-project-to-client"
					element={<All.ProjectToClient />}
					loading
				/>

				<Route
					exact
					path="/report"
					element={<All.Template />}
					loading
				/>
				<Route
					exact
					path="/report/attendance"
					element={<All.AttendanceReport />}
				/>
				<Route
					exact
					path="/report/monthly-attendance"
					element={<All.MonthlyAttendance />}
				/>
				<Route
					exact
					path="/report/attendance-regularization"
					element={<All.AttendanceRegularization />}
				/>
				<Route
					exact
					path="/report/late-mark"
					element={<All.LateMark />}
				/>
				<Route exact path="/report/leave" element={<All.Leave />} />
				<Route
					exact
					path="/report/employee-leave-balance"
					element={<All.EmployeeLeaveBalance />}
				/>
				<Route
					exact
					path="/report/app-usage"
					element={<All.AppUsageReport />}
				/>
				<Route
					exact
					path="/report/app-usage-by-day"
					element={<All.AppUsagebyDay />}
				/>
				<Route
					exact
					path="/report/daily-productivity"
					element={<All.DailyProductivity />}
				/>

				<Route exact path="/admin" element={<All.Admin />} loading />
				<Route
					exact
					path="/admin/:type"
					element={<All.Admin />}
					loading
				/>
				<Route
					exact
					path="/admin/:type/:uuid"
					element={<All.Admin />}
					loading
				/>
				<Route exact path="/profile" element={<All.Employees />} />

				<Route exact path="/404" element={<All.PageNotFound />} />
				<Route exact path="*" element={<Navigate to="/404" />} />
			</Routes>
		</>
	);
};

function App() {
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);
	const [isdark, setisdark] = useState(0);

	if (isAuth === null) {
		document.body.classList.add("login");
	}

	const dispatch = useDispatch();
	// console.log(window.location.pathname);

	const isLogin = (status) => {
		setIsAuth(status);
		document.body.classList.remove("login");
	};
	const isLogout = () => {
		setIsAuth(null);
		document.body.classList.add("login");
	};
	const isDarkTheme = (e) => {
		let value = e.target.checked;
		var v = document.getElementById("html");
		if (value == true) {
			v.classList.add("dark");
			setisdark(1);
			dispatch(onSwitchTheme(1));
		} else {
			v.classList.remove("dark");
			setisdark(0);
			dispatch(onSwitchTheme(0));
		}
	};

	return (
		<ToastProvider>
			<Router>
				<div
					data-url="https://rubick.left4code.com/page/side-menu/dark/dashboard-overview-1"
					className="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10"
				>
					<div className="mr-4 text-slate-600 dark:text-slate-200">
						Dark Mode
					</div>
					<div className="form-switch">
						<input
							type="checkbox"
							name="status"
							onChange={isDarkTheme}
							className="form-check-input"
						/>
					</div>
				</div>
				<div className="App">
					{/* window.location.pathname.startsWith("/job-questionaries/") ?
      <>
      <All.jobQuestionaries/>
      </>
      : */}
					{isAuth !== null &&
						window.location.pathname !== "/job-application" &&
						window.location.pathname !== "/security-entries" &&
						!window.location.pathname.startsWith(
							"/view-job-application-status"
						) ? (
						<>
							<All.MobileNav />
							<div className="flex mt-[4.7rem] md:mt-0">
								<All.Sidenav />
								<div className="content">
									<All.Header isLogout={isLogout} />
									<div className="content-inner">
										<Routing isdark={isdark} />

										<All.Footer />
									</div>
								</div>
							</div>
						</>
					) : window.location.pathname === "/job-application" ? (
						<All.JobApplication isLogin={isLogin} />
					) : window.location.pathname.startsWith(
						"/job-application-general"
					) ? (
						<All.JobApplicationGeneral isLogin={isLogin} />
					) : window.location.pathname.startsWith(
						"/view-job-application-status"
					) ? (
						<All.JobApplicationStatus isLogin={isLogin} />
					) : window.location.pathname === "/security-entries" ? (
						<All.SecurityEntries isLogin={isLogin} />
					) : window.location.pathname === "/client-login" ? (
						<All.LoginClient isLogin={isLogin} />
					) : (
						<All.Login isLogin={isLogin} />
					)}
				</div>
			</Router>
		</ToastProvider>
		// && !window.location.pathname.startsWith("/job-questionaries/")
	);
}

export default App;

// const mapStateToProps = ({ auth }) => {
//   const { currentTheme } =  auth;
//   return { currentTheme }
// };
// const mapDispatchToProps = {
// 	onSwitchTheme,
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App)
