import React from "react";
import Chart from "react-apexcharts";

const SidebarChartNew = ({ data }) => {
  if (!data) return <div>Loading...</div>;

  const chartData = {
    series: data.series, // Productivity & Unproductivity percentages
    options: {
      chart: { type: "pie", width: 280, height: 200 },
      labels: data.labels, // ["Productivity", "Unproductivity"]
      legend: { position: "bottom", fontSize: "10px", offsetY: -5 },
      dataLabels: {
        formatter: (val) => `${val.toFixed(2)}%`, // Show percentage with 2 decimals
      },
      responsive: [
        {
          breakpoint: 480,
          options: { chart: { width: 200, height: 180 } },
        },
      ],
      colors: ['#24A184', '#919191'],
    },
  };

  return (
    <div id="chart" style={{ maxWidth: "280px", margin: "0 auto" }}>
      <Chart options={chartData.options} series={chartData.series} type="pie" height={200} width={200} />
    </div>
  );
};

export default SidebarChartNew;
