// import React, { useEffect, useState } from "react";
// import { BASEURL } from "./BASEURL";
// import { FaCalendar, FaFilter, FaPencilAlt, FaTimes, FaTrash } from "react-icons/fa";
// import HashLoader from "react-spinners/HashLoader";
// import "react-tabulator/lib/styles.css";
// import "react-tabulator/lib/css/tabulator.min.css";
// import { ReactTabulator, reactFormatter } from "react-tabulator";
// import { format } from "date-fns";
// import ReactMonthPicker from "react-month-picker";
// import "react-month-picker/css/month-picker.css";
// import Modal from "react-modal";
// import regularDelete from '../assets/regular_delete.svg';
// import regularEdit from '../assets/regular_edit.svg';
// import { useLoadingContext } from "react-router-loading";
// import { FormControl, InputLabel, MenuItem, NativeSelect, Select, Tab, Tabs } from "@mui/material";
// Modal.setAppElement("#root");

// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//     padding: "0px",
//     border: "1px solid rgb(187, 181, 181)",
//     borderRadius: "14px",
//     overflowX: "hidden",
//     overflowY: "visible",
//     width: "600px",
//   },
// };

// const Leaves = () => {
//   let today = new Date();
//   let month = [];
//   month[0] = "1";
//   month[1] = "2";
//   month[2] = "3";
//   month[3] = "4";
//   month[4] = "5";
//   month[5] = "6";
//   month[6] = "7";
//   month[7] = "8";
//   month[8] = "9";
//   month[9] = "10";
//   month[10] = "11";
//   month[11] = "12";

//   let monthNumber = month[today.getMonth()];

//   let [loading, setLoading] = useState(false);
//   const [attdata, setattdata] = useState(null);
//   const [leavedata, setleavedata] = useState({});
//   const [isVisible, setisVisible] = useState(false);
//   const [monthYear, setmonthYear] = useState({});
//   const currmonth = monthNumber;
//   const [isopen, setIsOpen] = useState(false);
//   const curryear = today.getFullYear();
//   const [activeTab, setActiveTab] = useState("leave-type");
//   const handleTabChange = (tab) => {
//     setActiveTab(tab);
//   };

//   useEffect(() => {
//     const year = format(new Date(), "yyyy");
//     const month = format(new Date(), "MM");

//     console.log('Year:', year, 'Month:', month);

//     getData({
//       year,
//       month
//     });
//   }, []);

//   const [statusDropdownIndex, setStatusDropdownIndex] = useState(null);
//   const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

//   const toggleStatusDropdown = (index) => {
//     setStatusDropdownIndex(statusDropdownIndex === index ? null : index);
//   };


//   const toggleFilterDropdown = () => {
//     setFilterDropdownOpen(!filterDropdownOpen);
//   };

//   const columns = [
//     {
//       title: "Employee",
//       field: "employee",
//       formatter: reactFormatter(<DetailVal />),
//     },
//     { title: "Day", field: "day" },
//     {
//       title: "Type",
//       field: "type",
//       width: 150,
//       formatter: reactFormatter(<TypeVal />),
//     },
//     {
//       title: "Status",
//       field: "status",
//       width: 120,
//       formatter: reactFormatter(<StatusVal />),
//     },
//     { title: "From", field: "from" },
//     { title: "To", field: "to" },
//     { title: "Applied On", field: "appliedon" },
//   ];

//   function StatusVal(props) {
//     const rowData = props.cell._cell.row.data;
//     return (
//       <>
//         {rowData.status === 1 ? (
//           <>
//             <button className="btn btn-success btn-sm text-white">
//               Approved
//             </button>
//           </>
//         ) : rowData.status === 0 ? (
//           <button
//             onClick={() => ApproveLeave(rowData)}
//             className="btn btn-danger btn-sm text-white"
//           >
//             Pending
//           </button>
//         ) : null}
//       </>
//     );
//   }
//   function ApproveLeave(rowData) {
//     setleavedata(rowData);
//     setIsOpen(true);
//   }
//   function DetailVal(props) {
//     const rowData = props.cell._cell.row.data;
//     if (rowData.employee !== "") {
//       return (
//         <a
//           href={"employees/" + rowData.uuid}
//           rel="noreferrer"
//           target="_blank"
//           alt=""
//         >
//           <u>{rowData.employee}</u>
//         </a>
//       );
//     } else {
//       return "No data Available";
//     }
//   }
//   function TypeVal(props) {
//     const rowData = props.cell._cell.row.data;
//     if (rowData.employee === "") {
//       return null;
//     } else {
//       let result = rowData ? rowData.type.split(",") : null;
//       let resultcount = rowData ? rowData.leavecountbytype.split(",") : null;
//       let typearr = [];
//       result &&
//         result.forEach((element) => {
//           if (element === "LWP") {
//             typearr.push({ type: element + " : " + resultcount[0] });
//           } else if (element === "PL") {
//             typearr.push({ type: element + " : " + resultcount[1] });
//           } else if (element === "SL") {
//             typearr.push({ type: element + " : " + resultcount[2] });
//           } else if (element === "CL") {
//             typearr.push({ type: element + " : " + resultcount[3] });
//           } else if (element === "PTL") {
//             typearr.push({ type: element + " : " + resultcount[4] });
//           } else if (element === "MTL") {
//             typearr.push({ type: element + " : " + resultcount[5] });
//           } else {
//             typearr.push({ type: "-" });
//           }
//         });
//       return (
//         <>
//           {typearr &&
//             typearr.map((item, i) => {
//               return (
//                 <span className="ml-2">
//                   <span className="leavetype">{item.type}</span>
//                 </span>
//               );
//             })}
//         </>
//       );
//     }
//   }
//   const getData = async ({ year = format(new Date(), "yyyy"), month = format(new Date(), "MM") } = {}) => {
//     console.log('getData called with year:', year, 'and month:', month);
//     setLoading(true);
//     const myHeaders = new Headers();
//     const userdata = JSON.parse(localStorage.getItem("isAuth"));

//     if (userdata) {
//       const token = userdata.usertoken;
//       myHeaders.append("Authorization", token);
//     }
//     myHeaders.append("Content-Type", "application/json");

//     try {
//       const response = await fetch(BASEURL + `get-monthwise-leaves/all`, {
//         method: "POST",
//         headers: myHeaders,
//         body: JSON.stringify({
//           month,
//           year,
//           type: 1,
//           uuid: userdata.uuid,
//         }),
//       });
//       const result = await response.json();

//       if (result.status === 201) {
//         const formattedData = result.data.map((leave) => ({
//           empId: leave.user_id,
//           name: leave.name,
//           leaveDate: leave.start_date,
//           reason: leave.reason,
//           noOfDays: leave.noofdays,
//           leaveType: leave.leave_type,
//           action: leave.hrstatus,
//         }));
//         setleavedata(formattedData);
//       } else {
//         setleavedata([]);
//       }
//     } catch (error) {
//       console.error("Error fetching leave data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     getData()
//   }, [])
//   const handleOnDismiss = () => {
//     setisVisible(false);
//   };
//   const getStatusColor = (status) => {
//     switch (status) {
//       case "Approved":
//         return "green";
//       case "On Hold":
//         return "orange";
//       case "Reject":
//         return "red";
//       default:
//         return "black";
//     }
//   };
//   const handleOnChange = (year, month) => {
//     const monthNames = [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ];
//     let monthname = monthNames[month - 1];
//     setisVisible(false);
//     setmonthYear({ year, month: monthname });

//     getData({ year, month });
//   };

//   const showMonthPicker = (e) => {
//     setisVisible(true);
//     e.preventDefault();
//   };
//   const getMonthValue = () => {
//     const month = monthYear && monthYear.month ? monthYear.month : 0;
//     const year = monthYear && monthYear.year ? monthYear.year : 0;

//     let date = new Date();
//     let curryear = date.getFullYear();

//     const monthNames = [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ];

//     return month && year
//       ? `${month}-${year}`
//       : monthNames[currmonth - 1] + "-" + curryear;
//   };

//   function closeModal() {
//     setIsOpen(false);
//   }
//   return (
//     <>
//       <div>
//       </div>
//       <div>
//         <ul className="nav nav-tabs">
//           <li className="nav-item">
//             <button
//               style={{
//                 backgroundColor: activeTab === "leave-type" ? "#38b2ac" : "#fff",
//                 color: activeTab === "leave-type" ? "#fff" : "#000",
//                 border: "1px solid #ccc",
//                 padding: "10px 20px",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleTabChange("leave-type")}
//             >
//               Leave Type
//             </button>
//           </li>
//           <li className="nav-item">
//             <button
//               style={{
//                 backgroundColor: activeTab === "request" ? "#38b2ac" : "#fff",
//                 color: activeTab === "request" ? "#fff" : "#000",
//                 border: "1px solid #ccc",
//                 padding: "10px 20px",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleTabChange("request")}
//             >
//               Request
//             </button>
//           </li>
//           <li className="nav-item">
//             <button
//               style={{
//                 backgroundColor: activeTab === "absenteeism-report" ? "#38b2ac" : "#fff",
//                 color: activeTab === "absenteeism-report" ? "#fff" : "#000",
//                 border: "1px solid #ccc",
//                 padding: "10px 20px",
//                 cursor: "pointer",
//               }}
//               onClick={() => handleTabChange("absenteeism-report")}
//             >
//               Absenteeism Report
//             </button>
//           </li>
//           <li className="nav-item">
//             <button
//               style={{
//                 padding: "10px 20px",
//                 cursor: "pointer",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 marginLeft: "10px",
//               }}
//               onClick={toggleFilterDropdown}
//             >
//               <FaFilter style={{ fontSize: "18px", color: "#fff" }} />
//             </button>
//           </li>


//         </ul>

//         <div className="tab-content mt-3">
//           {activeTab === "leave-type" && (
//             <div id="leave-type" role="tabpanel">
//               <div style={{
//                 border: "1px solid #FC9A30", borderTopLeftRadius: "10px",
//                 borderTopRightRadius: "10px",
//               }}>
//                 <div
//                   style={{
//                     backgroundColor: "#FC9A30",
//                     color: "#fff",
//                     padding: "5px 10px",
//                     textAlign: "start",
//                     borderTopLeftRadius: "10px",
//                     borderTopRightRadius: "10px",
//                   }}
//                 >
//                   <h3>Leave</h3>
//                 </div>
//                 <div className="table-outlineBorder" style={{ border: "1px solid #FC9A30" }}>
//                   <div className="employee-table-container">
//                     <table className="employee-table" style={{ width: "100%" }}>
//                       <thead>
//                         <tr>
//                           <th>ID</th>
//                           <th>Leave Type</th>
//                           <th>Description</th>
//                           <th>#No of Leaves</th>
//                           <th>Action</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <td>8</td>
//                         <td>MTL</td>
//                         <td>Went For Movies</td>
//                         <td>182</td>
//                         <td>
//                           <div className="flex justify-between items-center">
//                             <div style={{ marginRight: '10px' }}>
//                               <img src={regularEdit} alt="Edit" />
//                             </div>
//                             <div>
//                               <img src={regularDelete} alt="Delete" />
//                             </div>
//                           </div>
//                         </td>

//                       </tbody>
//                       {/* <tbody>
//                         {loading ? (
//                           <tr>
//                             <td colSpan="7" style={{ textAlign: "center" }}>Loading...</td>
//                           </tr>
//                         ) : leavedata.length > 0 ? (
//                           leavedata.map((leave, index) => (
//                             <tr key={index} className="bg-white">
//                               <td>{leave.empId}</td>
//                               <td>{leave.name}</td>
//                               <td>{leave.leaveDate}</td>
//                               <td>{leave.reason}</td>
//                               <td>{leave.noOfDays}</td>
//                               <td>{leave.leaveType}</td>
//                               <td>
//                                 <select
//                                   defaultValue={leave.action}
//                                   style={{
//                                     color: getStatusColor(leave.action),
//                                     fontWeight: "bold",
//                                   }}
//                                   onChange={(e) => {
//                                     const selectedValue = e.target.value;
//                                     e.target.style.color = getStatusColor(selectedValue);
//                                     // Handle action update logic here
//                                   }}
//                                 >
//                                   <option value="Approved" style={{ color: "green" }}>
//                                     Approved
//                                   </option>
//                                   <option value="On Hold" style={{ color: "orange" }}>
//                                     On Hold
//                                   </option>
//                                   <option value="Reject" style={{ color: "red" }}>
//                                     Reject
//                                   </option>
//                                 </select>
//                               </td>
//                             </tr>
//                           ))
//                         ) : (
//                           <tr>
//                             <td colSpan="7" style={{ textAlign: "center" }}>No Data Available</td>
//                           </tr>
//                         )}
//                       </tbody> */}
//                     </table>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           {activeTab === "request" && (
//             <div id="request" role="tabpanel">
//               <div className="py-2">
//                 <div style={{
//                   border: "1px solid #FC9A30", borderTopLeftRadius: "10px",
//                   borderTopRightRadius: "10px",
//                 }}>
//                   <div
//                     style={{
//                       backgroundColor: "#FC9A30",
//                       color: "#fff",
//                       padding: "5px 10px",
//                       textAlign: "start",
//                       borderTopLeftRadius: "10px",
//                       borderTopRightRadius: "10px",
//                     }}
//                   >
//                     <h3>Leave</h3>
//                   </div>
//                   <div className="table-outlineBorder" style={{ border: "1px solid #FC9A30" }}>
//                     <div className="employee-table-container">
//                       {/* <table className="employee-table" style={{ width: "100%" }}>
//                         <thead>
//                           <tr>
//                             <th>EMP ID</th>
//                             <th>Name</th>
//                             <th>Leave Date</th>
//                             <th>Reason</th>
//                             <th>#No of Days</th>
//                             <th>Leave Type</th>
//                             <th>Action</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {loading ? (
//                             <tr>
//                               <td colSpan="7" style={{ textAlign: "center" }}>Loading...</td>
//                             </tr>
//                           ) : leavedata.length > 0 ? (
//                             leavedata.map((leave, index) => (
//                               <tr key={index} className="bg-white">
//                                 <td>{leave.empId}</td>
//                                 <td>{leave.empId}</td>
//                                 <td>{leave.name}</td>
//                                 <td>{leave.leaveDate}</td>
//                                 <td>{leave.reason}</td>
//                                 <td>{leave.noOfDays}</td>
//                                 <td>{leave.leaveType}</td>
//                                 <td>
//                                   <select
//                                     defaultValue={leave.action}
//                                     style={{
//                                       color: getStatusColor(leave.action),
//                                       fontWeight: "bold",
//                                     }}
//                                     onChange={(e) => {
//                                       const selectedValue = e.target.value;
//                                       e.target.style.color = getStatusColor(selectedValue);
//                                       // Handle action update logic here
//                                     }}
//                                   >
//                                     <option value="Approved" style={{ color: "green" }}>
//                                       Approved
//                                     </option>
//                                     <option value="On Hold" style={{ color: "orange" }}>
//                                       On Hold
//                                     </option>
//                                     <option value="Reject" style={{ color: "red" }}>
//                                       Reject
//                                     </option>
//                                   </select>
//                                 </td>
//                               </tr>
//                             ))
//                           ) : (
//                             <tr>
//                               <td colSpan="7" style={{ textAlign: "center" }}>No Data Available</td>
//                             </tr>
//                           )}
//                         </tbody>
//                       </table> */}
//                       <table
//                         className="employee-table"
//                         style={{ width: "100%" }}
//                       >
//                         <thead>
//                           <tr>
//                             <th className="whitespace-nowrap">EMP ID</th>
//                             <th className="whitespace-nowrap">Leave Date</th>
//                             <th className="whitespace-nowrap">
//                               #No of Days
//                             </th>

//                             <th className="whitespace-nowrap">
//                               Leave Type
//                             </th>
//                             <th className="whitespace-nowrap">
//                               Reason
//                             </th>
//                             <th className="whitespace-nowrap">
//                               Action
//                             </th>

//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr className="bg-white">
//                             <td className="text-left">
//                               4865
//                             </td>
//                             <td className="text-left">
//                               23/01/2025
//                             </td>
//                             <td className="text-left">
//                               2
//                             </td>
//                             <td className="text-left">
//                               SL
//                             </td>
//                             <td className="text-left">
//                               Suffering form Heavy fever
//                             </td>
//                             <td className="text-left">
//                               <div>
//                                 <FormControl fullWidth>
//                                   <NativeSelect
//                                     defaultValue="On Hold"
//                                     inputProps={{
//                                       name: "status",
//                                       id: "uncontrolled-native",
//                                     }}
//                                     style={{
//                                       color: getStatusColor("On Hold"),
//                                       fontWeight: "bold",
//                                     }}
//                                     onChange={(e) => {
//                                       const selectedValue = e.target.value;
//                                       e.target.style.color = getStatusColor(selectedValue); // Update color dynamically
//                                     }}
//                                   >
//                                     <option value="Approved" style={{ color: "green" }}>
//                                       Approved
//                                     </option>
//                                     <option value="On Hold" style={{ color: "orange" }}>
//                                       On Hold
//                                     </option>
//                                     <option value="Reject" style={{ color: "red" }}>
//                                       Reject
//                                     </option>
//                                   </NativeSelect>
//                                 </FormControl>
//                               </div>
//                             </td>
//                           </tr>

//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//           {/* Absenteeism Report Tab */}
//           {activeTab === "absenteeism-report" && (
//             <div id="absenteeism-report" role="tabpanel">
//               <div style={{
//                 border: "1px solid #FC9A30", borderTopLeftRadius: "10px",
//                 borderTopRightRadius: "10px",
//               }}>
//                 <div
//                   style={{
//                     backgroundColor: "#FC9A30",
//                     color: "#fff",
//                     padding: "5px 10px",
//                     textAlign: "start",
//                     borderTopLeftRadius: "10px",
//                     borderTopRightRadius: "10px",
//                   }}
//                 >
//                   <h3>Absenteeism Report </h3>
//                 </div>
//                 <div className="table-outlineBorder" style={{ border: "1px solid #FC9A30" }}>
//                   <div className="employee-table-container">
//                     <table className="employee-table" style={{ width: "100%" }}>
//                       <thead>
//                         <tr>
//                           <th>EMP ID</th>
//                           <th>EMP Name</th>
//                           <th>Department</th>
//                           <th>Total Leave</th>
//                           <th>LWP</th>
//                           <th>PL</th>
//                           <th>SL</th>
//                           <th>CL</th>
//                           <th>PTL</th>
//                           <th>MTL</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <tr>
//                           <td>1196</td>
//                           <td>Bhavesh Prajapti </td>
//                           <td>Software Devlopment</td>
//                           <td>5</td>              <td>0</td>                          <td>0</td>                          <td>0</td>                          <td>0</td>                          <td>0</td>                          <td>0</td>                        </tr>                      </tbody>
//                     </table>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       <Modal isOpen={isopen} style={customStyles} contentLabel="">
//         <div className="box">
//           <div className="flex flex-col sm:flex-row items-center p-3 border-b border-slate-200/60 dark:border-darkmode-400">
//             <h2 className="font-medium text-base mr-auto">Approve Leave</h2>
//             <button
//               className="btn btn-outline-secondary hidden sm:flex"
//               onClick={() => closeModal()}
//             >
//               <FaTimes />
//             </button>
//           </div>
//           <div id="vertical-form" className="p-3">
//             <div className="preview">
//               <div>
//                 <label for="vertical-form-1" className="form-label">
//                   Reason
//                 </label>
//                 <input
//                   id="vertical-form-1"
//                   type="text"
//                   className="form-control"
//                   disabled
//                   value={leavedata.reason}
//                 />
//               </div>
//               <div className="mt-3">
//                 <label for="vertical-form-2" className="form-label">
//                   Password
//                 </label>
//                 <input
//                   id="vertical-form-2"
//                   type="text"
//                   className="form-control"
//                   placeholder="secret"
//                 />
//               </div>
//               <button className="btn btn-primary mt-5">Approve</button>
//             </div>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default Leaves;



import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { FaCalendar, FaFilter, FaPencilAlt, FaTimes, FaTrash } from "react-icons/fa";
import HashLoader from "react-spinners/HashLoader";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format, parseISO } from "date-fns";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import Modal from "react-modal";
import regularDelete from '../assets/regular_delete.svg';
import regularEdit from '../assets/regular_edit.svg';
import { useLoadingContext } from "react-router-loading";
import { FormControl, InputLabel, MenuItem, NativeSelect, Select, Tab, Tabs } from "@mui/material";
import swal from "sweetalert";
import CommonModal from "./admin/CommonModal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    border: "1px solid rgb(187, 181, 181)",
    borderRadius: "14px",
    overflowX: "hidden",
    overflowY: "visible",
    width: "600px",
  },
};

const Leaves = () => {
  let today = new Date();
  let month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  let monthNumber = month[today.getMonth()];

  let [loading, setLoading] = useState(false);
  let [modalLoading, setModalLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [leavedata, setleavedata] = useState({});
  const [isVisible, setisVisible] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [requestedData, setRequestedData] = useState([])
  const [absenteeismData, setAbsenteeismData] = useState([]);
  const [monthYear, setmonthYear] = useState({});
  const [leavetypeData, setLeaveTypeData] = useState()
  const [selectedMonth, setSelectedMonth] = useState({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
  const [loaderAbsenteism, setLoaderAbsenteism] = useState(true)
  const [IsVisible, setIsVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [newComment, setNewComment] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split("T")[0]
  );
  const [data, setdata] = useState({
    leaveType: ""
  });
  const currmonth = monthNumber;
  // const [isopen, setIsOpen] = useState(false);
  const curryear = today.getFullYear();

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false)
  const [isSecondModal, setIsSecondModal] = useState(false)
  const [selectedData, setSelectedData] = useState()
  const [leaveDays, setLeaveDays] = useState({
    LWP: "0",
    CL: "0",
    SL: "0",
    PL: "0",
    PTL: "0",
  });
  const [error, setError] = useState("");
  const totalDays = Object.values(leaveDays).reduce((sum, value) => sum + parseFloat(value || 0), 0).toFixed(1);

  const toggleRowSelection = (row) => {
    setSelectedRows((prevSelected) => {
      const isSelected = prevSelected.some((selectedRow) => selectedRow.request_id === row.request_id);
      return isSelected
        ? prevSelected.filter((selectedRow) => selectedRow.request_id !== row.request_id)
        : [...prevSelected, { request_id: row.request_id, user_id: row.user_id, hrstatus: row.hrstatus, noofdays: row.noofdays, leaveType: row.leaveType }];
    });
  };

  // Toggle selection for all rows
  const toggleSelectAll = () => {
    if (selectedRows.length === requestedData.length) {
      setSelectedRows([]); // Unselect all
    } else {
      setSelectedRows(requestedData.map(({ request_id, user_id, hrstatus, noofdays, leaveType }) => ({ request_id, user_id, hrstatus, noofdays, leaveType }))); // Select all
    }
  };


  // const handleInputChange = (e, type) => {
  //   let value = e.target.value;

  //   // Prevent non-numeric values
  //   if (value === "" || isNaN(value)) {
  //     value = "0";
  //   }

  //   setLeaveDays({
  //     ...leaveDays,
  //     [type]: value,
  //   });
  // };

  const [leaveStock, setLeaveStock] = useState()
  const handleCLose = () => {
    setModalOpen(false)
    setLeaveDays({
      LWP: "0",
      CL: "0",
      SL: "0",
      PL: "0",
      PTL: "0",
    })
  }
  let defaultValue = "On Hold"
  const [selectedStatus, setSelectedStatus] = useState(defaultValue);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setIsOpen(false);
  };

  const [activeTab, setActiveTab] = useState("request");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const year = format(new Date(), "yyyy");
    const month = format(new Date(), "MM");


    getData({
      year,
      month
    });
  }, []);
  const getMonthValue = () => {
    // Format selected month and year for display in the input
    return `${selectedMonth.month}/${selectedMonth.year}`;
  };


  const [statusDropdownIndex, setStatusDropdownIndex] = useState(null);
  const [newstatusDropdownIndex, setnewStatusDropdownIndex] = useState(null);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [filterDropdownOpen2, setFilterDropdownOpen2] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const toggleStatusDropdown = (index) => {
    setStatusDropdownIndex(statusDropdownIndex === index ? null : index);
  };

  const togglenewStatusDropdown = (index) => {
    setnewStatusDropdownIndex(newstatusDropdownIndex === index ? null : index);
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!filterDropdownOpen);
  };
  const toggleFilterDropdown2 = () => {
    setFilterDropdownOpen2(!filterDropdownOpen2);
  };

  const filteredFilteredTasks = React.useMemo(() => {
    return absenteeismData?.filter((item) => {
      const search = searchTerm.toLowerCase();
      return (
        item?.username?.toLowerCase().includes(search) ||
        item?.name?.toLowerCase().includes(search) ||
        item?.department?.toLowerCase().includes(search) ||
        String(item?.total_noofdays).toLowerCase().includes(search)
      );
    });
  }, [absenteeismData, searchTerm]);

  const filteredData = React.useMemo(() => {
    return requestedData?.filter((table) => {
      const search = searchTerm.toLowerCase();
      return (
        table?.name?.toLowerCase().includes(search) ||
        table?.leave_type?.toLowerCase().includes(search) ||
        String(table?.noofdays).toLowerCase().includes(search) ||
        table?.reason?.toLowerCase().includes(search)
      );
    });
  }, [requestedData, searchTerm]);

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Pending";
      case 2:
        return "Approved";
      case 3:
        return "Rejected";
    }
  };


  const columns = [
    {
      title: "Employee",
      field: "employee",
      formatter: reactFormatter(<DetailVal />),
    },
    { title: "Day", field: "day" },
    {
      title: "Type",
      field: "type",
      width: 150,
      formatter: reactFormatter(<TypeVal />),
    },
    {
      title: "Status",
      field: "status",
      width: 120,
      formatter: reactFormatter(<StatusVal />),
    },
    { title: "From", field: "from" },
    { title: "To", field: "to" },
    { title: "Applied On", field: "appliedon" },
  ];

  function StatusVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.status === 1 ? (
          <>
            <button className="btn btn-success btn-sm text-white">
              Approved
            </button>
          </>
        ) : rowData.status === 0 ? (
          <button
            onClick={() => ApproveLeave(rowData)}
            className="btn btn-danger btn-sm text-white"
          >
            Pending
          </button>
        ) : null}
      </>
    );
  }
  function ApproveLeave(rowData) {
    setleavedata(rowData);
    setIsOpen(true);
  }
  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.employee !== "") {
      return (
        <a
          href={"employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.employee}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }
  function TypeVal(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.employee === "") {
      return null;
    } else {
      let result = rowData ? rowData.type.split(",") : null;
      let resultcount = rowData ? rowData.leavecountbytype.split(",") : null;
      let typearr = [];
      result &&
        result.forEach((element) => {
          if (element === "LWP") {
            typearr.push({ type: element + " : " + resultcount[0] });
          } else if (element === "PL") {
            typearr.push({ type: element + " : " + resultcount[1] });
          } else if (element === "SL") {
            typearr.push({ type: element + " : " + resultcount[2] });
          } else if (element === "CL") {
            typearr.push({ type: element + " : " + resultcount[3] });
          } else if (element === "PTL") {
            typearr.push({ type: element + " : " + resultcount[4] });
          } else if (element === "MTL") {
            typearr.push({ type: element + " : " + resultcount[5] });
          } else {
            typearr.push({ type: "-" });
          }
        });
      return (
        <>
          {typearr &&
            typearr.map((item, i) => {
              return (
                <span className="ml-2">
                  <span className="leavetype">{item.type}</span>
                </span>
              );
            })}
        </>
      );
    }
  }
  const handleOnChange = async (year, month) => {
    setSelectedMonth({ year, month });
    setIsVisible(false);
    if (activeTab === "request") {
      setRequestedData([])
      fetchLeaveRequests()
    }
    fetchAbsenteeismReport()
    // await fetchAttendanceData(selectedDepartment, month, year)
  };
  const getData = async ({ year = format(new Date(), "yyyy"), month = format(new Date(), "MM") } = {}) => {
    setLoading(true);
    const myHeaders = new Headers();
    const userdata = JSON.parse(localStorage.getItem("isAuth"));

    if (userdata) {
      const token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(BASEURL + `get-monthwise-leaves/all`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          month,
          year,
          type: 1,
          uuid: userdata.uuid,
        }),
      });
      const result = await response.json();

      if (result.status === 201) {
        const formattedData = result.data.map((leave) => ({
          empId: leave.user_id,
          name: leave.name,
          leaveDate: leave.start_date,
          reason: leave.reason,
          noOfDays: leave.noofdays,
          leaveType: leave.leave_type,
          action: leave.hrstatus,
        }));
        setleavedata(formattedData);
      } else {
        setleavedata([]);
      }
    } catch (error) {
      console.error("Error fetching leave data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData()
  }, [])

  const approveLeave = async (data) => {
    setModalLoading(true)
    try {
      const response = await fetch(BASEURL + "approve-leave", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Fix: Set content type
        },
        body: JSON.stringify(data), // Fix: Stringify data
      });

      if (!response.ok) {
        throw new Error("Failed to approve leave");
      }

      const responseData = await response.json();
      return responseData; // Fix: Return the response data

    } catch (error) {
      console.error("Error approving leave:", error);
    } finally {
      handleCLose()
      fetchLeaveRequests()
      setModalLoading(false)
    }
  };


  const handleSubmit = () => {
    try {
      if (parseFloat(totalDays) !== parseFloat(selectedData?.noofdays)) {
        setError("Total leave days must match the requested leave days.");
        return;
      }

      setError(""); // Clear error if validation passes


      const formattedLeaveDays = ["LWP", "CL", "SL", "PL", "PTL"]
        .map((type) => leaveDays[type] || 0)
        .join(", ");

      const formatedLeaveTypes = Object.entries(leaveDays)
        .filter(([_, value]) => parseFloat(value) > 0) // Keep only non-zero values
        .map(([key, _]) => key) // Extract leave type names
        .join(", ");

      const payload = {
        leavecountbytype: formattedLeaveDays,
        comment: comment.trim(),
        request_id: selectedData?.request_id,
        leave_type: formatedLeaveTypes,
        hrstatus: selectedData?.hrstatus,
        user_id: selectedData?.user_id
      };
      approveLeave(payload)
    } catch (err) {
      console.error(err);

    } finally {
      // handleCLose()
      fetchLeaveRequests()
    }



    // Send `payload` in API request
    // dispatch(submitLeaveRequest(payload));
  };

  const distributeLeaveDays = (leaveType, days) => {
    let remainingDays = days;
    const updatedLeaveDays = { ...leaveDays };

    const leaveOrder = ["CL", "PL", "LWP"];

    for (let type of leaveOrder) {
      if (remainingDays <= 0) break;
      const availableLeave = parseFloat(leaveStock?.[type] || 0);

      if (type === leaveType || availableLeave > 0) {
        const assignDays = Math.min(remainingDays, availableLeave);
        updatedLeaveDays[type] = assignDays.toString();
        remainingDays -= assignDays;
      }
    }

    setLeaveDays(updatedLeaveDays);
  };

  useEffect(() => {
    if (selectedData?.leaveType && selectedData?.noofdays) {
      distributeLeaveDays(selectedData.leaveType, parseFloat(selectedData.noofdays));
    }
  }, [selectedData, leaveStock]);

  const handleInputChange = (e, type) => {
    setLeaveDays({ ...leaveDays, [type]: e.target.value });
  };


  const modalContent = (<>
    <div className="">
      <div
        className="flex p-2 py-4"
        style={{
          justifyContent: "space-between",
          backgroundColor: "#FC9A30",
          color: "#fff",
          padding: "10px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          fontSize: "15px"
        }}
      >
        <div className="ml-2">Approve Leave</div>
        <div className="cursor-pointer mr-2" onClick={handleCLose}>
          x
        </div>
      </div>
      <div className="px-4">
        <div className="flex">
          <h6 className="my-4">Leaves Days: {selectedData?.noofdays}</h6>
        </div>

        <div className="table-outlineBorder my-3" style={{ border: "1px solid #FC9A30" }}>
          <div className="employee-table-container">
            <table className="modal-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Leave Type</th>
                  <th>Available Leave</th>
                  <th>Enter no. Days</th>
                </tr>
              </thead>
              <tbody>
                {["LWP", "CL", "SL", "PL", "PTL"].map((type) => (
                  <tr key={type}>
                    <td className="p-">{type}</td>
                    <td className="p-">{leaveStock?.[type]}</td>
                    <td className="p-">
                      <input
                        type="number"
                        step="0.1"
                        min="0"
                        value={leaveDays[type]}
                        onChange={(e) => handleInputChange(e, type)}
                        className="w-full p-"
                        disabled={leaveStock?.[type] === "0"} // Ensure this condition is in place
                      />

                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className="p-">
                    <strong>Total</strong>
                  </td>
                  <td className="p-">
                    <input
                      type="number"
                      value={totalDays}
                      readOnly
                      className="w-full p- bg-gray-200"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {error && <span className="text-red-500 text-sm my-2">{error}</span>}

        <div>
          <textarea
            type="text"
            className="modal-input my-4"
            rows={1}
            placeholder="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />

        </div>
        <div className="flex justify-end mb-2">
          {/* <button className="modal-button-close" onClick={handleCLose}>
            Close
          </button> */}
          <button disabled={modalLoading} className="modal-button-new" onClick={!modalLoading && handleSubmit}>{modalLoading ? "Loading ..." : "Submit"}</button>
        </div>
      </div>
    </div>
  </>)




  const handleSubmitModal = () => {

    console.log(selectedRows, 'selectedRows')
    if (!newComment.trim()) {
      alert("Please enter a comment.");
      return;
    }

    const payload = selectedRows.map(({ leaveType, request_id, user_id, hrstatus, noofdays }) => ({
      leaveType,
      comment: newComment.trim(),
      request_id,
      hrstatus,
      user_id,
      noofdays
    }));

    approveAllRequest(payload)

    console.log("Submitting leave request:", payload);

    // Call your API or handle the submission logic here
  };


  const selectmodalContent = (
    <>
      <div
        className="flex p-2 py-4"
        style={{
          justifyContent: "space-between",
          backgroundColor: "#FC9A30",
          color: "#fff",
          padding: "10px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          fontSize: "15px"
        }}
      >
        <div className="ml-2">Approve Select Leave</div>
        <div className="cursor-pointer mr-2" onClick={() => setIsSecondModal(false)}>
          x
        </div>
      </div>
      <div className="p-2 px-4 py-4 my-3">

        <div>
          <textarea
            type="text"
            className="modal-input"
            rows={1}
            placeholder="Comment"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />


        </div>

        <button className="modal-button-new mt-2" onClick={handleSubmitModal}>
          Submit
        </button>
      </div>
    </>
  )

  const showMonthPicker = (e) => {
    e.preventDefault();
    setIsVisible(true); // Show the month picker
  };

  // const fetchLeaveRequests = async (status = null) => {
  //   const myHeaders = new Headers();
  //   try {
  //     // const response = await fetch(BASEURL + `leave-requests/${userUuid}/${usetrId} `, {
  //     const response = await fetch(BASEURL + `all-leave-requests `, {
  //       method: "post",
  //       headers: myHeaders,
  //       body: JSON.stringify({ status: status || undefined }),
  //     });
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch leave requests");
  //     }
  //     const responsedata = await response.json();
  //     const paidleaveData = responsedata?.leavesData[0]?.PL
  //     // setPaidLeave(paidleaveData)
  //     // Filter leave requests for the logged-in user
  //     const filteredLeaveRequests = responsedata?.data
  //     // const filteredLeaveRequests = responsedata?.data.filter(request => request.user_id === userdata.id);

  //     setLeaveRequests(filteredLeaveRequests);
  //   } catch (error) {
  //     console.error("Error fetching leave requests:", error);
  //   }
  // };

  const fetchLeaveRequests = async (status = null) => {
    try {
      const response = await fetch(BASEURL + "all-leave-requests", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          status: status || undefined,
          startDate,
          endDate,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch leave requests");
      }

      const responseData = await response.json();

      setRequestedData(responseData?.data || []); // Ensure it updates state properly
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const fetchAbsenteeismReport = async () => {
    setLoaderAbsenteism(true)
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));

    if (userdata) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
    }

    try {
      const response = await fetch(BASEURL + "absenteeism-report", {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          month: selectedMonth.month, // Send only month
          year: selectedMonth.year    // Send only year
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch absenteeism report");
      }

      const responsedata = await response.json();
      setAbsenteeismData(responsedata?.data || []);

    } catch (error) {
      console.error("Error fetching absenteeism report:", error);
    } finally {
      setLoaderAbsenteism(false)
    }
  };

  const FetchLeaveType = async () => {

    try {
      const response = await fetch(BASEURL + `leaves-type-list`, {
        method: "get",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch leave requests");
      }
      const responsedata = await response.json();
      setLeaveTypeData(responsedata[0])
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  };

  const approveAllRequest = async (payload) => {
    try {
      const response = await fetch(BASEURL + "approve-multiple-leave", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload), // <-- Send payload directly
      });

      if (!response.ok) {
        throw new Error("Failed to fetch leave requests");
      }

      const responseData = await response.json();
      console.log("Response:", responseData);

    } catch (error) {
      console.error("Error fetching leave requests:", error);
    } finally {
      fetchLeaveRequests()
      setIsSecondModal(false)
    }
  };


  useEffect(() => {
    // Fetch initial leave requests
    fetchLeaveRequests();
    fetchAbsenteeismReport();
    FetchLeaveType()
  }, []);
  const handleOnDismiss = () => {
    setisVisible(false);
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "green";
      case "On Hold":
        return "orange";
      case "Reject":
        return "red";
      default:
        return "black";
    }
  };

  const handleStatus = (data, status) => {
    if (status === "2") {
      handleDelete(data.request_id);
    } else {
      fetchUserLeaves(data?.user_id);
      setSelectedData(data);
      setModalOpen(true);
    }
  };


  const handleFilter = async (status) => {
    setStatusDropdownIndex(status);
    setRequestedData([])
    // Ensure state updates before API call
    setTimeout(() => {
      fetchLeaveRequests(status);
    }, 100);
  };

  const fetchUserLeaves = async (uuid) => {
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    try {
      // const response = await fetch(BASEURL + `leave-requests/${userUuid}/${usetrId} `, {
      const response = await fetch(BASEURL + `user-leaves/${uuid} `, {
        method: "get",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch leave requests");
      }
      const responsedata = await response.json();
      setLeaveStock(responsedata?.data[0])
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    }
  }

  // const handleStatusConfirmation = (projectId, newStatus) => {
  //   Swal({
  //     title: "Are you sure?",
  //     text: "Do you want to change the project status?",
  //     icon: "warning",
  //     buttons: ["Cancel", "Yes"],
  //     dangerMode: true,
  //   }).then((willChange) => {
  //     if (willChange) {
  //       // handleStatusChange(projectId, newStatus);
  //     }
  //   });
  // };
  function closeModal() {
    setIsOpen(false);
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (range) => {
    if (!range || !range[0] || !range[1]) {
      console.error("Invalid date range!");
      return;
    }

    const newStartDate = new Date(range[0]).toISOString().split("T")[0];
    const newEndDate = new Date(range[1]).toISOString().split("T")[0];

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    // Ensure fetch is called after state update
    setTimeout(() => {
      fetchLeaveRequests();
    }, 100);
  };

  const handleDelete = async (requestId) => {
    console.log("Deleting request with ID:", requestId);
    // Show SweetAlert confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this request!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(
            BASEURL + `reject-leave-request/${requestId}`,
            {
              method: "PUT",
            }
          );
          if (!response.ok) {
            throw new Error("Failed to reject leave request");
          }
          // Remove the deleted leave request from the state
          // setLeaveRequests((prevLeaveRequests) =>
          //   prevLeaveRequests.filter(
          //     (request) => request.request_id !== requestId
          //   )
          // );
          fetchLeaveRequests()
          // Show success message with SweetAlert
          swal(
            "Success!",
            "Leave request deleted successfully!",
            "success"
          );
        } catch (error) {
          console.error("Error deleting leave request:", error);
          // Show error message with SweetAlert
          swal("Error!", "Failed to delete leave request", "error");
        }
      } else {
        // User clicked cancel, do nothing
      }
    });
  };

  return (
    <>
      <div>
      </div>
      <div>
        <div className="flex justify-between">
          <ul className="nav nav-tabs border-0">
            <li className="nav-item">
              <button
                style={{
                  backgroundColor: activeTab === "request" ? "#38b2ac" : "#fff",
                  color: activeTab === "request" ? "#fff" : "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabChange("request")}
              >
                Request
              </button>
            </li>
            <li className="nav-item">
              <button
                style={{
                  backgroundColor: activeTab === "leave-type" ? "#38b2ac" : "#fff",
                  color: activeTab === "leave-type" ? "#fff" : "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabChange("leave-type")}
              >
                Leave Type
              </button>
            </li>

            <li className="nav-item">
              <button
                style={{
                  backgroundColor: activeTab === "absenteeism-report" ? "#38b2ac" : "#fff",
                  color: activeTab === "absenteeism-report" ? "#fff" : "#000",
                  border: "1px solid #ccc",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
                onClick={() => handleTabChange("absenteeism-report")}
              >
                Absenteeism Report
              </button>
            </li>

            {/* Conditionally render the filter icon */}
            {activeTab === "request" && (
              <li className="nav-item" style={{ position: "relative" }}>
                <button
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                  onClick={toggleFilterDropdown}
                >
                  <FaFilter style={{ fontSize: "18px" }} />
                </button>
                {filterDropdownOpen && (
                  <div
                    className="absolute left-0 top-full w-40 bg-white border-gray-200 shadow-lg rounded-md z-10"
                    style={{ top: "100%", marginTop: "4px" }}
                  >
                    <ul className="py-1">
                      {[
                        { status: "All", label: "All" },
                        { status: "Approved", label: "Approved" },
                        { status: "Rejected", label: "Rejected" },
                        { status: "Pending", label: "Pending" },
                        { status: "On Probation", label: "On Probation" },
                        // { status: "Medical", label: "Medical" },
                      ].map((option) => (
                        <li
                          key={option.status}
                          className={`px-4 py-2 text-sm cursor-pointer text-left 
                  ${statusDropdownIndex === option.status
                              ? "text-white font-semibold"
                              : "text-gray-700"
                            }`}
                          style={{
                            backgroundColor:
                              statusDropdownIndex === option.status ? "#fc9a30" : "",
                            borderRadius: "5px",
                            margin: "0 auto",
                            width: "90%",
                          }}
                          onClick={() => handleFilter(option.status)}
                          onMouseEnter={(e) =>
                            (e.target.style.backgroundColor = "#fc9a30")
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.backgroundColor = "")
                          }
                        >
                          {option.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            )}
          </ul>
          <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">


            <input
              id="tabulator-html-filter-value"
              type="text"
              onChange={handleSearchChange}
              className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0 mr-1"
              placeholder="Search..."
            />

            {
              activeTab === "request" &&

              <DateRangePicker
                clearIcon=""
                calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
                className="form-control"
                onChange={handleDateChange}
                value={[new Date(startDate), new Date(endDate)]} // Prefill with state values
              />
            }
            {
              activeTab === "absenteeism-report"
              &&

              <>
                <div className="input-group ml-2  mt-4 calenderdate" style={{
                  marginTop: "8px",
                }}>
                  <input
                    type="text"
                    style={{ fontSize: "12px" }}
                    onFocus={(e) => showMonthPicker(e)}
                    value={getMonthValue()}
                    className="form-control calenderdate1"
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    onChange={() => { }}
                  />
                  <div className="input-group-append calendaricon">
                    <FaCalendar
                      style={{
                        fontSize: "12px",
                      }}
                      onClick={(e) => showMonthPicker(e)}
                    />
                  </div>
                  <ReactMonthPicker
                    show={IsVisible}
                    lang={[
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ]}
                    value={selectedMonth}
                    onChange={handleOnChange}
                    onDismiss={handleOnDismiss}
                  />
                </div>
              </>
            }

          </div>
        </div>
        <div className="tab-content ">
          <div className="flex justify-end my-2">
            {
              activeTab === "request" &&
              selectedRows?.length > 0 &&
              <button className="btn btn-primary"
                style={
                  { backgroundColor: '#38b2ac', border: "none", width: "100px", marginTop: "5px" }
                } onClick={() => setIsSecondModal(true)}>
                Approve all
              </button>
            }
          </div>
          {activeTab === "leave-type" && (
            <div id="leave-type" role="tabpanel">
              <div style={{
                border: "1px solid #FC9A30", borderTopLeftRadius: "10px",
                marginTop: "8px",
                borderTopRightRadius: "10px",
              }}>
                <div
                  style={{
                    backgroundColor: "#FC9A30",
                    color: "#fff",
                    padding: "5px 10px",
                    textAlign: "start",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <h3>Leave</h3>
                </div>
                <div className="table-outlineBorder" style={{ border: "1px solid #FC9A30" }}>
                  <div className="employee-table-container">
                    <table className="employee-table" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Leave Type</th>
                          <th>Description</th>
                          <th>#No of Leaves</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          leavetypeData?.map((item, i) => (
                            <tr key={i}>
                              <td>
                                {item?.type_id}
                              </td>
                              <td>
                                {item?.name}
                              </td>
                              <td>
                                {item?.description}
                              </td>
                              <td>
                                {item?.no}
                              </td>
                              <td>
                                <div className="flex justify-between items-center">
                                  <div style={{ marginRight: '10px', cursor: "pointer" }}>
                                    <img src={regularEdit} alt="Edit" />
                                  </div>
                                  <div style={{
                                    cursor: "pointer"
                                  }}>
                                    <img src={regularDelete} alt="Delete" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                        {/* <td>8</td>
                        <td>MTL</td>
                        <td>Went For Movies</td>
                        <td>182</td>
                        <td>
                          <div className="flex justify-between items-center">
                            <div style={{ marginRight: '10px' }}>
                              <img src={regularEdit} alt="Edit" />
                            </div>
                            <div>
                              <img src={regularDelete} alt="Delete" />
                            </div>
                          </div>
                        </td> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === "request" && (
            <div id="request" role="tabpanel">
              <div className="">
                <div style={{
                  border: "1px solid #FC9A30", borderTopLeftRadius: "10px",
                  marginTop: "8px",
                  borderTopRightRadius: "10px",
                }}>

                  {/* <tbody>
                    {leaveRequests.map((table) => (
                      <tr key={table.user_id}>
                        <td>
                          {table.start_date && table.end_date ? (
                            table.start_date === table.end_date ? (
                              format(parseISO(table.start_date), "dd/MM/yyyy")
                            ) : (
                              `${format(parseISO(table.start_date), "dd/MM/yyyy")} to ${format(parseISO(table.end_date), "dd/MM/yyyy")}`
                            )
                          ) : (
                            "N/A" // Fallback text if the dates are missing
                          )}
                        </td>

                        <td>{table.noofdays}</td>
                        <td>sl</td>
                        <td>{table.reason}</td>
                        <td>
                          {table.hrstatus === 0 ? (
                            <span style={{ color: "#FC9A30" }}>Pending</span>
                          ) : table.hrstatus === 1 ? (
                            <span style={{ color: "#24A184" }}>Approved</span>
                          ) : (
                            <span style={{ color: "#FB7474" }}>Rejected</span>
                          )}
                        </td>
                      </tr>
                    ))}
                    {leaveRequests.length === 0 && (
                      <tr><td colSpan="5">No data available</td></tr>
                    )}
                  </tbody> */}
                  <div style={{
                    // border: "1px solid #FC9A30",
                    // padding: "10px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",

                  }}>
                    <div
                      style={{
                        backgroundColor: "#FC9A30",
                        color: "#fff",
                        padding: "12px",
                        textAlign: "start",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      <h3>Leave Details </h3>
                    </div>
                    <div className="table-outlineBorder"
                      style={{
                        border: "1px solid #FC9A30",
                      }}>
                      <table className="employee-table" style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                onChange={toggleSelectAll}
                                checked={selectedRows.length === requestedData.length && requestedData.length > 0}
                              />
                            </th>
                            <th>Leave Date</th>
                            <th>Name</th>
                            <th>#No of Days</th>
                            <th>Leave Type</th>
                            <th>Reason</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData?.map((table, i) => (
                            <tr key={`${table.user_id}-${table.start_date}-${table.end_date}`}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedRows.some((row) => row.request_id === table.request_id)}
                                  onChange={() => toggleRowSelection(table)}
                                />
                              </td>
                              <td>
                                {table.start_date && table.end_date ? (
                                  table.start_date === table.end_date ? (
                                    format(parseISO(table.start_date), "dd/MM/yyyy")
                                  ) : (
                                    `${format(parseISO(table.start_date), "dd/MM/yyyy")} to ${format(parseISO(table.end_date), "dd/MM/yyyy")}`
                                  )
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{table?.name}</td>
                              <td>{table.noofdays}</td>
                              <td>{table.leave_type ? table.leave_type : table.leaveType}</td>
                              <td style={{ width: "40%", textWrap: "wrap" }}>{table.reason}</td>
                              <td>
                                <div className="flex">
                                  <div>
                                    {table.hrstatus === 0 ? (
                                      <span style={{ color: "#FC9A30" }}>Pending</span>
                                    ) : table.hrstatus === 1 ? (
                                      <span style={{ color: "#24A184" }}>Approved</span>
                                    ) : (
                                      <span style={{ color: "#FB7474" }}>Rejected</span>
                                    )}
                                  </div>
                                  <div className="relative">
                                    <button
                                      onClick={() => togglenewStatusDropdown(i)}
                                      className="text-sm bg-white border-gray-300 pt-0 rounded-md shadow-sm flex items-center justify-between w-36 text-gray-700 text-left"
                                    >
                                      <span className="ml-2">{newstatusDropdownIndex === i ? "▲" : "▼"}</span>
                                    </button>
                                    {newstatusDropdownIndex === i && (
                                      <div className="absolute left-0 mt-1 w-40 bg-white border-gray-200 shadow-lg rounded-md z-10">
                                        <ul className="py-1">
                                          {[
                                            { status: "0", label: "Pending" },
                                            { status: "1", label: "Approved" },
                                            { status: "2", label: "Rejected" },
                                          ].map((option) => (
                                            <li
                                              key={option.status}
                                              onClick={() => handleStatus(table, option.status)}
                                              className={`px-4 py-2 text-sm cursor-pointer text-left ${String(table.hrstatus) === String(option.status) ? "text-white font-semibold" : "text-gray-700 hover:bg-gray-100"
                                                }`}
                                              style={{
                                                backgroundColor: String(table.hrstatus) === String(option.status) ? "#fc9a30" : "",
                                                borderRadius: "5px",
                                                margin: "0 auto",
                                                width: "90%",
                                              }}
                                            >
                                              {option.label}
                                            </li>

                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {filteredData.length === 0 && (
                            <tr>
                              <td colSpan="7">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Absenteeism Report Tab */}
          {activeTab === "absenteeism-report" && (
            <div id="absenteeism-report" role="tabpanel">
              <div style={{
                border: "1px solid #FC9A30", borderTopLeftRadius: "10px",
                marginTop: "8px",
                borderTopRightRadius: "10px",
              }}>
                <div
                  style={{
                    backgroundColor: "#FC9A30",
                    color: "#fff",
                    padding: "5px 10px",
                    textAlign: "start",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <h3>Absenteeism Report </h3>
                </div>
                <div className="table-outlineBorder" style={{ border: "1px solid #FC9A30" }}>
                  <div className="employee-table-container">

                    {
                      loaderAbsenteism ?

                        <>
                          <div style={{
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}>
                            <HashLoader
                              color="#5755d9"
                              size={30}

                            />
                          </div>
                        </>
                        :
                        <table className="employee-table" style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>EMP ID</th>
                              <th>EMP Name</th>
                              <th>Department</th>
                              <th>Total Leave</th>
                              <th>LWP</th>
                              <th>PL</th>
                              <th>SL</th>
                              <th>CL</th>
                              <th>PTL</th>
                              <th>MTL</th>
                            </tr>
                          </thead>
                          <tbody>

                            {filteredFilteredTasks?.map((data, i) => (
                              <tr key={i}>
                                <td>
                                  {data?.username}
                                </td>
                                <td>
                                  {data?.name}
                                </td>
                                <td>
                                  {data?.department}
                                </td>
                                <td>
                                  {data?.total_noofdays}
                                </td>
                                <td>
                                  {data?.leavestype?.lwp}
                                </td>
                                <td>
                                  {data?.leavestype?.pl}
                                </td><td>
                                  {data?.leavestype?.sl}
                                </td><td>
                                  {data?.leavestype?.cl}
                                </td><td>
                                  {data?.leavestype?.ptl}
                                </td><td>
                                  {data?.leavestype?.mtl}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                    }

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div >
      {/* <Modal isOpen={isOpen} style={customStyles} contentLabel="">
        <div className="box">
          <div className="flex flex-col sm:flex-row items-center p-3 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">Approve Leave</h2>
            <button
              className="btn btn-outline-secondary hidden sm:flex"
              onClick={() => closeModal()}
            >
              <FaTimes />
            </button>
          </div>
          <div id="vertical-form" className="p-3">
            <div className="preview">
              <div>
                <label for="vertical-form-1" className="form-label">
                  Reason
                </label>
                <input
                  id="vertical-form-1"
                  type="text"
                  className="form-control"
                  disabled
                  value={leavedata.reason}
                />
              </div>
              <div className="mt-3">
                <label for="vertical-form-2" className="form-label">
                  Password
                </label>
                <input
                  id="vertical-form-2"
                  type="text"
                  className="form-control"
                  placeholder="secret"
                />
              </div>
              <button className="btn btn-primary mt-5">Approve</button>
            </div>
          </div>
        </div>
      </Modal> */}

      <CommonModal isOpen={isModalOpen} content={modalContent} onClose={handleCLose} />

      <CommonModal isOpen={isSecondModal} content={selectmodalContent} onClose={() => setIsSecondModal(false)} />
    </>
  );
};

export default Leaves;
