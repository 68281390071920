import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { BASEURL } from '../BASEURL';
import { HashLoader } from 'react-spinners';
import CommonModal from './CommonModal';
import swal from "sweetalert";
import { useLoadingContext } from 'react-router-loading';
import { FaEdit, FaTrash } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Task = () => {
    const loadingContext = useLoadingContext();
    const [username, setusername] = useState("");
    const [statusDropdownIndex, setStatusDropdownIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [userdata, setUserData] = useState([
        { value: "", label: "Select All", active: "", uuid: "" },
    ]);
    const [dept, setdept] = useState(null);
    const [loading, setLoading] = useState(false);
    const [seledept, setseledept] = useState("0");
    const [tasks, setTasks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [clients, setClients] = useState(0);
    const [projectsdata, setProjects] = useState([]);
    const [departmentId, setDepartmentId] = useState()
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories] = useState([]);
    const [userdatalist, setuserdata] = useState([]);
    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [alluser, setalluser] = useState(null);
    const user_data = JSON.parse(localStorage.getItem("isAuth"));

    const userUuid = user_data?.uuid;
    const userType = user_data?.type;
    const [selectedval, setselectedval] = useState(
        userType !== 0 ? { uuid: userUuid } : ""
    );
    console.log(userType, 'userType')
    const [selectedorg, setselectedorg] = React.useState(
        JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
    );

    const toggleStatusDropdown = (index) => {
        setStatusDropdownIndex(statusDropdownIndex === index ? null : index);

    };

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return "Active";
            case 2:
                return "In Progress";
            case 3:
                return "On Hold";
            case 4:
                return "Completed";
            default:
                return "Unknown";
        }
    };


    // const toggleActionsDropdown = (index) => {
    //     setActionsDropdownIndex(actionsDropdownIndex === index ? null : index);
    // };

    const filteredTasks = React.useMemo(() => {
        return tasks?.filter((item) => {
            const search = searchTerm.toLowerCase();
            return (
                item?.start_date?.toLowerCase().includes(search) ||
                item?.project_name?.toLowerCase().includes(search) ||
                item?.category?.toLowerCase().includes(search) ||
                item?.user?.toLowerCase().includes(search)
            );
        });
    }, [tasks, searchTerm]);

    const [data, setdata] = useState({
        id: "",
        taskName: "",
        department: "0",
        project: "0",
        status: true,
        category: "", // New field
        taskdetails: "",
        startDate: new Date(),
        endDate: new Date(),
    });

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStartDateChange = (date) => {
        setdata({ ...data, startDate: date });
    };

    const handleEndDateChange = (date) => {
        setdata({ ...data, endDate: date });
    };


    const handleStatusChange = async (taskId, newStatus) => {
        const userdata = JSON.parse(localStorage.getItem('isAuth'));
        const myHeaders = new Headers();
        myHeaders.append('Authorization', userdata.usertoken);
        myHeaders.append('Content-Type', 'application/json');

        try {
            const response = await fetch(`${BASEURL}update-task-status`, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({ task_id: Number(taskId), status: Number(newStatus) }), // Ensure task_id is a number
            });

            const res = await response.json();
            if (res.status === 200) {
                Swal.fire('Success', 'Task status updated successfully!', 'success');
                getUserData();
                setStatusDropdownIndex(null);
            } else {
                Swal.fire('Error', res.error, 'error');
            }
        } catch (error) {
            console.error('Error updating task status:', error);
            Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
        }
    };

    const handleStatusConfirmation = (taskId, newStatus) => {
        Swal.fire({
            title: '<strong>Status Change</strong>',
            text: 'Are you sure you want to change the status?',
            showCancelButton: true,
            confirmButtonText: '<strong>Yes</strong>',
            cancelButtonText: '<strong>Cancel</strong>',
            customClass: {
                popup: 'custom-swal-popup',
                title: 'custom-swal-title',
                htmlContainer: 'custom-swal-html',
                confirmButton: 'custom-swal-confirm-btn',
                cancelButton: 'custom-swal-cancel-btn',
            },
            buttonsStyling: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleStatusChange(taskId, newStatus);
            }
        });
    };


    // const handleStatusConfirmation = (projectId, newStatus) => {
    //     Swal.fire({
    //         title: '<strong>Status Change</strong>',
    //         text: 'Are you sure you want to change the status?',
    //         showCancelButton: true,
    //         confirmButtonText: '<strong>Yes</strong>',
    //         cancelButtonText: '<strong>Cancel</strong>',
    //         customClass: {
    //             popup: 'custom-swal-popup',
    //             title: 'custom-swal-title',
    //             htmlContainer: 'custom-swal-html',
    //             confirmButton: 'custom-swal-confirm-btn',
    //             cancelButton: 'custom-swal-cancel-btn',
    //         },
    //         buttonsStyling: false,
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             handleStatusChange(projectId, newStatus);
    //         }
    //     });
    // };

    // const handleStatusChange = async (projectId, newStatus) => {
    //     const userdata = JSON.parse(localStorage.getItem('isAuth'));
    //     const myHeaders = new Headers();
    //     myHeaders.append('Authorization', userdata.usertoken);
    //     myHeaders.append('Content-Type', 'application/json');

    //     try {
    //         const response = await fetch(`${BASEURL}update-project-status/`, {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: JSON.stringify({ id: projectId, status: newStatus }),
    //         });

    //         const res = await response.json();
    //         if (res.status === 200) {
    //             Swal.fire('Success', 'Status updated successfully!', 'success');
    //             getUserData();
    //             setStatusDropdownIndex(null);
    //         } else {
    //             Swal.fire('Error', res.error, 'error');
    //         }
    //     } catch (error) {
    //         console.error('Error updating status:', error);
    //         Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
    //     }
    // };

    const handleEdit = async (item) => {
        try {
            // Find the matching department
            const matchingDepartment = dept.find(d => d.department_id === item.department);
            if (!matchingDepartment) {
                console.error("No matching department found for:", item.department);
                return;
            }
            setseledept(matchingDepartment.department_id)
            // Fetch project data
            const projectdata = await getProjects(matchingDepartment.department_id);
            if (!projectdata || !Array.isArray(projectdata)) {
                console.error("Failed to fetch project data or invalid format.");
                return;
            }

            // Fetch categories data
            const categoriesData = await getCategories();
            if (!categoriesData || !Array.isArray(categoriesData)) {
                console.error("Failed to fetch categories data or invalid format.");
                return;
            }

            const data = await getUserData("", matchingDepartment.department_id)

            // Find the matching project

            const matchingDUser = data.find(d => d.name === item.user);
            console.log(matchingDUser, 'matchingDUser')
            if (!matchingDUser) {
                console.warn("No matching project found for:", item.user);
            }

            // Find the matching project
            const matchingDProject = projectdata.find(d => d.name === item.project_name);
            if (!matchingDProject) {
                console.warn("No matching project found for:", item.project_name);
            }

            // Find the matching category
            const matchingCategories = categoriesData.find(d => d.name === item.category);
            if (!matchingCategories) {
                console.warn("No matching category found for:", item.category);
            }

            // Helper function for formatting dates
            const formatDate = (dateString) => {
                if (!dateString) return "";
                const date = new Date(dateString);
                return date.toISOString().split("T")[0];
            };

            // Set form data
            setdata({
                taskId: item?.task_id,
                taskName: item.name,
                department: matchingDepartment?.department_id,
                status: item.status === 1,
                category: matchingCategories?.id || "", // Use category ID if found
                project: matchingDProject?.project_id, // Use project ID if found
                taskdetails: item.task_detail,
                startDate: item.start_date,
                endDate: item.end_date,
            });

            // Set selected employee if applicable
            if (item.employee_id) {
                setselectedval({
                    value: item.employee_id,
                    label: item.employee_name,
                });
            }

            // Open edit modal
            setIsEdit(true);
            setIsOpen(true);
        } catch (error) {
            console.error("Error in handleEdit:", error.message);
        }
    };

    const handleDelete = async (task_id) => {
        if (!task_id) return;

        // Show confirmation modal
        const confirmDelete = await swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this task!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (!confirmDelete) return;

        try {
            const userdata = JSON.parse(localStorage.getItem("isAuth"));
            const token = userdata?.usertoken || null;

            const myHeaders = new Headers({
                "Content-Type": "application/json",
                Authorization: token,
            });

            const response = await fetch(`${BASEURL}delete-tasks/${task_id}`, {
                method: "DELETE",
                headers: myHeaders,
            });

            const result = await response.json();
            if (response.ok && result.status === 201) {
                swal({
                    title: "Success",
                    text: "Task deleted successfully!",
                    icon: "success",
                    timer: 2000,
                });

                // Fetch updated tasks and wait for them to load
                const updatedTasks = await getTaskData(seledept);
            } else {
                swal({
                    title: "Error",
                    text: result.error || "Failed to delete the task.",
                    icon: "error",
                    timer: 2000,
                });
            }
        } catch (error) {
            console.error("Error deleting task:", error);
            swal({
                title: "Error",
                text: "An error occurred while deleting the task.",
                icon: "error",
                timer: 2000,
            });
        }
    };


    const getdepartments = async () => {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata !== "00:00:00") {
            let token = userdata.usertoken;
            myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        await fetch(BASEURL + `getAllDepartments/`, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
                    .uuid,
            }),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === 201) {
                    // console.log(res.data);
                    if (userdata.role == 2) {
                        let dept = res.data.filter(
                            (item) => item.department_id === userdata.department
                        );
                        setdept(dept);
                        getTaskData(seledept);
                    } else {
                        setdept(res.data);
                        getTaskData(seledept);

                    }

                    //   setdept_selected(res.data[0].department_id)
                    // console.log(res.data);
                }
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const getCategories = async () => {
        setLoadingCategories(true);

        const myHeaders = new Headers();
        const userdata = JSON.parse(localStorage.getItem("isAuth"));

        if (!userdata || !userdata.usertoken) {
            console.error("Invalid or missing user data in localStorage.");
            setLoadingCategories(false);
            return [];
        }

        myHeaders.append("Authorization", userdata.usertoken);
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(BASEURL + "get-categories", {
                method: "GET",
                headers: myHeaders,
            });

            const res = await response.json();

            if (res.status === 200) {
                setCategories(res.data); // Update the state with fetched categories
                console.log("Categories fetched successfully:", res.data);
                return res.data; // Return the fetched categories
            } else {
                console.error("No categories found:", res.error);
                setCategories([]); // Set empty array in state if no data
                return [];
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
            setCategories([]); // Set empty array in case of an error
            return [];
        } finally {
            setLoadingCategories(false);
        }
    };


    const getProjects = async (department = "") => {
        try {
            setLoading(true);
            const myHeaders = new Headers();
            const userdata = JSON.parse(localStorage.getItem("isAuth"));

            if (!userdata || !userdata.usertoken || !userdata.companydata) {
                throw new Error("Invalid user data");
            }

            const token = userdata.usertoken;
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            const response = await fetch(BASEURL + `get-projects`, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    department,
                    listtype: 1,
                    role: 1,
                    uuid: userdata.companydata[0].uuid,
                }),
            });

            const res = await response.json();

            if (res.status === 201) {
                setProjects(res.projects);
                return res.projects; // Return projects for further use
            } else {
                console.error("Error fetching projects:", res.message || res);
                return null;
            }
        } catch (error) {
            console.error("Error in getProjects:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const getTaskData = async (e = "") => {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));

        if (userdata !== "00:00:00") {
            let token = userdata.usertoken;
            myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(`${BASEURL}get-tasks/`, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    department: e,
                    listtype: 1,
                    role: 1,
                    uuid: userdata.companydata[0].uuid,
                }),
            });

            const res = await response.json();

            if (res.status === 201) {
                setTasks(res.tasks); // Update state
                return res.tasks; // Return fetched tasks
            } else {
                console.error("Error fetching tasks:", res.error);
                setTasks([]); // Clear state on error
            }
        } catch (err) {
            console.error("Error fetching tasks:", err);
            setTasks([]); // Clear state on exception
        } finally {
            setLoading(false);
        }
    };


    const handleSelectChange = ({
        event
    }) => {
        const selectedUser = userdata?.find((item) => item?.value === event.target.value);
        // Update selected value state
        setselectedval(selectedUser || null);
    };

    const toggleDropdown = (index) => {
        setDropdownIndex(dropdownIndex === index ? null : index);
    };

    const savedata = () => {
        const payload = {
            taskName: data.taskName,
            department: data.department,
            project: data.project,
            status: data.status,
            taskdetails: data.taskdetails,
            startDate: data.startDate || new Date(),
            endDate: data.endDate || new Date(),
            assignedUser: selectedval?.uuid || null,
        };

        fetch(BASEURL + `add-task`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: JSON.parse(localStorage.getItem("isAuth")).usertoken,
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === 201) {
                    swal("Success!", "Task saved successfully.", "success");
                    setIsOpen(false);
                    resetForm();
                    getTaskData(seledept)
                } else {
                    swal("Error!", "Failed to save the task.", "error");
                }
            })
            .catch((err) => {
                console.error("Error saving task:", err);
                swal("Error!", "Something went wrong.", "error");
            });
    };

    const fetchData = () => {
        getdepartments()
    }

    const getUserFetchData = async () => {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));

        if (userdata !== "00:00:00") {
            let token = userdata.usertoken;
            myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(`${BASEURL}get-tasks-by-uuid/`, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    uuid: userUuid,
                }),
            });

            const res = await response.json();

            if (res.status === 200) {
                console.log(res, 'res')
                setTasks(res.tasks); // Update state
                return res.tasks; // Return fetched tasks
            } else {
                console.error("Error fetching tasks:", res.error);
                setTasks([]); // Clear state on error
            }
        } catch (err) {
            console.error("Error fetching tasks:", err);
            setTasks([]); // Clear state on exception
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        if (userType === 2) {
            getUserFetchData()
        } else {
            fetchData()
        }
    }, [])

    const resetForm = () => {
        setdata({
            id: "",
            taskName: "",
            department: "0",
            project: "0",
            status: true,
            category: "",
            taskdetails: "",
            startDate: "",
            endDate: "",
        });
        setselectedval(null);
        setDepartmentId("");
        setIsEdit(false);
    };


    const handleClose = () => {
        setIsOpen(false);
        resetForm();
    };


    // const handleOpen = (project) => {
    //     if (project) {
    //         setdata({
    //             id: project.project_id,
    //             name: project.name,
    //             department: project.department,
    //             status: project.status === 1,
    //         });
    //         setIsEdit(true);
    //     } else {
    //         resetForm();
    //         getCategories();
    //     }
    //     setIsOpen(true);
    // };

    const handleOpen = async (project) => {
        if (project) {
            try {
                const response = await fetch(`${BASEURL}get-task/${project.project_id}`, {
                    method: "GET",
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem("isAuth")).usertoken,
                    },
                });

                if (response.ok) {
                    const taskDetails = await response.json();

                    // Prepopulate form data with the fetched task details
                    setdata({
                        id: taskDetails.id,
                        taskName: taskDetails.taskName,
                        department: taskDetails.department,
                        project: taskDetails.project,
                        status: taskDetails.status === 1,
                        category: 0,
                        taskdetails: taskDetails.taskDetails,
                        startDate: taskDetails.startDate,
                        endDate: taskDetails.endDate,
                    });

                    setIsEdit(true);
                } else {
                    console.error("Failed to fetch task details:", response.statusText);
                    swal("Error!", "Unable to fetch task details.", "error");
                }
            } catch (err) {
                console.error("Error fetching task details:", err.message);
                swal("Error!", "Something went wrong.", "error");
            }
        } else {
            // Reset form for new task creation
            resetForm();
            getCategories();
        }
        setIsOpen(true);
    };

    const handlechangeinput = (e) => {
        let value = e.target.value;
        if (e.target.name === "status") {
            value = e.target.checked;
        }
        setdata({
            ...data,
            [e.target.name]: value,
        });
    };

    const getUserData = async (org = "", departmentId = "") => {
        setLoading(true);
        const myHeaders = new Headers();
        const userdata = JSON.parse(localStorage.getItem("isAuth"));

        if (userdata && userdata.usertoken) {
            const token = userdata.usertoken;
            myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");

        try {
            const response = await fetch(BASEURL + `admin-getAllUsers/`, {
                method: "POST",
                headers: myHeaders,
                body: JSON.stringify({
                    type: 1,
                    uuid: org || selectedorg, // Use `org` or default to `selectedorg`
                    usertype: userdata.type,
                    department: departmentId || "", // Use department ID or default to all
                }),
            });

            if (!response.ok) {
                throw new Error(
                    `Error: ${response.status} - ${response.statusText}`
                );
            }

            const res = await response.json();

            if (res.status === 201) {
                const filteredData = res.data.filter(
                    (item) => item.department !== 34 && item.username !== "1119"
                );

                setuserdata(filteredData);
                setalluser(res.alluser);

                const userOptions = filteredData.map((user) => ({
                    value: user.username,
                    label: user.name,
                    active: user.active ? "🟢" : "🔴",
                    uuid: user.uuid,
                    department: user.department,
                }));

                userOptions.unshift({
                    value: "",
                    label: "Select Employee",
                    active: "",
                });

                setUserData(userOptions);

                if (res.alluser.length > 0 && filteredData.length > 0) {
                    setusername(res.alluser[0]["username"]);
                }

                return filteredData; // Return filtered data
            }
        } catch (err) {
            console.error("Error fetching user data:", err.message);
        } finally {
            setLoading(false);
            loadingContext.done();
        }

        return []; // Return an empty array in case of failure
    };


    // const handleSubmit = async () => {
    //     if (isEdit) {
    //         console.log("Editing project:", data.id);
    //     }
    //     await savedata();

    // }

    const handleSubmit = async () => {
        if (isEdit) {

            const payload = {
                ...data,
                assignedUser: selectedval?.uuid || null,
            };

            if (!payload.assignedUser) {
                swal("Error!", "Please select an assigned user.", "error");
                return;
            }

            try {
                const response = await fetch(`${BASEURL}edit-task/`, {
                    method: "PUT", // Ensure this matches the backend
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: JSON.parse(localStorage.getItem("isAuth")).usertoken,
                    },
                    body: JSON.stringify(payload),
                });

                const res = await response.json();

                if (res.status === 200) {
                    swal("Success!", "Task updated successfully.", "success");
                    setIsOpen(false);
                    resetForm();
                    getTaskData(seledept);
                } else {
                    swal("Error!", res.message || "Failed to update the task.", "error");
                }
            } catch (err) {
                console.error("Error updating task:", err.message);
                swal("Error!", "Something went wrong.", "error");
            }
        } else {
            await savedata(); // Handle new task creation
        }
    };

    const modalContent = (
        <>
            <div >
                <div
                    className="px-4 py-4"
                    style={{
                        textAlign: 'center',
                        fontSize: "20px",
                        fontWeight: "600",
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>

                    <div></div>
                    <h2>
                        {isEdit ? "Edit" : "Add"} Task
                    </h2>

                    <div className="cursor-pointer" onClick={handleClose}>
                        x
                    </div>
                </div>
                <div className="my-2 mt-2 px-4 py-4 ">

                    <div className="mb-3">
                        <input
                            type="text"
                            name="taskName"
                            placeholder="Task Name"
                            value={data.taskName}
                            onChange={(e) => setdata({ ...data, taskName: e.target.value })}
                            className="modal-input"
                        />                    </div>
                    <div className="mb-3">
                        <div className="col-span-12 md:col-span-6 mb-4">
                            <select
                                className="modal-input"
                                name="department"
                                value={data.department || ""}
                                onChange={(e) => {
                                    const department = e.target.value;
                                    setdata({ ...data, department });
                                    getProjects(department);
                                    getUserData("", department)
                                    setDepartmentId(department)
                                }}
                            >
                                <option value="" disabled>
                                    Select Department
                                </option>
                                <option key={-1} value="0">
                                    All Departments
                                </option>
                                {dept && dept?.length > 0 &&
                                    dept?.map((item, i) => (
                                        <option key={i} value={item.department_id}>
                                            {item.deptname}
                                        </option>
                                    ))
                                }
                            </select>
                            {errors.department && (
                                <span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                                    {errors.department}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="col-span-12 md:col-span-6 mb-4">
                            <select
                                className="modal-input"
                                name="project"
                                value={data.project || ""}
                                onChange={(e) => {
                                    setdata({ ...data, project: e.target.value })
                                }}
                            >
                                <option value="" disabled>
                                    Select Projects
                                </option>
                                <option key={-1} value="0">
                                    All Projects
                                </option>
                                {projectsdata && projectsdata?.length > 0 ? (
                                    projectsdata.map((item, i) => (
                                        <option key={i} value={item.project_id}>
                                            {item.project_name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No projects available</option>
                                )}

                            </select>
                            {errors.department && (
                                <span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                                    {errors.department}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="col-span-12 md:col-span-6 mb-4">
                            <select
                                className="modal-input"
                                name="selectedval"
                                value={selectedval?.value || ""}
                                onChange={(e) => {
                                    const selectedUser = userdata.find((item) => item.value === e.target.value);
                                    setselectedval(selectedUser || null); // Update selected value
                                }}
                            >
                                <option value="" disabled>
                                    Select Employee
                                </option>
                                {userdata.map((item) => (
                                    <option key={item.value} value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {/* <div className="mb-3">
                        <div className="col-span-12 md:col-span-6 mb-4">
                            <select
                                className="modal-input category-select"
                                name="category"
                                value={data.category || ""}
                                onChange={(e) => setdata({ ...data, category: e.target.value })}
                            >
                                <option value="" disabled className="default-option">
                                    Select Category
                                </option>
                                {loadingCategories ? (
                                    <option disabled className="default-option">
                                        Loading...
                                    </option>
                                ) : categories.length > 0 ? (
                                    categories.map((category) => (
                                        <option key={category.id} value={category.id} className="category-option">
                                            {category.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled className="default-option">
                                        No categories available
                                    </option>
                                )}
                            </select></div></div> */}
                    {/* <div className="grid grid-cols-12 gap-1">
                        <div className="col-span-12 sm:col-span-6  mb-4">
                            <label htmlFor="startDate" className="block text-sm font-medium">
                                Start Date
                            </label>
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={data.startDate || ""}
                                onChange={(e) => setdata({ ...data, startDate: e.target.value })}
                                className="modal-input w-full border border-gray-300 mr-0 sm:mr-1 rounded-md px-3 py-2 focus:ring focus:ring-blue-500"
                            />
                            {errors.startDate && (
                                <span className="text-red-500 text-xs mt-1 block">
                                    {errors.startDate}
                                </span>
                            )}
                        </div>

                        <div className="col-span-12 sm:col-span-6 mb-4">
                            <label htmlFor="endDate" className="block text-sm font-medium">
                                End Date
                            </label>
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={data.endDate || ""}
                                onChange={(e) => setdata({ ...data, endDate: e.target.value })}
                                className="modal-input w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-500"
                            />
                            {errors.endDate && (
                                <span className="text-red-500 text-xs mt-1 block">
                                    {errors.endDate}
                                </span>
                            )}
                        </div>
                    </div> */}

                    <div className="grid grid-cols-12 gap-1">
                        <div className="col-span-12 sm:col-span-6 mb-4">
                            <label className="block text-sm font-medium">Start Date</label>
                            <DatePicker
                                selected={data.startDate ? new Date(data.startDate) : new Date()} // Default to today
                                onChange={handleStartDateChange}
                                className="modal-input w-full border border-gray-300 rounded-md px-3 py-2"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6 mb-4">
                            <label className="block text-sm font-medium">End Date</label>
                            <DatePicker
                                selected={data.endDate ? new Date(data.endDate) : new Date()} // Default to today
                                onChange={handleEndDateChange}
                                className="modal-input w-full border border-gray-300 rounded-md px-3 py-2"
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <textarea
                            id="taskdetails"
                            value={data.taskdetails}
                            required
                            name="taskdetails"
                            className="modal-input"
                            onChange={handlechangeinput}
                            rows="2"
                            placeholder="Task Details"
                        />
                        {errors.permanent_address && (
                            <span style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                                {errors.permanent_address}
                            </span>
                        )}
                    </div>
                    {/* <button
						type="button"
						onClick={() => {
							if (isEdit) {
								console.log(data?.uuid, 'data?.uuid')
								updateEmployee("");
							}
							savedata()

						}}
						className="modal-button-new"
					>
						Submit
					</button> */}
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="modal-button-new"
                    >
                        {isEdit ? "Update" : "Save"}
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <>
            {
                !(userType === 2) &&

                <div className='' style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: "10px"
                }}>
                    <div className="flex mt-5 sm:mt-0">
                        <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                            <input
                                id="tabulator-html-filter-value"
                                type="text"
                                onChange={handleSearchChange}
                                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                                placeholder="Search..."
                            />
                        </div>
                        <div className="flex mt-5 sm:mt-0">
                            <select
                                className="form-select"
                                name="department"
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    setseledept(selectedValue);
                                    getTaskData(selectedValue);
                                }}
                                value={seledept} // Ensure the select element reflects seledept
                            >
                                <option key={-1} value="0">
                                    All department
                                </option>
                                {dept &&
                                    dept.length > 0 &&
                                    dept.map((item, i) => (
                                        <option key={i} value={item.department_id}>
                                            {item.deptname}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="flex mt-5 sm:mt-0 sm:ml-auto">
                        <button
                            type="button"
                            onClick={() => handleOpen(null)}
                            className="btn w-50 add-emp-btn"
                            style={{ backgroundColor: '#24a184', borderColor: '#24a184', color: 'white' }}
                        >
                            Add Task
                        </button>
                    </div>
                </div>
            }

            <div>
                <div className="overflow-x-auto">
                    {loading ? (
                        <HashLoader
                            color="#5755d9"
                            size={30}
                            style={{ position: "absolute", right: "50%" }}
                        />
                    ) : (
                        <div
                            style={{
                                // border: "1px solid #FC9A30",
                                // padding: "10px",
                                borderTopLeftRadius: "8px",
                                borderTopRightRadius: "8px",
                                height: "100vh"
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "#FC9A30",
                                    color: "#fff",
                                    padding: "12px",
                                    textAlign: "start",
                                    borderTopLeftRadius: "8px",
                                    borderTopRightRadius: "8px",
                                }}
                            >
                                <h3>Task </h3>
                            </div>
                            <div
                                className="table-outlineBorder"
                                style={{
                                    border: "1px solid #FC9A30",
                                }}
                            >

                                <table className="employee-table" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%" }} className="whitespace-nowrap">
                                                Start Date
                                            </th>
                                            <th style={{ width: "20%" }} className="whitespace-nowrap">
                                                Project Name
                                            </th>
                                            {/* <th style={{ width: "30%" }} className="whitespace-nowrap">
                                                Category
                                            </th> */}
                                            <th className="whitespace-nowrap">Developer</th>
                                            <th className="whitespace-nowrap">End Date</th>
                                            <th className="whitespace-nowrap">Status</th>
                                            <th className="whitespace-nowrap">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredTasks?.length > 0 ? (
                                            filteredTasks.map((item, i) => (
                                                <tr key={i}>
                                                    <td className='text-left'>{item?.start_date
                                                        ? new Date(item.start_date).toISOString().split("T")[0]
                                                        : "No Date"}</td>
                                                    <td className='text-left'>{item?.project_name}</td>
                                                    {/* <td className='text-left'>{item?.category}</td> */}

                                                    <td className='text-left'>{item?.user}</td>
                                                    <td className='text-left'>
                                                        {item?.start_date
                                                            ? new Date(item.end_date).toISOString().split("T")[0]
                                                            : "No Date"}</td>
                                                    {/* <td>
														<div className="flex items-center justify-between">
															<span className="text-sm font-medium mr-2">
																{item.status === 1 ? "Active" : "Inactive"}
															</span>
															<div className="relative">
																<button
																	onClick={() => toggleDropdown2(i)}
																	className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200"
																>
																	{dropdownIndex2 === i ? "▲" : "▼"}
																</button>
															</div>
														</div>
													</td> */}
                                                    <td>
                                                        <div className="relative">
                                                            <button
                                                                onClick={() => toggleStatusDropdown(i)}
                                                                className="text-sm bg-white border-gray-300 p-2 rounded-md shadow-sm flex items-center justify-between w-36 text-gray-700 text-left"
                                                            >
                                                                {getStatusText(item.status)}
                                                                <span className="ml-2">{statusDropdownIndex === i ? "▲" : "▼"}</span>
                                                            </button>
                                                            {statusDropdownIndex === i && (
                                                                <div className="absolute left-0 mt-1 w-40 bg-white border-gray-200 shadow-lg rounded-md z-10">
                                                                    <ul className="py-1">
                                                                        {[
                                                                            { status: "1", label: "Active" },
                                                                            { status: "2", label: "In Progress" },
                                                                            { status: "3", label: "On Hold" },
                                                                            { status: "4", label: "Completed" },
                                                                        ].map((option) => (
                                                                            <li
                                                                                key={option.status}
                                                                                onClick={() => handleStatusConfirmation(item.task_id, option.status)}
                                                                                className={`px-4 py-2 text-sm cursor-pointer text-left ${String(item.taskstatus) === String(option.status) ? "text-white font-semibold" : "text-gray-700 hover:bg-gray-100"
                                                                                    }`}
                                                                                style={{
                                                                                    backgroundColor: String(item.status) === String(option.status) ? "#fc9a30" : "",
                                                                                    borderRadius: "5px",
                                                                                    margin: "0 auto",
                                                                                    width: "90%",
                                                                                }}
                                                                            >
                                                                                {option.label}
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="relative">
                                                            {!(userType === 2) &&
                                                                <button
                                                                    onClick={() => toggleDropdown(i)}
                                                                    className="text-sm bg-gray-100 p-1 rounded hover:bg-gray-200"
                                                                >
                                                                    {dropdownIndex === i ? "▲" : "▼"}
                                                                </button>}

                                                            {dropdownIndex === i && (
                                                                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg rounded-md z-50">
                                                                    <ul className="py-1">
                                                                        <li
                                                                            onClick={() => {
                                                                                setIsEdit(true)
                                                                                handleEdit(item);
                                                                                setDropdownIndex(null);
                                                                            }}
                                                                            className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                                                                        >
                                                                            <FaEdit className="text-gray-600 mr-2" />
                                                                            Edit
                                                                        </li>

                                                                        <li
                                                                            onClick={() => {
                                                                                handleDelete(item.task_id);
                                                                                setDropdownIndex(null);
                                                                            }}
                                                                            className="flex items-center px-4 py-2 text-sm text-red-700 cursor-pointer hover:bg-red-100"
                                                                        >
                                                                            <FaTrash className="mr-2 text-red-600" />
                                                                            Delete
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" className="text-center">
                                                    No projects available
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                    {/* <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                        <br />
                        <nav className="w-full sm:w-auto sm:mr-auto">
                            {!loading ? (
                                <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-4">
                                        <ReactPaginate
                                            pageCount={pageCount}
                                            pageRangeDisplayed={10}
                                            marginPagesDisplayed={1}
                                            onPageChange={handlePageClick}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            pageLinkClassName="page-link"
                                            breakLinkClassName="page-link"
                                            nextLinkClassName="page-link"
                                            previousLinkClassName="page-link"
                                            pageClassName="page-item"
                                            breakClassName="page-item"
                                            nextClassName="page-item"
                                            previousClassName="page-item"
                                            previousLabel={<>&laquo;</>}
                                            nextLabel={<>&raquo;</>}
                                        />
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                            ) : (
                                ""
                            )}
                        </nav>
                    </div> */}
                </div>
            </div>

            <CommonModal isOpen={isOpen} content={modalContent} />
        </>
    )
}

export default Task
