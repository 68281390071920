import React from "react";
import Chart from "react-apexcharts";
import equal from "fast-deep-equal";

class SidebarChart extends React.Component {
  constructor(props) {
    super(props);

    const initialData = {
      series: [5.83, 8.99],
      options: {
        chart: {
          width: 280,
          height: 200,
          customScale: 0.7,
          type: "pie"
        },
        legend: {
          position: "bottom",
          fontSize: "10px",
          offsetY: -5
        },
        labels: ["Productivity", "Unproductivity"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 180
              }
            }
          }
        ]
      }
    };

    this.state = initialData;
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.data, prevProps.data)) {
      this.setState({
        series: this.props.data[0].value,
        options: {
          ...this.state.options,
          labels: this.props.data[0].appname
        }
      });
    }
  }

  render() {
    return (
      <div id="chart" style={{ maxWidth: '280px', margin: '0 auto' }}>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height={300}
          width={350}
        />
      </div>
    );
  }
}

export default SidebarChart;