// import React, { useState, useEffect } from "react";
// import swal from "sweetalert";
// import { BASEURL } from "./BASEURL";
// import HashLoader from "react-spinners/HashLoader";
// import axios from "axios";
// import Webcam from "react-webcam";
// import { useNavigate } from "react-router-dom";
// const videoConstraints = {
// 	width: 400,
// 	height: 400,
// 	facingMode: "user",
// };
// const JobApplication = (props) => {
// 	const [data, setdata] = useState({
// 		fullname: "",
// 		email: "",
// 		contactno: "",
// 		city: 1,
// 		address: "",
// 		position: "",
// 		noticeperiod: "",
// 		cv: "",
// 	});
// 	let [loading, setLoading] = useState(false);
// 	const [selectedFile, setSelectedFile] = useState(null);
// 	const [positions, setpositions] = useState([]);
// 	const [imgSrc, setImgSrc] = useState(null);
// 	const [iscapture, setiscapture] = useState(false);
// 	const [
// 		is_jobapplication_next_form_avail,
// 		setis_jobapplication_next_form_avail,
// 	] = useState(0);
// 	let navigate = useNavigate();
// 	const [picture, setPicture] = useState("");
// 	const webcamRef = React.useRef(null);
// 	const capture = React.useCallback(() => {
// 		setiscapture(true);
// 		const pictureSrc = webcamRef.current.getScreenshot();
// 		setPicture(pictureSrc);
// 	});
// 	const setRetake = () => {
// 		setPicture("");
// 		capture();
// 	};
// 	useEffect(() => {
// 		document.body.classList.add("login");
// 		getPositions();
// 	}, []);

// 	const getPositions = async () => {
// 		try {
// 			setLoading(true);
// 			const myHeaders = new Headers();
// 			const userdata = JSON.parse(localStorage.getItem("isAuth"));
// 			if (userdata) {
// 				myHeaders.append("Authorization", userdata.usertoken);
// 			}
// 			myHeaders.append("Content-Type", "application/json");

// 			const response = await fetch(BASEURL + `getAllPositions/`, {
// 				method: "POST",
// 				headers: myHeaders,
// 				body: JSON.stringify({
// 					company_uuid: "abc3bab0-2b6e-4fb7-a453-4049c8157ac5",
// 				}),
// 			});

// 			const res = await response.json();
// 			console.log("Parsed Response: ", res);

// 			if (res.status === 201) {
// 				setpositions(res.data || []); // Handle missing `data` gracefully
// 				setis_jobapplication_next_form_avail(
// 					res.jobappdata?.is_jobapplication_next_form_avail || 0
// 				);
// 			} else {
// 				setpositions([]);
// 				setis_jobapplication_next_form_avail(0); // Fallback to default
// 			}
// 		} catch (err) {
// 			console.error("Error fetching positions: ", err);
// 			setpositions([]);
// 			setis_jobapplication_next_form_avail(0); // Handle error gracefully
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	// const getPositions = async () => {
// 	// 	setLoading(true);
// 	// 	const myHeaders = new Headers();
// 	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 	// 	if (userdata != null) {
// 	// 		let token = userdata.usertoken;
// 	// 		myHeaders.append("Authorization", token);
// 	// 	}
// 	// 	myHeaders.append("Content-Type", "application/json");
// 	// 	await fetch(BASEURL + `getAllPositions/`, {
// 	// 		method: "POST",
// 	// 		headers: myHeaders,
// 	// 		body: JSON.stringify({
// 	// 			company_uuid: "abc3bab0-2b6e-4fb7-a453-4049c8157ac5",
// 	// 		}),
// 	// 	})
// 	// 		.then((response) => response.json())
// 	// 		.then((res) => {
// 	// 			if (res.status === 201) {
// 	// 				setpositions(res.data);
// 	// 				setis_jobapplication_next_form_avail(
// 	// 					res.jobappdata.is_jobapplication_next_form_avail
// 	// 				);
// 	// 			} else {
// 	// 				setpositions([]);
// 	// 			}
// 	// 		})
// 	// 		.catch((err) => console.log(err));
// 	// 	setLoading(false);
// 	// };
// 	const handlechange = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 	};
// 	const handleFileInputChange = (e) => {
// 		setSelectedFile(e.target.files[0]);
// 		let value = e.target.value;
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 	};
// 	const savedata = async (elm) => {
// 		if (
// 			data.fullname === "" ||
// 			data.email === "" ||
// 			data.contactno === "" ||
// 			data.city === "" ||
// 			data.position === "" ||
// 			data.noticeperiod === ""
// 		) {
// 			swal({
// 				title: "Error",
// 				text: "Please Enter all value Required value",
// 				icon: "error",
// 				timer: 2000,
// 			});
// 			return;
// 		}
// 		let selectedpositon = positions.filter(
// 			(item) => item.uuid === data.position
// 		);
// 		let msg = is_jobapplication_next_form_avail === 1 ? "Save" : "Submit";
// 		swal({
// 			title: msg + " Application",
// 			text: `Are you sure you want to ${msg} Application? You can't change after ${msg} application`,
// 			buttons: ["No", "Yes"],
// 			showCloseButton: true,
// 		}).then((willDelete) => {
// 			if (willDelete) {
// 				finalSubmit();
// 			}
// 		});
// 	};
// 		const finalSubmit = async () => {
// 			setLoading(true);
// 			const formData = new FormData();
// 			formData.append("cv", selectedFile);
// 			formData.append("data", JSON.stringify(data));
// 			formData.append("picture", picture);
// 			const res = await axios.post(BASEURL + "job-application", formData, {
// 				headers: {
// 					"Content-Type": "multipart/form-data",
// 				},
// 			});
// 			if (res.data.status === 201) {
// 				let selectedpositon = positions.filter(
// 					(item) => item.uuid === data.position
// 				);
// 				let seleposuuid = selectedpositon[0].isaddedque;
// 				let msg =
// 					is_jobapplication_next_form_avail === 1 && seleposuuid === 1
// 						? "Saved"
// 						: "Submitted";
// 				swal({
// 					title: msg,
// 					text: res.message,
// 					icon: "success",
// 					timer: 2000,
// 				});
// 				if (is_jobapplication_next_form_avail === 1 && seleposuuid === 1) {
// 					navigate("/job-application-general/" + res.data.uuid);
// 					navigate(0);
// 				} else {
// 					setdata({
// 						fullname: "",
// 						email: "",
// 						contactno: "",
// 						city: 1,
// 						address: "",
// 						position: "",
// 						noticeperiod: "",
// 						cv: "",
// 					});
// 					setPicture("");
// 					setiscapture(false);
// 				}
// 				setLoading(false);
// 			} else {
// 				setLoading(false);
// 				swal({
// 					title: "Error occured! Please try again",
// 					text: res.message,
// 					icon: "error",
// 					timer: 2000,
// 				});
// 			}
// 		};
// 	return (
// 		<div className="p-0">
// 			<div
// 				className="container "
// 				style={{
// 					height: "100vh",
// 					overflow: "scroll",
// 				}}
// 			>
// 				<div className="block xl:grid grid-cols-2 ">
// 					<div className="hidden xl:flex flex-col min-h-screen">
// 						<div className="-intro-x flex items-center pt-5">
// 							<img
// 								alt="Midone - HTML Admin Template"
// 								className="w-6"
// 								src="../images/logo.svg"
// 							/>
// 							<span className="text-white text-lg ml-3">
// 								Jenya Tracking
// 							</span>
// 						</div>
// 						<div className="my-auto">
// 							<img
// 								alt=""
// 								className="-intro-x w-1/2 "
// 								src="../images/illustration.svg"
// 							/>
// 							<div className="-intro-x text-white font-medium text-3xl leading-tight mt-10 text-left">
// 								A few more clicks to
// 								<br />
// 								submit your job application form
// 							</div>
// 						</div>
// 					</div>
// 					<div
// 						className="h-screen xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0"
// 						style={{ overflow: "scroll" }}
// 					>
// 						<div className="">
// 							<div className="grid grid-cols-24 gap-12 mt-5">
// 								<div className="intro-y col-span-12 lg:col-span-6">
// 									<div className="intro-y box">
// 										<div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
// 											<h2
// 												className="text-base mr-auto"
// 												style={{
// 													fontSize: "25px",
// 													fontWeight: 500,
// 												}}
// 											>
// 												Job Application
// 											</h2>
// 										</div>
// 										<div
// 											id="form-validation"
// 											className="p-5"
// 										>
// 											<div className="preview">
// 												<div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
// 													<form className="pb-5 mb-5">
// 														<div className="grid grid-cols-12 gap-x-5 text-left">
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Full
// 																		Name{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.fullname
// 																		}
// 																		required
// 																		type="text"
// 																		name="fullname"
// 																		className="form-control"
// 																		onChange={
// 																			handlechange
// 																		}
// 																	/>
// 																</div>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Email ID{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.email
// 																		}
// 																		required
// 																		type="email"
// 																		name="email"
// 																		className="form-control"
// 																		onChange={
// 																			handlechange
// 																		}
// 																	/>
// 																</div>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Contact
// 																	Number{" "}
// 																	<span className="text-danger">
// 																		(*)
// 																	</span>
// 																</label>
// 																<input
// 																	id="update-profile-form-1"
// 																	value={
// 																		data.contactno
// 																	}
// 																	required
// 																	minLength={
// 																		10
// 																	}
// 																	maxLength={
// 																		13
// 																	}
// 																	type="number"
// 																	name="contactno"
// 																	className="form-control"
// 																	onChange={
// 																		handlechange
// 																	}
// 																/>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	City{" "}
// 																	<span className="text-danger">
// 																		(*)
// 																	</span>
// 																</label>
// 																<select
// 																	className="form-select"
// 																	name="city"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data.city
// 																	}
// 																>
// 																	<option value="0">
// 																		Ahmedabad
// 																	</option>
// 																	<option value="1">
// 																		Gandhinagar
// 																	</option>
// 																	<option value="1">
// 																		Other
// 																	</option>
// 																</select>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-12">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Address
// 																</label>
// 																<br />
// 																<textarea
// 																	name="address"
// 																	className="form-control"
// 																	cols="80"
// 																	rows="2"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data &&
// 																		data.address
// 																	}
// 																></textarea>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Position
// 																		Aplied
// 																		for
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<select
// 																		className="form-select"
// 																		name="position"
// 																		onChange={
// 																			handlechange
// 																		}
// 																		value={
// 																			data.position
// 																		}
// 																	>
// 																		<option value="">
// 																			Select
// 																		</option>
// 																		{positions.map(
// 																			(
// 																				item,
// 																				i
// 																			) => {
// 																				return (
// 																					<option
// 																						value={
// 																							item.uuid
// 																						}
// 																					>
// 																						{
// 																							item.name
// 																						}
// 																					</option>
// 																				);
// 																			}
// 																		)}
// 																	</select>
// 																</div>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Notice
// 																		Period:{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.noticeperiod
// 																		}
// 																		required
// 																		type="text"
// 																		name="noticeperiod"
// 																		className="form-control"
// 																		onChange={
// 																			handlechange
// 																		}
// 																	/>
// 																</div>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Upload
// 																		CV:
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.cv
// 																		}
// 																		required
// 																		type="file"
// 																		name="cv"
// 																		className="form-control p-1"
// 																		onChange={
// 																			handleFileInputChange
// 																		}
// 																	/>
// 																</div>
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Upload
// 																		Image:
// 																	</label>
// 																	<div className="container">
// 																		{!iscapture ||
// 																		picture ===
// 																			"" ? (
// 																			<Webcam
// 																				audio={
// 																					false
// 																				}
// 																				height={
// 																					200
// 																				}
// 																				ref={
// 																					webcamRef
// 																				}
// 																				width={
// 																					200
// 																				}
// 																				screenshotFormat="image/jpeg"
// 																				videoConstraints={
// 																					videoConstraints
// 																				}
// 																			/>
// 																		) : (
// 																			<img
// 																				src={
// 																					picture
// 																				}
// 																				alt="Captured"
// 																			/>
// 																		)}

// 																		{picture !==
// 																		"" ? (
// 																			<button
// 																				type="button"
// 																				onClick={(
// 																					e
// 																				) => {
// 																					e.preventDefault();
// 																					setRetake();
// 																				}}
// 																				className="btn btn-primary"
// 																			>
// 																				Retake
// 																				Image
// 																			</button>
// 																		) : (
// 																			<button
// 																				type="button"
// 																				onClick={(
// 																					e
// 																				) => {
// 																					e.preventDefault();
// 																					capture();
// 																				}}
// 																				className="btn btn-danger"
// 																			>
// 																				Capture
// 																				Image
// 																			</button>
// 																		)}
// 																	</div>
// 																</div>
// 															</div>
// 														</div>

// 														{loading ? (
// 															<HashLoader
// 																color="#5755d9"
// 																size={30}
// 																style={{
// 																	position:
// 																		"absolute",
// 																	left: "10%",
// 																	paddingTop:
// 																		"10%",
// 																}}
// 															/>
// 														) : (
// 															<div className="text-left">
// 																<button
// 																	type="button"
// 																	onClick={() =>
// 																		savedata()
// 																	}
// 																	className="btn btn-primary w-50 mt-3 mb-5"
// 																>
// 																	{is_jobapplication_next_form_avail ===
// 																	1
// 																		? "Save & Next Application"
// 																		: "Submit Application"}
// 																</button>
// 															</div>
// 														)}
// 													</form>
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };
// export default JobApplication;

// import React, { useState, useEffect } from "react";
// import swal from "sweetalert";
// import { BASEURL } from "./BASEURL";
// import HashLoader from "react-spinners/HashLoader";
// import axios from "axios";
// import Webcam from "react-webcam";
// // import { useCallback,z useRef } from "react";
// import { useNavigate } from "react-router-dom";
// const WebcamComponent = () => <Webcam />;
// const videoConstraints = {
// 	width: 400,
// 	height: 400,
// 	facingMode: "user",
// };
// const JobApplication = (props) => {
// 	// console.log(props);
// 	const [data, setdata] = useState({
// 		fullname: "",
// 		email: "",
// 		contactno: "",
// 		city: 1,
// 		address: "",
// 		position: "",
// 		noticeperiod: "",
// 		cvname: "",
// 		cv: "",
// 		currentsalary: "",
// 		salaryexp: "",
// 		totalworkexp_years: "",
// 		totalworkexp_months: "",
// 		istraining: "0",
// 		isrelocate: 0,
// 		knowvavancy: "",
// 		fromwheretraining: "",
// 		portfolio: null,
// 		// portfolioName: "",
// 	});

// 	let [loading, setLoading] = useState(false);
// 	const [selectedFile, setSelectedFile] = useState(null);
// 	const [showCustomInput, setShowCustomInput] = useState(false);
// 	const [positions, setpositions] = useState([]);
// 	const [imgSrc, setImgSrc] = useState(null);
// 	const [iscapture, setiscapture] = useState(false);
// 	const [
// 		is_jobapplication_next_form_avail,
// 		setis_jobapplication_next_form_avail,
// 	] = useState(0); // initialize it
// 	let navigate = useNavigate();

// 	const [picture, setPicture] = useState("");
// 	const [hasExperience, setHasExperience] = useState(false);
// 	const webcamRef = React.useRef(null);
// 	const capture = React.useCallback(() => {
// 		setiscapture(true);
// 		const pictureSrc = webcamRef.current.getScreenshot();
// 		console.log(pictureSrc);
// 		setPicture(pictureSrc);
// 	});
// 	const setRetake = () => {
// 		setPicture("");
// 		capture();
// 	};

// 	useEffect(() => {
// 		document.body.classList.add("login");
// 		getPositions();
// 	}, []);

// 	const getPositions = async () => {
// 		setLoading(true);
// 		const myHeaders = new Headers();
// 		let userdata = JSON.parse(localStorage.getItem("isAuth"));
// 		if (userdata != null) {
// 			let token = userdata.usertoken;
// 			myHeaders.append("Authorization", token);
// 		}
// 		myHeaders.append("Content-Type", "application/json");
// 		await fetch(BASEURL + `getAllPositions/`, {
// 			method: "POST",
// 			headers: myHeaders,
// 			body: JSON.stringify({
// 				company_uuid: "abc3bab0-2b6e-4fb7-a453-4049c8157ac5",
// 			}),
// 		})
// 			.then((response) => response.json())
// 			.then((res) => {
// 				if (res.status === 201) {
// 					setpositions(res.data);
// 					// console.log(res.data);
// 					setis_jobapplication_next_form_avail(
// 						res.jobappdata.is_jobapplication_next_form_avail
// 					);
// 				} else {
// 					setpositions([]);
// 				}
// 			})
// 			.catch((err) => console.log(err));
// 		setLoading(false);
// 	};
// 	const handlechange = (e) => {
// 		let value = e.target.value;
// 		if (e.target.name === "status") {
// 			value = e.target.checked;
// 		}
// 		setdata({
// 			...data,
// 			[e.target.name]: value,
// 		});
// 	};
// 	const handlePortfolioInputChange = (e) => {
// 		const portfolioUrl = e.target.value;
// 		setdata((prev) => ({
// 			...prev,
// 			portfolio: portfolioUrl || null, // Set null if empty
// 		}));
// 	};

// 	const handleFileInputChange = (e) => {
// 		setSelectedFile(e.target.files[0]);
// 		let value = e.target.value;
// 		setdata({
// 			...data,
// 			[e.target.name]: value, // Keep the original approach
// 		});
// 	};

// 	const savedata = async (elm) => {
// 		if (
// 			data.fullname === "" ||
// 			data.email === "" ||
// 			data.contactno === "" ||
// 			data.city === "" ||
// 			data.position === "" ||
// 			data.noticeperiod === ""
// 		) {
// 			swal({
// 				title: "Error",
// 				text: "Please Enter all value Required value",
// 				icon: "error",
// 				timer: 2000,
// 			});
// 			return;
// 		}
// 		let selectedpositon = positions.filter(
// 			(item) => item.uuid === data.position
// 		);
// 		let msg = is_jobapplication_next_form_avail === 1 ? "Save" : "Submit";

// 		swal({
// 			title: msg + " Application",
// 			text: `Are you sure you want to ${msg} Application? You can't change after ${msg} application`,
// 			buttons: ["No", "Yes"],
// 			showCloseButton: true,
// 		}).then((willDelete) => {
// 			if (willDelete) {
// 				finalSubmit();
// 			}
// 		});
// 	};

// 	const finalSubmit = async () => {
// 		setLoading(true);

// 		const formData = new FormData();

// 		// Add selected CV file
// 		if (selectedFile) {
// 			formData.append("cv", selectedFile);
// 		}

// 		// Include all data, including portfolio and the final value of `knowvavancy`
// 		formData.append(
// 			"data",
// 			JSON.stringify({
// 				...data,
// 				portfolio: data.portfolio || null, // Add portfolio URL if available
// 			})
// 		);

// 		// Add picture if captured
// 		if (picture) {
// 			formData.append("picture", picture);
// 		}

// 		try {
// 			const res = await axios.post(BASEURL + "job-application", formData);
// 			if (res.status === 201) {
// 				swal({
// 					title: "Success",
// 					text: "Your application was submitted successfully!",
// 					icon: "success",
// 					timer: 2000,
// 					buttons: false,
// 				}).then(() => {
// 					// Reset state after successful submission
// 					setdata({
// 						fullname: "",
// 						email: "",
// 						contactno: "",
// 						city: 1,
// 						address: "",
// 						position: "",
// 						noticeperiod: "",
// 						cvname: "",
// 						currentsalary: "",
// 						salaryexp: "",
// 						totalworkexp_years: "",
// 						totalworkexp_months: "",
// 						istraining: 0,
// 						isrelocate: 0,
// 						knowvavancy: "",
// 						fromwheretraining: "",
// 						portfolio: null,
// 						// portfolioName: "",
// 					});
// 					setSelectedFile(null);
// 					setPicture("");
// 					setiscapture(false);
// 				});
// 			} else {
// 				console.error("Error Response:", res.data);
// 				swal("Error", res.data.message, "error");
// 			}
// 		} catch (error) {
// 			console.error("Request Failed:", error);
// 			swal("Error", "An error occurred. Please try again.", "error");
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	// const finalSubmit = async () => {
// 	// 	setLoading(true);

// 	// 	const formData = new FormData();

// 	// 	// Add selected CV file
// 	// 	if (selectedFile) {
// 	// 		formData.append("cv", selectedFile);
// 	// 	}

// 	// 	// Include all data, including portfolio
// 	// 	formData.append(
// 	// 		"data",
// 	// 		JSON.stringify({
// 	// 			...data,
// 	// 			portfolio: data.portfolio || null, // Add portfolio URL if available
// 	// 		})
// 	// 	);

// 	// 	// Add picture if captured
// 	// 	if (picture) {
// 	// 		formData.append("picture", picture);
// 	// 	}

// 	// 	try {
// 	// 		const res = await axios.post(BASEURL + "job-application", formData);
// 	// 		if (res.status === 201) {
// 	// 			swal({
// 	// 				title: "Success",
// 	// 				text: "Your application was submitted successfully!",
// 	// 				icon: "success",
// 	// 				timer: 2000,
// 	// 				buttons: false,
// 	// 			}).then(() => {
// 	// 				// Reset state after successful submission
// 	// 				setdata({
// 	// 					fullname: "",
// 	// 					email: "",
// 	// 					contactno: "",
// 	// 					city: 1,
// 	// 					address: "",
// 	// 					position: "",
// 	// 					noticeperiod: "",
// 	// 					cvname: "",
// 	// 					currentsalary: "",
// 	// 					salaryexp: "",
// 	// 					totalworkexp_years: "",
// 	// 					totalworkexp_months: "",
// 	// 					istraining: 0,
// 	// 					isrelocate: 0,
// 	// 					knowvavancy: "",
// 	// 					fromwheretraining: "",
// 	// 					portfolio: null,
// 	// 					portfolioName: "",
// 	// 				});
// 	// 				setSelectedFile(null);
// 	// 				setPicture("");
// 	// 				setiscapture(false);
// 	// 			});
// 	// 		} else {
// 	// 			console.error("Error Response:", res.data);
// 	// 			swal("Error", res.data.message, "error");
// 	// 		}
// 	// 	} catch (error) {
// 	// 		console.error("Request Failed:", error);
// 	// 		swal("Error", "An error occurred. Please try again.", "error");
// 	// 	} finally {
// 	// 		setLoading(false);
// 	// 	}
// 	// };

// 	// const finalSubmit = async () => {
// 	// 	setLoading(true);

// 	// 	const formData = new FormData();
// 	// 	formData.append("cvname", selectedFile);
// 	// 	formData.append("data", JSON.stringify(data));
// 	// 	formData.append("picture", picture);

// 	// 	try {
// 	// 		const res = await axios.post(BASEURL + "job-application", formData);
// 	// 		if (res.status === 201) {
// 	// 			swal({
// 	// 				title: "Success",
// 	// 				text: "Your application submitted successfully!",
// 	// 				icon: "success",
// 	// 				timer: 2000,
// 	// 				buttons: false,
// 	// 			}).then(() => {

// 	// 				setdata({
// 	// 					fullname: "",
// 	// 					email: "",
// 	// 					contactno: "",
// 	// 					city: 1,
// 	// 					address: "",
// 	// 					position: "",
// 	// 					noticeperiod: "",
// 	// 					cvname: "",
// 	// 					currentsalary: "",
// 	// 					salaryexp: "",
// 	// 					totalworkexp_years: "",
// 	// 					totalworkexp_months: "",
// 	// 					istraining: 0,
// 	// 					isrelocate: 0,
// 	// 				});
// 	// 				setSelectedFile(null);
// 	// 				setPicture("");
// 	// 				setiscapture(false);
// 	// 			});
// 	// 		} else {
// 	// 			console.error("Error Response:", res.data);
// 	// 			swal("Error", res.data.message, "error");
// 	// 		}
// 	// 	} catch (error) {
// 	// 		console.error("Request Failed:", error);
// 	// 		swal("Error", "An error occurred. Please try again.", "error");
// 	// 	} finally {
// 	// 		setLoading(false);
// 	// 	}
// 	// };
// 	return (
// 		<div className="p-0">
// 			<div
// 				className="container "
// 				style={{
// 					height: "100vh",
// 					overflow: "scroll",
// 				}}
// 			>
// 				<div className="block xl:grid grid-cols-2 ">
// 					<div className="hidden xl:flex flex-col min-h-screen">
// 						<div className="-intro-x flex items-center pt-5">
// 							<img
// 								alt="Midone - HTML Admin Template"
// 								className="w-6"
// 								src="../images/logo.svg"
// 							/>
// 							<span className="text-white text-lg ml-3">
// 								Jenya Tracking
// 							</span>
// 						</div>
// 						<div className="my-auto">
// 							<img
// 								alt=""
// 								className="-intro-x w-1/2 "
// 								src="../images/illustration.svg"
// 							/>
// 							<div className="-intro-x text-white font-medium text-3xl leading-tight mt-10 text-left">
// 								A few more clicks to
// 								<br />
// 								submit your job application form
// 							</div>
// 						</div>
// 					</div>

// 					<div
// 						className="h-screen xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0"
// 						style={{ overflow: "scroll" }}
// 					>
// 						<div className="">
// 							<div className="grid grid-cols-24 gap-12 mt-5">
// 								<div className="intro-y col-span-12 lg:col-span-6">
// 									<div className="intro-y box">
// 										<div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
// 											<h2
// 												className="text-base mr-auto"
// 												style={{
// 													fontSize: "25px",
// 													fontWeight: 500,
// 												}}
// 											>
// 												Job Application
// 											</h2>
// 										</div>
// 										<div
// 											id="form-validation"
// 											className="p-5"
// 										>
// 											<div className="preview">
// 												<div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
// 													<form className="pb-5 mb-5">
// 														<div className="grid grid-cols-12 gap-x-5 text-left">
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Full
// 																		Name{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.fullname
// 																		}
// 																		required
// 																		type="text"
// 																		name="fullname"
// 																		className="form-control"
// 																		onChange={
// 																			handlechange
// 																		}
// 																	/>
// 																</div>
// 															</div>

// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Email ID{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.email
// 																		}
// 																		required
// 																		type="email"
// 																		name="email"
// 																		className="form-control"
// 																		onChange={
// 																			handlechange
// 																		}
// 																	/>
// 																</div>
// 															</div>

// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Contact
// 																	Number{" "}
// 																	<span className="text-danger">
// 																		(*)
// 																	</span>
// 																</label>
// 																<input
// 																	id="update-profile-form-1"
// 																	value={
// 																		data.contactno
// 																	}
// 																	required
// 																	minLength={
// 																		10
// 																	}
// 																	maxLength={
// 																		13
// 																	}
// 																	type="number"
// 																	name="contactno"
// 																	className="form-control"
// 																	onChange={
// 																		handlechange
// 																	}
// 																/>
// 															</div>

// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	City{" "}
// 																	<span className="text-danger">
// 																		(*)
// 																	</span>
// 																</label>
// 																<select
// 																	className="form-select"
// 																	name="city"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data.city
// 																	}
// 																>
// 																	<option value="0">
// 																		Ahmedabad
// 																	</option>
// 																	<option value="1">
// 																		Gandhinagar
// 																	</option>
// 																	<option value="1">
// 																		Other
// 																	</option>
// 																</select>
// 															</div>

// 															<div className="col-span-12 2xl:col-span-12">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Address
// 																</label>
// 																<br />
// 																<textarea
// 																	name="address"
// 																	className="form-control"
// 																	cols="80"
// 																	rows="2"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data &&
// 																		data.address
// 																	}
// 																></textarea>
// 															</div>

// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Position
// 																		Applied
// 																		for{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<select
// 																		className="form-select"
// 																		name="position"
// 																		onChange={
// 																			handlechange
// 																		}
// 																		value={
// 																			data.position
// 																		}
// 																	>
// 																		<option value="">
// 																			Select
// 																		</option>
// 																		{positions.map(
// 																			(
// 																				item,
// 																				i
// 																			) => {
// 																				return (
// 																					<option
// 																						value={
// 																							item.uuid
// 																						}
// 																					>
// 																						{
// 																							item.name
// 																						}
// 																					</option>
// 																				);
// 																			}
// 																		)}
// 																	</select>

// 																	{/* <option value="0">Python Developer</option>
//                             <option value="1">React JS Developer</option>
//                             <option value="2">PHP Developer</option>
//                             <option value="3">HR Executive</option>
//                             <option value="4">Angular Developer</option>
//                             <option value="5">SEO Executive</option>
//                             <option value="6">Admin</option>
//                             <option value="7">Tele Caller</option>
//                             <option value="8">Accountant</option> */}
// 																	{/* <input
//                           id="update-profile-form-1"
//                           value={data.position}
//                           required
//                           type="text"
//                           name="position"
//                           className="form-control"
//                           onChange={handlechange}
//                         /> */}
// 																</div>
// 															</div>
// 															{[
// 																"UI/UX Designer",
// 																"Graphics Designer",
// 															].includes(
// 																positions.find(
// 																	(item) =>
// 																		item.uuid ===
// 																		data.position
// 																)?.name
// 															) && (
// 																	<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																		<label
// 																			htmlFor="portfolio"
// 																			className="form-label"
// 																		>
// 																			Portfolio
// 																			Link:
// 																		</label>
// 																		<input
// 																			type="text"
// 																			name="portfolio"
// 																			id="portfolio"
// 																			className="form-control"
// 																			placeholder="Enter portfolio URL"
// 																			onChange={
// 																				handlePortfolioInputChange
// 																			}
// 																			value={
// 																				data.portfolio ||
// 																				""
// 																			}
// 																		/>
// 																	</div>
// 																)}

// 															<div className="col-span-6 2xl:col-span-6">
// 																<label className="form-label">
// 																	Do you have
// 																	work
// 																	experience?
// 																</label>
// 																<div className="flex space-x-4  mr-5">
// 																	<label className="flex items-center space-x-3">
// 																		<input
// 																			type="radio"
// 																			name="workExperience"
// 																			value="yes"
// 																			onChange={() =>
// 																				setHasExperience(
// 																					true
// 																				)
// 																			}
// 																		/>
// 																		<span>
// 																			&nbsp;
// 																			Yes
// 																		</span>
// 																	</label>
// 																	&nbsp;
// 																	<label className="flex items-center space-x-3">
// 																		<input
// 																			type="radio"
// 																			name="workExperience"
// 																			value="no"
// 																			onChange={() =>
// 																				setHasExperience(
// 																					false
// 																				)
// 																			}
// 																		/>
// 																		<span>
// 																			&nbsp;
// 																			No
// 																		</span>
// 																	</label>
// 																</div>

// 																{/* Conditional Work Experience Fields */}
// 																{hasExperience && (
// 																	<div className="mt-4">
// 																		<label
// 																			htmlFor="update-profile-form-2"
// 																			className="form-label"
// 																		>
// 																			What
// 																			is
// 																			your
// 																			total
// 																			work
// 																			experience?{" "}
// 																			<br />{" "}
// 																			In
// 																			Years
// 																			and
// 																			Months
// 																		</label>
// 																		<br />
// 																		<div className="flex space-x-2">
// 																			<select
// 																				name="totalworkexp_years"
// 																				className="form-control mt-0 w-1/2"
// 																				onChange={
// 																					handlechange
// 																				}
// 																				value={
// 																					data.totalworkexp_years
// 																				}
// 																			>
// 																				{/* Options for years */}
// 																				{[
// 																					...Array(
// 																						9
// 																					).keys(),
// 																				].map(
// 																					(
// 																						year
// 																					) => (
// 																						<option
// 																							key={
// 																								year
// 																							}
// 																							value={
// 																								year
// 																							}
// 																						>
// 																							{
// 																								year
// 																							}
// 																						</option>
// 																					)
// 																				)}
// 																			</select>
// 																			<select
// 																				name="totalworkexp_months"
// 																				className="form-control mt-0 w-1/2"
// 																				onChange={
// 																					handlechange
// 																				}
// 																				value={
// 																					data.totalworkexp_months
// 																				}
// 																			>
// 																				{/* Options for months */}
// 																				{[
// 																					...Array(
// 																						12
// 																					).keys(),
// 																				].map(
// 																					(
// 																						month
// 																					) => (
// 																						<option
// 																							key={
// 																								month
// 																							}
// 																							value={
// 																								month
// 																							}
// 																						>
// 																							{
// 																								month
// 																							}
// 																						</option>
// 																					)
// 																				)}
// 																			</select>
// 																		</div>
// 																	</div>
// 																)}
// 															</div>
// 															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Notice
// 																		Period:{" "}
// 																		<span className="text-danger">
// 																			(*)
// 																		</span>
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		value={
// 																			data.noticeperiod
// 																		}
// 																		required
// 																		type="text"
// 																		name="noticeperiod"
// 																		className="form-control"
// 																		onChange={
// 																			handlechange
// 																		}
// 																	/>
// 																</div>
// 															</div>

// 															{/* current salary */}
// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<div className="col-md-6 pb-2">
// 																	<label
// 																		htmlFor="update-profile-form-2"
// 																		className="form-label"
// 																	>
// 																		Current
// 																		Salary?
// 																	</label>
// 																	<br />
// 																	<input
// 																		type="text"
// 																		name="currentsalary"
// 																		className="form-control"
// 																		cols="80"
// 																		onChange={
// 																			handlechange
// 																		}
// 																		value={
// 																			data.currentsalary
// 																		}
// 																	></input>
// 																</div>
// 															</div>
// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Salary
// 																	Expectations?
// 																</label>
// 																<br />
// 																<input
// 																	type="text"
// 																	name="salaryexp"
// 																	className="form-control"
// 																	cols="80"
// 																	rows="5"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data.salaryexp
// 																	}
// 																></input>
// 															</div>
// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Are You
// 																	Willing to
// 																	Relocate to
// 																	Gandhinagar?
// 																</label>
// 																<br />
// 																<select
// 																	name="isrelocate"
// 																	className="form-control"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data.isrelocate
// 																	}
// 																>
// 																	<option value="0">
// 																		&nbsp;
// 																		Yes
// 																	</option>
// 																	<option value="1">
// 																		&nbsp;
// 																		No
// 																	</option>
// 																</select>
// 															</div>
// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<label
// 																	htmlFor="update-profile-form-2"
// 																	className="form-label"
// 																>
// 																	Have you
// 																	done
// 																	training/Internship?
// 																</label>
// 																<br />
// 																<select
// 																	name="istraining"
// 																	className="form-control"
// 																	onChange={
// 																		handlechange
// 																	}
// 																	value={
// 																		data.istraining
// 																	}
// 																>
// 																	<option value="0">
// 																		No
// 																	</option>
// 																	<option value="1">
// 																		Yes
// 																	</option>
// 																</select>
// 															</div>

// 															{/* Conditionally render Training/Internship Source */}
// 															{data.istraining ===
// 																"1" && (
// 																	<div className="col-span-6 2xl:col-span-6 pb-2">
// 																		<label
// 																			htmlFor="fromwheretraining"
// 																			className="form-label"
// 																		>
// 																			From
// 																			where
// 																			did you
// 																			get
// 																			Training/Internship?
// 																		</label>
// 																		<input
// 																			type="text"
// 																			name="fromwheretraining"
// 																			id="fromwheretraining"
// 																			className="form-control"
// 																			placeholder="Enter the source"
// 																			onChange={
// 																				handlechange
// 																			}
// 																			value={
// 																				data.fromwheretraining
// 																			}
// 																		/>
// 																	</div>
// 																)}

// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<label
// 																	htmlFor="knowvavancy"
// 																	className="form-label"
// 																>
// 																	Reference
// 																	By:
// 																</label>
// 																<select
// 																	name="knowvavancy"
// 																	id="knowvavancy"
// 																	className="form-control"
// 																	onChange={(
// 																		e
// 																	) => {
// 																		handlechange(
// 																			e
// 																		); // Existing handler for state update
// 																		if (
// 																			e
// 																				.target
// 																				.value ===
// 																			"Other"
// 																		) {
// 																			setShowCustomInput(
// 																				true
// 																			); // Show text input
// 																			setdata(
// 																				{
// 																					...data,
// 																					knowvavancy:
// 																						"",
// 																				}
// 																			); // Clear existing value
// 																		} else {
// 																			setShowCustomInput(
// 																				false
// 																			); // Hide text input
// 																			setdata(
// 																				{
// 																					...data,
// 																					knowvavancy:
// 																						e
// 																							.target
// 																							.value,
// 																				}
// 																			); // Update `knowvavancy`
// 																		}
// 																	}}
// 																	value={
// 																		data.knowvavancy
// 																	}
// 																>
// 																	<option value="">
// 																		Select
// 																	</option>
// 																	<option value="Google">
// 																		Google
// 																	</option>
// 																	<option value="Indeed">
// 																		Indeed
// 																	</option>
// 																	<option value="Naukri">
// 																		Naukri
// 																	</option>
// 																	<option value="LinkedIn">
// 																		LinkedIn
// 																	</option>
// 																	<option value="Other">
// 																		Other
// 																	</option>
// 																</select>

// 																{showCustomInput && (
// 																	<div
// 																		style={{
// 																			marginTop:
// 																				"10px",
// 																		}}
// 																	>
// 																		<label
// 																			htmlFor="customReference"
// 																			className="form-label"
// 																		>
// 																			Specify
// 																			Reference:
// 																		</label>
// 																		<input
// 																			type="text"
// 																			id="customReference"
// 																			name="customReference"
// 																			className="form-control"
// 																			placeholder="Enter reference source"
// 																			value={
// 																				data.knowvavancy
// 																			} // Bind directly to `knowvavancy`
// 																			onChange={(
// 																				e
// 																			) =>
// 																				setdata(
// 																					{
// 																						...data,
// 																						knowvavancy:
// 																							e
// 																								.target
// 																								.value,
// 																					}
// 																				)
// 																			}
// 																		/>
// 																	</div>
// 																)}

// 																{/* Conditionally Render Input Field */}
// 																{data.knowvavancy ===
// 																	"Other" && (
// 																		<input
// 																			type="text"
// 																			name="knowvavancyOther"
// 																			id="knowvavancyOther"
// 																			className="form-control mt-2"
// 																			placeholder="Please specify"
// 																			onChange={(
// 																				e
// 																			) =>
// 																				setdata(
// 																					{
// 																						...data,
// 																						knowvavancy:
// 																							e
// 																								.target
// 																								.value,
// 																					}
// 																				)
// 																			}
// 																			value={
// 																				data.knowvavancy !==
// 																					"Other"
// 																					? data.knowvavancy
// 																					: ""
// 																			}
// 																		/>
// 																	)}
// 															</div>

// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Upload
// 																		CV:
// 																	</label>
// 																	<input
// 																		id="update-profile-form-1"
// 																		required
// 																		type="file"
// 																		name="cv"
// 																		className="form-control p-1"
// 																		onChange={
// 																			handleFileInputChange
// 																		}
// 																	/>
// 																</div>
// 															</div>
// 															{/* Reference By */}

// 															<div className="col-span-6 2xl:col-span-6 pb-2">
// 																<div>
// 																	<label
// 																		htmlFor="update-profile-form-1"
// 																		className="form-label"
// 																	>
// 																		Upload
// 																		Image:
// 																	</label>
// 																	<div className="container">
// 																		{iscapture &&
// 																			picture ==
// 																			"" ? (
// 																			<Webcam
// 																				audio={
// 																					false
// 																				}
// 																				height={
// 																					200
// 																				}
// 																				ref={
// 																					webcamRef
// 																				}
// 																				width={
// 																					200
// 																				}
// 																				screenshotFormat="image/jpeg"
// 																				videoConstraints={
// 																					videoConstraints
// 																				}
// 																			/>
// 																		) : (
// 																			<img
// 																				src={
// 																					picture
// 																				}
// 																			/>
// 																		)}
// 																		{/* <div className="btn-container">
//                             <button type='button' onClick={capture}>Capture photo</button>
//                           </div> */}

// 																		{picture !=
// 																			"" ? (
// 																			<button
// 																				type="button"
// 																				onClick={(
// 																					e
// 																				) => {
// 																					e.preventDefault();
// 																					// setPicture()
// 																					setRetake();
// 																				}}
// 																				className="btn btn-primary"
// 																			>
// 																				Retake
// 																				Image
// 																			</button>
// 																		) : (
// 																			<button
// 																				type="button"
// 																				onClick={(
// 																					e
// 																				) => {
// 																					e.preventDefault();
// 																					capture();
// 																				}}
// 																				className="btn btn-danger"
// 																			>
// 																				Capture
// 																				Image
// 																			</button>
// 																		)}
// 																	</div>
// 																</div>
// 															</div>
// 														</div>

// 														{loading ? (
// 															<HashLoader
// 																color="#5755d9"
// 																size={30}
// 																style={{
// 																	position:
// 																		"absolute",
// 																	left: "10%",
// 																	paddingTop:
// 																		"10%",
// 																}}
// 															/>
// 														) : (
// 															<div className="text-left">
// 																<button
// 																	type="button"
// 																	onClick={() =>
// 																		savedata()
// 																	}
// 																	className="btn btn-primary w-50 mt-3 mb-5"
// 																>
// 																	{is_jobapplication_next_form_avail ===
// 																		1
// 																		? "Save & Next Application"
// 																		: "Submit Application"}
// 																</button>
// 															</div>
// 														)}
// 													</form>
// 												</div>
// 											</div>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default JobApplication;



import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import Webcam from "react-webcam";
// import { useCallback,z useRef } from "react";
import { useNavigate } from "react-router-dom";
const WebcamComponent = () => <Webcam />;
const videoConstraints = {
	width: 400,
	height: 400,
	facingMode: "user",
};
const JobApplication = (props) => {
	// console.log(props);
	const [data, setdata] = useState({
		fullname: "",
		email: "",
		contactno: "",
		city: 1,
		address: "",
		position: "",
		noticeperiod: "",
		cvname: "",
		cv: "",
		currentsalary: "",
		salaryexp: "",
		totalworkexp_years: "",
		totalworkexp_months: "",
		istraining: "0",
		isrelocate: 0,
		knowvavancy: "",
		fromwheretraining: "",
		portfolio: null,
		// portfolioName: "",
	});

	let [loading, setLoading] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [showCustomInput, setShowCustomInput] = useState(false);
	const [positions, setpositions] = useState([]);
	const [imgSrc, setImgSrc] = useState(null);
	const [iscapture, setiscapture] = useState(false);
	const [
		is_jobapplication_next_form_avail,
		setis_jobapplication_next_form_avail,
	] = useState(0); // initialize it
	let navigate = useNavigate();

	const [picture, setPicture] = useState("");
	const [hasExperience, setHasExperience] = useState(false);
	const webcamRef = React.useRef(null);
	const capture = React.useCallback(() => {
		setiscapture(true);
		const pictureSrc = webcamRef.current.getScreenshot();
		console.log(pictureSrc);
		setPicture(pictureSrc);
	});
	const setRetake = () => {
		setPicture("");
		capture();
	};

	useEffect(() => {
		document.body.classList.add("login");
		getPositions();
	}, []);

	const getPositions = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllPositions/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: "abc3bab0-2b6e-4fb7-a453-4049c8157ac5",
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setpositions(res.data);
					// console.log(res.data);
					setis_jobapplication_next_form_avail(
						res.jobappdata.is_jobapplication_next_form_avail
					);
				} else {
					setpositions([]);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};
	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
	};
	const handlePortfolioInputChange = (e) => {
		const portfolioUrl = e.target.value;
		setdata((prev) => ({
			...prev,
			portfolio: portfolioUrl || null, // Set null if empty
		}));
	};

	const handleFileInputChange = (e) => {
		setSelectedFile(e.target.files[0]);
		let value = e.target.value;
		setdata({
			...data,
			[e.target.name]: value, // Keep the original approach
		});
	};

	const savedata = async (elm) => {
		if (
			data.fullname === "" ||
			data.email === "" ||
			data.contactno === "" ||
			data.city === "" ||
			data.position === "" ||
			data.noticeperiod === ""
		) {
			swal({
				title: "Error",
				text: "Please Enter all value Required value",
				icon: "error",
				timer: 2000,
			});
			return;
		}
		let selectedpositon = positions.filter(
			(item) => item.uuid === data.position
		);
		let msg = is_jobapplication_next_form_avail === 1 ? "Save" : "Submit";

		swal({
			title: msg + " Application",
			text: `Are you sure you want to ${msg} Application? You can't change after ${msg} application`,
			buttons: ["No", "Yes"],
			showCloseButton: true,
		}).then((willDelete) => {
			if (willDelete) {
				finalSubmit();
			}
		});
	};

	const finalSubmit = async () => {
		setLoading(true);

		const formData = new FormData();

		// Add selected CV file
		if (selectedFile) {
			formData.append("cv", selectedFile);
		}

		// Include all data, including portfolio and the final value of `knowvavancy`
		formData.append(
			"data",
			JSON.stringify({
				...data,
				portfolio: data.portfolio || null, // Add portfolio URL if available
			})
		);

		// Add picture if captured
		if (picture) {
			formData.append("picture", picture);
		}

		try {
			const res = await axios.post(BASEURL + "job-application", formData);
			if (res.status === 201) {
				swal({
					title: "Success",
					text: "Your application was submitted successfully!",
					icon: "success",
					timer: 2000,
					buttons: false,
				}).then(() => {
					// Reset state after successful submission
					setdata({
						fullname: "",
						email: "",
						contactno: "",
						city: 1,
						address: "",
						position: "",
						noticeperiod: "",
						cvname: "",
						currentsalary: "",
						salaryexp: "",
						totalworkexp_years: "",
						totalworkexp_months: "",
						istraining: 0,
						isrelocate: 0,
						knowvavancy: "",
						fromwheretraining: "",
						portfolio: null,
						// portfolioName: "",
					});
					setSelectedFile(null);
					setPicture("");
					setiscapture(false);
				});
			} else {
				console.error("Error Response:", res.data);
				swal("Error", res.data.message, "error");
			}
		} catch (error) {
			console.error("Request Failed:", error);
			swal("Error", "An error occurred. Please try again.", "error");
		} finally {
			setLoading(false);
		}
	};

	// const finalSubmit = async () => {
	// 	setLoading(true);

	// 	const formData = new FormData();

	// 	// Add selected CV file
	// 	if (selectedFile) {
	// 		formData.append("cv", selectedFile);
	// 	}

	// 	// Include all data, including portfolio
	// 	formData.append(
	// 		"data",
	// 		JSON.stringify({
	// 			...data,
	// 			portfolio: data.portfolio || null, // Add portfolio URL if available
	// 		})
	// 	);

	// 	// Add picture if captured
	// 	if (picture) {
	// 		formData.append("picture", picture);
	// 	}

	// 	try {
	// 		const res = await axios.post(BASEURL + "job-application", formData);
	// 		if (res.status === 201) {
	// 			swal({
	// 				title: "Success",
	// 				text: "Your application was submitted successfully!",
	// 				icon: "success",
	// 				timer: 2000,
	// 				buttons: false,
	// 			}).then(() => {
	// 				// Reset state after successful submission
	// 				setdata({
	// 					fullname: "",
	// 					email: "",
	// 					contactno: "",
	// 					city: 1,
	// 					address: "",
	// 					position: "",
	// 					noticeperiod: "",
	// 					cvname: "",
	// 					currentsalary: "",
	// 					salaryexp: "",
	// 					totalworkexp_years: "",
	// 					totalworkexp_months: "",
	// 					istraining: 0,
	// 					isrelocate: 0,
	// 					knowvavancy: "",
	// 					fromwheretraining: "",
	// 					portfolio: null,
	// 					portfolioName: "",
	// 				});
	// 				setSelectedFile(null);
	// 				setPicture("");
	// 				setiscapture(false);
	// 			});
	// 		} else {
	// 			console.error("Error Response:", res.data);
	// 			swal("Error", res.data.message, "error");
	// 		}
	// 	} catch (error) {
	// 		console.error("Request Failed:", error);
	// 		swal("Error", "An error occurred. Please try again.", "error");
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	// const finalSubmit = async () => {
	// 	setLoading(true);

	// 	const formData = new FormData();
	// 	formData.append("cvname", selectedFile);
	// 	formData.append("data", JSON.stringify(data));
	// 	formData.append("picture", picture);

	// 	try {
	// 		const res = await axios.post(BASEURL + "job-application", formData);
	// 		if (res.status === 201) {
	// 			swal({
	// 				title: "Success",
	// 				text: "Your application submitted successfully!",
	// 				icon: "success",
	// 				timer: 2000,
	// 				buttons: false,
	// 			}).then(() => {

	// 				setdata({
	// 					fullname: "",
	// 					email: "",
	// 					contactno: "",
	// 					city: 1,
	// 					address: "",
	// 					position: "",
	// 					noticeperiod: "",
	// 					cvname: "",
	// 					currentsalary: "",
	// 					salaryexp: "",
	// 					totalworkexp_years: "",
	// 					totalworkexp_months: "",
	// 					istraining: 0,
	// 					isrelocate: 0,
	// 				});
	// 				setSelectedFile(null);
	// 				setPicture("");
	// 				setiscapture(false);
	// 			});
	// 		} else {
	// 			console.error("Error Response:", res.data);
	// 			swal("Error", res.data.message, "error");
	// 		}
	// 	} catch (error) {
	// 		console.error("Request Failed:", error);
	// 		swal("Error", "An error occurred. Please try again.", "error");
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };
	return (
		<div className="p-0">
			<div
				className="container "
				style={{
					height: "100vh",
					overflow: "scroll",
				}}
			>
				<div className="block xl:grid grid-cols-2 ">
					<div className="hidden xl:flex flex-col min-h-screen">
						<div className="-intro-x flex items-center pt-5">
							<img
								alt="Midone - HTML Admin Template"
								className="w-6"
								src="../images/logo.svg"
							/>
							<span className="text-white text-lg ml-3">
								Jenya Tracking
							</span>
						</div>
						<div className="my-auto">
							<img
								alt=""
								className="-intro-x w-1/2 "
								src="../images/illustration.svg"
							/>
							<div className="-intro-x text-white font-medium text-3xl leading-tight mt-10 text-left">
								A few more clicks to
								<br />
								submit your job application form
							</div>
						</div>
					</div>

					<div
						className="h-screen xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0"
						style={{ overflow: "scroll" }}
					>
						<div className="">
							<div className="grid grid-cols-24 gap-12 mt-5">
								<div className="intro-y col-span-12 lg:col-span-6">
									<div className="intro-y box">
										<div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
											<h2
												className="text-base mr-auto"
												style={{
													fontSize: "25px",
													fontWeight: 500,
												}}
											>
												Job Application
											</h2>
										</div>
										<div
											id="form-validation"
											className="p-5"
										>
											<div className="preview">
												<div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
													<form className="pb-5 mb-5">
														<div className="grid grid-cols-12 gap-x-5 text-left">
															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																<div>
																	<label
																		htmlFor="update-profile-form-1"
																		className="form-label"
																	>
																		Full
																		Name{" "}
																		<span className="text-danger">
																			(*)
																		</span>
																	</label>
																	<input
																		id="update-profile-form-1"
																		value={
																			data.fullname
																		}
																		required
																		type="text"
																		name="fullname"
																		className="form-control"
																		onChange={
																			handlechange
																		}
																	/>
																</div>
															</div>

															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																<div>
																	<label
																		htmlFor="update-profile-form-1"
																		className="form-label"
																	>
																		Email ID{" "}
																		<span className="text-danger">
																			(*)
																		</span>
																	</label>
																	<input
																		id="update-profile-form-1"
																		value={
																			data.email
																		}
																		required
																		type="email"
																		name="email"
																		className="form-control"
																		onChange={
																			handlechange
																		}
																	/>
																</div>
															</div>

															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																<label
																	htmlFor="update-profile-form-2"
																	className="form-label"
																>
																	Contact
																	Number{" "}
																	<span className="text-danger">
																		(*)
																	</span>
																</label>
																<input
																	id="update-profile-form-1"
																	value={
																		data.contactno
																	}
																	required
																	minLength={
																		10
																	}
																	maxLength={
																		13
																	}
																	type="number"
																	name="contactno"
																	className="form-control"
																	onChange={
																		handlechange
																	}
																/>
															</div>

															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																<label
																	htmlFor="update-profile-form-2"
																	className="form-label"
																>
																	City{" "}
																	<span className="text-danger">
																		(*)
																	</span>
																</label>
																<select
																	className="form-select"
																	name="city"
																	onChange={
																		handlechange
																	}
																	value={
																		data.city
																	}
																>
																	<option value="0">
																		Ahmedabad
																	</option>
																	<option value="1">
																		Gandhinagar
																	</option>
																	<option value="1">
																		Other
																	</option>
																</select>
															</div>

															<div className="col-span-12 2xl:col-span-12">
																<label
																	htmlFor="update-profile-form-2"
																	className="form-label"
																>
																	Address
																</label>
																<br />
																<textarea
																	name="address"
																	className="form-control"
																	cols="80"
																	rows="2"
																	onChange={
																		handlechange
																	}
																	value={
																		data &&
																		data.address
																	}
																></textarea>
															</div>

															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																<div>
																	<label
																		htmlFor="update-profile-form-1"
																		className="form-label"
																	>
																		Position
																		Applied
																		for{" "}
																		<span className="text-danger">
																			(*)
																		</span>
																	</label>
																	<select
																		className="form-select"
																		name="position"
																		onChange={
																			handlechange
																		}
																		value={
																			data.position
																		}
																	>
																		<option value="">
																			Select
																		</option>
																		{positions.map(
																			(
																				item,
																				i
																			) => {
																				return (
																					<option
																						value={
																							item.uuid
																						}
																					>
																						{
																							item.name
																						}
																					</option>
																				);
																			}
																		)}
																	</select>

																	{/* <option value="0">Python Developer</option>
                            <option value="1">React JS Developer</option>
                            <option value="2">PHP Developer</option>
                            <option value="3">HR Executive</option>
                            <option value="4">Angular Developer</option>
                            <option value="5">SEO Executive</option>
                            <option value="6">Admin</option>
                            <option value="7">Tele Caller</option>
                            <option value="8">Accountant</option> */}
																	{/* <input
                          id="update-profile-form-1"
                          value={data.position}
                          required
                          type="text"
                          name="position"
                          className="form-control"
                          onChange={handlechange}
                        /> */}
																</div>
															</div>
															{[
																"UI/UX Designer",
																"Graphics Designer",
															].includes(
																positions.find(
																	(item) =>
																		item.uuid ===
																		data.position
																)?.name
															) && (
																	<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																		<label
																			htmlFor="portfolio"
																			className="form-label"
																		>
																			Portfolio
																			Link:
																		</label>
																		<input
																			type="text"
																			name="portfolio"
																			id="portfolio"
																			className="form-control"
																			placeholder="Enter portfolio URL"
																			onChange={
																				handlePortfolioInputChange
																			}
																			value={
																				data.portfolio ||
																				""
																			}
																		/>
																	</div>
																)}

															<div className="col-span-6 2xl:col-span-6">
																<label className="form-label">
																	Do you have
																	work
																	experience?
																</label>
																<div className="flex space-x-4  mr-5">
																	<label className="flex items-center space-x-3">
																		<input
																			type="radio"
																			name="workExperience"
																			value="yes"
																			onChange={() =>
																				setHasExperience(
																					true
																				)
																			}
																		/>
																		<span>
																			&nbsp;
																			Yes
																		</span>
																	</label>
																	&nbsp;
																	<label className="flex items-center space-x-3">
																		<input
																			type="radio"
																			name="workExperience"
																			value="no"
																			onChange={() =>
																				setHasExperience(
																					false
																				)
																			}
																		/>
																		<span>
																			&nbsp;
																			No
																		</span>
																	</label>
																</div>

																{/* Conditional Work Experience Fields */}
																{hasExperience && (
																	<div className="mt-4">
																		<label
																			htmlFor="update-profile-form-2"
																			className="form-label"
																		>
																			What
																			is
																			your
																			total
																			work
																			experience?{" "}
																			<br />{" "}
																			In
																			Years
																			and
																			Months
																		</label>
																		<br />
																		<div className="flex space-x-2">
																			<select
																				name="totalworkexp_years"
																				className="form-control mt-0 w-1/2"
																				onChange={
																					handlechange
																				}
																				value={
																					data.totalworkexp_years
																				}
																			>
																				{/* Options for years */}
																				{[
																					...Array(
																						9
																					).keys(),
																				].map(
																					(
																						year
																					) => (
																						<option
																							key={
																								year
																							}
																							value={
																								year
																							}
																						>
																							{
																								year
																							}
																						</option>
																					)
																				)}
																			</select>
																			<select
																				name="totalworkexp_months"
																				className="form-control mt-0 w-1/2"
																				onChange={
																					handlechange
																				}
																				value={
																					data.totalworkexp_months
																				}
																			>
																				{/* Options for months */}
																				{[
																					...Array(
																						12
																					).keys(),
																				].map(
																					(
																						month
																					) => (
																						<option
																							key={
																								month
																							}
																							value={
																								month
																							}
																						>
																							{
																								month
																							}
																						</option>
																					)
																				)}
																			</select>
																		</div>
																	</div>
																)}
															</div>
															<div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
																<div>
																	<label
																		htmlFor="update-profile-form-1"
																		className="form-label"
																	>
																		Notice
																		Period:{" "}
																		<span className="text-danger">
																			(*)
																		</span>
																	</label>
																	<input
																		id="update-profile-form-1"
																		value={
																			data.noticeperiod
																		}
																		required
																		type="text"
																		name="noticeperiod"
																		className="form-control"
																		onChange={
																			handlechange
																		}
																	/>
																</div>
															</div>

															{/* current salary */}
															<div className="col-span-6 2xl:col-span-6 pb-2">
																<div className="col-md-6 pb-2">
																	<label
																		htmlFor="update-profile-form-2"
																		className="form-label"
																	>
																		Current
																		Salary?
																	</label>
																	<br />
																	<input
																		type="text"
																		name="currentsalary"
																		className="form-control"
																		cols="80"
																		onChange={
																			handlechange
																		}
																		value={
																			data.currentsalary
																		}
																	></input>
																</div>
															</div>
															<div className="col-span-6 2xl:col-span-6 pb-2">
																<label
																	htmlFor="update-profile-form-2"
																	className="form-label"
																>
																	Salary
																	Expectations?
																</label>
																<br />
																<input
																	type="text"
																	name="salaryexp"
																	className="form-control"
																	cols="80"
																	rows="5"
																	onChange={
																		handlechange
																	}
																	value={
																		data.salaryexp
																	}
																></input>
															</div>
															<div className="col-span-6 2xl:col-span-6 pb-2">
																<label
																	htmlFor="update-profile-form-2"
																	className="form-label"
																>
																	Are You
																	Willing to
																	Relocate to
																	Gandhinagar?
																</label>
																<br />
																<select
																	name="isrelocate"
																	className="form-control"
																	onChange={
																		handlechange
																	}
																	value={
																		data.isrelocate
																	}
																>
																	<option value="0">
																		&nbsp;
																		Yes
																	</option>
																	<option value="1">
																		&nbsp;
																		No
																	</option>
																</select>
															</div>
															<div className="col-span-6 2xl:col-span-6 pb-2">
																<label
																	htmlFor="update-profile-form-2"
																	className="form-label"
																>
																	Have you
																	done
																	training/Internship?
																</label>
																<br />
																<select
																	name="istraining"
																	className="form-control"
																	onChange={
																		handlechange
																	}
																	value={
																		data.istraining
																	}
																>
																	<option value="0">
																		No
																	</option>
																	<option value="1">
																		Yes
																	</option>
																</select>
															</div>

															{/* Conditionally render Training/Internship Source */}
															{data.istraining ===
																"1" && (
																	<div className="col-span-6 2xl:col-span-6 pb-2">
																		<label
																			htmlFor="fromwheretraining"
																			className="form-label"
																		>
																			From
																			where
																			did you
																			get
																			Training/Internship?
																		</label>
																		<input
																			type="text"
																			name="fromwheretraining"
																			id="fromwheretraining"
																			className="form-control"
																			placeholder="Enter the source"
																			onChange={
																				handlechange
																			}
																			value={
																				data.fromwheretraining
																			}
																		/>
																	</div>
																)}

															<div className="col-span-6 2xl:col-span-6 pb-2">
																<label
																	htmlFor="knowvavancy"
																	className="form-label"
																>
																	Reference
																	By:
																</label>
																<select
																	name="knowvavancy"
																	id="knowvavancy"
																	className="form-control"
																	onChange={(
																		e
																	) => {
																		handlechange(
																			e
																		); // Existing handler for state update
																		if (
																			e
																				.target
																				.value ===
																			"Other"
																		) {
																			setShowCustomInput(
																				true
																			); // Show text input
																			setdata(
																				{
																					...data,
																					knowvavancy:
																						"",
																				}
																			); // Clear existing value
																		} else {
																			setShowCustomInput(
																				false
																			); // Hide text input
																			setdata(
																				{
																					...data,
																					knowvavancy:
																						e
																							.target
																							.value,
																				}
																			); // Update `knowvavancy`
																		}
																	}}
																	value={
																		data.knowvavancy
																	}
																>
																	<option value="">
																		Select
																	</option>
																	<option value="Google">
																		Google
																	</option>
																	<option value="Indeed">
																		Indeed
																	</option>
																	<option value="Naukri">
																		Naukri
																	</option>
																	<option value="LinkedIn">
																		LinkedIn
																	</option>
																	<option value="Other">
																		Other
																	</option>
																</select>

																{showCustomInput && (
																	<div
																		style={{
																			marginTop:
																				"10px",
																		}}
																	>
																		<label
																			htmlFor="customReference"
																			className="form-label"
																		>
																			Specify
																			Reference:
																		</label>
																		<input
																			type="text"
																			id="customReference"
																			name="customReference"
																			className="form-control"
																			placeholder="Enter reference source"
																			value={
																				data.knowvavancy
																			} // Bind directly to `knowvavancy`
																			onChange={(
																				e
																			) =>
																				setdata(
																					{
																						...data,
																						knowvavancy:
																							e
																								.target
																								.value,
																					}
																				)
																			}
																		/>
																	</div>
																)}

																{/* Conditionally Render Input Field */}
																{data.knowvavancy ===
																	"Other" && (
																		<input
																			type="text"
																			name="knowvavancyOther"
																			id="knowvavancyOther"
																			className="form-control mt-2"
																			placeholder="Please specify"
																			onChange={(
																				e
																			) =>
																				setdata(
																					{
																						...data,
																						knowvavancy:
																							e
																								.target
																								.value,
																					}
																				)
																			}
																			value={
																				data.knowvavancy !==
																					"Other"
																					? data.knowvavancy
																					: ""
																			}
																		/>
																	)}
															</div>

															<div className="col-span-6 2xl:col-span-6 pb-2">
																<div>
																	<label
																		htmlFor="update-profile-form-1"
																		className="form-label"
																	>
																		Upload
																		CV:
																	</label>
																	<input
																		id="update-profile-form-1"
																		required
																		type="file"
																		name="cv"
																		className="form-control p-1"
																		onChange={
																			handleFileInputChange
																		}
																	/>
																</div>
															</div>
															{/* Reference By */}

															<div className="col-span-6 2xl:col-span-6 pb-2">
																<div>
																	<label
																		htmlFor="update-profile-form-1"
																		className="form-label"
																	>
																		Upload
																		Image:
																	</label>
																	<div className="container">
																		{iscapture &&
																			picture ==
																			"" ? (
																			<Webcam
																				audio={
																					false
																				}
																				height={
																					200
																				}
																				ref={
																					webcamRef
																				}
																				width={
																					200
																				}
																				screenshotFormat="image/jpeg"
																				videoConstraints={
																					videoConstraints
																				}
																			/>
																		) : (
																			<img
																				src={
																					picture
																				}
																			/>
																		)}
																		{/* <div className="btn-container">
                            <button type='button' onClick={capture}>Capture photo</button>
                          </div> */}

																		{picture !=
																			"" ? (
																			<button
																				type="button"
																				onClick={(
																					e
																				) => {
																					e.preventDefault();
																					// setPicture()
																					setRetake();
																				}}
																				className="btn btn-primary"
																			>
																				Retake
																				Image
																			</button>
																		) : (
																			<button
																				type="button"
																				onClick={(
																					e
																				) => {
																					e.preventDefault();
																					capture();
																				}}
																				className="btn btn-danger"
																			>
																				Capture
																				Image
																			</button>
																		)}
																	</div>
																</div>
															</div>
														</div>

														{loading ? (
															<HashLoader
																color="#5755d9"
																size={30}
																style={{
																	position:
																		"absolute",
																	left: "10%",
																	paddingTop:
																		"10%",
																}}
															/>
														) : (
															<div className="text-left">
																<button
																	type="button"
																	onClick={() =>
																		savedata()
																	}
																	className="btn btn-primary w-50 mt-3 mb-5"
																>
																	{is_jobapplication_next_form_avail ===
																		1
																		? "Save & Next Application"
																		: "Submit Application"}
																</button>
															</div>
														)}
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobApplication;