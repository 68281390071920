import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import SummaryChart from "./SummaryChart";
import { FaCalendar } from "react-icons/fa";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import HashLoader from "react-spinners/HashLoader";
import { BsGraphUp, BsGraphDown, BsBriefcase } from "react-icons/bs";
import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import reload from "../../reload.png";
import "./DesktopActivity.css";
// import "./App.css";
import axios from "axios";
import Swal from "sweetalert2";

const Summary = () => {
	const loadingContext = useLoadingContext();
	const [birthdayData, setBirthdayData] = useState();
	const [holidaydata, setholidaydata] = useState([]);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [attdata, setattdata] = useState(null);
	const [idledata, setidledata] = useState(null);
	const [countdata, setcountdata] = useState([]);
	const [bdaydata, setbdaydata] = useState([]);
	const [chart1, setchart1] = useState([]);
	const [chart2, setchart2] = useState([]);
	const [chart3, setchart3] = useState([]);
	const [username, setusername] = useState("");
	const [currdate] = useState(new Date());
	const [orgval, setorgval] = React.useState([]);
	// const [value, onChange] = useState([
	// 	new Date(currdate.getFullYear(), currdate.getMonth(), 1),
	// 	new Date(),
	// ]);
	const [value, onChange] = useState([currdate, currdate]);
	const [userdata, setuserdata] = useState([]);
	const [alluser, setalluser] = useState(null);
	const [isAuth, setIsAuth] = useState(
		JSON.parse(localStorage.getItem("isAuth"))
	);

	const [orgs, setorgs] = React.useState([]);
	const [master] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).master
	);
	const [type] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).type
	);
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);


	// const loadingContext = useLoadingContext();
	const [hasOptedOut, setHasOptedOut] = useState(false);

	const fetchOptOutStatus = async () => {
		try {
			const isAuth = JSON.parse(localStorage.getItem("isAuth"));

			if (isAuth) {
				const { uuid } = isAuth;
				const response = await fetch(`${BASEURL}getOptOutUsers`);
				const result = await response.json();

				if (response.status === 200 && result.data) {
					const userOptOut = result.data.some(
						(user) => user.uuid === uuid
					);
					setHasOptedOut(userOptOut);
				}
			} else {
				Swal.fire("Error!", "User details not found.", "error");
			}
		} catch (error) {
			console.error("Error fetching opt-out status:", error);
			Swal.fire(
				"Error!",
				"An error occurred while fetching opt-out status.",
				"error"
			);
		}
	};

	const handleYesClick = async (reason) => {
		try {
			const isAuth = JSON.parse(localStorage.getItem("isAuth"));
			if (isAuth) {
				const { department, username, name, dept, uuid } = isAuth;
				const payload = {
					department,
					username,
					name,
					dept,
					uuid,
					opt_out: 1,
					reason,
				};
				const response = await fetch(`${BASEURL}userStore`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(payload),
				});
				const result = await response.json();
				console.log("User Details", result);
				if (result.status === 201) {
					Swal.fire({
						html: `
							<span style="font-size: 14px; line-height: 1.5;">
								${result?.message}
							</span>
						`,
						icon: "success",
						width: "400px",
						padding: "20px",
					});
					setHasOptedOut(true);
				} else {
					Swal.fire({
						html: `
							<span style="font-size: 14px; line-height: 1.5;">
								${result.error || "Failed to process request"}
							</span>
						`,
						icon: "error",
						width: "400px",
						padding: "20px",
					});
				}
			} else {
				Swal.fire({
					html: `
						<span style="font-size: 14px; line-height: 1.5;">
							User details not found.
						</span>
					`,
					icon: "error",
					width: "400px",
					padding: "20px",
				});
			}
		} catch (error) {
			Swal.fire({
				html: `
					<span style="font-size: 14px; line-height: 1.5;">
						An error occurred while processing your request.
					</span>
				`,
				icon: "error",
				width: "400px",
				padding: "20px",
			});
		}
	};

	const showPopup = async () => {
		loadingContext.done();
		Swal.fire({
			title: `<span style="font-size: 15px">Please confirm if you will not be participating in the exercise session<br/>કૃપા કરીને પુષ્ટિ કરો કે તમે કસરત સત્રમાં ભાગ લેશો નહીં.</span>`,
			html: `
			<span style="font-size: 15px; color: red;">
				Note: If you opt out of the exercise session, you will no longer be eligible for sick leave (SL) moving forward.<br/>
				નોંધ: જો તમે કસરત સત્રમાંથી બહાર નીકળો છો, તો આગળથી તમે SL (બીમાર રજા) માટે પાત્ર નહીં હો.
			</span>
			<div style="margin-top: 15px; text-align: left;">
				<label for="reasonTextarea" style="font-weight: bold; margin-bottom: 5px; display: inline-block;">
					Reason:
				</label>
				<textarea id="reasonTextarea" placeholder="Please provide your reason here..." 
					style="width: 100%; height: 100px; border-radius: 5px; border: 1px solid #ccc; padding: 10px; font-size: 14px; margin-top: 5px;"></textarea>
			</div>
		`,
			showCancelButton: true,
			confirmButtonText: `
			<div style="font-size: 12px; color: white; font-weight: bold; padding: 1px 2px; border-radius: 8px;">
					Yes, I will not participate
								</div>
		`,
			cancelButtonText: "No",
			icon: "question",
			width: "750px",
			preConfirm: () => {
				// Get the textarea value and trim it to remove any leading/trailing whitespace
				const reason = document.getElementById("reasonTextarea").value.trim();
				if (!reason) {
					Swal.showValidationMessage("Reason is required to proceed.");
					return false;
				}
				return reason; // Return the reason to the `then` block.
			},
		}).then((result) => {
			if (result.isConfirmed) {
				const reason = result.value; // Get the reason from the resolved value.
				console.log("Reason provided by user:", reason);
				handleYesClick(reason); // Pass the reason to handleYesClick.
			} else if (result.isDismissed) {
				// handleNoClick();
			}
		});
	};


	const handleRowClick = () => {
		showPopup();
	};
	useEffect(() => {
		fetchOptOutStatus();
	}, []);
	useEffect(() => {
		if (master === 1) {
			getOrganizations();
		}
		if (type !== 2) {
			getUserData();
		}
		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});

		if (isAuth.role === 2) {
			setusername(isAuth.username);
			getData(
				isAuth.username,
				format(new Date(value[0]), "yyyy-MM-dd"),
				format(new Date(value[1]), "yyyy-MM-dd")
			);
		} else {
			getData(
				username,
				format(new Date(value[0]), "yyyy-MM-dd"),
				format(new Date(value[1]), "yyyy-MM-dd")
			);
		}

		const loading = async () => {
			loadingContext.done();
		};

		loading();
	}, []);

	const reloadFun = () => {
		if (isAuth.role === 2) {
			setusername(isAuth.username);
			getData(
				isAuth.username,
				format(new Date(value[0]), "yyyy-MM-dd"),
				format(new Date(value[1]), "yyyy-MM-dd")
			);
		} else {
			getData(
				username,
				format(new Date(value[0]), "yyyy-MM-dd"),
				format(new Date(value[1]), "yyyy-MM-dd")
			);
		}
	};

	const columns = [
		{ title: "Date", field: "date", width: 180 },
		{ title: "Working Time", field: "working", width: 180 },
		{ title: "Idle Time", field: "idletime", width: 180 },
		{ title: "Productive Time", field: "prod", width: 250 },
		{
			title: "Productive %",
			field: "prodper",
			width: 300,
			formatter: reactFormatter(<AppUsage />),
		},
	];

	function DetailVal(props) {
		const rowData = props.cell._cell.row.data;

		if (rowData.employee !== "") {
			return (
				<a
					href={"/employees/" + rowData.uuid}
					rel="noreferrer"
					target="_blank"
					alt=""
				>
					<u>{rowData.employee}</u>
				</a>
			);
		} else {
			return "No data Available";
		}
	}

	const idlecolumns = [
		{
			title: "Employee",
			field: "employee",
			width: "50%",
			formatter: reactFormatter(<DetailVal />),
		},
		{ title: "Unproductive Time", field: "idletime", width: "50%" },
	];

	const getOrganizations = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-organizations/`, {
			method: "POST",
			headers: myHeaders,
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setorgs(res.data);
				} else {
					setorgs([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const getorg = async (org) => {
		setorgval(org);
		org = org.value;
		setselectedorg(org);
		if (isAuth.role === 0) {
			getUserData(org);
		}
	};

	function AppUsage(props) {
		const rowData = props.cell._cell.row.data;
		if (rowData.prodper !== undefined) {
			return (
				<>
					<div
						id="progressbar-height"
						className="p-1"
						style={{ width: "215px" }}
					>
						<div className="intro-y grid grid-cols-12 gap-5 mt-2">
							<div className="col-span-12 lg:col-span-11 2xl:col-span-10">
								<div className="preview">
									<div className="progress h-3 mb-1 mt-2">
										<div
											className="progress-bar"
											style={{
												width: rowData.prodper + "%",
											}}
											role="progressbar"
											aria-valuenow="0"
											aria-valuemin="0"
											aria-valuemax="100"
										>
											{rowData.prodper}%
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return "";
		}
	}

	const options = {
		pagination: "local",
		paginationSize: 50,
	};

	const getData = async (username, date, ldate, org = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-datewise-productivity`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				username,
				date,
				ldate,
				company_uuid: org !== "" ? org : selectedorg,
				master: JSON.parse(localStorage.getItem("isAuth")).master,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setattdata(res.data);
					setidledata(res.idlearr);
					setcountdata(res.actinfo);
					setchart1(res.totalworkingchart);
					setchart2(res.totalidlechart);
					setchart3(res.totalprodchart);
				} else {
					setattdata([{ date: "No data Available" }]);
					setcountdata([]);
					setidledata([{ employee: "No data Available" }]);
					setchart1([]);
					setchart2([]);
					setchart3([]);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};
	// const getData = async (username, date, ldate, org = "") => {
	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata != null) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");
	// 	await fetch(BASEURL + `get-datewise-productivity`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			username,
	// 			date,
	// 			ldate,
	// 			// company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
	// 			company_uuid: org !== "" ? org : selectedorg,
	// 			master: JSON.parse(localStorage.getItem("isAuth")).master,
	// 			usertype: JSON.parse(localStorage.getItem("isAuth")).type,
	// 			department: JSON.parse(localStorage.getItem("isAuth"))
	// 				.department,
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				setattdata(res.data);

	// 				setidledata(res.idlearr);
	// 				setcountdata(res.actinfo);
	// 				setchart1(res.totalworkingchart);
	// 				setchart2(res.totalidlechart);
	// 				setchart3(res.totalprodchart);
	// 			} else {
	// 				setattdata([
	// 					{
	// 						date: "No data Available",
	// 					},
	// 				]);
	// 				setcountdata([]);
	// 				setidledata([
	// 					{
	// 						employee: "No data Available",
	// 					},
	// 				]);
	// 				setchart1([]);
	// 				setchart2([]);
	// 				setchart3([]);
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));

	// 	setLoading(false);
	// };

	const handledate = (e) => {
		onChange(e);
		getData(
			username,
			format(new Date(e[0]), "yyyy-MM-dd"),
			format(new Date(e[1]), "yyyy-MM-dd")
		);
	};

	const getusername = (e) => {
		setusername(e.value);
		getData(
			e.value,
			format(new Date(value[0]), "yyyy-MM-dd"),
			format(new Date(value[1]), "yyyy-MM-dd")
		);
	};

	const getUserData = async (org = "") => {
		// setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-getAllUsers/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				// department: JSON.parse(localStorage.getItem("isAuth"))
				// 	.department,
				department: "",
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setuserdata(res.data);
					let data4 = res.alluser ? res.alluser : [];
					setalluser(data4);
				}
			})
			.catch((err) => console.log(err));
	};

	const filterByReference = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return !arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};

	const filterByReference2 = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};
	let filterarr = filterByReference(userdata, alluser);
	let filterarr2 = filterByReference2(userdata, alluser);

	if (filterarr2 && filterarr2.length > 0) {
		for (let i = 0; i < filterarr2.length; i++) {
			filterarr2[i].active2 = true;
		}
	}

	let finalarr3 = filterarr2.concat(filterarr);

	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < finalarr3.length; i++) {
		data.push({
			value: finalarr3[i].username,
			label: finalarr3[i].name,
			active: finalarr3[i].active2 ? "🟢" : "🔴",
		});
	}

	// const getBirthdayData = async () => {
	// 	setLoading(true);
	// 	setError("");

	// 	try {
	// 	  const authData = JSON.parse(localStorage.getItem("isAuth"));
	// 	  if (!authData || !authData.usertoken) {
	// 		throw new Error("No authenticated user");
	// 	  }

	// 	  const payload = {
	// 		month: "01",
	// 		year: "2025",
	// 		uuid: authData.companydata[0].uuid,
	// 		department: authData.department,
	// 	  };

	// 	  const config = {
	// 		headers: {
	// 		  Authorization: `Bearer ${authData.usertoken}`,
	// 		  "Content-Type": "application/json",
	// 		},
	// 	  };

	// 	  const response = await axios.post(
	// 		BASEURL + "get-birthdaydata/",
	// 		payload,
	// 		config
	// 	  );

	// 	  if (response.status === 200) {
	// 		setBirthday(response.data);
	// 		console.log("Birthday" + response.data)
	// 	  } else {
	// 		throw new Error("Failed to fetch birthday data");
	// 	  }
	// 	} catch (error) {
	// 	  setError(error.message || "Something went wrong");
	// 	} finally {
	// 	  setLoading(false);
	// 	}
	//   };

	const getBirthdayData = async ({
		year = "",
		month = "",
		org = "",
	} = {}) => {
		setLoading(true);

		// Fallback to current month and year if not provided
		const pmonth = month === "" ? format(new Date(), "MM") : month;
		const pyear = year === "" ? format(new Date(), "yyyy") : year;

		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== null) {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(BASEURL + `get-birthdaydata/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					month: pmonth,
					year: pyear,
					uuid: org !== "" ? org : selectedorg,
					usertype: userdata?.type,
					department: userdata?.department,
				}),
			});

			const res = await response.json();

			if (res.status === 201) {
				// Sort birthdays by day of the month
				const compare = (a, b) => {
					const dayA = new Date(a.dob).getDate();
					const dayB = new Date(b.dob).getDate();
					return dayA - dayB;
				};

				setbdaydata(res.data.sort(compare));
			} else {
				setbdaydata([]);
			}
		} catch (error) {
			console.error("Error fetching birthday data:", error);
		} finally {
			setLoading(false);
		}
	};

	// useEffect(() => {
	// 	getData(
	// 		username,
	// 		format(new Date(value[0]), "yyyy-MM-dd"),
	// 		format(new Date(value[1]), "yyyy-MM-dd")
	// 	);
	// }, []);
	useEffect(() => {
		getBirthdayData();
		getHolidayData();
	}, []);

	const getHolidayData = async ({ year = "", month = "", org = "" } = {}) => {
		setLoading(true);

		let pmonth;
		if (year === "") {
			pmonth = format(new Date(), "MM");
		} else {
			pmonth = month;
		}
		let pyear;
		if (year === "") {
			pyear = format(new Date(), "yyyy");
		} else {
			pyear = year;
		}

		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-holidaydata/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				month: pmonth,
				year: pyear,
				uuid: org !== "" ? org : selectedorg,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setholidaydata(res.data);
				} else {
					setholidaydata([]);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	return (
		<>

			{type === 2 && (
				<div className="col-span-12 lg:col-span-12 mt-1 tblborder">
					<div className="intro-y block sm:flex items-center h-10">
						{!hasOptedOut && (
							<button
								className="btn btn-primary"
								onClick={handleRowClick}
								style={{ borderRadius: "5px" }}
							>
								Opting out from Exercise Session?
							</button>
						)}
					</div>
				</div>
			)}

			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 2xl:col-span-12">
					<div className="intro-y flex flex-col md:flex-row items-center mt-2">
						<div className="intro-y block w-full sm:w-auto sm:ml-auto sm:mt-0">
							<div className="flex flex-col sm:flex-row sm:items-center gap-2 mb-2 mt-2">
								<div className="flex flex-wrap items-center gap-2">
									{master === 1 && (
										<Select
											value={orgval}
											className="basic-single"
											onChange={(e) => {
												getorg(e);
											}}
											options={orgs}
											styles={{
												option: (base) => ({
													...base,
													display: "flex",
													textAlign: "left",
												}),
											}}
										/>
									)}
									{type !== 2 && (
										<Select
											defaultValue={data[0]}
											className="basic-single text-center"
											onChange={(e) => {
												getusername(e);
											}}
											options={data}
											getOptionLabel={(option) =>
												` ${option.active} ${option.label}`
											}
											styles={{
												option: (base) => ({
													...base,
													display: "flex",
												}),
											}}
										/>
									)}
									{/* <DateRangePicker
										clearIcon=""
										calendarIcon={
											<FaCalendar
												style={{
													color: "rgb(30 64 175)",
												}}
											/>
										}
										className="desktop-datepicker"
										onChange={handledate}
										value={value}
									/> */}
									<DateRangePicker
										clearIcon=""
										calendarIcon={
											<FaCalendar
												style={{
													color: "rgb(30 64 175)",
												}}
											/>
										}
										className="desktop-datepicker"
										onChange={handledate}
										value={value}
									/>

									<button className="btn btn-default">
										<img
											width={20}
											onClick={reloadFun}
											src={reload}
											alt="reload icon"
										/>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-12 gap-6">
						<div className="col-span-12 mt-8 ">
							<div className="">
								{loading ? (
									<div className="text-center m-5 p-5">
										<HashLoader
											color="#5755d9"
											size={30}
											style={{
												position: "absolute",
												right: "42%",
											}}
										/>
									</div>
								) : (
									<div className="lg:flex">
										<div className="box boxaddon">
											<div
												className="text-primary dark:text-slate-200 text-md xl:text-md font-medium flex  px-5"
											// style={{ justifyContent: "" ,width:"100%" }}
											>
												<span>Total Working</span>
												{/* <span>
                          <BsBriefcase />
                        </span> */}
											</div>
											<div>
												{countdata &&
													countdata.totalworking
													? countdata.totalworking
													: "00:00:00 "}{" "}
												hh:mm:ss
											</div>
										</div>
										<div className={"box boxaddon"}>
											<div
												className="text-primary dark:text-slate-200 text-md xl:text-md font-medium px-4 flex flex-col lg:flex-row lg:items-center flex "
											// style={{
											//   justifyContent: "space-between",
											//   color: "#5bdba1",
											//   width:"100%"
											// }}
											>
												<span>Total Productive</span>
												{/* <span className="justify-end">
                          <BsGraphUp />
                        </span> */}
											</div>
											<div className="mt-0.5 text-slate-500">
												{countdata &&
													countdata.totalprod
													? countdata.totalprod
													: "00:00:00 "}{" "}
												hh:mm:ss
											</div>
										</div>
										<div className={"box boxaddon"}>
											<div
												className="text-primary dark:text-slate-200 text-md xl:text-md font-medium px-5 flex flex-col lg:flex-row lg:items-center flex"
											// style={{
											//   justifyContent: "space-between",
											//   color: "red",
											//   width:"100%"
											// }}
											>
												<span>Total Idle</span>
												{/* <span>
                          <BsGraphDown />
                        </span> */}
											</div>
											<div className="mt-0.5 text-slate-500">
												{countdata &&
													countdata.totalidle
													? countdata.totalidle
													: "00:00:00 "}{" "}
												hh:mm:ss
											</div>
										</div>
										<div className={"box boxaddon"}>
											<div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
												Avg. Working
											</div>
											<div className="mt-0.5 text-slate-500">
												{countdata &&
													countdata.avgtotalworking
													? countdata.avgtotalworking
													: "00:00:00 "}{" "}
												hh:mm:ss
											</div>
										</div>
										<div className={"box boxaddon"}>
											<div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
												Avg. Productive
											</div>
											<div className="mt-0.5 text-slate-500">
												{countdata &&
													countdata.avgtotalprod
													? countdata.avgtotalprod
													: " 00:00:00 "}{" "}
												hh:mm:ss
											</div>
										</div>
										<div className={"box boxaddon"}>
											<div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
												Avg. Idle
											</div>
											<div className="mt-0.5 text-slate-500">
												{countdata &&
													countdata.avgtotalidle
													? countdata.avgtotalidle
													: " 00:00:00 "}{" "}
												hh:mm:ss
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="col-span-12">
							<div className="grid grid-cols-12 gap-6">
								<div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
									<div className="intro-y box p-5 text-left">
										<h2
											style={{
												fontSize: "15px",
												fontWeight: 600,
											}}
										>
											Productive Time
										</h2>
										<div className="overflow-x-auto scrollbar-hidden">
											{loading ? (
												<div className="text-center m-5 p-5">
													<HashLoader
														color="#5755d9"
														size={30}
														style={{
															position:
																"absolute",
															right: "50%",
														}}
													/>
												</div>
											) : chart3 && chart3.length > 0 ? (
												<SummaryChart
													attdata={chart3}
													type="Productive Time"
												></SummaryChart>
											) : (
												"No data available"
											)}
										</div>
									</div>
								</div>
								<div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
									<div className="intro-y box p-5 text-left">
										<h2
											style={{
												fontSize: "15px",
												fontWeight: 600,
											}}
										>
											Idle Time
										</h2>

										<div className="overflow-x-auto scrollbar-hidden">
											{loading ? (
												<div className="text-center m-5 p-5">
													<HashLoader
														color="#5755d9"
														size={30}
														style={{
															position:
																"absolute",
															right: "50%",
														}}
													/>
												</div>
											) : chart2 && chart2.length > 0 ? (
												<SummaryChart
													attdata={chart2}
													type="Idle Time"
												></SummaryChart>
											) : (
												"No data available"
											)}
										</div>
									</div>
								</div>

								{isAuth.role === 0 && (
									<div className="col-span-12 ">
										<div className="intro-y box p-5 text-left">
											<h2
												style={{
													fontSize: "15px",
													fontWeight: 600,
												}}
											>
												Unproductive Time
											</h2>
											<div className="intro-y box">
												<div className="overflow-x-auto scrollbar-hidden">
													{loading ? (
														<div className="text-center m-5 p-5">
															<HashLoader
																color="#5755d9"
																size={30}
																style={{
																	position:
																		"absolute",
																	right: "50%",
																}}
															/>
														</div>
													) : (
														<ReactTabulator
															style={{
																width: "100%",
															}}
															columns={
																idlecolumns
															}
															data={idledata}
															options={options}
															className="mt-5 table-report table-report--tabulator"
														/>
													)}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="col-span-12">
							<div className="grid grid-cols-12 gap-6">
								<div className="col-span-12 sm:col-span-12 xl:col-span-12 intro-y">
									<div className="intro-y box p-2 text-left">
										<h2
											style={{
												fontSize: "15px",
												fontWeight: 600,
											}}
											className="pt-4 pl-2"
										>
											Employee Productivity
										</h2>
										<div className="overflow-x-auto scrollbar-hidden">
											{loading ? (
												<div className="text-center m-5 p-5">
													<HashLoader
														color="#5755d9"
														size={30}
														style={{
															position:
																"absolute",
															right: "50%",
														}}
													/>
												</div>
											) : (
												// <ReactTabulator
												// 	columns={columns}
												// 	data={attdata}
												// 	options={options}
												// 	className=" table-report table-report--tabulator"
												// />
												<table
													style={{
														width: "100%",
														borderCollapse:
															"collapse",
														margin: "20px 0",
													}}
												>
													<thead
														style={{
															backgroundColor:
																"#1e40af",
														}}
													>
														<tr>
															<th
																style={{
																	border: "1px solid #ddd",
																	padding:
																		"8px",
																	color: "white", // To make the text color white for better contrast
																}}
															>
																Date
															</th>
															<th
																style={{
																	border: "1px solid #ddd",
																	padding:
																		"8px",
																	color: "white", // To make the text color white for better contrast
																}}
															>
																Working Time
															</th>
															<th
																style={{
																	border: "1px solid #ddd",
																	padding:
																		"8px",
																	color: "white", // To make the text color white for better contrast
																}}
															>
																Idle Time
															</th>
															<th
																style={{
																	border: "1px solid #ddd",
																	padding:
																		"8px",
																	color: "white",
																}}
															>
																Productive Time
															</th>
															<th
																style={{
																	border: "1px solid #ddd",
																	padding:
																		"8px",
																	color: "white",
																}}
															>
																Productive %
															</th>
														</tr>
													</thead>
													<tbody>
														{attdata &&
															attdata.length > 0 ? (
															attdata.map(
																(
																	item,
																	index
																) => (
																	<tr
																		key={
																			index
																		}
																	>
																		<td
																			style={{
																				border: "1px solid #ddd",
																				padding:
																					"8px",
																			}}
																		>
																			{
																				item.date
																			}
																		</td>
																		<td
																			style={{
																				border: "1px solid #ddd",
																				padding:
																					"8px",
																			}}
																		>
																			{
																				item.working
																			}
																		</td>
																		<td
																			style={{
																				border: "1px solid #ddd",
																				padding:
																					"8px",
																			}}
																		>
																			{
																				item.idletime
																			}
																		</td>
																		<td
																			style={{
																				border: "1px solid #ddd",
																				padding:
																					"8px",
																			}}
																		>
																			{
																				item.prod
																			}
																		</td>
																		<td
																			style={{
																				border: "1px solid #ddd",
																				padding:
																					"8px",
																			}}
																		>
																			<div
																				className="progress-container"
																				style={{
																					width: "100%",
																					backgroundColor:
																						"#ddd",
																					borderRadius:
																						"5px",
																					height: "10px",
																					position:
																						"relative",
																				}}
																			>
																				<div
																					className="progress-bar"
																					style={{
																						width: `${item.prodper}%`,
																						backgroundColor:
																							"#1e40af", // Color for progress bar
																						borderRadius:
																							"5px",
																						height: "100%",
																					}}
																				></div>
																			</div>
																			<span
																				style={{
																					display:
																						"block",
																					marginTop:
																						"5px",
																				}}
																			>
																				{
																					item.prodper
																				}

																				%
																			</span>
																		</td>
																	</tr>
																)
															)
														) : (
															<tr>
																<td
																	colSpan="5"
																	style={{
																		textAlign:
																			"center",
																		padding:
																			"8px",
																	}}
																>
																	No data
																	available
																</td>
															</tr>
														)}
													</tbody>
												</table>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="dark:text-slate-200">
				<div className="dark:text-slate-200">
					<div
						className="flex flex-wrap justify-between mt-5 dark:text-slate-200"

					>
						<div className="birthday-holiday-section">
						
							<div className="w-full lg:w-1/2 pr-4" style={{ backgroundColor: "white" }}>
								<div className="box border border-gray-300 rounded-lg p-4">
									<div>
										<h2
											style={{
												fontSize: "15px",
												fontWeight: 600,
											}}
											className="pt-4 pl-2 text-left ml-2"
										>
											Birthday
										</h2>
									</div>
									<div className="w-full overflow-auto">
										
									</div>
								</div>
							</div>

							
							<div className="w-full lg:w-1/2 pl-4">
								<div className="box border border-gray-300 rounded-lg p-4">
									<h2
										style={{
											fontSize: "15px",
											fontWeight: 600,
										}}
										className="pt-4 pl-2 text-left ml-2"
									>
										Holiday
									</h2>
									
								</div>
							</div>
						</div>

					</div>
				</div>
			</div> */}
		</>
	);
};

export default Summary;
