import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import { NativeSelect } from "@mui/material";
const Departments = () => {
	const [showTicket, setShowTicket] = useState([]);
	const [attdata, setattdata] = useState(null);
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState([]);
	const [userdata, setuserdata] = useState([]);
	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
	useEffect(() => {
		fetchData();
		fetchTickets();
	}, []);

	useEffect(() => {
		const endOffset = itemOffset + 20;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 20));
	}, [itemOffset, items]);

	const getUserData = async (e = "") => {
		let isactive = 1;
		if (e !== "") {
			isactive = e.target.value;
		}
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-Employee/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				master,
				isactive,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					setuserdata(res.data);
				}

				console.log("response from the user data" + userdata);
				console.log("response from the user data" + userdata);
				console.log("response from the user data" + userdata);
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};
	useEffect(() => {
		getUserData();
	}, []);

	const handleApprove = async (
		id,
		punchInTime,
		punchOutTime,
		date,
		selectedUsername,
		companyUuid,
		user_department,
		user_type
	) => {
		console.log("Received Date:", date);

		if (!date || isNaN(new Date(date))) {
			swal("Error", "Invalid or missing date. Cannot approve this ticket.", "error");
			return;
		}

		const formattedDate = format(new Date(date), "yyyy-MM-dd");
		console.log("Formatted Date:", formattedDate);

		try {
			const authData = JSON.parse(localStorage.getItem("isAuth"));
			const userToken = authData?.usertoken;

			const response = await fetch(
				`${BASEURL}admin-update-ticket-after-office/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: userToken,
					},
					body: JSON.stringify({
						action: "accept",
						punch_in_time: punchInTime || "00:00:00",
						punch_out_time: punchOutTime || "00:00:00",
						username: selectedUsername,
						company_uuid: companyUuid,
						date: formattedDate,
						department: user_department || 0,
						role: user_type || 0,
					}),
				}
			);

			const result = await response.json();

			if (response.status === 200) {
				swal("Success", result.message, "success");
				fetchTickets(); // Refresh the list
			} else {
				throw new Error(result.error || "Failed to approve ticket");
			}
		} catch (error) {
			console.error("Error:", error);
			swal("Error", error.message, "error");
		}
	};

	const handleReject = async (id) => {
		const willReject = await swal({
			title: "Are you sure?",
			text: "Do you want to reject this after-office hours ticket?",
			icon: "warning",
			buttons: {
				cancel: {
					text: "Cancel",
					value: null,
					visible: true,
					className: "swal-button-cancel",
				},
				confirm: {
					text: "Reject",
					value: true,
					visible: true,
					className: "swal-button-reject",
				},
			},
			className: "custom-swal",
			dangerMode: true,
		});

		if (!willReject) {
			return;
		}

		setLoading(true);

		try {
			const authData = JSON.parse(localStorage.getItem("isAuth"));
			const userToken = authData?.usertoken;

			const response = await fetch(
				`${BASEURL}admin-update-ticket-after-office/${id}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: userToken,
					},
					body: JSON.stringify({ action: "reject" }),
				}
			);
			const result = await response.json();
			if (response.status === 200) {
				swal("Rejected", result.message, "success");
				fetchTickets();
			} else {
				swal(
					"Error",
					result.error || "Failed to reject ticket",
					"error"
				);
			}
		} catch (error) {
			console.error("Error rejecting ticket:", error);
			swal("Error", "An error occurred while processing the request", "error");
		}
		setLoading(false);
	};
	const getStatusText = (status) => {
		switch (status) {
			case 0:
				return "Pending";
			case 1:
				return "Resolved";
			case 2:
				return "Rejected";
			default:
				return "Unknown";
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case 0:
				return "badge bg-warning";
			case 1:
				return "badge bg-success";
			case 2:
				return "badge bg-danger";
			default:
				return "badge bg-secondary";
		}
	};

	const fetchData = async () => {
		setLoading(true);
		// Fetch data for "All Tickets"
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			myHeaders.append("Authorization", userdata.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		// await fetch(BASEURL + `show-emp-ticket`, {
		await fetch(BASEURL + `show-emp-ticket-after-office`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 0,
				uuid: userdata.companydata[0].uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					const keys = Object.keys(res.data);
					setItems(keys);
					setCurrentItems(keys.slice(0, 20));
					setPageCount(Math.ceil(keys.length / 20));
					setattdata(res.data);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const fetchTickets = async () => {
		setLoading(true);
		// Fetch data for "Employee Tickets"
		await fetch(BASEURL + `show-emp-ticket-after-office`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				username: JSON.parse(localStorage.getItem("isAuth")).username,
				role: JSON.parse(localStorage.getItem("isAuth")).role,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				setShowTicket(res.data);
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const handlePageClick = (event) => {
		const newOffset = (event.selected * 20) % items.length;
		setItemOffset(newOffset);
	};

	return (
		<div className="intro-y ">
			<div className="mt-2">
				<div
					style={{
						// padding: "10px",
						borderTopLeftRadius: "10px",
						borderTopRightRadius: "10px",
					}}
				>
					<div
						style={{
							backgroundColor: "#FC9A30",
							color: "#fff",
							padding: "10px",
							textAlign: "start",
							borderTopLeftRadius: "10px",
							borderTopRightRadius: "10px",
						}}
					>
						<h3>
							Employees Ticket After Office HRS
						</h3>
					</div>
					<div className="table-outlineBorder" style={{
						border: "1px sold #FC9A30 !important",
					}}>
						<div className="employee-table-container">
							<table
								className="employee-table"
								style={{ width: "100%" }}
							>
								<thead>
									<tr>
										<th className="whitespace-nowrap">Date</th>
										<th className="whitespace-nowrap">Name</th>
										<th className="whitespace-nowrap">
											Reason
										</th>
										<th>Punch Start Time</th>
										<th>Punch End Time</th>
										<th className="whitespace-nowrap">
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{showTicket?.length === 0 ? (
										<tr>
											<td colSpan="11">No data available</td>
										</tr>
									) : (
										showTicket?.map((ticket) => (
											<tr key={ticket.id}>
												<td className="text-left">
													{new Date(
														ticket.date
													).toLocaleDateString()}
												</td>
												<td className="text-left">{ticket.user_name}</td>
												<td className="text-left">{ticket.reason}</td>
												<td>{ticket.punch_in_time}</td>
												<td>{ticket.punch_out_time}</td>

												<td className="text-left">
													<NativeSelect

														defaultValue={
															ticket?.status === 0
																? "On Hold"
																: ticket?.status === 1
																	? "Approved"
																	: ticket?.status === 2
																		? "Reject"
																		: "On Hold"
														}
														inputProps={{
															name: "age",
															id: "uncontrolled-native",
														}}
														className="border-0 bg-transparent focus:outline-none w-32"
														onChange={(e) => {
															const selectedValue = e.target.value;
															if (selectedValue === "Approved") {
																// handleApprove(
																// 	ticket.id,
																// 	ticket.punch_start_time,
																// 	ticket.punch_end_time,
																// 	// ticket.date,
																// 	ticket.date || new Date().toISOString().split("T")[0],
																// 	ticket.username,
																// 	JSON.parse(localStorage.getItem("isAuth")).companydata[0]?.uuid,
																// 	ticket.user_department,
																// 	ticket.user_type
																// );
																handleApprove(ticket.id, ticket.punch_in_time, ticket.punch_out_time, ticket.date, ticket.username, JSON.parse(localStorage.getItem("isAuth")).companydata[0]?.uuid, ticket.department, ticket.role);
															} else if (selectedValue === "Reject") {
																handleReject(ticket.id);
															}
														}}
													>
														<option value="Approved" className="hover:bg-yellow-300 text-[#24A184]">
															Approved
														</option>
														<option value="On Hold" className="hover:bg-yellow-300 text-[#FC9A30]">
															On Hold
														</option>
														<option value="Reject" className="hover:bg-yellow-300 text-[#FB7474]">
															Reject
														</option>
													</NativeSelect>
												</td>
											</tr>
										)))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default Departments;
